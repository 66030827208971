import React from 'react';
import PropTypes from 'prop-types';
import { Button } from '@mui/material';
import { useTranslation } from 'react-i18next';

ImportExampleField.propTypes = {
    example: PropTypes.string.isRequired,
    loadExample: PropTypes.func
}
export default function ImportExampleField({example,loadExample}){
    const {t} = useTranslation();
    return <div>
        <h5 style={{marginBottom: '25px'}}>{t('import_example_field_title')}: <Button style={{float: "right"}} color="primary" variant="contained" onClick={loadExample} size="small">{t('import_example_field_btn_load')}</Button></h5>
        <pre onClick={loadExample}>{example}</pre>
    </div>
}