import { gpxError } from './parserErrors';
import * as constants from '../constants';

var tj = require('@mapbox/togeojson');
var DOMParser = require('xmldom').DOMParser;

export default function parseGpx(string){
    if(string.indexOf('<gpx')<= -1) throw new gpxError(constants.ERROR_IMPORT_GPX_TAG);
    let gpx = new DOMParser(); 
    const x = tj.gpx(gpx.parseFromString(string));
    return x;
}