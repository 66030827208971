import React from "react";
import { Link } from "react-router-dom";
import ExternalLink from "@mui/material/Link";

import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import MenuItem from "@mui/material/MenuItem";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export default function BevMenuListItem({
  url,
  icon,
  label,
  handleClick,
  children,
  isActive,
  isLast,
  cssStyle,
  newTab,
  external,
}) {
  const baseProps = { label, children };
  baseProps.className = "menu-item" + (isActive ? " active" : "") + (isLast ? " last" : "");
  baseProps.newTab = newTab ? "_blank" : "_self";
  baseProps.icon = !!icon && (
    <ListItemIcon className={"menu-icon"}>
      <FontAwesomeIcon icon={icon} fixedWidth style={cssStyle} />
    </ListItemIcon>
  )
  
  const isUrl = !!url && !handleClick;
  const isClick = !url && !!handleClick;

  if (isUrl && !external) return <LinkUrl {...baseProps} linkProps={{to:url, component:Link}} />;
  if (isUrl && !!external) return <LinkUrl {...baseProps} linkProps={{href:url, component:ExternalLink}}/>;
  if (isClick) return <LinkClick {...baseProps} handleClick={handleClick} />;
  return <LinkDisplay {...baseProps} />;
}

const LinkUrl = ({ label, className, icon, children, newTab, linkProps }) => {
  return (
    <MenuItem className={className} target={newTab} {...linkProps}>
      {icon}
      <ListItemText className={"menu-label"} inset primary={label} />
      {!!children && children}
    </MenuItem>
  );
};

const LinkClick = ({ label, className, handleClick, icon, children }) => {
  return (
    <MenuItem className={className} onClick={handleClick}>
      {icon}
      <ListItemText className={"menu-label"} inset primary={label} />
      {!!children && children}
    </MenuItem>
  );
};

const LinkDisplay = ({ label, className, icon, children }) => {
  return (
    <MenuItem className={className}>
      {icon}
      <ListItemText className={"menu-label"} inset primary={label} />
      {!!children && children}
    </MenuItem>
  );
};
