
export default function cleanUpGeoJsonCoordinates(geoJson) {
  geoJson.features = checkGeometryFeatureCollection(geoJson.features);
  return geoJson;
}

function checkGeometryFeatureCollection(features){
  for(let feature of features){
    let { geometry } = feature;
    if(feature.type === 'Feature'){
      const { type }  = geometry
      if(type === 'Point'){
        geometry = checkGeometryPoint(geometry);
      }else if(['MultiPoint','LineString'].indexOf(type) > -1){
        geometry = checkGeometryMultiPoint(geometry);
      }else if(['MultiLineString','Polygon'].indexOf(type) > -1){
        geometry = checkGeometryMultiLineString(geometry)
      }else if(type === 'MultiPolygon'){
        geometry = checkGeometryMultiPolygon(geometry)
      }
    }else if(feature.type === 'FeatureCollection'){;
      checkGeometryFeatureCollection(feature.features);
    }else{
      throw new Error('Etwas ist bei diesen Feature schief gegenagen: ' + feature )
    }
  }
  return features
}

function checkGeometryCoorrdinatesPoint(coordinates){
  if(coordinates.length < 3 && coordinates.length > 1){
    coordinates.push(0);
  }
  return [...coordinates];
}

function checkGeometryPoint(geometry){
  const {coordinates, velocities} = geometry;
  geometry.coordinates = checkGeometryCoorrdinatesPoint(coordinates);
  if(!!velocities) geometry.velocities = checkGeometryCoorrdinatesPoint(velocities);
  return geometry;
}

function checkGeometryMultiPoint(geometry){
  const {coordinates, velocities} = geometry;
  for(let pointCoordinates of coordinates){
    pointCoordinates = checkGeometryCoorrdinatesPoint(pointCoordinates);
  } 
  if(!!velocities){
    for(let pointVelocities of velocities){
      pointVelocities = checkGeometryCoorrdinatesPoint(pointVelocities);
    } 
  }
  return geometry
}

function checkGeometryMultiLineString(geometry){
  const {coordinates, velocities} = geometry;
  for(let line of coordinates){
    for(let point of line){
      point = checkGeometryCoorrdinatesPoint(point);
    }
  }
  if(!!velocities){
    for(let line of velocities){
      for(let point of line){
        point = checkGeometryCoorrdinatesPoint(point);
      }
    }
  }
  return geometry
}

function checkGeometryMultiPolygon(geometry){
  const {coordinates, velocities} = geometry;
  for(let polygon of coordinates){
    for(let line of polygon){
      for(let point of line){
        point = checkGeometryCoorrdinatesPoint(point);
      }
    }
  }
  if(!!velocities){
    for(let polygon of velocities){
      for(let line of polygon){
        for(let point of line){
            point = checkGeometryCoorrdinatesPoint(point);
        }
      }
    }
  }

  return geometry
}