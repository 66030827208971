import {parseGeoJsonToCsv} from './csvParser';

const types = {
    csv: 'text/csv',
    gpx: 'application/gpx+xml',
    kml: 'application/vnd.google-earth.kml+xml',
    json: 'application/json'
}

const endings = {
    csv: '.csv',
    gpx: '.gpx',
    kml: '.kml',
    json: '.json'
}

const tokml = require('tokml');
const togpx = require('togpx');

export const getFile = (string,type) => {
    return new Blob([parseToString(string,type)], {type: types[type]});
}

export const getFileName = (name,type) => {
    return name + endings[type];
}

export const parseToString = (file,type) => {
    let output;
    if(type === 'json') output = JSON.stringify(file,null,1);
    else if(type === 'csv') output = parseGeoJsonToCsv(file);
    else if(type === 'gpx') output = togpx(file);
    else if(type === 'kml') output = tokml(file);
    return output;
}
export default parseToString;
