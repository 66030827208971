import React, { Component } from "react";
import { connect } from "react-redux";
import axios from "axios";

import * as constants from "common/constants.js";
import {WIKI_LINK_AUSTIRAN}from "common/constants.js";
import { AustrianExample } from "common/modeExamples";
import * as actions from "store/actions";

import BevSolutionCard from "components/BevSolutionCard";
import ExtendedTitleCard from "components/ExtendedTitleCard";

import { getCoordType } from "common/utility";
import errorHandler from "common/errorHandler";

import ParameterInputCard from "./ParameterInputCard";
import PointSourceCard from "./PointSourceCard";
import { isSameSystem, checkIfTransformReady } from "./AustrianHelperFunctions";
import { CheckGeoJsonMGICoordinatsForCadastre } from "common/GeoJsonManipulator";
import { withTranslation } from "react-i18next";

const CLEAR_STATE = {
  tabindex: 1,
  showSolution: false,
  openSolution: false,
  showPoints: false,
  openPoints: true,
  openParams: true,
  sourceHeight: false,
  targetHeight: false,
  loadedGeoJson: null,
};

const EMPTY_PROPS = {
  singlePoint: {
    x: "",
    y: "",
    z: "",
    vx: "",
    vy: "",
    vz: "",
  },
  pointList: {},
};

const mapStateToProps = (state) => {
  return {
    crs: state.austrian.crs,

    sourceCRS: state.austrian.sourceCRS,
    sourceHeight: state.austrian.sourceHeight,
    targetCRS: state.austrian.targetCRS,
    targetHeight: state.austrian.targetHeight,
    transformationMethod: state.austrian.transformationMethod,

    config: state.auth.config,
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    changeCRS: (event, key) =>
      dispatch(actions.austrian.changeAutValue(key, event.target.value)),
    changeCRSProp: (value, key) =>
      dispatch(actions.austrian.changeAutValue(key, value)),
    updateProp: (type, value) =>
      dispatch(actions.points.updateProp(type, value)),
    cleanInput: () => dispatch(actions.points.cleanInput()),
  };
};

class Austrian extends Component {
  state = { ...CLEAR_STATE };

  clearPage = () => {
    this.setState(CLEAR_STATE);
    this.props.changeCRSProp("", "transformationMethod");
    this.props.changeCRSProp("", "sourceCRS");
    this.props.changeCRSProp("", "targetCRS");
    this.props.changeCRSProp("", "sourceHeight");
    this.props.changeCRSProp("", "targetHeight");
    this.props.updateProp("sourcePoints", EMPTY_PROPS);
  };

  componentDidMount() {
    const { sourceCRS, targetCRS } = this.props;
    this.checkHeightSystem(sourceCRS, "sourceCRS");
    this.checkHeightSystem(targetCRS, "targetCRS");
  }

  componentWillUnmount() {
    this.props.cleanInput();
  }

  onChangeHandleTransformationMethod = (e, value) => {
    this.props.changeCRSProp(value, "transformationMethod");
  };

  checkHeightSystem = (value, target) => {
    const { crs } = this.props;
    const checkSystem =
      value === "" || getCoordType(crs[value]) === "geocentric";
    const state = target === "sourceCRS" ? "sourceHeight" : "targetHeight";
    this.setHeightDisabled(state, checkSystem);
  };

  setHeightDisabled = (state, check) => {
    this.setState({ [state]: check });
    if (check) this.props.changeCRSProp("", state);
  };

  changeCRS = (target, e) => {
    const { value } = e.target;
    this.props.changeCRS(e, target);
    this.checkHeightSystem(value, target);
    this.checkTransfromationMethod(value, target);
  };

  checkTransfromationMethod = (value, target) => {
    const { targetCRS, sourceCRS } = this.props;
    if (target === "sourceCRS" && isSameSystem(value, targetCRS))
      this.props.changeCRSProp("", "transformationMethod");
    else if (target === "targetCRS" && isSameSystem(sourceCRS, value))
      this.props.changeCRSProp("", "transformationMethod");
  };

  startTransformation = (f, name) => {
    const {
      sourceCRS,
      targetCRS,
      targetHeight,
      sourceHeight,
      transformationMethod,
    } = this.props;
    let geoJson = JSON.parse(JSON.stringify(f));
    if (
      sourceCRS.toLowerCase() === "mgi_gk28" ||
      sourceCRS.toLowerCase() === "mgi_gk31" ||
      sourceCRS.toLowerCase() === "mgi_gk34"
    ) {
      geoJson = CheckGeoJsonMGICoordinatsForCadastre(
        JSON.parse(JSON.stringify(geoJson))
      );
    }
    let req = {
      geojson: geoJson,
      source_crs: sourceCRS,
      target_crs: targetCRS,
      target_height_system: targetHeight !== "" ? targetHeight : null,
      source_height_system: sourceHeight !== "" ? sourceHeight : null,
      transformation_method:
        transformationMethod !== "" ? transformationMethod : null,
    };
    axios
      .post(constants.TRANSFORMATOR_API_URL_AUT, req, this.props.config)
      .then((res) => {
        let x = res.data.result.points;
        x.name = name;

        this.setState({
          openSolution: true,
          showSolution: true,
          openPoints: false,
          openParams: false,
          solutionComputed: true,
          solution: x,
        });
      })
      .catch(errorHandler);
  };

  toggleOpenPoints = () => {
    this.setState((prevState) => {
      return { openPoints: !prevState.openPoints };
    });
  };
  toggleOpenParams = () => {
    this.setState((prevState) => {
      return { openParams: !prevState.openParams };
    });
  };
  toggleOpenSolution = () => {
    this.setState((prevState) => {
      return { openSolution: !prevState.openSolution };
    });
  };
  handleChangeTabIndex = (i) => {
    this.setState((prevState) => {
      return i;
    });
  };

  loadExample = () => {
    const example = AustrianExample;
    this.setState({ loadedGeoJson: null }, () => {
      Object.keys(example).forEach((key) => {
        if (key !== "geoJson") this.props.changeCRSProp(example[key], key);
        else this.setState({ loadedGeoJson: example[key] });
        if (key === "sourceCRS" || key === "targetCRS")
          this.checkHeightSystem(example[key], key);
      });
    });
  };

  render() {
    const {
      sourceHeight,
      targetHeight,
      openParams,
      openPoints,
      openSolution,
      solution,
      showSolution,
      solutionComputed,
    } = this.state;
    const { tabindex, loadedGeoJson } = this.state;
    const { transformationMethod, t } = this.props;

    return (
      <div>
        <ExtendedTitleCard
          clearPage={this.clearPage}
          loadExample={this.loadExample}
          wikiLink={WIKI_LINK_AUSTIRAN}
          title={t("austrian_title_card_title")}
          description={t("austrian_title_card_text")}
        />
        <ParameterInputCard
          openParams={openParams}
          transformation_method={transformationMethod}
          isTargetHeightDisabled={targetHeight}
          isSourceHeightDisabled={sourceHeight}
          changeCRS={this.changeCRS}
          onChangeHandleTransformationMethod={
            this.onChangeHandleTransformationMethod
          }
          toggleOpenParams={this.toggleOpenParams}
        />
        <PointSourceCard
          tabindex={tabindex}
          openPoints={openPoints}
          velocities={false}
          toggleOpenPoints={this.toggleOpenPoints}
          startTransformation={this.startTransformation}
          handleChangeTabIndex={this.handleChangeTabIndex}
          loadedGeoJson={loadedGeoJson}
        />
        <BevSolutionCard
          isDisplayed={showSolution && checkIfTransformReady(this.props)}
          isOpen={openSolution}
          isComputed={solutionComputed}
          solution={solution}
          hasProtokoll={false}
          toggleOpen={this.toggleOpenSolution}
        />
      </div>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(Austrian));
