import Kataster from './kataster';

export class Store { 
    kataster; 

    constructor() {
        this.kataster = new Kataster();
    }
}

export const store = new Store();