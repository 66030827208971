export function Admin(g) {
    if (g.indexOf("admin")>-1) {
        return true;
    } else {
        return false;
    }
}


export function Manager(g) {
    if (Admin(g)) { return true; }
    if (g.indexOf("manager")>-1) {
        return true;
    } else {
        return false;
    }
}

export function User(g) {
    if (Admin(g)) { return true; }
    if (Manager(g)) { return true; }
    if (g.indexOf("employee")>-1) {
        return true;
    } else {
        return false;
    }
}


export const hasGroup = (user, group) => {
    let g = user.groups;
    let x = { 'employee': User(g), 'manager': Manager(g), 'admin': Admin(g) }
    return x[group];
}

export default hasGroup;