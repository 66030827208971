import React from 'react';
import PropTypes from 'prop-types';

import Popover from '@mui/material/Popover';

import CheckCRSOutput from './CheckCRSOutput';

CheckCRSPopover.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  anchorEl: PropTypes.any,
  onClose: PropTypes.func.isRequired,
  anchoreOrigin: PropTypes.object,
  transformOrigin: PropTypes.object,
  frame: PropTypes.string.isRequired,
  epoch: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number
  ]).isRequired,
  ellipsoid: PropTypes.string.isRequired,
  prime_meridian: PropTypes.string.isRequired,
  projection: PropTypes.string.isRequired,
  height_system: PropTypes.string,
}

export default function CheckCRSPopover ({isOpen, anchorEl, onClose, anchoreOrigin = { vertical: 'bottom', horizontal: 'center' }, transformOrigin =  {vertical: 'top', horizontal: 'center' }, frame, epoch, ellipsoid, prime_meridian, projection , height_system}){
  return <Popover id="simple-popper" open={isOpen} 
            anchorEl={anchorEl} 
            onClose={onClose}
            anchorOrigin={anchoreOrigin}
            transformOrigin={transformOrigin} >
            <CheckCRSOutput frame={frame} 
                            epoch={epoch}
                            ellipsoid={ellipsoid}
                            prime_meridian={prime_meridian}
                            projection={projection}
                            height_system={height_system}
                            inPopover/>
          </Popover>
}


