import React, { useState } from "react";
import { inject, observer } from "mobx-react";
import { Box, Collapse, IconButton } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChevronDown,
  faChevronUp,
} from "@fortawesome/free-solid-svg-icons";
import { classes } from "../styling";
import { useTranslation } from 'react-i18next';

export const ParamsMapComponent = inject("store")(
  observer(({ store }) => {
    const { t } = useTranslation();
    const [isParamOpen, setIsParamOpen] = useState(true);

    return (
      <Box
        sx={
          !store.kataster.isGreen() ?
            { ...classes.paramOverlay, ...classes.highlightBox } :
            classes.paramOverlay
        }
      >
        <IconButton onClick={() => setIsParamOpen(!isParamOpen)} size={"small"}>
          {isParamOpen ? (
            <FontAwesomeIcon icon={faChevronDown} />
          ) : (
            <FontAwesomeIcon icon={faChevronUp} />
          )}
        </IconButton>
        <Collapse in={isParamOpen}>
          <Box
            sx={classes.paramBoxStyle}
            style={isParamOpen ? null : { width: 0, overflow: "hidden" }}
          >
            <Box>
              <Box sx={classes.paramsLableStyle}>{t('cadastre:selection_params_centroid_east')}</Box>
              <Box sx={classes.paramDataStyle}>
                {store.kataster.params.ce}
                {store.kataster.sigmas.ce
                  ? `+/-${store.kataster.sigmas.ce}`
                  : ""}
              </Box>
            </Box>
            <Box>
              <Box sx={classes.paramsLableStyle}>{t('cadastre:selection_params_centroid_north')}</Box>
              <Box sx={classes.paramDataStyle}>
                {store.kataster.params.cn}
                {store.kataster.sigmas.cn
                  ? `+/-${store.kataster.sigmas.cn}`
                  : ""}
              </Box>
            </Box>
            <Box>
              <Box sx={classes.paramsLableStyle}>{t('cadastre:selection_params_translation_east')}</Box>
              <Box sx={classes.paramDataStyle}>
                {store.kataster.params.de}
                {store.kataster.sigmas.de
                  ? `+/-${store.kataster.sigmas.de}`
                  : ""}
              </Box>
            </Box>
            <Box>
              <Box sx={classes.paramsLableStyle}>{t('cadastre:selection_params_translation_north')}</Box>
              <Box sx={classes.paramDataStyle}>
                {store.kataster.params.dn}
                {store.kataster.sigmas.dn
                  ? `+/-${store.kataster.sigmas.dn}`
                  : ""}
              </Box>
            </Box>
            <Box
              sx={
                Math.abs(store.kataster.params.m - 1) * 1e6 >= 100
                  ? classes.highlight
                  : {}
              }
            >
              <Box sx={classes.paramsLableStyle}>{t('cadastre:selection_params_scale')}</Box>
              <Box sx={classes.paramDataStyle}>
                {store.kataster.params.m}
                {store.kataster.sigmas.m ? `+/-${store.kataster.sigmas.m}` : ""}
              </Box>
            </Box>
            <Box>
              <Box sx={classes.paramsLableStyle}>{t('cadastre:selection_params_rotation')}</Box>
              <Box sx={classes.paramDataStyle}>
                {store.kataster.params.r}
                {store.kataster.sigmas.r ? `+/-${store.kataster.sigmas.r}` : ""}
              </Box>
            </Box>
            <Box>
              <Box sx={classes.paramsLableStyle}>{t('cadastre:selection_params_height_shift')}</Box>
              <Box sx={classes.paramDataStyle}>
                {store.kataster.height_shift}
              </Box>
            </Box>
          </Box>
        </Collapse>
      </Box >
    );
  })
);
