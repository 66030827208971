import React from "react";
import PropTypes from "prop-types";

import BevModal from "components/BevModal";
import CheckCRS from "components/CRS/CheckCRS";

import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import Button from "@mui/material/Button";
import Avatar from "@mui/material/Avatar";

import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTranslation } from "react-i18next";

/**
 * isModalOpen, if true the Modal will be shown
 * hanldeOnClose, gives a function to handel the Close Event of the modal
 * handleLoadTrafo, gives a function to handle the LoadTrafo Event and gets an index
 * trafos, is an Array with all trafos that can be loaded.
 */
SelcetTrafoModal.propTypes = {
  isModalOpen: PropTypes.bool.isRequired,
  handleOnClose: PropTypes.func.isRequired,
  handleLoadTrafo: PropTypes.func.isRequired,
  trafos: PropTypes.array.isRequired,
};

/**
 * If no isMoadlOpen is given the modal will be closed.
 * If no trafos is given than the default is an empty array as fallback.
 */
SelcetTrafoModal.defaultProps = {
  isModalOpen: false,
  trafos: [],
};

export default function SelcetTrafoModal({
  isModalOpen,
  handleOnClose,
  trafos,
  handleLoadTrafo,
}) {
  const loadTrafo = (i) => handleLoadTrafo(i);
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up("sm"));
  const { t } = useTranslation();
  return (
    <BevModal
      width={matches ? 580 : 350}
      open={isModalOpen}
      onClose={handleOnClose}
    >
      <h3>{t("advanced_select_trafo_title")}</h3>
      <List>
        {trafos.map((t, i) => (
          <ListItem key={i} value={i} onClick={loadTrafo.bind(this, i)}>
            <Avatar>T</Avatar>
            <ListItemText
              primary={t.name}
              secondary={
                <span style={{ display: "block" }}>
                  <CheckCRS crs={t.sourceCRS} isInSelect={true} /> nach{" "}
                  <CheckCRS crs={t.targetCRS} isInSelect={true} />
                </span>
              }
            />
          </ListItem>
        ))}
      </List>
      <Button onClick={handleOnClose}>
        {t("advanced_select_trafo_cancel")}
      </Button>
    </BevModal>
  );
}
