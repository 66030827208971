export const header = {
    "api_keys" : "API Keys",
    "farming": "Farming",
    "punktspeicher" : "Punktspeicher",
    "austrian" : "Austrian",
    "advanced" : "Advanced",
    "datumstransformation": "Datumstransformation",
    "kataster" : "Kataster",
    "helmert" : "Helmert",
    "helmert_parametern": "Helmert mit Parametern",
    "parameterbestimmung" : "Parameterbestimmung",
    "transformator" :"Transformator",
    "contact": "Kontakt",
    "impressum" : "Impressum",
    "insert_key" : "API Key einfügen",
    "logout" : "Abmelden",
    "api": "API",
    "wiki" : "WIKI",

}