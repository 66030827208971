import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import { format, getDate } from 'date-fns'
import { toast } from 'react-toastify';

import Grid from '@mui/material/Grid';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import * as constants from 'common/constants.js';
import errorHandler from 'common/errorHandler';
import { prepareNumber } from 'common/utility';

import GridCalendar from './GridCalendar';
import GridExamples from './GridExamples';
import GridDateInput from './GridDateInput';


const TRANSFORMATOR_API_URL_DATE = constants.BASE_API_URL + 'datetime/';

const BevTimeTransformator = ({value=2002.56, today=false, handleChange}) => {
    /**
     * value, to set the start/load value for the component else it is new Date
     * handleChange, a callback function, if given it will be called after an server check.
     */
     const [bevTime, setBevTime] = useState({yr: value})
     const [bevTimeComputed, setBevTimeComputed] = useState(false)
   
     useEffect(() => {

       console.log("today: ", bevTime)
       if (today === true) {
         getBevTime('bdat', format(new Date(), 'yyyy-MM-dd'))
       } else {
         getBevTime('yr', value)
       }
     }, [today]);

     const getBevTime = (category, value) => {
          const toastID = toast.info( ({closeToast}) => <div><FontAwesomeIcon icon="spinner" spin /> Warten auf Berechnungsserver </div>,{autoClose: false});
          value = category === 'yr' ? parseFloat(value).toFixed(2).toString() : value
          const body = { category, value }

          axios.post(TRANSFORMATOR_API_URL_DATE, body)
          .then(res => {
              setBevTime({...res.data})
              handleChange(res.data.yr)
          })
          .catch(errorHandler)
          .finally( () => {
              toast.dismiss(toastID);
              setBevTimeComputed(true)
          })
     }


  
    /* send request to Transform Date data and save them in the state */
    const changeDate = (category, value) => {
      console.log(category, value)
      setBevTime({ ...bevTime, [category]: value});
    }
      
    /* Hepler function to format date from Examples Button for getDate function */  
    const setDateTo = (category, value, today=false) => {
      if (today === true) {
        category = 'bdat'
        value = format(new Date(), 'yyyy-MM-dd')
      }
      getBevTime(category, value);
    }

    /* Hepler function to format Date from Calendar for getDate function */  
    const setDateFromDatePicker = (date) => {
      getBevTime('bdat', format(date, 'yyyy-MM-dd') );
    }

   
    return bevTimeComputed && <Grid container alignItems="flex-start" justifyContent="flex-start" spacing={1}>
        <GridCalendar
            setDateFromDatePicker={setDateFromDatePicker}
            bevTime={bevTime}/>
        <GridExamples 
            setDateTo={setDateTo} />
        <GridDateInput 
            getDate={getBevTime}
            changeDate={changeDate}
            {...bevTime}/>
    </Grid>    
    }

export default BevTimeTransformator;

