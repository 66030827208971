import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Alert, TextField } from '@mui/material';
import PropTypes from 'prop-types';

import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';

import * as actions from '../../../store/actions';
import * as example from '../../../common/examples';
import  parseFile from '../../../common/parsers/fileParser';
import prepareFileName from '../../../common/prepareFileName';
import { DEFAULT_PAGE_SIZE } from '../../../common/constants.js';
import errorHandler from '../../../common/errorHandler';
import BevSaveDialog from 'components/BevSaveDialog';

import ImportGeometryModal from './importGeometryModal';
import ImportTypeSelect from './importTypeSelect';
import ImportFileLoadButton from './importFileLoadButton';
import ImportExampleField from './importExampleField';
import { Hidden } from '@mui/material';
import i18next from 'common/i18n'
import { useTranslation, withTranslation } from 'react-i18next';

const isValidCRDLoad = (files) => {
    if(files.length > 2) throw new Error(i18next.t('point_import_valid_error_length'))
    const file1 = files[0];
    
    if( !( file1.name.match('.crd') || file1.name.match('.CRD') ) ) throw new Error(i18next.t('point_import_valid_error_crd'))
    if(files.length === 2){
      const file2 = files[1];
      if(file1.name.split('.')[0] !== file2.name.split('.')[0]) throw new Error(i18next.t('point_import_valid_error_name'))
      if( !( file2.name.match('.vel') || file2.name.match('.VEL') ) )throw new Error(i18next.t('point_import_valid_error_vel'))
    }
    return true;
}

const mapStateToProps = state => {
  return {
    geometryList: state.points.geometryList,
    fileId: state.points.fileId,
    fileName: state.points.fileName,
    fileNameCounter: state.points.fileNameCounter,
    lastInputString: state.points.lastInputString,
    lastInputFormat: state.points.lastInputFormat,
  }
}

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    addGeometryFile: (event) => dispatch( actions.points.addGeometryFile( event ) ),
    findNextFileNumber:() => dispatch(actions.points.findNextFileNumber()),
    setFileName: (fileName) => dispatch(actions.points.setFileName(fileName)),
    updateProp: (type,value) => dispatch(actions.points.updateProp(type,value)),    
 }
}

const fileTypes = {
    json: ['.json', '.geojson'],
    kml: ['.kml', '.KML'],
    gpx: ['.gpx', '.GPX'],
    csv: ['.csv', '.CSV'],
    csvega: ['.csv', '.CSV'],
    crd: ['.crd', '.CRD','.vel', '.VEL'],
}

const examples = {
    json: example.JSON,
    kml: example.KML,
    gpx: example.GPX,
    csv: example.CSV,
    csvega: example.CSVEGA,
    crd: example.CRD,
}

function getFileName(filename){
  let splitted = filename.split('.');
  splitted.splice(splitted.length - 1, 1);
  return splitted.join('.');
}

class ImportFiles extends Component {
  static propTypes = {
    inputformat: PropTypes.string,
    fileName: PropTypes.string,
    loadingDone: PropTypes.func,
    redirectAfterSave: PropTypes.bool,
    handleRedirect: PropTypes.func,
    handleClose: PropTypes.func,
    is2d: PropTypes.bool,
    isGeomManager: PropTypes.bool,
    hideTransformButton: PropTypes.bool,
    sourceCrs: PropTypes.object,
    hideButtons: PropTypes.bool,
  }
  static defaultProps = {
    inputformat: 'json',
    fileName: null,
    is2d: false,
    isGeomManager: false,
    hideTransformButton: false,
    hideButtons: false,
  }
  state = {
    inputformat: 'json',
    string: '',
    fullFileName: '',
    loadedGeometryFile: null,
    isDialogOpen: false,
    isModalOpen: false,
    offset: 0,
    pageSize: DEFAULT_PAGE_SIZE,
    exampleLoaded: false,
  }

  componentDidMount(){
    const { lastInputString, lastInputFormat, pointList} = this.props;
    this.setState({
      inputformat: lastInputFormat !== "" ? lastInputFormat : this.props.inputformat,
      string: lastInputString !== "" ? lastInputString : ( !!pointList && !!pointList.features ? JSON.stringify(pointList, 0 , 1) :  "")
    });
  }

  componentDidUpdate(prevProps, prevState){
    if(this.props.loadedExample !== prevProps.loadedExample){
      this.setState({string:JSON.stringify(this.props.loadedExample,0,1)});
    }
  }

  startTransformation = () => this.handleParseFile(this.props.startTransformation);
  

  startParseString = () => this.handleParseFile(this.toggleModal);
  

  handleStartSelect = () => this.handleParseFile(this.props.sendHandleLoadGeometry);
  

  handleParseFile = next =>{
    const {loadingDone, sourceCrs} =this.props;
    const {inputformat,string,fullFileName} = this.state;

    this.setState({ loadedGeometryFile: null});
    if(!!loadingDone) loadingDone();

    (async () => {
      try {
        let x = await parseFile(inputformat, string)
        x.name = fullFileName;
        if(!!sourceCrs) x.crs = sourceCrs;
        this.setState({loadedGeometryFile: x});
        if(!!loadingDone) loadingDone(x);
        next();
      }catch(error) {
        errorHandler(error)
      }
    })()
  }  

  importFiles = (event) => {
    const {loadingDone, updateProp} = this.props;
    
    this.setState({loadedGeometryFile: null});
    if(!!loadingDone) loadingDone();
    
    this.setState(prevState => {return { string: ''}});
    updateProp('lastInputString', '');

    const files = event.target.files;
    try{
      if(this.state.inputformat === 'crd' && isValidCRDLoad(files)){
          this.readFile(files[0]);
          if(files.length > 1) setTimeout(this.readFile(files[1]),10);
      }else{
        for(let file of files){
          this.readFile(file);
        }
      }
    }catch(error){
      errorHandler(error);
    }
  }

  readFile = (file) => {
    const {setFileName, findNextFileNumber, updateProp} = this.props;
    const f = new FileReader();
    f.onloadend = (status) => {
      this.setState(prevState => {
        updateProp('lastInputString', prevState.string + f.result);
        return { string: prevState.string + f.result, fullFileName: file.name}
      });
      
      setFileName(getFileName(file.name));
      findNextFileNumber();
    }
    f.readAsText(file);
  }
  
  saveGeometries = () => this.handleParseFile(this.handleSaveGeometries);

  handleSaveGeometries = () => {
    const { addGeometryFile, handleRedirect, setFileName } = this.props;
    const { fullFileName } = this.state;

    let geometryFile = this.state.loadedGeometryFile;
    geometryFile.name = fullFileName;
    
    setFileName(prepareFileName(fullFileName));
    addGeometryFile(geometryFile);
    
    this.toggleSaveDialog();
    if(this.props.redirectAfterSave){handleRedirect()};
  }
  
  handleChangeType = (e) => {
    this.setState({inputformat: e.target.value});
    this.props.updateProp('lastInputFormat', e.target.value);
  }
  
  handleClickOffset = (offset) => {this.setState({offset});}
  
  handleChangeString = (event) => {
    this.setState({ string: event.target.value });
    this.props.updateProp('lastInputString', event.target.value);
  }

  handleChangeFilename = (event) => {
    console.log(event.target.value);
    this.setState({ fullFileName: event.target.value});
  }
  
  toggleModal = () => {this.setState(prevState => {return {isModalOpen: !prevState.isModalOpen} });}
  
  toggleSaveDialog = () => {
    const { fileName, fileNameCounter} = this.props;

    this.setState(prevState => {return {isDialogOpen: !prevState.isDialogOpen, fullFileName: fileName + '_' + fileNameCounter } });
  }

  loadExample = () => {
    const string = examples[this.state.inputformat];
    this.setState({string});
    this.props.updateProp('lastInputString', string);
  }

render() {
  //Special Variables
  const formats = this.props.formats ? this.props.formats : ['json', 'gpx', 'kml', 'csv', 'csvega', 'crd']
  const example = examples[this.state.inputformat];

  //Props Variables
  const {is2d, isGeomManager, isSelect, isSave, t, handleClose, hideButtons} = this.props;

  //State Variables
  const {inputformat,string, loadedGeometryFile} = this.state; //Common
  const {isModalOpen, fullFileName, isDialogOpen} = this.state; //GeometryModal

  return <div>
      <div>
        <Grid container spacing={4} >
          <Grid item sm={12} md={6} >
            <ImportTypeSelect formats={formats} inputformat={inputformat} handleChange={this.handleChangeType} />
            <ImportFileLoadButton importFiles={this.importFiles} inputformat={inputformat} fileTypes={fileTypes[inputformat]}/>
            <TextField sx={{mt:1}} multiline fullWidth name="pointlist1" rows="10" value={string} onChange={this.handleChangeString} />
            { is2d && <Alert color="warning" style={{marginTop: '10px'}}>{t('point_import_alert')}</Alert>}
            { !hideButtons&& <ImportButtons 
              handleStartParse={this.startParseString}
              isDisabled={string === ""}
              startTransformation={this.startTransformation} 
              hideTransformButton={this.props.hideTransformButton}
              isGeomManager={isGeomManager}
              isSelect={isSelect}
              handleStartSelect={this.handleStartSelect}
              isSave={isSave}
              handleStartSave={this.toggleSaveDialog}
              toggleModal={handleClose || this.toggleModal}
              loadExample={this.loadExample} /> 
            }
          </Grid>
          <Grid item sm={12} md={6}>
            <Hidden smDown>
              <ImportExampleField example={example} loadExample={this.loadExample}/>
            </Hidden>
          </Grid>
        </Grid>
      </div>
      <BevSaveDialog 
        dialogTitle={t('point_import_dialog_title')}
        isDialogOpen={isDialogOpen}
        toggleDialog={this.toggleSaveDialog}
        handleChange={this.handleChangeFilename}
        handleSave={this.saveGeometries}
        value={fullFileName} />

      { !!loadedGeometryFile &&
        <ImportGeometryModal isModalOpen={isModalOpen}
                              hideSave={false}
                              geometries={loadedGeometryFile}
                              toggleModal={this.toggleModal}/>
      }
    </div>;
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(ImportFiles));

export const ImportButtons = ({handleStartParse, handleStartSelect, toggleModal, loadExample, isDisabled, isSave, 
                                startTransformation, isGeomManager, hideTransformButton, isSelect, handleStartSave, }) => {
  const style={ margin:'10px 20px 0 0' };
  const {t} = useTranslation();
  return <div>{ !hideTransformButton && !isGeomManager && <Button 
        style={style}
        color="primary" 
        variant="contained" 
        onClick={startTransformation} 
        disabled={isDisabled}>
          {t('point_import_buttons_start_calc')}
      </Button> 
    }
    { isSelect && <Button
      style={style}
      color="primary" 
      variant="outlined" 
      disabled={isDisabled} 
      onClick={handleStartSelect}>
          {t('point_import_buttons_start_calc')}
    </Button>}
    { isSave && <Button
      style={style}
      color="primary" 
      variant="outlined" 
      disabled={isDisabled} 
      onClick={handleStartSave}>
          {t('point_import_buttons_start_calc')}
    </Button>}
    
    <Button 
      style={style}      
      variant="outlined" 
      color="primary" 
      onClick={handleStartParse} 
      disabled={isDisabled}>
          {t('point_import_buttons_look_at')}
    </Button>

    <Hidden mdUp>
      <Button 
        style={style} 
        variant="outlined" 
        color="primary" 
        onClick={loadExample}>
          {t('point_import_buttons_load')}
      </Button>
    </Hidden>

    <Hidden lgUp>
      <Button 
        style={style}
        variant="outlined" 
        color="primary" 
        onClick={toggleModal}>
          {t('point_import_buttons_cancle')}
      </Button>
    </Hidden>

    
  </div>
}