import PropTypes from 'prop-types';
import * as actionTypes from '../actions/actionTypes';
import * as constants from '../../common/constants.js';

const initialState = {
    sourceCRS: { type: 'BEV', name: 'default_source_crs', properties: {
        frame : constants.FRAME_ETRS89,
        epoch: 2002.56,
        ellipsoid : constants.ELLIPSOID_GRS80,
        prime_meridian: constants.MERIDIAN_GREENWICH,
        projection : constants.PROJ_NONE,
        height_system : constants.HEIGHT_ELLIPSOID,
      }
    },

    targetCRS: { type: 'BEV', name: 'default_target_crs', properties: {
        frame : constants.FRAME_MGI,
        epoch: 2002.56,
        ellipsoid : constants.ELLIPSOID_BESSEL,
        prime_meridian: constants.MERIDIAN_FERRO,
        projection : constants.PROJ_MGI_GK34,
        heightsystem : constants.HEIGHT_USAGE,
      }
    }
}

initialState.sourceCRS.properties.propTypes = {
    epoch: PropTypes.number
}

initialState.targetCRS.properties.propTypes = {
    epoch: PropTypes.number
}


const reducer = ( state = initialState, action) => {
    switch ( action.type ) {

        case actionTypes.ADV_SET_SOURCE_CRS: 
            return {...state, sourceCRS: { ...action.crs }};

        case actionTypes.ADV_SET_TARGET_CRS: 
            return {...state, targetCRS: { ...action.crs }};

        default:
            return state;
    }
}

export default reducer;
