import React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide';
import { useTranslation } from "react-i18next";
import { observer, inject } from 'mobx-react';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';
import ImageIcon from '@mui/icons-material/Image';
import TableChartIcon from '@mui/icons-material/TableChart';
import CodeIcon from '@mui/icons-material/Code';
import {ExportEntry} from './ExportEntry';
import i18next from "common/i18n";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const EXPORT_LIST = [
   {
     title: i18next.t("cadastre:export_title_protokoll"), 
     description: i18next.t("cadastre:export_description_protokoll"),
     icon: <PictureAsPdfIcon style={{fontSize: '5rem'}} />,
     dataType: ".pdf",
     url: "pdf"
   },
   {
    title: i18next.t("cadastre:export_title_rmgeo"),
    description: i18next.t("cadastre:export_description_rmgeo"),
    icon: <InsertDriveFileIcon style={{fontSize: '5rem'}} />,
    dataType: ".rmg",
    url: "rmgeo"
   },
   {
    title: i18next.t("cadastre:export_title_geosi"),
    description: i18next.t("cadastre:export_description_geosi"),
    icon: <InsertDriveFileIcon style={{fontSize: '5rem'}} />,
    dataType: ".xggt",
    url: "geosi"
   },
   {
    title: i18next.t("cadastre:export_title_kvz"),
    description: i18next.t("cadastre:export_description_kvz"),
    icon: <TableChartIcon style={{fontSize: '5rem'}} />,
    dataType: ".csv",
    url: "kvz"
   },
   {
    title: i18next.t("cadastre:export_title_png"),
    description: i18next.t("cadastre:export_description_png"),
    icon: <ImageIcon style={{fontSize: '5rem'}} />,
    dataType: ".png",
    url: "frontend/png"
   },
   {
    title: i18next.t("cadastre:export_title_json"),
    description: i18next.t("cadastre:export_description_json"),
    icon: <CodeIcon style={{fontSize: '5rem'}} />,
    dataType: ".json",
    url: "frontend/json"
   }
]

export const ExportDialog = inject("store")(observer(({store, isOpen, toggleOpen}) => {
  const { t } = useTranslation();

  const startExport = (url) => (e) => {
    store.kataster.startExportDownload(url)
    toggleOpen();
  }

  return <Dialog
        open={isOpen}
        TransitionComponent={Transition}
        keepMounted
        fullWidth
        maxWidth="xl"
        onClose={toggleOpen}
        aria-labelledby="export-dialog-cadastre"
        aria-describedby="all-exports-for-cadaster-mode"
      >
        <DialogTitle id="export-dialog-title">{t("cadastre:export_dialog_title")}</DialogTitle>
        <DialogContent style={{display: 'flex', flexWrap:'wrap'}}>

          {EXPORT_LIST.map((entry, index) => (
            <ExportEntry
              key={index}
              title={entry.title}
              description={entry.description}
              icon={entry.icon}
              dataType={entry.dataType}
              startExport={startExport(entry.url)}
               />
          ))}

        </DialogContent>
      </Dialog>
}))
