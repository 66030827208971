export const AustrianExample = {
    sourceCRS: "etrs89_lph",
    sourceHeight: "ellps",
    targetCRS: "mgi_gk34",
    targetHeight: "usage",
    transformationMethod: "BEV::2002",
    geoJson:{
        type:"FeatureCollection",
        crs:{
            type:"BEV",
            name:"",
            properties:{
                frame:"",
                epoch:"2019.49",
                ellipsoid:"",
                prime_meridian:"",
                projection:"",
                height_system:"",
            }
        },
        features:[{
            type:"Feature",
            geometry:{
                type:"Point",
                coordinates:[15.28122821,48.829794943,549.967],
                velocities:[]
            },
            properties:{name:"T270-6"}
        }],
        filetype:"csv",
        name:"",
        }
};

export const AdvancedExample = null;

export const DateExample = null;

export const KatasterExample = {
    etrs: { 
        type:"FeatureCollection",
        crs:{
            type:"BEV",
            name:"",
            properties:{
                frame:"",
                ellipsoid:"",
                epoch:"2019.24",
                prime_meridian:"",
                projection:"",
                height_system:""
            }
        },
        features:[{
            type:"Feature",
            geometry:{
                type:"Point",
                coordinates:[4217534.793, 907752.004, 4682895.595],
            },
            properties:{name:"E 83015-0041A1", type: "FP", messdatum: "2002-24-07T00:00:00Z"}
        },{

            type:"Feature",
            geometry:{
                type:"Point",
                coordinates:[4217134.846, 908663.169, 4683147.703],
            },
            properties:{name:"E 83015-0055A2", type: "FP", messdatum: "2002-07-24"}
        },{
             type :"Feature",
             geometry :{
                 type :"Point",
                 coordinates :[4217871.591, 908238.154, 4682584.843],
            },
             properties :{ name :"E 83015-0118A1", type: "FP", messdatum: "2002-07-24"}
        },{
             type :"Feature",
             geometry :{
                 type :"Point",
                 coordinates :[4217465.299, 908205.674, 4682894.753],
            },
             properties :{ name :"E 83015-0119A1", type: "FP", messdatum: "2002-07-24"}
        },{
            type :"Feature",
            geometry :{
                type :"Point",
                coordinates :[4217199.508, 908041.549, 4683157.886],
           },
            properties :{ name :"E 83015-0122E1", type: "FP", messdatum: "2002-07-24"}
       },{
            type :"Feature",
            geometry :{
                type :"Point",
                coordinates :[4217416.312, 908053.186, 4682952.060],
            },
             properties :{ name :"E 83015-0123E1", type: "FP", messdatum: "2002-07-24"}
   }],
         filetype :"csv",
    },
    mgi:{
        type:"FeatureCollection",
        crs:{
            type:"BEV",
            name:"",
            properties:{
                frame:"",
                ellipsoid:"",
                epoch:"2019.24",
                prime_meridian:"",
                projection:"",
                height_system:""
            }
        },
        features:[{
            type:"Feature",
            geometry:{
                type:"Point",
                coordinates:[-89288.810, 267365.420, 601.440],
            },
            properties:{name:"E 83015-0041A1", type: "FP"}
        },{
            type:"Feature",
            geometry:{
                type:"Point",
                coordinates:[-88309.112, 267667.562, 652.952],
            },
            properties:{name:"E 83015-0055A2", type: "FP"}
        },{
            type:"Feature",
            geometry:{
                type:"Point",
                coordinates:[-88892.504, 266831.074, 663.534],
            },
            properties:{name:"E 83015-0118A1", type: "FP"}
        },{
            type:"Feature",
            geometry:{
                type:"Point",
                coordinates:[ -88830.985, 267337.465, 619.415],
            },
            properties:{name:"E 83015-0119A1", type: "FP"}
        },{
            type:"Feature",
            geometry:{
                type:"Point",
                coordinates:[ -88929.437, 267733.827, 614.797],
            },
            properties:{name:"E 83015-0122E1", type: "FP"}
        },{
            type:"Feature",
            geometry:{
                type:"Point",
                coordinates:[-88968.229, 267437.319, 607.709],
            },
            properties:{name:"E 83015-0123E1", type: "FP"}
        }],
        filetype:"csv",
    },
    transformationsGeoJson: {
        type:"FeatureCollection",
        features:[{
            type:"Feature",
            geometry:{
                type:"Point",
                coordinates:[4217645.793, 907864.004, 4683006.595],
            },
            properties:{name:"11", type: "MP"}
        },{
            type:"Feature",
            geometry:{
                type:"Point",
                coordinates:[4217245.846, 908775.169, 4683258.703],
            },
            properties:{name:"12", type: "MP"}
        },{
            type :"Feature",
            geometry :{
                type :"Point",
                coordinates :[4217982.591, 908350.154, 4682695.843],
            },
            properties :{ name :"13", type: "MP"}
        },{
            type :"Feature",
            geometry :{
                type :"Point",
                coordinates :[4217576.299, 908317.674, 4683005.753],
            },
            properties :{ name :"14", type: "MP"}
        }],
filetype :"csv",
},
};

export const HelmertExample2dTransformation = {
    params:{
        dreid: {
            dx: 0,
            dy: 0,
            dz: 0,
            m: 0,
            rx: 0,
            ry: 0,
            rz: 0,
        },
        zweid: {
            de: 24.513,
            dn: 55.691,
            m: 1.0000024232,
            r: 0.0000256806,
        }
    },
    geoJson:{type:"FeatureCollection",
    crs:{
        type:"BEV",
        name:"",
        properties:{
            frame:"",
            epoch:"2019.49",
            ellipsoid:"",
            prime_meridian:"",
            projection:"",
            height_system:"",
        }
    },
    features:[{
        type:"Feature",
        geometry:{
            type:"Point",
            coordinates:[5424562.23,23107.34],
            velocities:[]
        },
        properties:{name:"P1"}
    }],
    filetype:"csv",
    name:"",}
  };
export const HelmertExample3dTransformation = {
    params:{
        dreid: {
            dx: -577.326,
            dy: -90.129,
            dz: -463.919,
            m: 0.9999975768,
            rx: 0.00142694,
            ry: 0.000409444,
            rz: 0.00147138,
        },
        zweid: {
            de: 0,
            dn: 0,
            m: 0,
            r: 0,
        }
    },
    geoJson: {type:"FeatureCollection",
    crs:{
        type:"BEV",
        name:"",
        properties:{
            frame:"",
            epoch:"2019.49",
            ellipsoid:"",
            prime_meridian:"",
            projection:"",
            height_system:"",
        }
    },
    features:[{
        type:"Feature",
        geometry:{
            type:"Point",
            coordinates:[4194423.59769, 1162702.92694, 4647245.55073],
            velocities:[]
        },
        properties:{name:"GRAZ 11001M002"}
    }],
    filetype:"csv",
    name:"",}
  };

export const HelmertExample2dDetermination = {
    "selectedGeometryOne":{
    "type":"FeatureCollection",
    "crs":{
    "type":"BEV",
    "name":"",
    "properties":{
    "frame":"",
    "ellipsoid":"",
    "epoch":"2019.49",
    "prime_meridian":"",
    "projection":"",
    "height_system":"",
    }
    },
    "features":[
    {
    "type":"Feature",
    "geometry":{
    "type":"Point",
    "coordinates":[
    12.341,44.591
    ],
    "velocities":[   ],
    },
    "properties":{
    "name":"P1",
    }
    },{
    "type":"Feature",
    "geometry":{
    "type":"Point",
    "coordinates":[
    10.491,55.194
    ],
    "velocities":[
    ],
    },
    "properties":{
    "name":"P2"
    }
    },{
    "type":"Feature",
    "geometry":{
    "type":"Point",
    "coordinates":[
    11.149,29.482
    ],
    "velocities":[
    ],
    },
    "properties":{
    "name":"P3",
    }
    }
    ],
    "filetype":"csv",
    "name":"",
    },
    "selectedGeometryTwo":{
            "type":"FeatureCollection",
            "crs":{
            "type":"BEV",
            "name":"",
            "properties":{
            "frame":"",
            "ellipsoid":"",
            "epoch":"2019.49",
            "prime_meridian":"",
            "projection":"",
            "height_system":"",
            }
            },
            "features":[{
            "type":"Feature",
            "geometry":{
            "type":"Point",
            "coordinates":[36.852885,100.282425
            ],
            "velocities":[            ]
            },
            "properties":{
            "name":"P1"
            }
            },{
            "type":"Feature",
            "geometry":{
            "type":"Point",
            "coordinates":[35.002608,110.885403
            ],
            "velocities":[
            ]
            },
            "properties":{
            "name":"P2",
            }
            },{
            "type":"Feature",
            "geometry":{
            "type":"Point",
            "coordinates":[35.66127,85.173358
            ],
            "velocities":[
            ]
            },
            "properties":{
            "name":"P3",
            }
            }
            ],
            "filetype":"csv",
            "name":"",
            }
    };
export const HelmertExample3dDetermination = {
    "selectedGeometryOne":{
    "type":"FeatureCollection",
    "crs":{
    "type":"BEV",
    "name":"",
    "properties":{
    "frame":"",
    "ellipsoid":"",
    "epoch":"2019.49",
    "prime_meridian":"",
    "projection":"",
    "height_system":"",
    }
    },
    "features":[
    {
    "type":"Feature",
    "geometry":{
    "type":"Point",
    "coordinates":[
        13.14, 25.26, 39
    ],
    "velocities":[   ],
    },
    "properties":{
    "name":"P1",
    }
    },{
    "type":"Feature",
    "geometry":{
    "type":"Point",
    "coordinates":[
        34.35, 67.68, 112
    ],
    "velocities":[
    ],
    },
    "properties":{
    "name":"P2"
    }
    },{
    "type":"Feature",
    "geometry":{
    "type":"Point",
    "coordinates":[
        35.35, 68.68, 113
    ],
    "velocities":[
    ],
    },
    "properties":{
    "name":"P3",
    }
    }
    ],
    "filetype":"csv",
    "name":"",
    },
    "selectedGeometryTwo":{
            "type":"FeatureCollection",
            "crs":{
            "type":"BEV",
            "name":"",
            "properties":{
            "frame":"",
            "ellipsoid":"",
            "epoch":"2019.49",
            "prime_meridian":"",
            "projection":"",
            "height_system":"",
            }
            },
            "features":[{
            "type":"Feature",
            "geometry":{
            "type":"Point",
            "coordinates":[47, 11, 78
            ],
            "velocities":[            ]
            },
            "properties":{
            "name":"P1"
            }
            },{
            "type":"Feature",
            "geometry":{
            "type":"Point",
            "coordinates":[ 48, 20, 111
            ],
            "velocities":[
            ]
            },
            "properties":{
            "name":"P2",
            }
            },{
            "type":"Feature",
            "geometry":{
            "type":"Point",
            "coordinates":[49, 21, 112
            ],
            "velocities":[
            ]
            },
            "properties":{
            "name":"P3",
            }
            }
            ],
            "filetype":"csv",
            "name":"",
            }
    };;
