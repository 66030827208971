export const BASE_URL = !!process.env.REACT_APP_BASE_URL
  ? process.env.REACT_APP_BASE_URL
  : "/";
export const BASE_API_URL = !!process.env.REACT_APP_API_URL ? process.env.REACT_APP_API_URL : "/api/";
//export const BASE_API_URL = 'http://10.70.2.39:80/at.gv.bev.transformator/api/v3.0/'
//export const BASE_API_URL = 'https://transformatordev.bev.gv.at/at.gv.bev.transformator/api/v3.0/'


const VERSION = !!process.env.REACT_APP_VERSION
  ? process.env.REACT_APP_VERSION
  : "0";
const VERSION_MESSAGE = !!process.env.REACT_APP_VERSION_MESSAGE
  ? process.env.REACT_APP_VERSION_MESSAGE
  : "0";
export const VERSION_NAME = `V${VERSION} - ${VERSION_MESSAGE}`;

export const SENTRY_URL = !!process.env.REACT_APP_SENTRY_URL
  ? process.env.REACT_APP_SENTRY_URL
  : "";
export const SENTRY_RELEASE = !!process.env.REACT_APP_SENTRY_RELEASE
  ? process.env.REACT_APP_SENTRY_RELEASE
  : "";

export const TRANSFORMATOR_API_URL_ADV = BASE_API_URL + "advanced/";
export const TRANSFORMATOR_API_URL_AUT = BASE_API_URL + "austrian/";
export const TRANSFORMATOR_API_URL_HLMTRA_3D =
  BASE_API_URL + "helmert/3d/transformation/";
export const TRANSFORMATOR_API_URL_HLMTRA_2D =
  BASE_API_URL + "helmert/2d/transformation/";
export const TRANSFORMATOR_API_URL_CAD =
  BASE_API_URL + "cadastre/transformation/";
export const DETERMINATION_API_URL_CAD =
  BASE_API_URL + "cadastre/determination/";
  //'https://transformator.bev.gv.at/at.gv.bev.transformator/api/v3.0/cadastre/determination/';


export const DETERMINATION_API_URL_HLMTRA_3D =
  BASE_API_URL + "helmert/3d/determination/";
export const DETERMINATION_API_URL_HLMTRA_2D =
  BASE_API_URL + "helmert/2d/determination/";

export const KATASTER_SERVICE_API_URL = !!process.env.REACT_APP_KATASTER_URL ? process.env.REACT_APP_KATASTER_URL+"api/" : "https://katasterdev.bev.gv.at/api/";
export const KATASTER_SERVICE_URL = !!process.env.REACT_APP_KATASTER_URL ? process.env.REACT_APP_KATASTER_URL : "https://katasterdev.bev.gv.at/"

export const DEFAULT_PAGE_SIZE = 10;

export const CRS_MGI = "BEV::500001";
export const CRS_ETRS = "BEV::500002";

export const FRAME_ITRS2014 = "BEV::112014";
export const FRAME_ITRS2000 = "BEV::112000";
export const FRAME_ITRS96 = "BEV::111996";
export const FRAME_ITRS89 = "BEV::111989";
export const FRAME_ETRS89 = "201"; //"BEV::121989";
export const FRAME_ETRS96 = "BEV::121996";
export const FRAME_ETRS2000 = "209"; //'BEV::122000';80
export const FRAME_MGI = "301"; //"BEV::131989";

export const HEIGHT_ELLIPSOID = "ellps";
export const HEIGHT_USAGE = "usage";
export const HEIGHT_ORTHO = "ortho";
export const HEIGHT_NONE = "-";

export const PROJ_NONE = "";
export const PROJ_UTM33N = "BEV::420033";
export const PROJ_UTM32N = "BEV::420032";
export const PROJ_LAMBERT = "BEV::440001";
export const PROJ_MGI_GK28 = "BEV::10028"; //'BEV::430028';
export const PROJ_MGI_GK31 = "BEV::10031"; //'BEV::430031';
export const PROJ_MGI_GK34 = "BEV::10034"; //'BEV::430034';
export const PROJ_MGI_AUTO = "BEV::10035"; //'BEV::430099';

export const ELLIPSOID_BESSEL = "EPSG::7004"; //"BEV::230001";
export const ELLIPSOID_GRS80 = "EPSG::7019"; //"BEV::200002";

export const MERIDIAN_GREENWICH = "EPSG::8901"; //"BEV::300001";
export const MERIDIAN_FERRO = "EPSG::8909"; //"BEV::330002";
export const MERIDIAN_DEFAULT = MERIDIAN_GREENWICH;

export const TM_GIS_GRID = "BEV::630002";
export const TM_SEVEN_PARAM = "BEV::630001";

export const VG_GEOID_GRS80 = "AT_GEOID_GRS80";
export const VG_GEOID_BESSEL = "AT_GEOID_BESSEL";
export const VG_HEIGHT = "GV_HoehenGrid";
export const VG_HEIGHT_PLUS = "GV_HoehenGrid_plus_Geoid";

export const LOCAL_STORAGE_NAME = "bevTransformatorState";

export const ERROR_IMPORT_CSV = "CSV Fehler";
export const ERROR_IMPORT_CSV_NOT_VALID =
  "Die eingetragenen Daten wurden nicht als valides CSV erkannt";
export const ERROR_IMPORT_CSV_EMPTY_INPUT =
  "Weder Parameter noch Name sollten leer gelassen werden, Problem gefunden in dieser Zeile: ";
export const ERROR_IMPORT_CSV_WRONG_NUMBER_FORMAT =
  'Einer der Parameter hat nicht das richtige Zahlenformat, eine Zahl draf keine 1000-er Trennzeichen besitzen und die Dezimalstelle kann durch einen "." oder "," dargestellt werden, Problem gefunden in dieser Zeile: ';
export const ERROR_IMPORT_CSV_WRONG_NUMBER_PARAMETER_1 =
  "Eine Zeile besteht aus einen Namen und 3 oder 6 Parametern die durch ein Semikolon oder Komma getrennt werden, gefunden wurden ";
export const ERROR_IMPORT_CSV_WRONG_NUMBER_PARAMETER_2 =
  " Paramerter in dieser Zeile: ";

export const ERROR_IMPORT_CSVEGA = "CSVEGA Fehler";
export const ERROR_IMPORT_CSVEGA_WRONG_FILE_TYPE =
  "Dieses File ist nicht BEV EGA konform";
export const ERROR_IMPORT_CSVEGA_EMPTY_STRING =
  "Ein Parameter der nicht leer sein sollte war leer, bitte Eingabe überprüfen.";
export const ERROR_IMPORT_CSVEGA_WRONG_NUMBER_FORMAT =
  "Ein Parameter der eine Zahl sein sollte konnte nicht verabeitet werden, vielleicht hat er das falsche Format oder wurde leer gelassen.";
export const ERROR_IMPORT_CSVEGA_WRONG_TYPE =
  "DAS FILE ist weder ETRS noch MGI.";
export const ERROR_IMPORT_CSVEGA_WRONG_POINT_TYPE =
  "Der Punkttyp darf nur TP oder EP sein.";
export const ERROR_IMPORT_CSVEGA_WRONG_MERIDIAN =
  "Der MERIDIAN darf nur M28, M31 oder M34 sein.";

export const ERROR_IMPORT_CRD = "CRD Fehler";
export const ERROR_IMPORT_CRD_AND_VEL_DONT_MATCH =
  "CRD- und VEL-File stimmen nicht überein";
export const ERROR_IMPORT_CRD_BEFORE_VEL =
  "Nach einen CRD- muss ein VEL-File kommen.";
export const ERROR_IMPORT_CRD_TO_MANY = "Zu viel CRD oder VEL files";

export const ERROR_IMPORT_JSON = "JSON Fehler";
export const ERROR_IMPORT_JSON_VALID =
  "Das übergebene File ist kein valides GeoJSON, oder lässt sich nicht in ein valides GeoJSON umwandeln";
export const ERROR_IMPORT_JSON_FEATURE_VALID =
  "Beim Check wurde ein Fehler im folgenden Objekt gefunden: ";

export const ERROR_IMPORT_KML = "KML Fehler";
export const ERROR_IMPORT_KML_TAG = "Input ist kein KML File";

export const ERROR_IMPORT_GPX = "GPX Fehler";
export const ERROR_IMPORT_GPX_TAG = "Input ist kein GPX File";

export const WIKI_LINK_AUSTIRAN = "wiki/doku.php?id=transformator:austrian";
export const WIKI_LINK_ADVANCED = "wiki/doku.php?id=transformator:advanced";
export const WIKI_LINK_FARMING = "wiki/doku.php?id=transformator:farming";
export const WIKI_LINK_DATE = "wiki/doku.php?id=wiki:dattrans";
export const WIKI_LINK_CADASTRE = "wiki/doku.php?id=transformator:kataster";
export const WIKI_LINK_HELMERT2D_TRANSFORMATION =
  "wiki/doku.php?id=transformator:helmert";
export const WIKI_LINK_HELMERT3D_TRANSFORMATION =
  "wiki/doku.php?id=transformator:helmert";
export const WIKI_LINK_HELMERT2D_DETERMINATION =
  "wiki/doku.php?id=transformator:helmert";
export const WIKI_LINK_HELMERT3D_DETERMINATION =
  "wiki/doku.php?id=transformator:helmert";

export const MAINTENANCE_AUSTRIAN = false;
export const MAINTENANCE_ADVANCED = false;
export const MAINTENANCE_FARMING = false;
export const MAINTENANCE_DATE = false;
export const MAINTENANCE_CADASTRE = false;
export const MAINTENANCE_HELMERT_TRANSFORMATION = false;
export const MAINTENANCE_HELMERT_DETERMINATION = false;

export const EMPTY_CRS = {
  type: "BEV",
  properties: {
    frame: "",
    ellipsoid: "",
    prime_meridian: "",
    epoch: 2002.56,
    projection: "",
  },
};

export const BASIC_GEOJSON = {
  type: "FeatureCollection",
  crs: {
    type: "BEV",
    name: "",
    properties: {
      frame: "",
      ellipsoid: "",
      epoch: "2019.24",
      prime_meridian: "",
      projection: "",
      height_system: "",
    },
  },
};

export const MERIDIAN_MAPPING = {
  GK_M28: "BEV::10028",
  GK_M31: "BEV::10031",
  GK_M34: "BEV::10034",
};

export const TOASTER_INFO_AUTOCLOSE = { autoClose: 2500 };
export const TOASTER_ERROR_AUTOCLOSE = { autoClose: 5000 };