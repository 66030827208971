import React from 'react';
import PropTypes from 'prop-types';

import Grid from '@mui/material/Grid';
import { Button, Link } from '@mui/material';

import TitleCard from 'components/TitleCard';
import { WIKI_LINK_CADASTRE } from 'common/constants.js';
import { KatasterExample } from 'common/modeExamples';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useTranslation } from 'react-i18next';
import { faVideo } from '@fortawesome/free-solid-svg-icons';

export const TitleCardCadastre = ({loadExample, clearPage}) => {
  const {t} = useTranslation();
  const toWiki = () => {window.open(WIKI_LINK_CADASTRE)};
  const loadExampleData = (example) => {loadExample(example)}
    return <Grid container alignItems="center" justifyContent="center" spacing={1}>
      <Grid item xs={12} sm={12}>
        <TitleCard title={t('cadastre:title_card_title')}>
          <p>{t('cadastre:title_card_text')}</p>
          { !!KatasterExample && <Button color="primary" onClick={loadExampleData.bind(this,KatasterExample)}><FontAwesomeIcon icon="folder-open" style={{marginRight: '7px'}} /> {t('common:example_btn')}</Button>}
          <Button color="primary" onClick={clearPage}><FontAwesomeIcon icon="redo" style={{marginRight: '7px'}}/> {t('common:reset_btn')}</Button>
          { WIKI_LINK_CADASTRE !== '' && <Button color="primary" onClick={toWiki}><FontAwesomeIcon icon="info"style={{marginRight: '7px'}}/> {t('common:wiki_btn')}</Button>}
          <Link component={Button} href="https://youtu.be/mAx77CKRN7o" target={"_blank"} color="primary"><FontAwesomeIcon icon={faVideo} style={{marginRight: '7px'}}/> {t('cadastre:vid_btn')}</Link>
        </TitleCard>
      </Grid>
    </Grid>
}

/**
 * loadExample, a function, that will be called on click " Load Example"
 * clearPage, a function, that will be called on click "Clear Page"
 */
TitleCardCadastre.propTypes = {
    loadExample: PropTypes.func.isRequired, 
    clearPage: PropTypes.func.isRequired, 
  }
