import parseCSV from './csvParser';
import parseEgaCSV from './csvEgaParser';
import parseCRD from './crdParser';
import parseJson from './jsonParser';
import parseGpx from './gpxParser';
import parseKml from './kmlParser';
import cleanUpGeoJsonCoordinates from './cleanGeoJson';
import { jsonError } from './parserErrors';

import { prepareNumber } from 'common/utility';

import * as constants from '../constants';

var d = new Date();
var aktepoch = prepareNumber(d.getFullYear() + (d.getMonth())/12 + d.getDate()/360, 2);
var GJV = require("geojson-validation");
/**
 * 
 * @param {string} format 
 * @param {string} string 
 * @param {boolean} removeVelocities 
 * @param {boolean} isMgi 
 */
export default function parseFile(format, string, removeVelocities, isMgi) {
  let x = {};
  if (format === "json") { x = parseJson(string) }
  if (format === "kml") { x = parseKml(string) }
  if (format === "gpx") { x = parseGpx(string) } 
  if (format === "csv") { x = parseCSV(string, isMgi); }
  if (format === "crd") { x = parseCRD(string); }
  if (format === "csvega") { x = parseEgaCSV(string); }

  
  if (['json','kml','gpx'].indexOf(format) > -1){
    x.crs = { type: 'BEV', name: '', properties: { frame: '302', ellipsoid: 'EPSG::7030', epoch: aktepoch, prime_meridian: 'EPSG::8901', projection : '', height_system : ''  }}
  }else if(format === 'csv'){
    x.crs = { type: 'BEV', name: '', properties: { frame: '', ellipsoid: '', epoch: aktepoch, prime_meridian: '', projection : '', height_system : ''  }}
  }
  x = cleanUpGeoJsonCoordinates(x);
  if(x.features.length <= 0) throw new jsonError('Beim Laden des Files wurden keine Geometrien entdeckt, möglicher Grund, falsches Format eingelesen')
  if(removeVelocities) {
    x.features.map(f => {
      if(f.geometry.velocities.length <= 0) delete f.geometry.velocities;
      return f;
    })
  }
  x.filetype = format;
  if(!GJV.valid(x)) throw new jsonError(constants.ERROR_IMPORT_JSON_VALID);
  return x;
}
