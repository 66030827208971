import React, { useState } from "react";
import { inject, observer } from "mobx-react";
import { MenuListComposition } from "../MenuListComposition";
import {
  Avatar,
  TableRow,
  TableContainer,
  Table,
  TableHead,
  TableBody,
  TableFooter,
  TableCell,
  Tooltip,
  TablePagination,
  IconButton,
} from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faPlus,
  faCheck,
  faCogs,
  faExpand,
  faCheckDouble,
} from "@fortawesome/free-solid-svg-icons";
import { useTranslation } from "react-i18next";
import { classes } from "../styling";
import { format } from 'date-fns';
import { Box } from "@mui/material";

export const PointTablePassPoint = inject("store")(
  observer(({ store, handleOpenInput }) => {
    const { t } = useTranslation();
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [page, setPage] = useState(0);
    const filteredPointList = store.kataster.pointList.filter(
      (p) => !p.isTransformationpoint && p.isPassPoint()
    );

    const handleChangePage = (event, newPage) => {
      setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
      setRowsPerPage(parseInt(event.target.value));
      setPage(0);
    };

    const meterToCentiemeterClean = (num) => {
      if (!isNaN(num)) return (num * 100).toFixed(2);
      return "-";
    };

    const toCentiemeter = (num) => {
      if (!isNaN(num)) return (num / 10).toFixed(2);
      return "-";
    };

    const sortByPunktnummer= (a,b) => {
      return a.punktnummer.toLowerCase().localeCompare(b.punktnummer.toLowerCase());
    }

    const emptyRows =
      rowsPerPage -
      Math.min(rowsPerPage, filteredPointList.length - page * rowsPerPage);

    return (
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>
                <Tooltip
                  title={t("cadastre:selection_table_header_check_tooltip")}
                >
                  <span>
                    <FontAwesomeIcon icon={faCheck} />
                  </span>
                </Tooltip>
              </TableCell>
              <TableCell>
                <Tooltip
                  title={t("cadastre:selection_table_header_type_tooltip")}
                >
                  <span>{t("cadastre:selection_table_header_type")}</span>
                </Tooltip>
              </TableCell>
              <TableCell>
                <Tooltip
                  title={t("cadastre:selection_table_header_name_tooltip")}
                >
                  <span>{t("cadastre:selection_table_header_name")}</span>
                </Tooltip>
              </TableCell>
              <TableCell>
                <Tooltip
                  title={t("cadastre:selection_table_header_r_east_tooltip")}
                >
                  <span>{t("cadastre:selection_table_header_r_east")}</span>
                </Tooltip>
              </TableCell>
              <TableCell>
                <Tooltip
                  title={t("cadastre:selection_table_header_r_north_tooltip")}
                >
                  <span>{t("cadastre:selection_table_header_r_north")}</span>
                </Tooltip>
              </TableCell>
              <TableCell>
                <Tooltip
                  title={t("cadastre:selection_table_header_r_lage_tooltip")}
                >
                  <span>{t("cadastre:selection_table_header_r_lage")}</span>
                </Tooltip>
              </TableCell>
              <TableCell>
                <Tooltip
                  title={t("cadastre:selection_table_header_r_hight_tooltip")}
                >
                  <span>{t("cadastre:selection_table_header_r_hight")}</span>
                </Tooltip>
              </TableCell>
              <TableCell>
                <Tooltip
                  title={t("cadastre:selection_table_header_eas_tooltipt")}
                >
                  <span>{t("cadastre:selection_table_header_east")}</span>
                </Tooltip>
              </TableCell>
              <TableCell>
                <Tooltip
                  title={t("cadastre:selection_table_header_north_tooltip")}
                >
                  <span>{t("cadastre:selection_table_header_north")}</span>
                </Tooltip>
              </TableCell>
              <TableCell>
                <Tooltip
                  title={t("cadastre:selection_table_header_option_tooltip")}
                >
                  <span>
                    <FontAwesomeIcon icon={faCogs} />
                  </span>
                </Tooltip>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {filteredPointList
              .sort(sortByPunktnummer)
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((point) => (
                <TableRow
                  key={point.punktnummer}
                  sx={
                    point.residualsLength && point.residualsLength > 100 ?
                    {... classes.hoverRow, ...classes.toLong}:
                    classes.hoverRow
                  }
                >
                  <TableCell onClick={() => store.kataster.togglePoint(point)}>
                    {point.isDeterminationSelected &&
                      (point.isHeightSelected ? (
                        <FontAwesomeIcon icon={faCheckDouble} />
                      ) : (
                        <FontAwesomeIcon icon={faCheck} />
                      ))}
                  </TableCell>
                  <TableCell onClick={() => store.kataster.togglePoint(point)} >
                    <Avatar sx={{...classes.greenAvatar, ...(!point.isDeterminationSelected ? classes.unSelectedAvatar : {}), ...(point.residualsLength && point.residualsLength > 100 ? classes.toLongAvatar : {})}}>
                      {point.punktArt?.toUpperCase() ??
                        point.punktType?.toUpperCase() ??
                        "FP"}
                    </Avatar>
                  </TableCell>
                  <TableCell
                    sx={
                      !point.isDeterminationSelected ? classes.unSelected : {}
                    }
                    onClick={() => store.kataster.togglePoint(point)}
                  >
                    <div>{point.punktnummer}</div>
                    <Box sx={classes.messdatum}>
                      {point.etrs89Messdatum ?? false
                        ? format(new Date(point.etrs89Messdatum),'dd.MM.yyyy')
                        : "-"}
                    </Box>
                  </TableCell>
                  <TableCell
                    sx={
                      !point.isDeterminationSelected ? classes.unSelected : {}
                    }
                    onClick={() => store.kataster.togglePoint(point)}
                  >
                    {point.isDeterminationSelected ? (
                      toCentiemeter(point?.residualsEast)
                    ) : (
                      <span>
                        {point.hasValidCalculatedGkCoordinates() &&
                          meterToCentiemeterClean(
                            Math.abs(point.gkY - point.gkYCalculated).toFixed(3)
                          )}
                      </span>
                    )}
                  </TableCell>
                  <TableCell
                    sx={
                      !point.isDeterminationSelected ? classes.unSelected : {}
                    }
                    onClick={() => store.kataster.togglePoint(point)}
                  >
                    {point.isDeterminationSelected ? (
                      toCentiemeter(point?.residualsNorth)
                    ) : (
                      <span>
                        {point.hasValidCalculatedGkCoordinates() &&
                          meterToCentiemeterClean(
                            Math.abs(point.gkX - point.gkXCalculated).toFixed(3)
                          )}
                      </span>
                    )}
                  </TableCell>
                  <TableCell
                    sx={
                      !point.isDeterminationSelected ? classes.unSelected : {}
                    }
                    onClick={() => store.kataster.togglePoint(point)}
                  >
                    {point.isDeterminationSelected ? (
                      meterToCentiemeterClean(point?.residualsLage)
                    ) : (
                      <span>
                        {point.hasValidCalculatedGkCoordinates() && point.hasValidGkCoordinates() &&
                          meterToCentiemeterClean(
                            Math.sqrt(
                              Math.pow(point.gkY - point.gkYCalculated, 2) +
                                Math.pow(point.gkX - point.gkXCalculated, 2)
                            )
                          )
                          }
                      </span>
                    )}
                  </TableCell>
                  <TableCell
                    sx={
                      !point.isDeterminationSelected ? classes.unSelected : {}
                    }
                    onClick={() => store.kataster.togglePoint(point)}
                  >
                    {point.isDeterminationSelected ? (
                      meterToCentiemeterClean(point?.residualsHeight)
                    ) : (
                      <span>
                        {point.hasValidCalculatedGkCoordinates() &&
                          meterToCentiemeterClean(
                            Math.abs(
                              point.gkHoeheAdria - point.gkHoeheAdriaCalculated
                            ).toFixed(3)
                          )}
                      </span>
                    )}
                  </TableCell>
                  <TableCell
                    sx={
                      !point.isDeterminationSelected ? classes.unSelected : {}
                    }
                    onClick={() => store.kataster.togglePoint(point)}
                  >
                    {point.improvementsEast && !isNaN(point.improvementsEast)
                      ? point.improvementsEast
                      : "-"}
                  </TableCell>
                  <TableCell
                    sx={
                      !point.isDeterminationSelected ? classes.unSelected : {}
                    }
                    onClick={() => store.kataster.togglePoint(point)}
                  >
                    {point.improvementsNorth && !isNaN(point.improvementsNorth)
                      ? point.improvementsNorth
                      : "-"}
                  </TableCell>
                  <TableCell>
                    <MenuListComposition
                      point={point}
                      handleDelete={store.kataster.removePointFromList}
                      handleTogglePoint={store.kataster.togglePoint}
                      handleToggleHeight={store.kataster.togglePointHeight}
                      handleToggleIsTrafoPoint={
                        store.kataster.toggleIsTrafoPoint
                      }
                    />
                  </TableCell>
                </TableRow>
              ))}

            {emptyRows > 0 && (
              <TableRow style={{ height: 81 * emptyRows }}>
                <TableCell colSpan={10} />
              </TableRow>
            )}

            <TableRow sx={classes.hoverRow} onClick={handleOpenInput}>
              <TableCell />
              <TableCell>
                <Avatar sx={classes.smallAvatar}>
                  <FontAwesomeIcon icon={faPlus} />
                </Avatar>
              </TableCell>
              <TableCell colSpan={9}>
                {t("cadastre:selection_table_body_add_passpoint")}
              </TableCell>
            </TableRow>
          </TableBody>
          <TableFooter>
            <TableRow>
              <TableCell>
                <Tooltip
                  title={t("cadastre:selection_extent_passpoint_tooltip")}
                >
                  <IconButton
                    onClick={() => {
                      store.kataster.setExtentByPointListAndFilter("pp");
                      window.scrollTo(0, 250);
                    }}
                  >
                    <FontAwesomeIcon icon={faExpand} />
                  </IconButton>
                </Tooltip>
              </TableCell>
              <TableCell colSpan={9}>
                <TablePagination
                  rowsPerPageOptions={[5, 10, 25, 50]}
                  component="div"
                  count={filteredPointList.length}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                />
              </TableCell>
            </TableRow>
          </TableFooter>
        </Table>
      </TableContainer>
    );
  })
);
