import { crdError } from './parserErrors';
import store from '../../store/index';
import * as constants from '../constants';
import { prepareNumber } from 'common/utility';

export default function parseCRD(file) {
    const linesString = file.split("\n");
    const regLine = /\s*\d+\s+\w{4}\s.{9}\s+[+-]*\d+[,.]\d+\s+[+-]*\d+[,.]\d+\s+[+-]*\d+[,.]\d+/;
    const regHead = /\s*\w+\s+\w+\s+\w+\s+\d+[-]\w+[-]\d+\s+\d{2}[:]\d{2}/;
    const regInfoLine = /\s*\w+\s+\w+\s+(DATUM:)\s+\w+\s+/;
    
    let checkObject;
    let addVelocities = false;

    let geojson = {}
    geojson.type = "FeatureCollection";
    geojson.crs = {};
    geojson.features = [];

    const frameCheck = (checkFrame, frame) => frame.name === checkFrame;

    for(let lineString of linesString){
        const isLineValid = lineString.match(regLine);

        const line = lineString.split(/\s+/g);
        if (line[0] === "") { line.splice(0,1); }
        if (isLineValid) {
            const name = line[1] + " " + line[2];
            if(!addVelocities){
                const x = parseFloat(line[3].replace(',','.'));
                const y = parseFloat(line[4].replace(',','.'));
                const z = parseFloat(line[5].replace(',','.'));
                geojson.features.push(createFeatureObject(name, x, y, z));
            }else{
                const vx = parseFloat(line[3].replace(',','.'));
                const vy = parseFloat(line[4].replace(',','.'));
                const vz = parseFloat(line[5].replace(',','.'));
                findFeatureAndUpdate(geojson.features, name, vx, vy, vz);
            }
        }else if(lineString.match(regHead)){
            if(!!checkObject) addVelocities = true;
        }else if(lineString.match(regInfoLine)){
            const epoch = line[5];
            const frameName = line[3];
            if(!checkObject) checkObject = createCheckObject(epoch, frameName);
            else if(!checkObjectAgainst(checkObject,frameName)) throw new crdError(constants.ERROR_IMPORT_CRD_AND_VEL_DONT_MATCH)
            else{
                const {frameList} = store.getState().api;
                const foundFrame = frameList.filter(frameCheck.bind(this,checkObject.frame))[0]
                const d = new Date(checkObject.epoch);
                const aktepoch = prepareNumber(d.getFullYear() + (d.getMonth())/12 + d.getDate()/360, 2);

                geojson.crs = { type: 'BEV', 
                            name: '',
                            properties: { frame: foundFrame ? foundFrame.id : '', 
                                        ellipsoid: foundFrame ? 'EPSG::7030' : '', 
                                        epoch: aktepoch,
                                        prime_meridian: '',
                                        projection : '',
                                        height_system : '' 
                                        }
                            };
            }
        }
    }
    return geojson;
}

function filterFeatureListListByName(lineObjectList, name){
    return lineObjectList.filter(lineObject => {
        return lineObject.properties.name === name;
    })
}

function createFeatureObject(name,x,y,z) {
    let newFeatrue = {};
    newFeatrue.type = "Feature";
    newFeatrue.properties = {};
    newFeatrue.properties.name = name;
    newFeatrue.geometry = {};
    newFeatrue.geometry.type = 'Point' ;
    newFeatrue.geometry.coordinates = [x,y,z];

    return newFeatrue;
}

function findFeatureAndUpdate(featureList,name,vx,vy,vz){
    let filterdList = filterFeatureListListByName(featureList, name);
    if(filterdList.length > 0){
        filterdList[0] = updateFeatureVelocities(filterdList[0], vx,vy,vz);
    }
}

function updateFeatureVelocities(feature,vx,vy,vz){
    let updatedFeatrue = {...feature};
    updatedFeatrue.geometry.velocities = [vx, vy, vz];
    return updatedFeatrue;
}

function checkObjectAgainst(obj,frame){
    obj.count++;
    if(obj.count > 1) throw new crdError(constants.ERROR_IMPORT_CRD_TO_MANY);
    return obj.frame === frame;
}

function createCheckObject(epoch,frame){
    return {
        count: 0,
        epoch: epoch,
        frame: frame,
    };
}

