import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import CRSIconButton from './CRSIconButton';
import CRSLoadModal from'./CRSLoadModal';

const mapStateToProps = (state) => {
  return {
    frameList: state.api.frameList,
    referenceSystems: state.base.referenceSystems,
  };
}

class CRSLoadButton extends Component {
  static propTypes = {
    handleChangeCRS: PropTypes.func.isRequired
  }
  state = {
    isModalOpen: false
  }

  toggleModal = () => {
      this.setState({isModalOpen: !this.state.isModalOpen});
  }

  checkReferenceSystems = (crs) => {
    let {name} = crs;
    let {frame, ellipsoid, prime_meridian, epoch, projection} = crs.properties
    if(name === 'blank' && frame === '' && ellipsoid === '' && prime_meridian === '' && epoch === '2002.56' && projection === '') {
       return true
    }
    return this.props.frameList.filter(v => {return v.value.toString() === crs.properties.frame}).length > 0;
  }

  handleChangeCRS(index,i,y) {
      this.toggleModal();
      this.props.handleChangeCRS(index);
  }

  render() {
    const {isModalOpen} = this.state;
    const {referenceSystems} = this.props;
    
    return (<div style={{display:'inline-block'}}>
      <CRSIconButton icon={"folder-open"} handleClick={this.toggleModal} />
      <CRSLoadModal isModalOpen={isModalOpen} 
                    toggleModal={this.toggleModal} 
                    referenceSystems={referenceSystems} 
                    handleChangeCRS={this.handleChangeCRS.bind(this)}
                    checkReferenceSystems={this.checkReferenceSystems}/>
    </div>);
  }
}
export default connect(mapStateToProps)(CRSLoadButton);