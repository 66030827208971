import React from "react";
import {
  MenuItem,
  MenuList,
  Tooltip,
  IconButton,
  Grow,
  Paper,
  Popper,
  ClickAwayListener,
} from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEllipsisH } from "@fortawesome/free-solid-svg-icons";
import { useTranslation } from "react-i18next";

export function MenuListComposition({
  point,
  handleTogglePoint,
  handleDelete,
  handleToggleHeight,
  handleToggleIsTrafoPoint,
  small,
}) {
  const [open, setOpen] = React.useState(false);
  const anchorRef = React.useRef(null);
  const {t} = useTranslation();

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }

    setOpen(false);
  };

  const onHandleTogglePoint = (event) => {
    handleClose(event);
    handleTogglePoint(point);
  };

  const onHandleToggleHeight = (event) => {
    handleClose(event);
    handleToggleHeight(point);
  };

  const onHandleDelete = (event) => {
    handleClose(event);
    handleDelete(point);
  };

  const onHandleChangeToTrafoPoint = (event) => {
    handleClose(event);
    handleToggleIsTrafoPoint(point);
  };

  function handleListKeyDown(event) {
    if (event.key === "Tab") {
      event.preventDefault();
      setOpen(false);
    }
  }

  // return focus to the button when we transitioned from !open -> open
  const prevOpen = React.useRef(open);
  React.useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current.focus();
    }

    prevOpen.current = open;
  }, [open]);

  return (
    <div>
      <Tooltip title={t('cadastre:selection_menulist_title')}>
        <IconButton ref={anchorRef} onClick={handleToggle}>
          <FontAwesomeIcon icon={faEllipsisH} />
        </IconButton>
      </Tooltip>
      <Popper
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        disablePortal
        style={{ zIndex: 999 }}
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{ transformOrigin: "center bottom" }}
          >
            <Paper>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList
                  autoFocusItem={open}
                  id="menu-list-grow"
                  onKeyDown={handleListKeyDown}
                >
                  {!small && point.isDeterminationSelected && (
                    <MenuItem onClick={onHandleToggleHeight}>
                      {
                        point.isHeightSelected ? 
                        t('cadastre:selection_menulist_btn_height_release'):
                        t('cadastre:selection_menulist_btn_height_select')
                      }
                    </MenuItem>
                  )}
                  {!small && (
                    <MenuItem onClick={onHandleTogglePoint}>
                      {
                        point.isDeterminationSelected ? 
                        t('cadastre:selection_menulist_btn_point_release'):
                        t('cadastre:selection_menulist_btn_point_select')
                      }
                    </MenuItem>
                  )}
                  {point.isPassPoint() && point.isTransformationpoint && (
                    <MenuItem onClick={onHandleChangeToTrafoPoint}>
                      { t('cadastre:selection_menulist_btn_use_as_passpoint')}
                    </MenuItem>
                  )}
                  {point.isPassPoint() && !point.isTransformationpoint && !point.isHeightSelected && (
                    <MenuItem onClick={onHandleChangeToTrafoPoint}>
                      { t('cadastre:selection_menulist_btn_use_as_transformationpoint')}
                    </MenuItem>
                  )}
                  <MenuItem onClick={onHandleDelete}>{ t('cadastre:selection_menulist_btn_delete_point')}</MenuItem>
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </div>
  );
}
