import React, { useEffect, useRef, useState } from "react";

import "ol/ol.css";
import { Feature } from "ol";
import { extend, containsExtent } from "ol/extent";
import VectorSource from "ol/source/Vector";
import { useTheme } from "@mui/material/styles";
import { Point } from "ol/geom";
import { shared } from "ol/style/IconImageCache";

import { toast } from "react-toastify";
import i18next from "common/i18n";

import { stylingPassPoint, stylingArrow, centerInteractionStyling } from "./styling";

import { setSource, _addHoverEffect, _addInteractionHandlerHoverLabel, _addInteractionHandlerClickPoint, getFestpunktById } from "./helperFunctions";
import { unByKey } from "ol/Observable";

import {
  basicView,
  arrowLayer,
  basicMap,
  centerPointLayer,
  festpunktLayer,
  otherPointsLayer,
  passPointLayer,
  trafoPointsLayer,
  katasterLayer as createKatasterLayer,
  hoverFpLayer,
  openStreetMapLayer,
  orthophotoLayer,
  aMapLayer,
  baseMapLayer
} from "./layers";
import { fromLonLat } from "ol/proj";
import { OverlayLayerSwitcher } from "./overlayLayerSwitcher";
import { TOASTER_INFO_AUTOCLOSE } from "common/constants";

/* TO ASK
  - Attribution, wo kommen die Daten her kann man sie Manuel setzen ?
*/

/* TODO 'S
 - Hover Effecte einbinden. Aber wie ?
*/



export default function OpenLayerMap({
  pointsList,
  buildGeoJsonFunction,
  handleOnClick,
  onCenterSelect,
  extent,
  heightMode,
  center,
  setStoreMap,
  startSelectedMap
}) {
  const theme = useTheme();
  const [map, setMap] = useState(null);
  const [selectedMap, setSelectedMap] = useState(startSelectedMap ?? "kataster");
  const OpenStreetMapLayer = useRef(null);
  const OrthophotoLayer = useRef(null);
  const AMapLayer = useRef(null);
  const BaseMapLayer = useRef(null);

  const VectorPassPoint = useRef(null);
  const VectorLayerArrow = useRef(null);
  const VectorTrafoPoints = useRef(null);
  const VectorOtherPoints = useRef(null);
  const VectorLayerCenter = useRef(null);
  const FestPunktLayer = useRef(null);
  const KatasterLayer = useRef(null);
  const HoverLayer = useRef(null);
  const mapContainer = useRef(null);
  const styleRefFestpunkt = useRef(null);
  const [currentExtent, setCurrentExtent] = useState(null);

  // OnInit set init map an layers,
  useEffect(() => {
    shared.setSize(1024);
    const view = basicView();
    const newMap = basicMap({ view });

    VectorPassPoint.current = passPointLayer({ pointsList, theme });
    VectorLayerArrow.current = arrowLayer();
    VectorTrafoPoints.current = trafoPointsLayer();
    VectorOtherPoints.current = otherPointsLayer();
    VectorLayerCenter.current = centerPointLayer();
    FestPunktLayer.current = festpunktLayer();
    KatasterLayer.current = createKatasterLayer();

    OpenStreetMapLayer.current = openStreetMapLayer({ visible: false });
    OrthophotoLayer.current = orthophotoLayer({ visible: false });
    AMapLayer.current = aMapLayer({ visible: false });
    BaseMapLayer.current = baseMapLayer({ visible: false });

    newMap.addLayer(OpenStreetMapLayer.current);
    newMap.addLayer(OrthophotoLayer.current);
    newMap.addLayer(AMapLayer.current);
    newMap.addLayer(BaseMapLayer.current);

    newMap.addLayer(VectorPassPoint.current);
    newMap.addLayer(VectorLayerArrow.current);
    newMap.addLayer(VectorTrafoPoints.current);
    newMap.addLayer(VectorOtherPoints.current);

    newMap.addLayer(KatasterLayer.current);
    newMap.addLayer(FestPunktLayer.current);
    newMap.addLayer(VectorLayerCenter.current);

    newMap.setTarget(mapContainer.current)
    setMap(newMap);
    setStoreMap(newMap);

    // Pointer effect on Map
    _addHoverEffect(newMap);

    // Hover effect on Center Point
    _addInteractionHandlerHoverLabel(VectorLayerCenter.current, centerInteractionStyling, newMap);

    newMap.on(['pointermove'], e => {
      if (e.dragging) { return; }
      if (!!HoverLayer.current) newMap.removeLayer(HoverLayer.current);
      const list = newMap.getFeaturesAtPixel(e.pixel, { hitTolerance: 5 }).filter(f => f.getProperties().layer === "fp");

      const isInList = list.some(f => {
        const has_etrs = f.get("has_etrs");
        const is_rutschpunkt = f.get("is_rutschpunkt");

        const hasEtrs = (has_etrs == "false") != Boolean(has_etrs);
        const isRutschpunkt = (is_rutschpunkt == "false") != Boolean(is_rutschpunkt);
        const featureName = f.get("art") === "E" ? `${f.get("art")} ${f.get("oek")}-${f.get("nr")}${f.get("ue_kz")}` : `${f.get("art")} ${f.get("nr")}-${f.get("oek")}${f.get("ue_kz")}`;

        const inList = pointsList.some(p => {
          return p.isSameNameWithoutStabilisierung(featureName) || !hasEtrs || isRutschpunkt;
        });

        return inList;
      })

      if (isInList) return;

      HoverLayer.current = hoverFpLayer(FestPunktLayer.current.getSource(), list.map(f => f.getId()));
      newMap.addLayer(HoverLayer.current)

      const container = newMap.getTargetElement();
      if (list.length > 0) {
        container.style.cursor = "pointer";
      } else {
        container.style.cursor = "";
      }
    })

    let b, c;
    c = newMap.on(['click'], e => {
      const feature = newMap.getFeaturesAtPixel(e.pixel, { hitTolerance: 5 }).filter(f => f.type_ === "Point")[0];
      if (!feature) { return; }
      const fp_id = feature.getId();
      const container = e.target.getTargetElement();
      container.style.cursor = "wait";

      const has_etrs = feature.get("has_etrs");
      const is_rutschpunkt = feature.get("is_rutschpunkt");

      const hasEtrs = (has_etrs == "false") != Boolean(has_etrs);
      const isRutschpunkt = (is_rutschpunkt == "false") != Boolean(is_rutschpunkt);

      if (!hasEtrs || isRutschpunkt) {
        return toast.warning(i18next.t("cadastre:map_missing_etrs_waring"), { autoClose: 7000 });
      }

      toast.info(i18next.t("cadastre:toast_info_start_fetch"), TOASTER_INFO_AUTOCLOSE);
      getFestpunktById(fp_id).then(data => {
        const f = new Feature({
          geometry: new Point(fromLonLat(data.geometry.coordinates))
        })
        const nextProperties = { ...data.properties };
        
        //Find Stabilisierung
        // Get Correct Stabilisierung from list of Stabilisierung
      // Try to Get Stabilisierung with same kennzeichen as kennzeichen_darstellung from Point
      const selectedStab = nextProperties.stabilisierungen.find(stab => nextProperties.kennzeichen_darstellung === stab.kennzeichen && !!stab.etrs[0]) ?? 
      // IF null then sort Stabilisierung and find fist one with etrs attributes
      nextProperties.stabilisierungen.sort((a,b) => a.kennzeichen.localeCompare(b.kennzeichen)).find(s => !!s.etrs[0]);

      if (!selectedStab) return toast.error(t("cadastre:toast_info_missing_stab"), TOASTER_INFO_AUTOCLOSE);


        nextProperties.ue_kz = selectedStab.kennzeichen;
        nextProperties.rw_mgi = selectedStab.mgi[0];
        nextProperties.hw_mgi = selectedStab.mgi[1];
        nextProperties.hh_mgi = selectedStab.mgi[2];

        nextProperties.x3d_grs = selectedStab.etrs[0];
        nextProperties.y3d_grs = selectedStab.etrs[1];
        nextProperties.z3d_grs = selectedStab.etrs[2];

        nextProperties.has_etrs = hasEtrs;
        nextProperties.is_rutschpunkt = isRutschpunkt;

        f.setProperties(nextProperties)
        const featureName = f.get("art") === "E" ? `${f.get("art")} ${f.get("oek")}-${f.get("nr")}${selectedStab.kennzeichen}` : `${f.get("art")} ${f.get("nr")}-${f.get("oek")}${selectedStab.kennzeichen}`
        const isInList = pointsList.some(p => p.isSameNameWithoutStabilisierung(featureName))
        if (!!HoverLayer.current) newMap.removeLayer(HoverLayer.current);
        container.style.cursor = "";
        return isInList ? null : handleOnClick(f, "passPoints");

      });
    });

    b = newMap.on("contextmenu", function (event) {
      event.preventDefault();
      onCenterSelect(event.coordinate);
    });

    FestPunktLayer.current.once('change', e => {
      styleRefFestpunkt.current = e.target.getStyleFunction();
      FestPunktLayer.current.setStyle((f, resolution) => {
        const featureName = f.get("art") === "E" ? `${f.get("art")} ${f.get("oek")}-${f.get("nr")}${f.get("ue_kz")}` : `${f.get("art")} ${f.get("nr")}-${f.get("oek")}${f.get("ue_kz")}`
        const isInList = pointsList.some(p => p.isSameNameWithoutStabilisierung(featureName)) && f.getProperties().layer === "fp";
        return !isInList ? styleRefFestpunkt.current(f, resolution) : undefined;
      })
    })

    switchLayerTo(startSelectedMap);


    return () => {
      setMap(null);
      if (b) unByKey(b);
      if (c) unByKey(c);
    };
  }, []);

  useEffect(() => {

    if (!!map) {
      VectorLayerArrow.current.setStyle(stylingArrow(theme, false, heightMode));

      // Hover effect on ArrowPasspoints
      _addInteractionHandlerHoverLabel(VectorLayerArrow.current, stylingArrow(theme, true, heightMode), map);
      //Click Effect on ArrowPasspoints
      _addInteractionHandlerClickPoint(VectorLayerArrow.current, stylingArrow(theme, false, heightMode), map, handleOnClick);

    }
    return () => {
      if (!!map) {
        map.getInteractions().pop();
        map.getInteractions().pop();
      }
    };
  }, [map, heightMode]);

  useEffect(() => {
    if (extent && map) {
      map.getView().fit(extent, { padding: [50, 100, 50, 100] });
    }
  }, [extent, map]);

  useEffect(() => {
    if (map && center) {
      var point = new Point(center);
      const source = new VectorSource({
        features: [new Feature(point)],
      });
      VectorLayerCenter.current.setSource(source);
    }
  }, [center, map]);

  // On Prop solutionJson change render new solutionJson
  useEffect(() => {

    if (!!map) {
      // Hover effect on Passpoints
      _addInteractionHandlerHoverLabel(
        VectorPassPoint.current,
        stylingPassPoint(pointsList, theme, true, false),
        map
      );

      _addInteractionHandlerClickPoint(
        VectorPassPoint.current,
        stylingPassPoint(pointsList, theme, false, true),
        map,
        handleOnClick,
      );

      if (styleRefFestpunkt.current) {
        FestPunktLayer.current.setStyle((f, resolution) => {
          const featureName = f.get("art") === "E" ? `${f.get("art")} ${f.get("oek")}-${f.get("nr")}${f.get("ue_kz")}` : `${f.get("art")} ${f.get("nr")}-${f.get("oek")}${f.get("ue_kz")}`
          const isInList = pointsList.some(p => p.isSameNameWithoutStabilisierung(featureName))
          return !isInList ? styleRefFestpunkt.current(f, resolution) : undefined;
        })
      }
    }

    if (!!map && !!buildGeoJsonFunction && pointsList.length > 0) {
      const arrowJson = buildGeoJsonFunction(
        [(p) => p.isPassPoint(), (p) => !p.isTransformationpoint, (p) => p.isWgsValid()],
        "wgs84"
      );
      const trafoJson = buildGeoJsonFunction(
        [(p) => p.isTransformationpoint, (p) => p.isWgsValid()],
        "wgs84"
      );
      const otherJson = buildGeoJsonFunction(
        [(p) => !p.isPassPoint(), (p) => p.isWgsValid(), (p) => !p.isTransformationpoint],
        "wgs84"
      );

      if (arrowJson.features.length > 0) setSource(VectorLayerArrow.current, arrowJson, centerMapViewWithVectorSource, map);
      setSource(VectorTrafoPoints.current, trafoJson);
      setSource(VectorOtherPoints.current, otherJson);
    }
    if (!!map) {
      VectorPassPoint.current.setStyle(stylingPassPoint(pointsList, theme, false, false));
    }
  }, [pointsList, buildGeoJsonFunction, map]);

  const centerMapViewWithVectorSource = (mapObject, vectorSource) => {
    if (currentExtent) {
      const features = vectorSource.getFeatures().filter((f) => f.get("selected"));
      let nextExtent = JSON.parse(JSON.stringify(currentExtent));

      features.forEach((f) => {
        const featureExtent = f.getGeometry().getExtent();
        if (!containsExtent(nextExtent, featureExtent)) {
          extend(nextExtent, featureExtent);
        }
      });

      if (!containsExtent(currentExtent, nextExtent)) {
        mapObject.getView().fit(nextExtent, { padding: [50, 100, 50, 100] });
        setCurrentExtent(nextExtent);
      }
    } else {
      const nextExtent = vectorSource.getExtent();
      mapObject.getView().fit(nextExtent, { padding: [50, 100, 50, 100] });
      setCurrentExtent(nextExtent);
    }
  };

  const switchLayerTo = (layer) => {
    setSelectedMap(layer);
    if (layer === "osm") {
      OpenStreetMapLayer.current.setVisible(true);
      KatasterLayer.current.setVisible(false);
      OrthophotoLayer.current.setVisible(false);
      AMapLayer.current.setVisible(false);
      BaseMapLayer.current.setVisible(false);

    } else if (layer === "kataster") {
      OpenStreetMapLayer.current.setVisible(false);
      OrthophotoLayer.current.setVisible(false);
      KatasterLayer.current.setVisible(true);
      AMapLayer.current.setVisible(false);
      BaseMapLayer.current.setVisible(false);

    } else if (layer === "ortho") {
      OpenStreetMapLayer.current.setVisible(false);
      OrthophotoLayer.current.setVisible(true);
      KatasterLayer.current.setVisible(false);
      AMapLayer.current.setVisible(false);
      BaseMapLayer.current.setVisible(false);

    } else if (layer === "orthoKataster") {
      OpenStreetMapLayer.current.setVisible(false);
      OrthophotoLayer.current.setVisible(true);
      KatasterLayer.current.setVisible(true);
      AMapLayer.current.setVisible(false);
      BaseMapLayer.current.setVisible(false);

    } else if (layer === "amap") {
      OpenStreetMapLayer.current.setVisible(false);
      OrthophotoLayer.current.setVisible(false);
      KatasterLayer.current.setVisible(false);
      AMapLayer.current.setVisible(true);
      BaseMapLayer.current.setVisible(false);
    } else if (layer === "baseMap") {
      OpenStreetMapLayer.current.setVisible(false);
      OrthophotoLayer.current.setVisible(false);
      KatasterLayer.current.setVisible(false);
      AMapLayer.current.setVisible(false);
      BaseMapLayer.current.setVisible(true);
    }
  }


  return (
    <>
      <OverlayLayerSwitcher handleSwitchLayerTo={switchLayerTo} selectedMap={selectedMap} />
      <div id="mapContainer" ref={mapContainer} style={{ width: "100%", height: "100%" }} />
    </>
  );
}
