export const advanced = {
    "advanced_title_card" : "Bietet dem fachkundigen Anwender zahlreiche Einstellungsmöglichkeiten für die Auswahl des Ausgangs- und Zielsystem für maximale Flexibilität bei der Transformation. Unterschiedliche Koordinatensysteme, Ellipsoide und Projektionen können individuell eingestellt werden. Bei bekannten Geschwindigkeiten ist auch eine Epochentransformation durchführbar. ",
    "advanced_title_card_title" : "Advanced Modus",
    "advanced_load_save_dialog_save" : "Transformation speichern",
    "advanced_load_save_dialog_title" : "Transformation",
    "advanced_load_save_dialog_label" : "Name der Transformation",
    "advanced_crs_picker_source" : "Ausgangs CRS",
    "advanced_crs_picker_target" : "Ziel CRS",
    "advanced_coord_input_title" : "Zu transformierende Punkte",
    "advanced_select_trafo_title" : "Transformation wählen",
    "advanced_select_trafo_cancel" : "Abbrechen",
}