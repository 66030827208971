import WMTSCapabilities from 'ol/format/WMTSCapabilities';
import {DEVICE_PIXEL_RATIO} from 'ol/has';
import TileLayer from 'ol/layer/Tile';
import WMTS, {optionsFromCapabilities} from 'ol/source/WMTS';

const getBaseMap = (map, setter) => {
    const capabilitiesUrl = 'https://basemap.at/wmts/1.0.0/WMTSCapabilities.xml';
    // HiDPI support:
    // * Use 'bmaphidpi' layer (pixel ratio 2) for device pixel ratio > 1
    // * Use 'geolandbasemap' layer (pixel ratio 1) for device pixel ratio == 1
    const hiDPI = DEVICE_PIXEL_RATIO > 1;
    const layer = hiDPI ? 'bmaphidpi' : 'geolandbasemap';
    const tilePixelRatio = hiDPI ? 2 : 1;
    fetch(capabilitiesUrl).then(function(response) {
        return response.text();
    }).then(function(text) {
        const result = new WMTSCapabilities().read(text);
        const mapOptions = optionsFromCapabilities(result, {
            layer: layer,
            matrixSet: 'google3857',
            style: 'normal',
            crossOrigin: 'anonymous',
        });
        mapOptions.tilePixelRatio = tilePixelRatio;
        const orthoOptions = optionsFromCapabilities(result, {
            layer: 'bmaporthofoto30cm',
            matrixSet: 'google3857',
            style: 'normal',
            crossOrigin: 'anonymous',
        });
        orthoOptions.tilePixelRatio = tilePixelRatio;
        const basemap = new TileLayer({
            source: new WMTS(mapOptions),
            title: 'BaseMap',
            showInLegend: true,
            zIndex: 30,
            crossOrigin: 'anonymous',
      
        });
        const orthoPhoto = new TileLayer({
            source: new WMTS(orthoOptions),
            showInLegend: true,
            title: 'Orthophoto',
            zIndex: 20,
            crossOrigin: 'anonymous',
      
        });
        map.addLayer(orthoPhoto);
        map.addLayer(basemap);

        map.on('pointermove', function (e) {
            map.forEachFeatureAtPixel(e.pixel, function (f) {
                //console.log(f)
            });
        });

        setter(map);
    }).catch(e => {
        console.log('no BaseMap found');
        console.log(e);
        setter(map);
    })
};
export default getBaseMap;