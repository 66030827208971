export const bevTime = {
    "bev_time_title_card_title" : "Date Transformation",
    "bev_time_title_card_desc" : "Convert different date formats.",

    "bev_time_gird_examples_heute" : "Today",
    "bev_time_gird_examples_epoche1" : "BEV Epoch 2002.56",
    "bev_time_gird_examples_epoche2" : "Epoch 2000.0",

    "bev_time_index_title" : "Date Transformation",

    "bev_time_grid_input_label_mjd" : "Modified Julian Date (MJD)",
    "bev_time_grid_input_helper_mjd" : "Enter MJD as a float" ,

    "bev_time_grid_input_label_bdat" : "Date",
    "bev_time_grid_input_helper_bdat" : "YYYY-MM-DD" ,

    "bev_time_grid_input_label_gps" : "GPS Week",
    "bev_time_grid_input_helper_gps" : "WWWW.D" ,

    "bev_time_grid_input_label_doy" : "Year.DOY",
    "bev_time_grid_input_helper_doy" : "YYYY.DOY" ,

    "bev_time_grid_input_label_floating" : "Floating Year",
    "bev_time_grid_input_helper_floating" : "Enter year as a float" ,
}