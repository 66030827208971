import React, { Component } from 'react';
import { connect } from 'react-redux';


import CheckCRSDisplay from './CheckCRSDisplay';
import CheckCRSPopover from './CheckCRSPopover';

function mapStateToProps(state) {
    return {
        api: state.api,
    }
}

const mapDispatchToProps = (dispatch, ownProps) => {
    return {
    }
}

class CheckCRS extends Component {
  constructor(props) {
    super(props);
    this.state = {
      anchorEl: null,
    }
  }

  handleClick = event => {
    this.setState({ anchorEl: event.currentTarget })
  }
  handleClose = () => {
    this.setState({ anchorEl: null, })
  }

  render () {
    const { crs, extra_height_system } = this.props;
    const { frame, epoch, ellipsoid, projection, prime_meridian, height_system } = crs.properties;

    const { anchorEl } = this.state;
    const isPopoverOpen = Boolean(anchorEl);

    return !this.props.isInSelect ? 
      <span style={{display: 'inline-block' }}>
        <CheckCRSDisplay crs={crs} handleClick={this.handleClick} />
        <CheckCRSPopover isOpen={isPopoverOpen}
                        anchorEl={anchorEl}
                        onClose={this.handleClose}
                        frame={frame}
                        epoch={epoch}
                        ellipsoid={ellipsoid}
                        prime_meridian={prime_meridian}
                        projection={projection}
                        height_system={!height_system || height_system === ''  ? extra_height_system : height_system} />
      </ span> : 
      <span><CheckCRSDisplay crs={crs} handleClick={this.handleClick} /></span>
  }
}

CheckCRS.defaulProp = {
  isInSelect: false
}

export default connect(mapStateToProps, mapDispatchToProps)(CheckCRS);
