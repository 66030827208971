import React, { Component } from 'react';
import PropTypes from 'prop-types';

import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import IconButton from '@mui/material/IconButton';
import { withTranslation } from 'react-i18next';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

class BevDialog extends Component {
  /**
   * button, text of the save button
   * title, title of the Dialog
   * label, label of the input text
   * saveFunction, function that will be triggert on save event
   */
  static propTypes = {
    button: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired, 
    saveFunction: PropTypes.func.isRequired,
  }

  static defaultProps = {
    button: "Speichern",
    title: "Speicher Dialog",
    label: "Name", 
  }

  state = {
    open: false,
    name: null,
  }

  nameChange = (e) => {
    const name = e.target.value
    this.setState({ name });
  }

  toggleOpen = () => {
    this.setState({ open: !this.state.open });
  }

  save = () => {
    this.toggleOpen();
    this.props.saveFunction(this.state.name);
  }

  render () {
    const {open} = this.state;
    const {id,title,label, button, t} = this.props

      return (<div style={{display: 'inline'}}> 
          <IconButton style={{marginRight: 10 }} variant="contained" color="default"  key={id} onClick={this.toggleOpen}>
                <FontAwesomeIcon icon="save" />
          </IconButton>


          <Dialog open={open} onClose={this.toggleOpen} aria-labelledby="form-dialog-title">
              <DialogTitle id="form-dialog-title">{t('bev_dialog_save_as', {title})}</DialogTitle>
              <DialogContent>
                <TextField autoFocus onChange={this.nameChange} margin="dense" id="name" label={label} type="text" fullWidth />
              </DialogContent>
              <DialogActions>
                <Button onClick={this.toggleOpen} color="primary">{t('bev_dialog_cancle')}</Button>
                <Button onClick={this.save} color="primary">{button}</Button>
              </DialogActions>
          </Dialog>
      </div>
      );
  }
}

export default withTranslation()(BevDialog);
