import React from "react";
import PropTypes from "prop-types";

import BevModal from "../../BevModal";
import BevStandardSiteMap from "../../BevStandardSiteMap";
import { DEFAULT_PAGE_SIZE } from "../../../common/constants.js";
import { useTranslation } from "react-i18next";

const checkGeoJsonCadastre = (geoJson) => {
  const { name, properties } = geoJson.crs;
  if (!!name && name.includes("Cadastre")) return true;
  if (
    !!properties &&
    !!geoJson.filetype &&
    properties.frame === "301" &&
    geoJson.filetype === "csvega"
  )
    return true;
  return false;
};

ImportGeometryModal.propTypes = {
  isModalOpen: PropTypes.bool.isRequired,
  toggleModal: PropTypes.func.isRequired,
  hideSave: PropTypes.bool.isRequired,
  geometries: PropTypes.object,
};
export default function ImportGeometryModal({
  isModalOpen,
  toggleModal,
  geometries,
  hideSave,
}) {
  const { t } = useTranslation();
  return (
    <BevModal
      open={isModalOpen}
      width={980}
      onClose={toggleModal}
      title={t("point_import_geometry_title")}
    >
      <BevStandardSiteMap
        hideExport={true}
        hideSave={hideSave}
        correctCadastarPoints={checkGeoJsonCadastre(geometries)}
        geometry={geometries}
        pageSize={DEFAULT_PAGE_SIZE}
      />
    </BevModal>
  );
}
