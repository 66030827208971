import React from "react";
import { Alert } from "@mui/material";
import { useTranslation } from "react-i18next";

const Impressum = () => {
const {t} = useTranslation()
  return <div>
    <h3 style={{color: '#d00'}}>{t('impressum_title')}</h3>
      <address style={{fontStyle: 'normal'}}>
          <strong>{t('impressum_organisation')}</strong>
          <br />
          {t('impressum_street')}<br />
          {t('impressum_post_code')}<br />
          <br />
          Tel. +43 1 21110-0<br />
          <a href="https://www.bev.gv.at/">bev.gv.at</a><br />
          <a href="mailto:info@bev.gv.at">info@bev.gv.at</a><br />
          <br />
          {t('impressum_vat_number')}<br />
          <br />
          {t('impressum_bank')}<br />
          {t('impressum_iban')}<br />
          {t('impressum_bic')}<br />
          <br />
          {t('impressum_regulatory_body')}<br />
      </address>
      <h3 style={{ color: "#d00" }}>{t("impressum_terms_of_use")}</h3>
      <Alert color="warning">{t("impressum_liability")}</Alert>
    </div>
};

export default Impressum;
