import React, { Component } from 'react';

import { WIKI_LINK_DATE } from 'common/constants.js';

import BevCard from 'components/BevCard';
import ExtendedTitleCard from 'components/ExtendedTitleCard';

import BevTimeTransformator from './BevTimeTransformator';

import { withTranslation } from 'react-i18next';

class BevTime extends Component {
    render() {
      const {t}=this.props
        return (
            <div>
              <ExtendedTitleCard title={t("bev_time_title_card_title")} 
                description={t("bev_time_title_card_desc")} 
                wikiLint={WIKI_LINK_DATE} /> 
              <BevCard title={t("bev_time_index_title")}>
                <BevTimeTransformator 
                  today={true}
                  handleChange={(x) => console.log(x)}
                />
              </BevCard>
            </div>
        );
    }
}

export default withTranslation()(BevTime);