import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';

import * as actions from '../../store/actions';
import BevSaveDialog from '../BevSaveDialog'; 
import CRSIconButton from './CRSIconButton';

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    addCRS: (event) => dispatch( actions.base.addCRS( event ) ),
  }
}

class CRSSaveButton extends Component {
  state = {
    isDialogOpen: false,
    CRSName: '',
  }
  
  toggleDialog = () => {
    this.setState({isDialogOpen: !this.state.isDialogOpen})
  }

  handleNameChange = (event) => {
    this.setState({CRSName: event.target.value});
  }

  handleSaveCRS = () =>{
    this.toggleDialog();
    let crs = {
      type: 'BEV', 
      name: this.state.CRSName, 
      properties:{  
        frame: this.props.frame,
        ellipsoid: this.props.ellipsoid,
        prime_meridian: this.props.prime_meridian,
        projection: this.props.projection,
        epoch: this.props.epoch,
        name: this.state.CRSName,
      }
    }
    this.props.addCRS(crs); //Dispatched
  }

  render() {
    const { isDialogOpen } = this.state;
    const {t} = this.props
    return (<div style={{display:'inline-block'}}>
      <CRSIconButton icon={"save"} handleClick={this.toggleDialog} />
      <BevSaveDialog dialogTitle={t('container_crs_save_title')}
                      isDialogOpen={isDialogOpen}
                      toggleDialog={this.toggleDialog}
                      handleChange={this.handleNameChange}
                      handleSave={this.handleSaveCRS} />
    </div>
    );
  }
}
export default connect(null, mapDispatchToProps)(withTranslation()(CRSSaveButton));