import {Circle as CircleStyle, Fill, Icon, Stroke, Style, RegularShape, Text} from "ol/style";
import {getModifiedResiduals} from 'common/restklaffungsWorker';

const colorAustria = '#e6320f';
const colorAustria2 = 'hsla(9.8,87.8%,48%,0.1)';

const labelStyling = (label) => new Text({
    font: '12px Calibri,sans-serif',
    fill: new Fill({color: '#808'}),
    stroke: createStroke('#fff', 2),
    offsetY: -10,
    text: label,
});

const image = new CircleStyle({
    radius: 8,
    fill: new Fill({color: colorAustria}),
    stroke: new Stroke({color: colorAustria, width: 1})
});

const stroke = new Stroke({
    color: '#fff',
    width: 1.25
});

const fill_mp = new Fill({color: 'green'});
const fill_gp = new Fill({color: 'blue'});
const fill_so = new Fill({color: 'yellow'});

const pointStyleGp = (label) => !!label ? new Style({
    image: new CircleStyle({
        fill: fill_gp,
        stroke: stroke,
        radius: 4
    }),
    fill: fill_gp,
    stroke: stroke,
    text: labelStyling(label)
}) : new Style({
    image: new CircleStyle({
        fill: fill_gp,
        stroke: stroke,
        radius: 4
    }),
    fill: fill_gp,
    stroke: stroke
});
const pointStyleMp = (label) => !!label ? new Style({
    image: new CircleStyle({
        fill: fill_mp,
        stroke: stroke,
        radius: 4
    }),
    fill: fill_mp,
    stroke: stroke,
    text: labelStyling(label)
}) : new Style({
    image: new CircleStyle({
        fill: fill_mp,
        stroke: stroke,
        radius: 4
    }),
    fill: fill_mp,
    stroke: stroke,
});
const pointStyleSo = (label) => !!label ? new Style({
    image: new CircleStyle({
        fill: fill_so,
        stroke: stroke,
        radius: 4
    }),
    fill: fill_so,
    stroke: stroke,
    text: labelStyling(label)
}) : new Style({
    image: new CircleStyle({
        fill: fill_so,
        stroke: stroke,
        radius: 4
    }),
    fill: fill_so,
    stroke: stroke
});

const gp_point_styles = (label) => [pointStyleGp(label)];
const mp_point_styles = (label) => [pointStyleMp(label)];
const so_point_styles = (label) => [pointStyleSo(label)];

const createPassStyle = (fill, stroke, radius = 7) => {
    return new Style({
        image: new RegularShape({
            fill: fill,
            stroke: stroke,
            points: 3,
            radius: radius,
        })
    })
};

const createStroke = (color, width) => {
    return new Stroke({
        color: color,
        width: width,
    })
};

const styles = {
    'Point': new Style({
        image: image
    }),
    'LineString': new Style({
        stroke: new Stroke({
            color: colorAustria,
            width: 1
        })
    }),
    'MultiLineString': new Style({
        stroke: new Stroke({
            color: colorAustria,
            width: 1
        })
    }),
    'MultiPoint': new Style({
        image: image
    }),
    'MultiPolygon': new Style({
        stroke: new Stroke({
            color: colorAustria,
            width: 1
        }),
        fill: new Fill({
            color: colorAustria2
        })
    }),
    'Polygon': new Style({
        stroke: new Stroke({
            color: colorAustria,
            width: 1
        }),
        fill: new Fill({
            color: colorAustria2
        })
    }),
    'GeometryCollection': new Style({
        stroke: new Stroke({
            color: colorAustria,
            width: 1
        }),
        fill: new Fill({
            color: colorAustria2
        }),
        image: new CircleStyle({
            radius: 10,
            fill: null,
            stroke: new Stroke({
                color: colorAustria
            })
        })
    }),
    'Circle': new Style({
        stroke: new Stroke({
            color: colorAustria,
            width: 1
        }),
        fill: new Fill({
            color: colorAustria2
        })
    })
};

export const GeoJsonStyleFunction = function(feature) {
    return styles[feature.getGeometry().getType()];
};

const extraPointsStyle = (label) => {
    return {
        'MP': mp_point_styles(label),
        'GP': gp_point_styles(label),
        'SO': so_point_styles(label)
    }
}

export const ExtraJsonStyleFunction = function(feature) {
    return extraPointsStyle(null)[feature.get('type')];
};

export const ExtraJsonStyleFunctionHover = function(feature) {
    return extraPointsStyle(feature.get('type') + ' - ' + feature.get('name'))[feature.get('type')];
};

export const ArrowStyleFunction = (getResiduals, isUnselected, isEventSelect) => (feature, resolution) => {

    const strokeStyle = createStroke('#fff', 1.25);
    const textStyling = new Text({
        font: '12px Calibri,sans-serif',
        fill: new Fill({color: '#808'}),
        stroke: createStroke('#fff', 2),
        offsetY: -15,
        text: feature.get('name'),
    });

    const isSelected = !!isUnselected ? !isUnselected(feature.get('name')) : false;

    const residuals = !!getResiduals ? getResiduals(feature.get('name')) : null;
    const {length, angle} = !!residuals ? getModifiedResiduals(residuals) : {length: 0, angle: 0};

    const color = !!isUnselected ? (isSelected ? (!!length && length > 100 ? '#f55' : '#3cb371') : '#999') : '#3cb371';

    let f = '<svg version="1.1" baseProfile="full" width="' + (1.5 * length + 45) + '" height="50" xmlns="http://www.w3.org/2000/svg">';
    f += '<rect style="fill:' + color + ';fill-opacity:1" stroke="black" stroke-width="0" width="' + (1.5 * length) + '" height="8" x="0" y="20" />';
    f += '<path style="fill:' + color + ';fill-opacity:1" stroke="black" stroke-width="0" d="m ' + (1.5 * length) + ',24 0,-10 30,10 -30,10 z" /></svg>';

    const arrowStyle = new Style({
        image: new Icon({
            src: 'data:image/svg+xml;base64,' + window.btoa(f),
            anchor: [0, 0.5],
            anchorOrigin: 'bottom-left',
            scale: 0.5,
            rotation: angle * -1
        }),
        text: textStyling
    });

    const radius = isEventSelect ? 12 : 8;
    const pointStyle = createPassStyle(new Fill({color}), strokeStyle, radius);
    return !!getResiduals && isSelected ? [arrowStyle, pointStyle] : [pointStyle]

};