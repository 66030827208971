export const JSON = '{ \n "type": "FeatureCollection", \n "features": [ \n { \n "geometry": { \n "type": "Point", \n "coordinates": [ \n 16.293545916, \n 48.270403017, \n 603.05 \n] \n }, \n "type": "Feature", \n"properties": { \n"name": "10-40A1" \n} \n}, \n    { \n"geometry": { \n"type": "Point", \n"coordinates": [ \n16.293931972, \n48.270517011, \n587.57 \n] \n}, \n"type": "Feature", \n"properties": { \n"name": "10-40A2" \n} \n} \n] \n} \n';

export const GPX = '<?xml version="1.0"?>\n<gpx version="1.1" creator="GDAL 2.2.2" xmlns:xsi="http://www.w3.org/2001/XMLSchema-instance" xmlns:ogr="http://osgeo.org/gdal" xmlns="http://www.topografix.com/GPX/1/1" xsi:schemaLocation="http://www.topografix.com/GPX/1/1 http://www.topografix.com/GPX/1/1/gpx.xsd">\n<metadata><bounds minlat="48.270403017000000" minlon="16.293545915999999" maxlat="48.270517011000003" maxlon="16.293931971999999"/></metadata>                  \n<wpt lat="48.270403017" lon="16.293545916">\n  <name>10-40A1</name>\n  <extensions>\n    <ogr:crs>WGS84</ogr:crs>\n    <ogr:velocity_units>m/year</ogr:velocity_units>\n  </extensions>\n</wpt>\n<wpt lat=\n  "48.270517011" lon="16.293931972">\n  <name>10-40A2</name>\n  <extensions>\n    <ogr:crs>WGS84</ogr:crs>\n    <ogr:velocity_units>m/year</ogr:velocity_units>\n  </extensions>\n</wpt>\n</gpx>\n';
export const KML = '<?xml version="1.0" encoding="utf-8" ?>\n<kml xmlns="http://www.opengis.net/kml/2.2">\n<Document id="root_doc">\n<Schema name="hermannskogel" id="hermannskogel">\n                <SimpleField name="Description" type="string"></SimpleField>\n                <SimpleField name="crs" type="string"></SimpleField>\n                <SimpleField name="velocity_units" type="string"></SimpleField>\n</Schema>\n<Folder><name>hermannskogel</name>\n  <Placemark>\n                <name>10-40A1</name>\n                <description>10-40A1</description>\n                <ExtendedData><SchemaData schemaUrl="#hermannskogel">\n                               <SimpleData name="crs">WGS84</SimpleData>\n                               <SimpleData name="velocity_units">m/year</SimpleData>\n                </SchemaData></ExtendedData>\n      <Point><coordinates>16.293545916,48.270403017,603.05</coordinates></Point>\n  </Placemark>\n  <Placemark>\n                <name>10-40A2</name>\n                <description>10-40A2</description>\n                <ExtendedData><SchemaData schemaUrl="#hermannskogel">\n                               <SimpleData name="crs">WGS84</SimpleData>\n                               <SimpleData name="velocity_units">m/year</SimpleData>\n                </SchemaData></ExtendedData>\n      <Point><coordinates>16.293931972,48.270517011,587.57</coordinates></Point>\n  </Placemark>\n</Folder>\n</Document></kml>\n>' ;
export const CSV = '#Punktnummer; X; Y; Z \n10-40A1; 4082885.131; 1193419.671; 4737392.091 \n10-40A2; 4082858.119; 1193441.636; 4737388.976';
export const CSVEGA = 'PUNKTTYP;OeK50_BMN_NR;KG_NUMMER;PUNKTNUMMER;ORDNUNG;KENNZEICHEN;STABART;AUFLAGE;PUNKTNAME;HINWEIS;X;Y;Z;mX;mY;mZ;vX;vY;vZ;GEODATUM;EPOCHE;EPSG_XYZ;MESSDATUM;HERKUNFTSOPERAT;KOORD_BEST_XYZ;BREITE;LAENGE;HOEHE;EPSG_GEOGR;UNDULATION_GRS80;UNDULATION_BESSEL;RW;HW;EPSG_PROJ \nTP;40;01502;10;1;A1;PFEILER/OBERFLÄCHE;9;WIEN 19.,HERMANNSKOGEL;E8;4082885.131;1193419.671;4737392.091;0.003;0.001;0.002;0.000;0.000;0.000;ETRS89/AUSTRIA 2002;2002.50;4936;11.05.2011;OG040;aus GPS Basislinien abgeleitet;48.2704005240;16.2935424733;603.055;4937;44.9050;0.4460;595986.22;5347163.52;25833;\nTP;40;01502;10;1;A2;VERS.-STEIN/NORDOST/STEIN OBERFLÄCHE;9;WIEN 19.,HERMANNSKOGEL;E ;4082858.119;1193441.636;4737388.976;0.003;0.001;0.002;0.000;0.000;0.000;ETRS89/AUSTRIA 2002;2002.50;4936;11.05.2011;OG040;aus GPS Basislinien abgeleitet;48.2705145178;16.2939285293;587.575;4937;44.9040;0.4460;596014.65;5347176.67;25833;';
export const CRD = 'Hermannskogel coordinates                                        01-JUL-19 12:00 \n--------------------------------------------------------------------------------\nLOCAL GEODETIC DATUM: MGI               EPOCH: 2002-07-24 00:00:00 \n\nNUM  STATION NAME           X (M)          Y (M)          Z (M)     FLAG\n\n  1  HERM 10-40M000    4082885.13100  1193419.67100  4737392.09100    A\n  2  HER2 10-40M000    4082858.11900  1193441.63600  4737388.97600    A\n';
export const TEST = {"type":"FeatureCollection",
                      "totalFeatures":250,
                      "features":[{
                        "type":"Feature",
                        "id":"ZAEHLBEZIRKOGD.147148",
                        "geometry":{
                          "type":"Polygon",
                          "coordinates":[
                            [
                              [16.377395763535013,48.23583240431871],
                              [16.37715000350647,48.235726822429164],
                              [16.376918099663737,48.23560828492313],
                              [16.37624544547206,48.23529322077301],
                              [16.3758311066897,48.234954681506835],
                              [16.378364152299522,48.231689164486966],
                              [16.379522664640728,48.23020800530342],
                              [16.38073232114265,48.228645246017265],
                              [16.38078533761129,48.22862786858631],
                              [16.381286821177913,48.22798385036036],
                              [16.381307082906456,48.22791306600271],
                              [16.38238140291144,48.22654045789866],
                              [16.382604836920606,48.22625483397105],
                              [16.382781350824445,48.22602930316552],
                              [16.38285434878665,48.22593592418905],
                              [16.3830405382543,48.22569644959198],
                              [16.383063525873418,48.22566658281116],
                              [16.38306392915032,48.225666043055],
                              [16.3830659462747,48.22566415366112],
                              [16.383067963316716,48.22566217433534],
                              [16.38306930806613,48.22566091473938],
                              [16.3830699804408,48.22566028494138],
                              [16.383071997564752,48.225658395547406],
                              [16.3830741492786,48.225656506098424],
                              [16.383076301074535,48.225654706581324],
                              [16.383078587460346,48.2256529070092],
                              [16.38308087384599,48.22565110743704],
                              [16.383083160231493,48.22564930786484],
                              [16.383085312191316,48.225647688211346],
                              [16.38308544669908,48.225647598224484],
                              [16.383087867674277,48.22564579859724],
                              [16.383090288731584,48.22564408890179],
                              [16.38309270987099,48.22564246913823],
                              [16.383095265600254,48.22564084931964],
                              [16.383097821247063,48.22563913956908],
                              [16.383100377058287,48.225637609682245],
                              [16.383102932787057,48.22563598986346],
                              [16.383105623187955,48.225634459921544],
                              [16.383107775475644,48.2256331999952],
                              [16.383108313588696,48.22563292997956],
                              [16.383111003989278,48.22563140003751],
                              [16.383113694471987,48.225629960027284],
                              [16.383116519544544,48.225628519962],
                              [16.383119344699253,48.22562716982851],
                              [16.38312015182749,48.22562671983901],
                              [16.3831221697715,48.2256257297631],
                              [16.383124994925904,48.225624379629494],
                              [16.383127954752453,48.225623119372706],
                              [16.383130914496522,48.22562176918397],
                              [16.383133874322773,48.225620508927],
                              [16.383136834148896,48.225619248669986],
                              [16.383139794057215,48.225618078344795],
                              [16.38314288855533,48.22561690796447],
                              [16.383145983053325,48.22561573758409],
                              [16.38314907763355,48.22561465713548],
                              [16.383152172213624,48.225613576686825],
                              [16.38315526687597,48.225612586169966],
                              [16.383158496045716,48.22561150566609],
                              [16.383161725297747,48.22561051509402],
                              [16.383164820042115,48.225609614508784],
                              [16.383166972904096,48.22560898410458],
                              [16.383168049376277,48.22560871386839],
                              [16.383171413300264,48.2256078131729],
                              [16.38317464263422,48.22560691253237],
                              [16.38317800664039,48.22560610176854],
                              [16.383181236138974,48.22560538099161],
                              [16.383182312610998,48.22560511075529],
                              [16.38318460014494,48.225604570227596],
                              [16.383187964233237,48.22560384939538],
                              [16.383191328403885,48.225603218494946],
                              [16.38319469257445,48.22560258759443],
                              [16.383198056744934,48.225601956693815],
                              [16.38320142091533,48.2256013257931],
                              [16.383203708613838,48.22560096512881],
                              [16.383204919758025,48.22560078476909],
                              [16.3832059963123,48.22560060446445],
                              [16.383208284093204,48.22560033373195],
                              [16.383209629827274,48.225600153317075],
                              [16.38321178293575,48.225599792707726],
                              [16.383215147353297,48.2255994316023],
                              [16.383218646278213,48.225598980509744],
                              [16.383222145285547,48.22559861934898],
                              [16.38322564429284,48.22559825818811],
                              [16.383227932156043,48.225598077387104],
                              [16.383229008792693,48.225597987014176],
                              [16.383232507882408,48.225597715785014],
                              [16.383236007054617,48.225597534487605],
                              [16.383239506144264,48.225597263258216],
                              [16.38324300539894,48.2255971718925],
                              [16.383246504571066,48.22559699059479],
                              [16.383250138415608,48.22559689917372],
                              [16.38325242644377,48.22559689823604],
                              [16.383253637752794,48.22559689773959],
                              [16.383257137089977,48.22559689630535],
                              [16.38326063642716,48.225596894870996],
                              [16.38326413576436,48.22559689343653],
                              [16.383267635266698,48.22559707186575],
                              [16.38327113468648,48.22559716036299],
                              [16.38327463418886,48.22559733879197],
                              [16.38327813369128,48.22559751722088],
                              [16.38328163327631,48.22559778558157],
                              [16.38328513286138,48.225598053942164],
                              [16.383288632446487,48.225598322302645],[16.383290382280375,48.22559850144879],
                              [16.38329213211426,48.22559868059493],[16.383295631782065,48.22559903888707],
                              [16.38329899694267,48.22559948716625],[16.383302496693222,48.2255999353901],
                              [16.383305861853938,48.22560038366904],[16.383309361687257,48.22560092182458],
                              [16.383312726930757,48.22560146003523],[16.383313399962944,48.22560154969097],
                              [16.38331609217432,48.225601998245786],[16.383319592090544,48.2256026263329],
                              [16.383322957499608,48.225603344407034],[16.383326322826086,48.225603972549216],
                              [16.383328342088152,48.22560442137999],[16.38332968823533,48.22560469062313],
                              [16.383332919137434,48.22560549868413],[16.38333628454688,48.225606216757896],
                              [16.383337900039376,48.22560666575426],[16.383339515531897,48.225607114750595],
                              [16.38334288102425,48.22560792275607],[16.383346112009495,48.22560882074856],
                              [16.383349342994855,48.225609718741005],[16.383352574063036,48.22561070666523],
                              [16.383355670541434,48.225611694644634],[16.383357420706936,48.22561223351735],
                              [16.383358901609874,48.22561268256871],[16.383361998171246,48.225613760479845],
                              [16.38336509473275,48.22561483839092],[16.383368191294384,48.22561591630189],
                              [16.38337128793891,48.225617084144666],[16.383623187926474,48.22570583311477],
                              [16.383688216143707,48.225728648945115],[16.38372927959102,48.225743110992944],
                              [16.384698787469254,48.22608444685445],[16.384851087408926,48.22616586073944],
                              [16.384957334154315,48.22622274257417],[16.38497928369872,48.2262344244003],
                              [16.385970123363833,48.226764417599405],[16.387964926845154,48.22783129707824],
                              [16.38797349856809,48.227922574085],[16.387976628611106,48.22795656692883],
                              [16.388049292596552,48.228071916756114],[16.388132940172653,48.22813429168567],
                              [16.388134152452565,48.228135190456285],[16.38818102332676,48.228166015885265],
                              [16.38791706973301,48.22935728281238],[16.3879135858358,48.229372842594955],
                              [16.387344324756864,48.229874018055085],[16.386814860242513,48.23034009969317],
                              [16.386784737546215,48.23036871129729],[16.387050054869277,48.23052039907237],
                              [16.38741313404588,48.2307104435689],[16.387406006630012,48.23071701177256],
                              [16.38770524654846,48.23086751377987],[16.38773541305917,48.23088269872649],
                              [16.387739313041003,48.23087918963405],[16.387959774777915,48.23099339384274],
                              [16.387955874711682,48.2309968130108],[16.387990890118164,48.231014963455195],
                              [16.387994256947024,48.231016670641594],[16.388086374669676,48.23106456272566],
                              [16.387888945087056,48.23123264444901],[16.38722081271978,48.231819208906415],
                              [16.38715630693406,48.231789649978474],[16.38702262803245,48.23191030780964],
                              [16.387036772145077,48.231920823572366],[16.386410731714214,48.232485241379436],
                              [16.386258623990887,48.232622363887586],[16.385680973266815,48.23313216793575],
                              [16.385114974669328,48.23359214185357],[16.38502771496045,48.23355872436449],
                              [16.38471565619794,48.233810485829395],[16.38458731934736,48.233897863763275],
                              [16.384494130620098,48.23385815314368],[16.38431618789475,48.23401623802118],
                              [16.384368046385145,48.23405119974538],[16.38431559648743,48.23410320231526],
                              [16.38433889461954,48.23411407429645],[16.384090871216927,48.23432983445824],
                              [16.384064359636938,48.23433748971588],[16.383756212397465,48.234607772859356],
                              [16.38349487960983,48.234847638946576],[16.383517645013473,48.2348645367417],
                              [16.38337171701474,48.235004080983884],[16.38307048813096,48.23534063913376],
                              [16.3827425413916,48.23575949487756],[16.382640607894636,48.235727790240674],
                              [16.38255054518966,48.23587189732827],[16.382613578781214,48.23590820429683],
                              [16.38263769077986,48.23592537151394],[16.38234207198379,48.236523536917296],
                              [16.382177903416583,48.23704727537478],[16.38204916799632,48.237154435569494],
                              [16.382016871457147,48.237469299499104],[16.382171881348235,48.237537315862035],
                              [16.38215572432642,48.23798482265448],[16.3820220851584,48.23803056139074],
                              [16.381880943201914,48.23796604665213],[16.381804437690693,48.237921111200556],
                              [16.381401886528685,48.23773385266246],[16.380697525186555,48.23740057100721],
                              [16.38048446738849,48.23729930031651],[16.379789404968328,48.236963941056004],
                              [16.37944639936002,48.23681100694549],[16.378237974190192,48.23621907535573],
                              [16.37801442371007,48.23611501602514],[16.377690002070473,48.23595694377135],
                              [16.377395763535013,48.23583240431871]
                            ]
                          ]
                        },
                        "geometry_name":"SHAPE",
                        "properties":{
                          "BEZNR":20,
                          "ZBEZNR":8,
                          "BEZ":"20",
                          "ZBEZ":"2008",
                          "FLAECHE":631813.8171,
                          "UMFANG":3607.3,
                          "AKT_TIMESTAMP":"2019-04-06Z",
                          "SE_SDO_ROWID":147148,
                          "SE_ANNO_CAD_DATA":null
                        }
                      }],
                      "crs":{
                        "type":"name",
                        "properties":{
                          "name":"urn:ogc:def:crs:EPSG::4326"
                        }
                      }
                    }