import * as actionTypes from '../actions/actionTypes';

const initialState = {
    user: {
        firstname: 'Anonymus',
        lastname: 'Anonymus',
        username: 'Anonymus',
        organisation: 'none',
        groups: [], //['TRAFO_Admin', 'TRAFO_Manager'],
    },
    tokenAuthentication: false,
    token: null,
    stpAuthentication: false,
    user_loading: true,
    config: {headers: {'Content-Type': 'application/json' }},
    apiDown: false,
}


const reducer = ( state = initialState, action) => {
    let conf = state.config
    switch ( action.type ) {

        case actionTypes.AUTH_LOAD_USER:
        return {...state, user_loading: action.val }

        case actionTypes.AUTH_API_DOWN:
        return {...state, apiDown: true }

        case actionTypes.AUTH_API_UP:
        return {...state, apiDown: false }

        case actionTypes.AUTH_SET_STP_AUTHENTICATION:
        delete conf.headers.Authorization
        return {...state, stpAuthentication: action.val, tokenAuthentication: false, config: conf }

        case actionTypes.AUTH_SET_TOKEN_AUTHENTICATION:
        return {...state, tokenAuthentication: action.val }

        case actionTypes.AUTH_UPDATE_USER:
        if(!state.stpAuthentication && state.tokenAuthentication) conf.headers.Authorization = 'Token ' + action.data.token
        // eslint-disable-next-line
        case actionTypes.AUTH_SET_ANONYMOUS_USER:
        return {...state, user: action.data.user, token: action.data.token, config: conf }

        case actionTypes.AUTH_UNSET_USER_SETTINGS:
        delete conf.headers.Authorization;
        return {...state, tokenAuthentication: false, token: null, stpAuthentication: false, user_loading: true, user: {username: 'anonymous', groups: []}, config: conf}

        default:
            return state;
    }
}

export default reducer;
