import React, { useEffect, useRef } from "react";
import "ol/ol.css";
import { Map, View } from "ol";
import { fromLonLat } from "ol/proj";
import { ScaleLine, ZoomSlider, Zoom } from "ol/control";
import { ortho, katasterLayer, symboleLayer } from "./layer";

export default function OpenLayerMap({ onCenterSelect }) {
  const mapContainer = useRef(null);

  // OnInit set init map an layers,
  useEffect(() => {
    const view = new View({
      center: fromLonLat([15, 47]),
      zoom: 7,
      maxZoom: 21,
    });

    const newMap = new Map({
      layers: [
        //ortho,
        katasterLayer('vermv'),
        symboleLayer('vermv'),
      ],
      target: mapContainer.current,
      view: view,
      controls: [
        new ScaleLine(),
        new ZoomSlider(),
        new Zoom(),
      ],
    });

    newMap.on("contextmenu", function (event) {
      event.preventDefault();
      onCenterSelect(event.coordinate, newMap);
    });
    newMap.getView().setCenter(fromLonLat([13.32456, 47.59397]));
    newMap.getView().setZoom(7.5);

    return () => {
      newMap.setTarget(null);
    };
  }, []);

  return (
    <div
      id="mapContainerSearch"
      ref={mapContainer}
      style={{ width: "100%", height: "100%" }}
    />
  );
}
