export const page = {
    "api_keys_copy" : "Erfolgreich kopiert",
    "api_keys_title" : "API Keys",
    "api_keys_desc" : "Hier können die API Keys verwaltet werden.",
    "api_keys_new_key" : "Neuen API Key erzeugen",
    "api_keys_table_active_inactive" : "'aktiv' : 'inaktiv'",
    "api_keys_user_active_inactive" : "User aktiv / inaktiv setzen",
    "api_keys_active_inactive" : "'API Key ist aktiv' : 'API Key ist inaktiv'",

    "coord_format_desc" : "Bitte Koordinaten eingeben",
    
    "geometry_manager_title" : "Punktspeicher",
    "geometry_manager_desc" : "Bearbeiten, löschen, importieren, exportieren von Dateien in allen möglichen Varianten.",
    "geometry_manager_title2" : "Punktspeicher",
    "geometry_manager_list_icon" : "Geometrieliste",
    "geometry_manager_import_icon" : "Datei importieren",
    "geometry_manager_view_tip" : "Klicken um die Geometrie anzuschauen",

    "home_title_news_text" : "Neu: BEV Transformator 3.0. Informationen zum BEV – Transformator finden sie <1> hier </1>",
    "home_title_austrian" : "Austrian Mode",
    "home_desc_austrian" : "Transformieren Sie Koordinaten innerhalb Österreichs mit den vom BEV zur Verfügung gestellten Produkten GIS-Grid in der Lage, sowie Geoid, Höhengrid und Höhengrid+Geoid für die Höhentransformation.",
    "home_title_kataster" : "Kataster Mode",
    "home_desc_kataster" : "Transformieren Sie Ihre mit GNSS bestimmten Koordinaten bei Katastervermessungen in das amtliche Koordinatensystem MGI/GK basierend auf den nächstgelegenen Festpunkten - robust in Lage und Höhe.",
    "home_title_farming" : "Farming Mode",
    "home_desc_farming" : "Sie haben ihre Feldgrenzen im ITRF2014 und wollen mit APOS arbeiten? Dann können Sie mit diesem Werkzeug ganz einfach in das APOS Koordinatensystem ETRS89 transformieren.",
    "home_title_advanced" : "Advanced Mode",
    "home_desc_advanced" : "Transformieren Sie ihre Koordinaten aus beliebigen geodätischen Koordinatensystemen. Hier können Sie zwischen Systemen in unterschiedlichen Referenzrahmen (Reference frames), auf unterschiedlichen Ellipsoiden und mit unterschiedlichen Projektionen wählen. Auch unterschiedliche Epochen und Geschwindigkeiten können umgerechnet werden.",

    "impressum_title" : "Impressum",
    "impressum_organisation" : "BEV - Bundesamt für Eich- und Vermessungswesen",
    "impressum_street" : "Schiffamtsgasse 1-3",
    "impressum_post_code" : "1020 Wien",
    "impressum_regulatory_body": "Aufsichtsbehörde: Bundesministerium für Arbeit und Wirtschaft",
    "impressum_vat_number" : "UID: ATU 38473200",
    "impressum_terms_of_use" : "Nutzungsbedingungen",
    "impressum_liability" : "Alle Angaben (Berechnungsergebnisse) sind trotz sorgfältigster Bearbeitung/Verarbeitung ohne Gewähr. Eine Haftung des BEV ist ausgeschlossen.",
    "impressum_bank" : "Bankverbindung",
    "impressum_iban" : "IBAN: AT95 0100 0000 0519 0001",
    "impressum_bic" : "BIC: BUNDATWW",

    "maintenance" : "Auf Grund von Wartungsarbeiten ist der {{modus}} derzeit nicht verfügbar. Wir danken für Ihr Verständnis.",

    "point_manager_title_geo" : "Geometrien",
    "point_manager_title_coord" : "Koordinatensystem",
    "point_manager_title_transform" : "Transformieren",
    "point_manager_title_export" : "Export",
    "point_manager_title_delete" : "Löschen",
    "point_manager_warning" : "Achtung Geometrie löschen",
    "point_manager_warning2" : "Jetzt wirklich die Geometrie \"{{name}}\" löschen?",
    "point_manager_coord_from" : "Koordinatensystem von \"{{name}}\" festlegen",
    "point_manager_coord_to" : "Transformieren nach",
    "point_manager_coord" : "Transformieren",
    "point_manager_file_name" : "Dateinamen wählen",

    "point_source_single" : "Einzelpunkt",
    "point_source_list" : "Punktliste",
    "point_source_saved" : "Gespeicherte Geometrien",
    "point_source_tip" : "Klicken um die Transformation zu starten",


}