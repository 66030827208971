import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import BevCard from 'components/BevCard';
import { getCoordType } from 'common/utility';

import PointSource from 'components/PointSource';

import { checkIfTransformReady } from './AustrianHelperFunctions';
import { AUSTRIAN_CRS } from 'common/austrianCrsList';

import { useTranslation } from 'react-i18next';

const mapStateToProps = state => {
  return {
    sourceCRS: state.austrian.sourceCRS,
    sourceHeight: state.austrian.sourceHeight, // needed for the CheckIfTransformReady
    targetCRS: state.austrian.targetCRS, // needed for the CheckIfTransformReady
    targetHeight: state.austrian.targetHeight,// needed for the CheckIfTransformReady
    transformationMethod: state.austrian.transformationMethod, // needed for the CheckIfTransformReady
  }
}

/**
 * tabindex, a number to set the show tab
 * openPoints, a boolean to open/cloase the card
 * velocities, a boolean to show/hide the input of Velocities
 * toggleOpenPoints, a funciton called on toggeleOpenPoints
 * startTransformation, a function called when start Transforamtion is triggered
 * handleChangeTabIndex, a function called on change tabe index
 */

PointSourceCardFunc.propTypes = {
  tabindex: PropTypes.number,
  openPoints: PropTypes.bool,
  velocities: PropTypes.bool,
  toggleOpenPoints: PropTypes.func,
  startTransformation: PropTypes.func.isRequired,
  handleChangeTabIndex: PropTypes.func
}

PointSourceCardFunc.defaultProps = {
  tabindex: 0,
  openPoints: true,
  velocities: true,
  errorMessage: false,
}

function PointSourceCardFunc (props) {
  const { tabindex, openPoints, velocities, loadedGeoJson } = props; // State Variables
  const { toggleOpenPoints,startTransformation,handleChangeTabIndex } = props; // State Functions
  
  const { sourceCRS } = props; //Store Variables
  const {t} = useTranslation();

  const handleChange = (i) => {handleChangeTabIndex({tabindex: i})}; 
  return <BevCard title={t("austrian_point_source_card_title")}
                  show={checkIfTransformReady(props)} 
                  open={openPoints} 
                  toggleOpen={toggleOpenPoints}>
    { checkIfTransformReady(props) && 
      <PointSource 
        coordType={getCoordType(AUSTRIAN_CRS[sourceCRS])} 
        sourceCrs={AUSTRIAN_CRS[sourceCRS]} 
        onStartTransformation={startTransformation} 
        velocities={velocities}
        tabindex={tabindex}
        handleChangeTabIndex={handleChange}
        loadedGeoJson={loadedGeoJson}/> }
  </BevCard>
}

const PointSourceCard = connect(mapStateToProps)(PointSourceCardFunc);
export default PointSourceCard;