import React from 'react';
import PropTypes from 'prop-types';

import {TextField, MenuItem} from '@mui/material';

CRSInputSelect.propTypes = {
  value: PropTypes.string.isRequired,
  options: PropTypes.array.isRequired,
  label: PropTypes.string,
  selectId: PropTypes.string,
  handleChange: PropTypes.func,
  isDisabled: PropTypes.bool,
  hasError: PropTypes.bool,
  focusInput: PropTypes.bool,
  isRequired: PropTypes.bool,
}

function CRSInputSelect ({value, options, label, hasError, isRequired, focusInput, selectId, handleChange, isDisabled, hideNoValue}) {
  return (
      <TextField select
                  id={selectId}
                  label={label}
                  value={value}
                  onChange={handleChange}
                  fullWidth
                  margin="normal"
                  disabled={isDisabled}
                  required={isRequired}
                  error={hasError}
                  InputProps={{ error: focusInput, sx:{ borderBottomWidth: hasError ? '2px' : '1px'} } }>
        { !hideNoValue && <MenuItem key="NaN" value="">-- No {label} --</MenuItem> }
        {options.map((option,i) => (
          <MenuItem key={i} value={option.value}>{option.display_name}</MenuItem>
        ))}
      </TextField>
    )

}

export default CRSInputSelect;