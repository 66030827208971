export const helmert = {
    "helmert_title_card_title" : "Transformation mit Helmert-Parametern",
    "helmert_title_card_info" : "Anwendung der ebenen oder räumlichen Helmert-Transformation mit eigenen Transformationsparametern.",
    "helmert_title_card_2d" : "Beispiel 2D",
    "helmert_title_card_3d" : "Beispiel 3D",
    "helmert_title_card_info2d" : "Weitere Informationen 2D",
    "helmert_title_card_info3d" : "Weitere Informationen 3D",
    "helmert_title_card_restart" : "Berechnung neustarten",

    "helmert_helmert_parameter" : "Parametereingabe",
    "helmert_helmert_punkte" : "Zu transformierende Punkte",

    "helmert_trafo_load" : "Transformation auswählen",
    "helmert_trafo_load_cancel" : "Abbrechen",

    "helmert_trafo_save_save" : "Transformationsparameter speichern",
    "helmert_trafo_save_parameter" : "Helmert-Parameter",
    "helmert_trafo_save_name" : "Transformationsname",

    "helmert_input_two_scale" : "Maßstab",
    
    "helmert_input_three_scale" : "Maßstab",


}