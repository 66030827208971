import { jsonError } from './parserErrors';

import * as constants from '../constants';

var GJV = require("geojson-validation");
  
export default function parseJson(string){
    let x = {}
    try{
        x = JSON.parse(string);
    }catch(error){
        throw new jsonError('Kein valides JSON Format: ' + error);
    }
    for(let feature of x.features){
        if(!GJV.isFeature(feature) && !GJV.isFeatureCollection(feature)) throw new jsonError(constants.ERROR_IMPORT_JSON_FEATURE_VALID + JSON.stringify(feature));
    }
    return x;
}