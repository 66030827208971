import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { toast } from "react-toastify";
//import ReactJson from "react-json-view";
import * as actions from "../store/actions";

import Divider from "@mui/material/Divider";
import List from "@mui/material/List";
import Tooltip from "@mui/material/Tooltip";
import IconButton from "@mui/material/IconButton";
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import Geometry from "../common/geometries";
import BevPagination from "../components/BevPagination";
import { GeomText } from "../common/geometryText";
import ExportFiles from "../components/points/export";
import BevSaveDialog from "../components/BevSaveDialog";
import prepareFileName from "../common/prepareFileName";
import * as constants from "../common/constants.js";
import OpenLayerMap from "./OpenLayerMap";
import { withTranslation } from "react-i18next";

const mapStateToProps = (state) => {
  return {
    fileId: state.points.fileId,
    fileNameCounter: state.points.fileNameCounter,
    fileName: state.points.fileName,
    geometryList: state.points.geometryList,
    config: state.auth.config,
  };
};
const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    updateGeometryFile: (file, id) =>
      dispatch(actions.points.updateGeometryFile(file, id)),
    addGeometryFile: (file) => dispatch(actions.points.addGeometryFile(file)),
    findNextFileNumber: () => dispatch(actions.points.findNextFileNumber()),
    setFileName: (fileName) => dispatch(actions.points.setFileName(fileName)),
  };
};

export class BevStandardSiteMap extends Component {
  state = {
    offset: 0,
    originaledit: "",
    isSaveDialogOpen: false,
    fileName: "",
    link: null,
    tabindex: 0,
  };

  componentDidMount = () => {
    this.props.setFileName(prepareFileName(this.props.geometry?.name ?? "newFileName"));
  };

  handleClickOffset = (offset) => {
    this.setState({ offset });
  };

  updateGeometryFile = (e) => {
    this.props.updateGeometryFile(e.updated_src, this.props.fileId);
  };
  handleBlur = (e) => {
    this.setState({ originaledit: e.target.value });
  };
  saveGeoJson = () => {
    this.props.geometry.name = this.state.fileName;
    this.props.addGeometryFile(this.props.geometry);
    this.toggleSaveDialog();
    toast.success(this.props.t('site_map_toaster_success', { fileNam: this.state.fileName }), { autoClose: 2500 });
    this.setState({ link: this.props.geometryList.length - 1 });
  };
  goToGeometry = () => {
    this.props.history.push(
      constants.BASE_URL + "geommanager/" + this.state.link
    );
  };
  toggleSaveDialog = () => {
    if (!this.state.isSaveDialogOpen) {
      this.props.findNextFileNumber();
      setTimeout(() => {
        this.setState({
          fileName: this.props.fileName + "_" + this.props.fileNameCounter,
        });
      }, 10);
    }
    this.setState((prev) => {
      return { isSaveDialogOpen: !prev.isSaveDialogOpen };
    });
  };

  changeFileName = (event) => {
    this.setState({ fileName: event.target.value });
  };

  handleChangeTabIndex = (e, tabindex) => {
    this.setState({ tabindex })
  }

  render() {
    const {
      geometry,
      fileId,
      pageSize,
      hideMap,
      hideSave,
      hideExport,
      config,
      arrowPoints,
      extraPoints,
      handleOnExport,
      t,
    } = this.props;
    const { isSaveDialogOpen, fileName, link, tabindex } = this.state;

    const g = geometry;
    return (
      !!g && (
        <div>
          {!hideSave && (
            <div>
              <Tooltip disableFocusListener title={t('site_map_tootltip_save_geo')}>
                <IconButton
                  variant="outlined"
                  color="default"
                  onClick={this.toggleSaveDialog}
                >
                  <FontAwesomeIcon icon={"save"} />
                </IconButton>
              </Tooltip>
              {link && (
                <Tooltip title={t('site_map_tootltip_to_manager')}>
                  <IconButton
                    variant="outlined"
                    color="default"
                    onClick={this.goToGeometry}
                  >
                    <FontAwesomeIcon icon={"external-link-alt"} />
                  </IconButton>
                </Tooltip>
              )}
            </div>
          )}
          <BevSaveDialog
            dialogTitle={t('site_map_dialog_file_name_title')}
            isDialogOpen={isSaveDialogOpen}
            toggleDialog={this.toggleSaveDialog}
            handleChange={this.changeFileName}
            handleSave={this.saveGeoJson}
            value={fileName}
          />
          <Tabs
            value={tabindex}
            onChange={this.handleChangeTabIndex}
            sx={{
              borderBottomColor: "primary.light",
              borderBottomWidth: 1,
              borderBottomStyle: "solid",
            }}
            indicatorColor="primary"
            textColor="primary"
          >
            <Tab value={0} label={t("site_map_tablist_tab_geome")} />
            {/*<Tab value={1} label={t("site_map_tablist_tab_geojson")} />*/}
            <Tab value={2} label={t("site_map_tablist_tab_geometext")} />
            {!hideMap && <Tab value={3} label={t("site_map_tablist_tab_map")} />}
            {!hideExport && <Tab value={4} label={t("site_map_tablist_tab_export")} />}
          </Tabs>
          {tabindex === 0 &&
            <BevPagination
              stepSize={pageSize}
              setPagination={this.handleClickOffset}
              length={g.features.length}
            >
              <List>
                {g.features.map(
                  (gg, ii) =>
                    this.state.offset <= ii &&
                    ii < this.state.offset + pageSize && (
                      <div key={ii}>
                        <Geometry src={gg} pageSize={pageSize} /> <Divider />
                      </div>
                    )
                )}
              </List>
            </BevPagination>
          }
          {tabindex === 1 &&
            <ReactJson
              src={g}
              name="features"
              onEdit={this.updateGeometryFile}
              onDelete={true}
              onAdd={this.updateGeometryFile}
              collapsed="2"
              style={{ maxHeight: "75vh", overflow: "auto" }}
            />
          }
          {tabindex === 2 &&
            <div style={{ maxHeight: "75vh", overflow: "auto" }}>
              <h4>{t('site_map_tablist_tab_geometext')}</h4>
              <GeomText src={g} />
            </div>
          }
          {tabindex === 3 &&
            <div style={{ height: "600px", width: "100%" }}>
              <OpenLayerMap
                solutionJson={g}
                config={config}
                arrowJson={arrowPoints}
                extraJson={extraPoints}
              />
            </div>
          }
          {tabindex === 4 &&
            (this.props.hasOwnProperty("handleOnExport") ? (
              <ExportFiles
                file={g}
                handleOnExport={this.props.handleOnExport}
              />
            ) : (
              <ExportFiles file={g} />
            )
            )
          }
        </div>
      )
    );
  }
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(withTranslation()(BevStandardSiteMap))
);
