import { combineReducers } from 'redux';


import austrianReducer from './austrian';
import advancedReducer from './advanced';
import pointsReducer from './points';
import apiReducer from './api';
import baseReducer from './base';
import helmertReducer from './helmert';
import cadastreReducer from './cadastre';
import authReducer from './auth';


const rootReducer = combineReducers({
    austrian: austrianReducer,
    advanced: advancedReducer,
    points: pointsReducer,
    api: apiReducer,
    base: baseReducer,
    helmert: helmertReducer,
    auth: authReducer,
    cadastre: cadastreReducer,
});

export default rootReducer;
