import * as actionTypes from '../actions/actionTypes';
import { updateObject } from 'common/utility';

const initialState = {
    fileId: 1,
    targetCRS: {},
    fileNameCounter: 1,
    fileName: "trafo",
    geometryList: [
      {
        "type": "FeatureCollection",
        "name": "Example 1",
        "crs": {type: 'BEV',
                name: '',
                properties: { frame: '201', ellipsoid: 'EPSG::7019', prime_meridian: 'EPSG::8901', projection: '', epoch: 2002.56}},
        "filetype": 'geojson',
        "features": [
          {
            "type": "Feature",
            "geometry": {
              "type": "Point",
              "coordinates": [102.0, 0.5],
              "velocities": [1.0, 0.5]
            },
            "properties": {
              "prop0": "value0"
            }
          },
          {
              "type": "GeometryCollection",
              "geometries": [{
                  "type": "Point",
                  "coordinates": [100.0, 0.0],
                  // "velocities": [1.0, 0.0]
              }, {
                  "type": "LineString",
                  "coordinates": [
                      [101.0, 0.0],
                      [102.0, 1.0]
                  ],
                  "velocities": [
                      [1.0, 0.0],
                      [2.0, 1.0]
                  ]
              }]
          },
          {
            "type": "Feature",
            "geometry": {
              "type": "LineString",
              "coordinates": [
                [102.0, 0.0], [103.0, 1.0], [104.0, 0.0], [105.0, 1.0]
              ],
              "velocities": [
                [1.0, 0.0], [3.0, 1.0], [1.0, 0.0], [5.0, 1.0]
              ],
            },
            "properties": {
              "prop0": "value0",
              "prop1": 0.0
            }
          },
          {
            "type": "Feature",
            "geometry": {
              "type": "Polygon",
              "coordinates": [
                [
                  [100.0, 0.0], [101.0, 0.0], [101.0, 1.0],
                  [100.0, 1.0], [100.0, 0.0]
                ]
              ],
              "velocities": [
                [
                  [0.0, 0.0], [1.0, 0.0], [1.0, 1.0],
                  [0.0, 1.0], [0.0, 0.0]
                ]
              ],
            },
            "properties": {
              "prop0": "value0",
              "prop1": { "this": "that" }
            }
          }
        ]
      },
    {
          "type": "FeatureCollection",
          "name": "Example 2",
          "crs": {type: 'BEV',
                  name: '',
                  properties: { frame: '301', ellipsoid: 'EPSG::7004', prime_meridian: 'EPSG::8901', projection: '', epoch: 2002.56}},
          "filetype": 'kml',
          "features": [{
              "type": "Feature",
              "geometry": {
                  "type": "Point",
                  "coordinates": [102.0, 0.5]
              },
              "properties": {
                  "prop0": "value0"
              }
          },
   {
              "type": "Feature",
              "geometry": {
                  "type": "MultiPoint",
                  "coordinates": [[102.0, 0.5],[99.0, 50.5],[22.0, 34.5]]
              },
              "properties": {
                  "prop0": "value0"
              }
          },

   	   {
              "type": "Feature",
              "geometry": {
                  "type": "LineString",
                  "coordinates": [
                      [102.0, 0.0],
                      [103.0, 1.0],
                      [104.0, 0.0],
                      [105.0, 1.0]
                  ]
              },
              "properties": {
   		            "prop0": "value0",
                  "prop1": 0.0
              }
          },
   {
              "type": "Feature",
              "geometry": {
                  "type": "MultiLineString",
                  "coordinates": [
   				   [[102.0, 0.0], [103.0, 1.0], [104.0, 0.0], [105.0, 1.0]],
   				   [[102.0, 1.0], [103.0, 2.0], [104.0, 1.0], [105.0, 2.0]]
                  ]
              },
              "properties": {
   		            "prop0": "value0",
                  "prop1": 0.0
              }
          },
   	   {
              "type": "Feature",
              "geometry": {
                  "type": "Polygon",
                  "coordinates": [
                      [
                          [100.0, 0.0],
                          [101.0, 0.0],
                          [101.0, 1.0],
                          [100.0, 1.0],
                          [100.0, 0.0]
                      ]
                  ]
              },
              "properties": {
                  "prop0": "value0",
                  "prop1": {
                      "this": "that"
                  }
              }
          },
   	   {
   	       "type": "Feature",
              "geometry": {
   				"type": "Polygon",
   				"coordinates": [
   					[[35, 10], [45, 45], [15, 40], [10, 20], [35, 10]],
   					[[20, 30], [35, 35], [30, 20], [20, 30]]
   				]
   			}
   		},
   		{
   	       "type": "Feature",
              "geometry": {
   				"type": "MultiPolygon",
   				"coordinates": [
   					[
   						[[40, 40], [20, 45], [45, 30], [40, 40]]
   					],
   					[
   						[[20, 35], [10, 30], [10, 10], [30, 5], [45, 20], [20, 35]],
   						[[30, 20], [20, 15], [20, 25], [30, 20]]
   					]
   				]
   			}
   		}]
      }
  ],
    sourcePoints:{
      singlePoint: {
        x: "",
        y: "",
        z: "",
        vx: "",
        vy: "",
        vz: "",
      },
      pointList: {}
    },
    lastInputString: "",
    lastInputFormat: ""
}



const addGeometryFile = (state, action) => {
    const gl = state.geometryList;
    if (!action.file.hasOwnProperty('crs')) {
      action.file.crs = {type: 'BEV', name: '', properties: {}}
    }
    if(!!action && !!action.file && !!action.file.features) action.file.features.forEach(function(f,i) {
      if (!f.hasOwnProperty('properties')) { f.properties ={};}
      if (!f.properties.hasOwnProperty('name')) { f.properties.name ="Pnr "+i;}
    });

    gl.push(action.file);
    return updateObject( state, {
        geometryList: [
          ...state.geometryList.slice(action.file)
        ]
      });
}

const isFileNameInList = (filename, geometryList) => {
  for(let i =0; i<geometryList.length; i++){
    if(geometryList[i].name === filename) return true;
  }
  return false;
}

const countStringInList = (string, geometryList) => {
  let counter = 0;
  for(let geometry of geometryList){
    if(geometry.name.indexOf(string) !== -1) counter++;
  }
  return counter
}

const findNextFileNumber = ( state, action) => {
  const { fileName, geometryList} = state;

  let number = countStringInList(fileName,geometryList) + 1;
  if(isFileNameInList(fileName + '_' + number, geometryList)){
    number++;
    while(isFileNameInList(fileName + '_' + number, geometryList)){
      number++;
    }
  }
  return updateObject(state, {
    fileNameCounter: number
  });

}

const reducer = ( state = initialState, action) => {
    switch ( action.type ) {

        case actionTypes.POINTS_ADD_FILE: 
          return addGeometryFile( state, action );
        
        case actionTypes.POINTS_UPDATE_GEOMETRY_FILE: 
          const gl = state.geometryList;
          gl[action.id] = action.file;
          return {...state, geometryList: [ ...state.geometryList ]}
        
        case actionTypes.POINTS_UPDATE_TARGET_CRS: 
          return {...state, targetCRS: JSON.parse(JSON.stringify(action.crs)) }
        
        case actionTypes.POINTS_REMOVE_GEOMETRY_LIST: 
          return {...state, geometryList: [...state.geometryList.filter((item, index) => index !== action.id)] }
        
        case actionTypes.POINTS_SET_FILE_ID: 
          return {...state, fileId: [action.id] }
        
        case actionTypes.POINTS_SET_FILE_NAME: 
          return {...state, fileName: action.fileName };
        
        case actionTypes.POINTS_FINDE_NEXT_FILE_NUMBER: 
          return findNextFileNumber( state, action);

        case actionTypes.POINTS_UPDATE_PROP:
          return {...state, [action.prop]: action.value }
        
        case actionTypes.POINTS_CLEAN_INPUT:
          return {...state, lastInputFormat: "", lastInputString: ""}
        
        default:
          return state;
    }
}

export default reducer;

