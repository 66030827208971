import React from 'react';
import PropTypes from 'prop-types';

import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import Avatar from '@mui/material/Avatar';

import { DEFAULT_PAGE_SIZE } from '../constants';
import BevPagination from '../../components/BevPagination';
import Point from './point';
import {makePoint} from '../geojson_geometries';

const classes = {
    subdivStyle: {
      paddingLeft: '40px',
    },
  }

/**
 * typeName: Is a String that will be displayed in the Avatar Icon, no default, is required
 * primary: Is a String that will be displayed in the First line of the List Item Text, no default, is required
 * secoundary: Is a String that will be diplayed in the Secound line of the List Item Text, no default, is required
 * style: is a Style Object that will give the Avatar an color an some other style, no default, is required
 * toggleList: is a Function that will trigger a variable to display a List or hide it, no default, is required
 * showList: is a Boolen that will switch between ExpandLess and ExpandMore, no default, is required
 */
ExpandableListElement.propTypes = {
  typeName: PropTypes.string.isRequired ,
  primary: PropTypes.string.isRequired, 
  secoundary: PropTypes.string, 
  style: PropTypes.object.isRequired, 
  toggleList: PropTypes.func.isRequired, 
  showList: PropTypes.bool.isRequired, 
}
export function ExpandableListElement ({toggleList, style, typeName, secondary = "", primary, showList}) {
    return (
      <ListItem>
          <Avatar onClick={toggleList} style={style}>{typeName}</Avatar>
          <ListItemText primary={primary} secondary={secondary} />
          {showList ? 
            <ExpandLessIcon style={{cursor: 'pointer'}}  onClick={toggleList}/> : 
            <ExpandMoreIcon style={{cursor: 'pointer'}}  onClick={toggleList} />
          }
      </ListItem>
    );
}

/**
 * feature: is an Object with the Geometry Set, no default, is required
 * offset: is a Number wich gives the offest ob the Pagination, no default, is required
 * listLenght: is a Number with the Information of the size of the List, no default, is required
 * handleChangeOffset: is a function to set the offset to given offset of the pagination, no default, is required
 * show: is a Boolean to check if the List is shown or hidden, no default, is required
 * pageSize: is a Number of how many elements will be displayed till pagianation will start, defaul 3, is not required
 * isPolygon: is a Boolen with the Information if the Geometry is an Polygon, default false, is not required
 * isInnerPolygon: is a Boolean with the Information if the Geometry is an inner Polygon. default false, is not required
 */
PointList.propTypes = {
  feature: PropTypes.object.isRequired,
  offset: PropTypes.number.isRequired ,
  listLength: PropTypes.number.isRequired, 
  handleChangeOffset: PropTypes.func.isRequired,
  show: PropTypes.bool.isRequired, // to hide the points list
  pageSize: PropTypes.number, // info about how many elements will be shown.
  isPolygon: PropTypes.bool, // defines if it is an Polygon,
  isInnerPolygon: PropTypes.bool, //defines if it is an inner Polygon
}

export function PointList ({feature, isPolygon = false, isInnerPolygon = false, pageSize = DEFAULT_PAGE_SIZE, listLength, show, handleChangeOffset, offset}) {
  if(isPolygon) feature.geometry.coordinates = isInnerPolygon ? JSON.parse(JSON.stringify(feature.geometry.coordinates[1])) : JSON.parse(JSON.stringify(feature.geometry.coordinates[0]));
  return (show && <div style={classes.subdivStyle}>
    <BevPagination setPagination={(offset) => handleChangeOffset(offset)} stepSize={pageSize} length={listLength}>
      {feature.geometry.coordinates.map((g, i) => (
        (offset <= i && i < offset+pageSize) && 
          (feature.geometry.velocities ? 
            <Point key={i} feature={makePoint(feature.properties.name+"_"+i, "Point", g, feature.geometry.velocities[i])} /> :
            <Point key={i} feature={makePoint(feature.properties.name+"_"+i, "Point", g)} />
          )
      ))}
    </BevPagination>
  </div>
  );
}

export default (PointList, ExpandableListElement);
