import React from 'react';
// import PropTypes from 'prop-types';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useTranslation } from 'react-i18next';

export default function GeometryTableHeader ({sort, changeSort,
                                      showCRSCheck, showBtns, 
                                      hideName, hideGeometriesCount, hideFileSize, hideFileType, hideCRS}) {
  const handleClickSort = (attr) => {changeSort(attr);};
  const {t} = useTranslation();

  return <TableHead>
    <TableRow>
    {showCRSCheck && 
        <TableCell padding="checkbox"></TableCell> 
    }
      {showCRSCheck && 
        <TableCell padding="checkbox"></TableCell> 
      }
      {!hideName &&
        <GeometryTableHeaderSortElement label={t('point_source_geometry_header_name')}
                                        sort={sort.name} 
                                        handleClickSort={handleClickSort.bind(this, 'name')} />
      }
      {!hideGeometriesCount &&       
        <GeometryTableHeaderSortElement label={t('point_source_geometry_header_geometries')}
                                        sort={sort.geometry} 
                                        handleClickSort={handleClickSort.bind(this, 'geometry')} />
      }
      {!hideFileSize &&
        <GeometryTableHeaderSortElement label={t('point_source_geometry_header_file_size')}
                                        sort={sort.fileSize} 
                                        handleClickSort={handleClickSort.bind(this, 'fileSize')} />
      }
      {!hideFileType && 
        <GeometryTableHeaderSortElement label={t('point_source_geometry_header_file_type')}
                                        sort={sort.filetype} 
                                        handleClickSort={handleClickSort.bind(this, 'filetype')} />
      }
      {!hideCRS && 
        <TableCell>{t('point_source_geometry_header_crs')}</TableCell>
      }
      {showBtns && 
        <TableCell></TableCell> 
      }
    </TableRow>
  </TableHead>
}

export function GeometryTableHeaderSortElement({handleClickSort, label, sort}){
  return <TableCell onClick={handleClickSort}>
    <span style={{paddingRight: '5px'}}>{label}</span>  
    { sort === 0 && <FontAwesomeIcon icon="sort" /> }
    { sort === -1 && <FontAwesomeIcon icon="sort-up" /> }
    { sort === 1 && <FontAwesomeIcon icon="sort-down" />  }
  </TableCell>
}