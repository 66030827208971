import React, {useState} from 'react';
import { Box, Pagination } from '@mui/material';

const BevPagination = ({ length= 12, stepSize=3, children, style, setPagination }) => {
  const [offset, setOffset] = useState(0);
  const pageCounte = Math.ceil(length / stepSize)

  const handleClickOffset = (offset) => {
    setOffset(offset);
    setPagination(offset);
  }

  return <Box {... !!style ? { sx: style } : {}}>
    {length > stepSize &&
      <Pagination
        count={pageCounte}
        color="primary"
        size="small"
        showFirstButton
        showLastButton
        boundaryCount={2}
        page={offset + 1}
        onChange={(e, offset) => handleClickOffset(offset)}
      />}
    {children}
  </Box>
}

export default BevPagination;
