import React from 'react';
import PropTypes from 'prop-types';

import ListItem from '@mui/material/ListItem';
import Avatar from '@mui/material/Avatar';
import ListItemText from '@mui/material/ListItemText';

CRSListElement.propTypes = {
  primary: PropTypes.string.isRequired, 
  secoundary: PropTypes.string, 
  handlClick: PropTypes.func.isRequired, 
  value: PropTypes.number,
  avatarInput: PropTypes.string.isRequired,
}
export default function CRSListElement ({handlClick, secondary = "", primary, value = 0,avatarInput}) {
    return (
      <ListItem onClick={handlClick} value={value} button={true}>
          <Avatar>{avatarInput}</Avatar>
          <ListItemText primary={primary} secondary={secondary} />
      </ListItem>
    );
}