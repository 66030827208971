import React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';
import { useTranslation } from 'react-i18next';

const BevDialog2 = props => {
const {t} = useTranslation();
return <Dialog open={props.open} onClose={props.toggleOpen} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">{props.title}</DialogTitle>
        <DialogContent>
            {props.children}
        </DialogContent>
        <DialogActions>
            <Button onClick={props.dismiss} color="primary">{t('bev_dialog_cancle')}</Button>
            <Button onClick={props.submit} color="primary">{t('bev_dialog_ok')}</Button>
        </DialogActions>
    </Dialog>;
} 


export default BevDialog2;
