import { put } from 'redux-saga/effects';
import axios from 'axios';
import store from '../../store';
import * as actions from '../actions/api';
import { BASE_API_URL } from '../../common/constants.js';



export function* updateAdvancedList(data) {
    let timediff = 1000 * 60 * 60 * 24;
    var deltaDate = Math.abs(new Date().getTime() - new Date(data.timestamp).getTime());

    // toast.info("update advanced wird gestartet", { autoClose: 8000 });
    // toast.error("update advanced wird fert", { autoClose: 2000 });
    // toast.warning("update advanced wird x", { autoClose: 3500 });
    // toast.success("update advanced wird ddd", { autoClose: 6000 });

    if (deltaDate > timediff ) {
        yield put(actions.start)
        try {
            const res = yield axios.options(BASE_API_URL + 'advanced/')
            if (res.status === 200) {
                yield put(actions.updateAdvancedOptions(res.data.actions.POST.source_crs.children))
                yield put(actions.done())
            } 
        } catch (err) {
            yield put(actions.fail(err))
        }
    }

}

export function* updateAustrianList(data) {
    let timediff = 1000 * 60 * 60 * 24;
    var deltaDate = Math.abs(new Date().getTime() - new Date(data.timestamp).getTime());

    if (deltaDate > timediff ) {
        yield put(actions.start)
        try {
            const res = yield axios.options(BASE_API_URL + 'austrian/')
            if (res.status === 200) {
                yield put(actions.updateAustrianOptions(res.data.actions.POST))
                yield put(actions.done)
            } 
        } catch (err) {
            yield put(actions.fail(err))
        }
    }

}


export function* onInit() {
    let timestamp = yield store.getState().api.optionsTimestamp;
    yield put(actions.updateAdvancedList(timestamp));
    yield put(actions.updateAustrianList(timestamp));

}