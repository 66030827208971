export class parserError{
    constructor(message){
        this.message = message
    }
}

export class jsonError extends parserError{
    constructor(message){
        super(message);
        this.name = 'jsonError';
    }
} 
export class kmlError extends parserError{
    constructor(message){
        super(message);
        this.name = 'kmlError';
    }
} 
export class gpxError extends parserError{
    constructor(message){
        super(message);
        this.name = 'gpxError';
    }
} 
export class csvError extends parserError{
    constructor(message){
        super(message);
        this.name = 'csvError';
    }
} 
export class cvsEgaError extends parserError{
    constructor(message){
        super(message);
        this.name = 'csvEgaError';
    }
} 
export class crdError extends parserError{
    constructor(message){
        super(message);
        this.name = 'crdError';
    }
} 

export class katasterError extends parserError{
    constructor(message){
        super(message);
        this.name = 'katasterError';
    }
} 