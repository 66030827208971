import React from 'react';
import PropTypes from 'prop-types';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormLabel from '@mui/material/FormLabel';
import { useTranslation } from 'react-i18next';

ImportTypeSelector.propTypes = {
  formats: PropTypes.array.isRequired,
  inputformat: PropTypes.string.isRequired,
  handleChange: PropTypes.func.isRequired
}

export function ImportTypeSelector ({formats, inputformat, handleChange}) {
  const {t} = useTranslation();
  return <FormControl component="fieldset">
    <FormLabel sx={{color: "text.primary"}} >{t('point_import_type_selection_title')}</FormLabel>
    <RadioGroup style={{display: "inline"}} aria-label="format" name="inputformat" value={inputformat} onChange={handleChange}>
      {formats.indexOf("json")>-1 && <FormControlLabel value="json" control={<Radio color={"primary"}/>} label={t('point_import_type_selection_geojson')} />}
      {formats.indexOf("gpx")>-1 && <FormControlLabel value="gpx" control={<Radio color={"primary"} />} label={t('point_import_type_selection_gpx')} />}
      {formats.indexOf("kml")>-1 && <FormControlLabel value="kml" control={<Radio color={"primary"} />} label={t('point_import_type_selection_kml')} />}
      {formats.indexOf("csv")>-1 && <FormControlLabel value="csv" control={<Radio color={"primary"} />} label={t('point_import_type_selection_csv')} />}
      {formats.indexOf("csvega")>-1 && <FormControlLabel value="csvega" control={<Radio color={"primary"} />} label={t('point_import_type_selection_csvega')} />}
      {formats.indexOf("crd")>-1 && <FormControlLabel value="crd" control={<Radio v />} label={t('point_import_type_selection_crd')} />}
    </RadioGroup>
  </FormControl>
}