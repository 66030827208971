
export function makePoint(name, type, coordinates, velocities=undefined) {
  let x= {}
  x.type = "Feature"
  x.geometry = { type: type, coordinates: coordinates}
  if (velocities) {
    x.geometry.velocities = velocities;
  }
  x.properties = {name: name}
  return x;
}
