import { kmlError } from './parserErrors';
import * as constants from '../constants';

var tj = require('@mapbox/togeojson');
var DOMParser = require('xmldom').DOMParser;

export default function parseKml(string){
    if(string.indexOf('<kml')<= -1) throw new kmlError(constants.ERROR_IMPORT_KML_TAG); 
    let kml = new DOMParser(); 
    const x = tj.kml(kml.parseFromString(string))
    return x;
  }