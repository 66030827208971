import React from 'react';

export const GeomText = g => {
  let txt = '';
  g.src.features.forEach(function(v) {
    if (v.type === "Feature") {
      txt += v.geometry.type + "(" + JSON.stringify(v.geometry.coordinates) + ")\n";
    } else {
      txt += 'GeometryCollection(';
      v.geometries.forEach(function(vv) {
        txt += vv.type + "(" + JSON.stringify(vv.coordinates) + "), ";
      });
      txt += ')\n';
    }
  });
  return <pre>{txt}</pre>
}