import React, { Component } from "react";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import CardContent from "@mui/material/CardContent";
import AustrianImage from "img/koordinatensysteme.png";
import KatasterImage from "img/katasterhome.png";
import AdvancedImage from "img/advanced.png";
import FarmingImage from "img/hummel_rund.jpg";
import { withRouter } from "react-router-dom";
import { BASE_URL } from "common/constants.js";

import { withTranslation } from "react-i18next";

const myStyle = {
  "&:hover": {
    boxShadow: "0px 1px 5px 0px rgba(255,0,0,.9)",
  },
}

const grid = {
  height: "100%",
  cursor: "pointer",
}

const image = {
  marginBottom: "50px",
  width: "70%",
}

class Home extends Component {
  redirect = (target) => {
    let url = BASE_URL + target;
    this.props.history.push(url);
  };

  render() {
    const { t } = this.props;
    return (
      <Grid container direction="row" spacing={2} alignItems="stretch">
        <Grid item sm={6} md={3} sx={grid}>
          <Card
            sx={myStyle}
            onClick={() => this.redirect("austrian")}
          >
            <CardHeader title={t("home_title_austrian")} />
            <CardContent>
              <img src={AustrianImage} style={image} alt="" />
              <p>{t("home_desc_austrian")}</p>
            </CardContent>
          </Card>
        </Grid>

        <Grid item sm={6} md={3} sx={grid}>
          <Card
            sx={myStyle}
            onClick={() => this.redirect("cadastre")}
          >
            <CardHeader title={t("home_title_kataster")} />
            <CardContent>
              <img src={KatasterImage} style={image} alt="" />
              <p>{t("home_desc_kataster")}</p>
            </CardContent>
          </Card>
        </Grid>

        <Grid item sm={6} md={3} sx={grid}>
          <Card
            sx={myStyle}
            onClick={() => this.redirect("farming")}
          >
            <CardHeader title={t("home_title_farming")} />
            <CardContent>
              <img src={FarmingImage} style={image} alt="" />
              <p>{t("home_desc_farming")}</p>
            </CardContent>
          </Card>
        </Grid>

        <Grid item sm={6} md={3} sx={grid}>
          <Card
            sx={myStyle}
            onClick={() => this.redirect("advanced")}
          >
            <CardHeader title={t("home_title_advanced")} />
            <CardContent>
              <img src={AdvancedImage} style={image} alt="" />
              <p>{t("home_desc_advanced")}</p>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    );
  }
}

export default withRouter(withTranslation()(Home));
