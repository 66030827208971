import React, { Component } from "react";
import Grid from "@mui/material/Grid";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { withTranslation } from "react-i18next";

class Maintenance extends Component {
  render() {
    const { modus } = this.props;
    const { t } = this.props;
    return (
      <Grid container direction="row" spacing={2} alignItems="stretch">
        <Grid item sm={12} md={12}>
          <div className={"maintenance"}>
            <FontAwesomeIcon
              icon={"exclamation-triangle"}
              style={{ marginRight: "10px" }}
            />
            {t("maintenance", { modus })}
          </div>
        </Grid>
      </Grid>
    );
  }
}

export default withTranslation()(Maintenance);
