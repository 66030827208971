import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { ExpandableListElement } from './geometriesListHelper';
import MultiPoint from './multiPoint';
import BevPagination from '../../components/BevPagination';
import { makePoint } from '../geojson_geometries';
import { DEFAULT_PAGE_SIZE } from '../constants';

import deepOrange from '@mui/material/colors/deepOrange';

const classes = {
  subdivStyle: {
    paddingLeft: '40px',
  },
}

/**
   * feature: is the Geometry Object, no default, is required
   * pageSize: is the number of elements that will be displayed on one Pagination Page, default 3, is not required
 */
export default class MultiLineString extends Component {
  static propTypes = {
    feature: PropTypes.object.isRequired,
    pageSize: PropTypes.number,
  }
  static defaultProps = {
    pageSize : DEFAULT_PAGE_SIZE
  }
  state = {
    showPoints: false,
    offset:0
  }

  togglePoints = (event) => {
    this.setState({ showPoints: !this.state.showPoints });
  }
  handleChangeOffset = (offset) =>{
    this.setState({offset});
  }

  render() {
    const {feature, pageSize, color} = this.props
    return (
      <div>
        <ExpandableListElement typeName="ML" 
          primary={feature.properties.name} 
          secondary={"MultiLineString ("+feature.geometry.coordinates.length+")"} 
          style={{backgroundColor: color,cursor: 'pointer', marginRight: '10px'}} 
          toggleList={this.togglePoints} 
          showList={this.state.showPoints}/>

        {this.state.showPoints && 
          <div style={classes.subdivStyle}>
            <BevPagination setPagination={this.handleChangeOffset} 
              stepSize={pageSize} 
              length={feature.geometry.coordinates.length}>

              { feature.geometry.coordinates.map((g, i) => (this.state.showPoints && 
                ( this.state.offset <= i && i < this.state.offset+pageSize ) &&
                  <MultiPoint key={i} 
                    feature={makePoint(feature.properties.name+"_"+i, "LineString", g, feature.geometry.velocities ? feature.geometry.velocities[i] : undefined )} 
                    typeName="L" 
                    secondary="LineString" 
                    backgroundColor={deepOrange[200]} />
              ))}
            </BevPagination>
        </div>}
      </div>
    );
  }
}

