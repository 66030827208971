import React from 'react';
import PropTypes from 'prop-types';
import {TextField, Grid} from '@mui/material';
import { useTranslation } from 'react-i18next';

/**
 * getDate, a function, to set the date
 * changeDate, a function, to change value of the given date type
 * mjd, a special date Type
 * bdat,a special date Type
 * gpsweek,a special date Type
 * ydoy, a special date Type
 * yr, a special date Type
 * classes, an Object to set the classes of a Component
 */

GridDateInput.propTypes = {
    getDate: PropTypes.func.isRequired,
    changeDate: PropTypes.func.isRequired,
    mjd: PropTypes.number.isRequired,
    bdat: PropTypes.string.isRequired,
    gpsweek: PropTypes.string.isRequired,
    ydoy: PropTypes.string.isRequired,
    yr:  PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
}

export default function GridDateInput({getDate, changeDate, mjd, bdat, gpsweek, ydoy, yr}){
    const checkSubmit = event => event.key === 'Enter' ? getDate(event.target.name, event.target.value) : null
    const {t} = useTranslation();
    const repeatingAttributes = {
        onBlur: (event) => getDate(event.target.name, event.target.value),
        onChange: (event) => changeDate(event.target.name, event.target.value),
        onKeyPress: checkSubmit,
    }

    return <Grid item xs={12} sm={6} md={4} container>
        <Grid item container alignItems="center" justifyContent="center" spacing={1}>
            <Grid item xs={12} sm={12}>
                <TextField label={t("bev_time_grid_input_label_mjd")}
                    value={mjd} 
                    id="mjd"
                    name="mjd"
                    helperText={t("bev_time_grid_input_helper_mjd")}
                    margin="normal"
                    { ...repeatingAttributes }/>
            </Grid>
            <Grid item xs={12} sm={12}>
                <TextField label={t("bev_time_grid_input_label_bdat")}
                    value={bdat} 
                    id="bdat" 
                    name="bdat" 
                    helperText={t("bev_time_grid_input_helper_bdat")}
                    margin="normal"
                    { ...repeatingAttributes }/>
            </Grid>
            <Grid item xs={12} sm={12}>
                <TextField label={t("bev_time_grid_input_label_gps")} 
                    value={gpsweek} 
                    id="gpsweek" 
                    name="gpsweek" 
                    helperText={t("bev_time_grid_input_helper_gps")}
                    margin="normal"
                    { ...repeatingAttributes }/>
            </Grid>
            <Grid item xs={12} sm={12}>
                <TextField label={t("bev_time_grid_input_label_doy")} 
                    value={ydoy} 
                    id="ydoy" 
                    name="ydoy" 
                    helperText={t("bev_time_grid_input_helper_doy")}
                    margin="normal"
                    { ...repeatingAttributes }/>
            </Grid>
            <Grid item xs={12} sm={12}>
                <TextField label={t("bev_time_grid_input_label_floating")}
                    value={yr} 
                    id="yr" 
                    name="yr" 
                    helperText={t("bev_time_grid_input_helper_floating")}
                    margin="normal"
                    { ...repeatingAttributes }/>
            </Grid>
        </Grid>
    </Grid>
}