export const updateObject = (oldObject, updatedProperties) => {
    return {
        ...oldObject,
        ...updatedProperties
    };
};

export const removeObjectById = (objectList, objectId) => {
    const newObjectList = objectList.filter( obj => {
        return obj.pointId !== objectId
    })
    return newObjectList;
}

export const removeObjectByIdx = (objectList, objectIdx) => {
    const newObjectList = [...objectList];
    newObjectList.splice(objectIdx, 1);
    return newObjectList;
}

export const prepareNumber = (valueIn, digits=3, valueNaN='NaN') => {
    const valueOut = valueIn === undefined || valueIn === '' ? valueNaN : parseFloat(String(valueIn)).toFixed(digits);
    return valueOut;
}



export function getCoordType(crs) {
  let ctype = ''
  const hasFrame = !!crs.properties.frame;
  const hasEllipsoid = crs.properties.ellipsoid !== '';
  const hasProjection = crs.properties.projection !== '';
  if (hasFrame) ctype = 'geocentric'
  if (hasFrame && hasEllipsoid) ctype = 'geographic'
  if (hasFrame && hasEllipsoid  && hasProjection) ctype = 'projected'
  return ctype;
}
export function sortName(a, b) {
  if (a.name < b.name) {
    return -1;
  }
  if (a.name > b.name) {
    return 1;
  }
  return 0;
}

export function prettifyBigNumber (number, afterPointNumbers = 3)  {
  console.log(number, afterPointNumbers);
  if (!number) return null;
  const afterPointZeros = Array.from({length: afterPointNumbers}, (_, i) => "0").join("");
  console.log(afterPointZeros);
  const afterNumberPart =  number.toString().split(".")?.[1] ?? afterPointZeros;
  console.log(afterNumberPart, (afterNumberPart + afterPointZeros).slice(0,afterPointNumbers), '\n');
  return <span>
    {number.toString().split(".")[0].match(/.{1,3}(?=(.{3})*$)/g).map( (n,i) => (<span key={i} style={{paddingLeft: '0.25em'}}>{n}</span>)) }
    .<span>{afterNumberPart.length < afterPointNumbers ? (afterNumberPart + afterPointZeros).slice(0,afterPointNumbers) : afterNumberPart}</span>
  </span>
}