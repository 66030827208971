import React, { Component } from 'react';
import { matchPath } from 'react-router';
import { Link, withRouter } from "react-router-dom";

import MenuList from '@mui/material/MenuList';
import Toolbar from '@mui/material/Toolbar';
import Collapse from '@mui/material/Collapse';
import { IconButton, SwipeableDrawer, Hidden } from '@mui/material';

import { BASE_URL } from '../common/constants.js';
import { hasGroup } from '../common/roles';

import BevMenuListItem from './BevMenuListItem';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { withTranslation } from 'react-i18next';

class AppHeaderMenuList extends Component {
  static propTypes = {
  }
  static defaultProps = {
  }
  state = {
    drowpdownHelmert: false,
    dropdownopen: false,
    open: false,
  }
  isActive = (path) => {
    return !!matchPath(this.props.path, path);
  }
  toggle = () => {
    this.setState(prev => { return { drowpdownHelmert: !prev.drowpdownHelmert } });
  }
  openDrawer = () => { this.setState({ open: true }) }
  closeDrawer = () => { this.setState({ open: false }) }
  toggleDrawer = () => { this.setState(prev => { return { open: !prev.open } }) };
  toWiki = () => { window.open(BASE_URL + "wiki/"); }
  toBev = () => { window.open("https://www.bev.gv.at/"); }
  toggleDropdown = () => { this.setState(prevState => { return { dropdownopen: !prevState.dropdownopen } }); }
  drawerLinkTo = (url) => {
    this.props.history.push(url);
    this.closeDrawer();
  }


  render() {
    const { drowpdownHelmert, dropdownopen } = this.state;
    const { auth, logoutUser, apiinsert, apiDown, t } = this.props;
    const { user } = auth;
    const { username } = auth.user_loading ? { username: 'user loading' } : auth.user;


    return <Toolbar className={'header-menu'} variant="dense" disableGutters={true}>
      <MenuList id={'header-menu-list'} className={'menu-list'}>

        {/*hasGroup(user, 'manager') && <BevMenuListItem url={BASE_URL + "apikeys"} icon={"key"} label={t('api_keys')} />*/}
        { /*hasGroup(user, 'manager') && <BevMenuListItem url={BASE_URL + "admin"} icon={"toolbox"} label={"Admin Area"} />*/}


        <BevMenuListItem url={BASE_URL + "geommanager"} icon={"database"} label={t('punktspeicher')} isActive={this.isActive(BASE_URL + "geommanager")} />
        <BevMenuListItem url={BASE_URL + "farming"} icon={"tractor"} label={t('farming')} isActive={this.isActive(BASE_URL + "farming")} />
        <BevMenuListItem url={BASE_URL + "austrian"} icon={"map-pin"} label={t('austrian')} isActive={this.isActive(BASE_URL + "austrian")} />
        <BevMenuListItem url={BASE_URL + "advanced"} icon={"plus"} label={t('advanced')} isActive={this.isActive(BASE_URL + "advanced")} />
        <BevMenuListItem url={BASE_URL + "calendar"} icon={"calendar-plus"} label={t('datumstransformation')} isActive={this.isActive(BASE_URL + "calendar")} />
        {/*<BevMenuListItem url={BASE_URL + "format"} icon={"file-alt"} label={"Koordinatenformat"} />*/}
        <BevMenuListItem url={BASE_URL + "cadastre"} icon={"map"} label={t('kataster')} />
        {/*<BevMenuListItem icon={"arrows-alt-h"} label={t('helmert')} handleClick={this.toggle} isLast isActive={this.isActive(BASE_URL + "helmert") || this.isActive(BASE_URL + "helmert2")} />
        {drowpdownHelmert && <div style={{ position: "fixed", top: 0, right: 0, bottom: 0, left: 0 }} onClick={this.toggle}></div>}
        <Collapse className={'dropdown'} in={drowpdownHelmert} timeout="auto" component="div" onClick={this.toggle}>
          <MenuList>
            <BevMenuListItem url={BASE_URL + "helmert"} icon={"plus"} label={t('helmert_parametern')} />
            <BevMenuListItem url={BASE_URL + "helmert2"} icon={"plus"} label={t('parameterbestimmung')} />
          </MenuList>
        </Collapse>*/}
      </MenuList>
      <Hidden smUp>
        <Link to={BASE_URL} style={{ textDecoration: 'none' }}><h1 className={'header-title'}>{t('transformator')}</h1></Link>
      </Hidden>
      <IconButton className={'hidden-menu-btn'} onClick={this.toggleDrawer}>
        <FontAwesomeIcon icon="bars" />
      </IconButton>
      <SwipeableDrawer
        anchor="right"
        open={this.state.open}
        onClose={this.closeDrawer}
        onOpen={this.openDrawer} >
        <MenuList id={'header-drawer'} className={'drawer-menu-list'}>
          {/*hasGroup(user, 'manager') && <BevMenuListItem url={BASE_URL + "apikeys"} icon={"key"} label={t('api_keys')} />*/}
          <BevMenuListItem handleClick={this.drawerLinkTo.bind(this, BASE_URL + "geommanager")} icon={"database"} label={t('punktspeicher')} isActive={this.isActive(BASE_URL + "geommanager")} />
          <BevMenuListItem handleClick={this.drawerLinkTo.bind(this, BASE_URL + "farming")} icon={"tractor"} label={t('farming')} isActive={this.isActive(BASE_URL + "farming")} />
          <BevMenuListItem handleClick={this.drawerLinkTo.bind(this, BASE_URL + "austrian")} icon={"map-pin"} label={t('austrian')} isActive={this.isActive(BASE_URL + "austrian")} />
          <BevMenuListItem handleClick={this.drawerLinkTo.bind(this, BASE_URL + "advanced")} icon={"plus"} label={t('advanced')} isActive={this.isActive(BASE_URL + "advanced")} />
          <BevMenuListItem handleClick={this.drawerLinkTo.bind(this, BASE_URL + "calendar")} icon={"calendar-plus"} label={t('datumstransformation')} isActive={this.isActive(BASE_URL + "calendar")} />
          <BevMenuListItem handleClick={this.drawerLinkTo.bind(this, BASE_URL + "cadastre")} icon={"map"} label={t('kataster')} />
          {/*<BevMenuListItem handleClick={this.drawerLinkTo.bind(this, BASE_URL + "helmert")}  icon={"arrows-alt-h"} label={t('helmert_parametern')} />
            <BevMenuListItem handleClick={this.drawerLinkTo.bind(this, BASE_URL + "helmert2")}  icon={"plus"} label={t('parameterbestimmung')} />*/}
          <Hidden smUp>
            <BevMenuListItem label={"WIKI"} icon={"atlas"} handleClick={this.toWiki} />
            <BevMenuListItem handleClick={this.toBev} icon={"address-book"} label={t('contact')} />
            <BevMenuListItem handleClick={this.drawerLinkTo.bind(this, BASE_URL + "impressum")} icon={"info"} label={t('impressum')} />
            <BevMenuListItem icon={"user"} label={username} isLast /> :
          </Hidden>
        </MenuList>
      </SwipeableDrawer>
    </Toolbar>
  }
}

export default withRouter(withTranslation()(AppHeaderMenuList));

/*

*/
