import {takeEvery } from 'redux-saga/effects';
import * as actionTypes from '../actions/actionTypes';

import * as auth from './auth';
import * as api from './api';
import * as points from './points';



export function* watchActions() {
    yield takeEvery(actionTypes.AUTH_GET_CURRENT_USER, auth.getCurrentUser);
    yield takeEvery(actionTypes.AUTH_CHECK_TOKEN, auth.checkToken);
    yield takeEvery(actionTypes.AUTH_LOGOUT_CURRENT_USER, auth.logoutUser);

    yield takeEvery(actionTypes.API_UPDATE_ADVANCED, api.updateAdvancedList);
    yield takeEvery(actionTypes.API_UPDATE_AUSTRIAN, api.updateAustrianList);
    yield takeEvery(actionTypes.API_INIT, api.onInit);

    yield takeEvery(actionTypes.POINTS_ADD_GEOMETRY_FILE, points.addGeometryFile);
    yield takeEvery(actionTypes.POINTS_REMOVE_GEOMETRY_FILE, points.removeGeometryFile);

}