// Base Classes for TRAFO API


export function point2d(xx) {
   let point = {};
   point['name']=xx[0];
   point['east']=xx[1];
   point['north']=xx[2];
   return point;
}


export function pointlist_2d(d) {
    let pointlist = [];
    let p = d.split("\n");
    p.forEach(function(v, i) {
        if (v[0] !== "#" && v.trim() !== "") {
            let xx = v.replace(";","").split(",");
            xx[1]=+xx[1];
            xx[2]=+xx[2];
            pointlist.push(point2d(xx));
        }
    });
    return pointlist;
}

export function point3d(xx) {
   let point = {};
   point['name']=xx[0];
   point['X']=xx[1];
   point['Y']=xx[2];
   point['Z']=xx[3];
   point['messdatum'] = xx[4];
   return point;
}

export function pointmgi(xx) {
    let point = {};
    point['name']=xx[0];
    point['RW']=xx[1];
    point['HW']=xx[2];
    point['Hoehe']=xx[3];
    point['Meridian']=xx[4];
    return point;
}


export function pointlist_3d(d) {
    let pointlist = [];
    let p = d.split("\n");
    p.forEach(function(v, i) {
        if (v[0] !== "#" && v.trim() !== "") {
            let xx=v.replace(";","").split(",");
             xx[1]=+xx[1];
             xx[2]=+xx[2];
             xx[3]=+xx[3];
             pointlist.push(point3d(xx));
        }
    });
    return pointlist;
}

export function pointlist_mgi(d) {
    let pointlist = [];
    let p = d.split("\n");
    p.forEach(function(v, i) {
        if (v[0] !== "#" && v.trim() !== "") {
             let xx=v.replace(";","").split(",");
             xx[1]=+xx[1];
             xx[2]=+xx[2];
             xx[3]=+xx[3];
             xx[4]=xx[4];
             pointlist.push(pointmgi(xx));
        }
    });
    return pointlist;
}



export function point_geocentric_vel(xx) {
   let point = {};
   point['name']=xx[0];
   point['X']=xx[1];
   point['Y']=xx[2];
   point['Z']=xx[3];
   if (xx[4] !== "") { point['v_X']=xx[4]; }
   if (xx[5] !== "") { point['v_Y']=xx[5]; }
   if (xx[6] !== "") { point['v_Z']=xx[6]; }
   return point;
}

export function point_geographic_vel(xx) {
   let point = {};
   point['name']=xx[0];
   point['lon']=xx[1];
   point['lat']=xx[2];
   point['ele']=xx[3];
   if (xx[4] !== "") { point['v_lon']=xx[4]; }
   if (xx[5] !== "") { point['v_lat']=xx[5]; }
   if (xx[6] !== "") { point['v_ele']=xx[6]; }
   return point;
}

export function point_projected_vel(xx) {
   if (xx[2]<5000000) { xx[2]+=5000000; }
   let point = {};
   point['name']=xx[0];
   point['east']=xx[1];
   point['north']=xx[2];
   point['height']=xx[3];
   if (xx[4] !== "") { point['v_east']=xx[4]; }
   if (xx[5] !== "") { point['v_north']=xx[5]; }
   if (xx[6] !== "") { point['v_height']=xx[6]; }
   return point;
}

export function pointlist_vel(d, coordtype) {
    let pointlist = [];
    let p = d.split("\n");
    p.forEach(function(v, i) {
        if (v[0] !== "#" && v.trim() !== "") {
            let xx=v.replace(";","").split(",");
            xx[1]=+xx[1];
            xx[2]=+xx[2];
            xx[3]=+xx[3];
            if ( xx.length>4) { xx[4]=+xx[4]; }
            if ( xx.length>5) { xx[5]=+xx[5]; }
            if ( xx.length>6) { xx[6]=+xx[6]; }
            if (coordtype === "geocentric") {
                pointlist.push(point_geocentric_vel(xx));
            } else if (coordtype === "geographic") {
                pointlist.push(point_geographic_vel(xx));
            } else if (coordtype === "projected") {
                pointlist.push(point_projected_vel(xx));
            }
        }
    });
    return pointlist;
}
