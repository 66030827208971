import React, { Component } from 'react'
import PropTypes from 'prop-types';


import CRS from 'components/CRS/CRS';
import BevCard from 'components/BevCard';
import { Box, Hidden } from '@mui/material';
import { withTranslation } from 'react-i18next';

const src = "source", trg = "target";

class CrsPicker extends Component {
    /**
     * showSource, boolean to check if SourceCrsInput should be diplayed
     * showTarget, boolean to check if TargetCrsInput should be diplayed
     * sourceCRS, the CRS object for source
     * targetCRS, the CRS object for target
     */
    static propTypes = {
        showSource: PropTypes.bool.isRequired,
        showTarget: PropTypes.bool.isRequired,
        sourceCRS: PropTypes.object.isRequired,
        targetCRS: PropTypes.object.isRequired,
    }

    /**
     * showSource, set to true just in case
     * showTarget, set to true just in case
     */
    static defaultProps = {
        showSource: true,
        showTarget: true,
    }

    state = {
        openSource: true,
        openTarget: true,
    }

    /* On update of component check if isClosed is changed, and then update State */
    componentDidUpdate(prevProps, prevStat) {
        const { isClosed } = this.props;
        if (prevProps.hasOwnProperty('isClosed') && prevProps.isClosed !== isClosed) {
            this.setState({ openSource: isClosed, openTarget: isClosed })
        }
    }

    handleToggleOpen = target => {
        if (target === src) this.setState(prevState => { return { openSource: !prevState.openSource } })
        else this.setState(prevState => { return { openTarget: !prevState.openTarget } })
    }

    handleChange = (target, attribute, event) => this.props.onHandleChange(event, target, attribute);

    handleChangeCrs = (target, event) => this.props.onCRSChangeAll(event, target);

    render() {
        const { showSource, showTarget, sourceCRS, targetCRS, classes, t } = this.props;
        const { openSource, openTarget } = this.state;


        return <Box sx={{
            display: 'flex',
            justifyContent: 'center',
            flexDirection: {
                sm: 'column',
                md: 'row'
            }
        }}
        >

            {/* This is the Source CRS input card*/}
            < BevCard title={t('advanced_crs_picker_source')} show={showSource} open={openSource} toggleOpen={this.handleToggleOpen.bind(this, src)} >
                <CRS crs={sourceCRS}
                    handleChangeEpoch={this.handleChange.bind(this, src, "epoch")}
                    handleChangeFrame={this.handleChange.bind(this, src, "frame")}
                    handleChangeEllps={this.handleChange.bind(this, src, "ellipsoid")}
                    handleChangeMeridian={this.handleChange.bind(this, src, "prime_meridian")}
                    handleChangeProjection={this.handleChange.bind(this, src, "projection")}
                    handleChangeCRS={this.handleChangeCrs.bind(this, src)} />
            </BevCard >

            {/* This is a placeholder*/}
            < Hidden smDown > <div style={{ width: '20px' }}> </div> </Hidden >

            {/* This is the Target CRS input card*/}
            < BevCard title={t('advanced_crs_picker_target')} show={showTarget} open={openTarget} toggleOpen={this.handleToggleOpen.bind(this, trg)} >
                <CRS crs={targetCRS}
                    handleChangeEpoch={this.handleChange.bind(this, trg, "epoch")}
                    handleChangeFrame={this.handleChange.bind(this, trg, "frame")}
                    handleChangeEllps={this.handleChange.bind(this, trg, "ellipsoid")}
                    handleChangeMeridian={this.handleChange.bind(this, trg, "prime_meridian")}
                    handleChangeProjection={this.handleChange.bind(this, trg, "projection")}
                    handleChangeCRS={this.handleChangeCrs.bind(this, trg)} />
            </BevCard >
        </Box >
    }


}

export default withTranslation()(CrsPicker);