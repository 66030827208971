import { makeAutoObservable, runInAction, reaction } from "mobx";
import { Point } from "common/store/classes/point";
import { KatasterPoint } from "common/store/classes/katasterPoint";
import errorHandler from "common/errorHandler";
import axios from "axios";
import * as constants from "common/constants.js";
import { fromLonLat, toLonLat } from "ol/proj";
import { createEmpty, extend } from "ol/extent";
import * as turf from "@turf/turf";
import { toast } from "react-toastify";
import { BASIC_GEOJSON, MERIDIAN_MAPPING, TOASTER_INFO_AUTOCLOSE } from "common/constants";
import i18next from "common/i18n";
import { format } from 'date-fns';


const TYPE_COORD_1_MAPPING = {
  etrs: "etrs89X",
  mgi: "gkY",
  calculatedMgi: "gkYCalculated",
  wgs: "wgs84Phi",
};
const TYPE_COORD_2_MAPPING = {
  etrs: "etrs89Y",
  mgi: "gkX",
  calculatedMgi: "gkXCalculated",
  wgs: "wgs84Lam",
};
const TYPE_COORD_3_MAPPING = {
  etrs: "etrs89Z",
  mgi: "gkHoeheAdria",
  calculatedMgi: "gkHoeheAdriaCalculated",
  wgs: "wgs84Hoehe",
};


/**
 * The Kataster Store is the Logic of the Kataster Mode.
 * All points that are used are insert in the pointList.
 * Every time the pontList or another importen Parameter changes, the Determination or Transfromation will start.
 *
 */
class Kataster {
  pointList = [];
  meridian = "";
  fix_scale = false;
  height_shift = 0;
  params = null;
  sigmas = null;
  didDeterminationOnce = false; // Check If Determination was called Once already with more thand 4 passPoints.
  config = null;
  holdDetermination = false; // If true will disable the Determination Check to reduce payload on ForEach Add Points actions
  transformationReportKey = "";
  determinationReportKey = "";
  extent = null;
  centerCoordinates = null;
  map = null;

  constructor() {
    makeAutoObservable(this);

    this.meridian = "GK_AUTO";
    this.fix_scale = false;
    this.params = null;
    this.sigmas = null;
    this.didDeterminationOnce = false;

    /**
     * This will start an Transfromation for Map coordinates if pointList changes or holdDetermination
     */
    this.disposer = reaction(
      () => [
        this.pointList,
        this.pointList.filter((p) => p.isWgsValid()),
        this.pointList.filter((p) => p.hasValidEtrsCoordinates()),
        this.holdDetermination,
        this.meridian,
      ],
      (
        [pointList, wgsPointList, etrsPointList, holdDetermination, meridian],
        [old1, old2, etrsOldPointList, old3, oldMeridian]
      ) => {
        if (holdDetermination) return;
        if (
          pointList.length === wgsPointList.length &&
          etrsPointList.length === etrsOldPointList.length &&
          meridian === oldMeridian
        )
          return;
        const missingWgsEtrs = pointList.filter((p) => !p.isWgsValid() && p.hasValidEtrsCoordinates());
        const allMgi = pointList.filter((p) => !p.hasValidEtrsCoordinates() && p.hasValidGkCoordinates());
        if (missingWgsEtrs.length > 0) {
          this.mapTransformationEtrs();
        }
        if (allMgi.length > 0 && meridian !== "GK_AUTO") {
          this.mapTransformationMgi();
        }
      }
    );

    /**
     * This will start the Determination if changes ocurse in the pointList, meridain, fix_scale or holdDetermination
     */
    this.disposer2 = reaction(
      () => [
        this.pointList
          .filter((p) => p.isPassPoint() && p.isDeterminationSelected && !p.isTransformationpoint)
          .map((p) => p.punktnummer),
        this.meridian,
        this.fix_scale,
        this.holdDetermination,
        this.pointList
          .filter((p) => p.isPassPoint() && p.isDeterminationSelected && p.isHeightSelected)
          .map((p) => p.punktnummer),
      ],
      (
        [passPointList, meridian, fixScale, holdDetermination, heightPassPointList],
        [passPointListOld, meridianOld, fixScaleOld, holdDeterminationOld, heightPassPointListOld]
      ) => {
        const passPointListsAreSame =
          passPointList.length === passPointListOld.length &&
          passPointList.filter((p) => passPointListOld.indexOf(p) > -1).length === passPointList.length;

        const heightPassPointListsAreSame =
          heightPassPointList.length === heightPassPointListOld.length &&
          heightPassPointList.filter((p) => heightPassPointListOld.indexOf(p) > -1).length ===
          heightPassPointList.length;

        if (
          !holdDetermination &&
          (!passPointListsAreSame ||
            !heightPassPointListsAreSame ||
            (meridian !== meridianOld && meridianOld !== "GK_AUTO") ||
            fixScale !== fixScaleOld ||
            holdDetermination !== holdDeterminationOld)
        ) {
          this.startDetermination();
        }
      }
    );

    /**
     * This will start a Cadaster Transfromation if data in pointList is ok and changed or params or height_shift is changed
     */
    this.disposer3 = reaction(
      () => [this.pointList.filter((p) => p.isTransformationpoint), this.params, this.height_shift],
      async (
        [transformationPointList, params, height_shift],
        [transformationPointListOld, paramsOld, height_shiftOld]
      ) => {
        if (
          transformationPointList.length > 0 &&
          ((transformationPointList.length !== transformationPointListOld.length && !!params) ||
            params !== paramsOld ||
            height_shift !== height_shiftOld)
        ) {
          this.transformationReportKey = "";

          const transformationsGeoJson = this.getGeoJsonFromPointList(
            [this.filterHasEtrs, this.filterIsTransformationPoint],
            "etrs89"
          ); // Get Data from Point List.

          const withHeight = false; // TODO add Options for Height check
          const meridian = this.meridian;

          let req = {
            meridian,
            helmert_parameter: params,
            use_height_grid: withHeight,
            height_shift: height_shift,
            etrs_points: transformationsGeoJson,
          };

          try {
            const res = await axios.post(constants.TRANSFORMATOR_API_URL_CAD, req, this.config);
            let x = res.data.result.points;
            if (this.pointList.length > 0) {
              this.setPointsKoordinatsFromGeoJson("calculatedMgi", x, true);

              runInAction(() => {
                this.transformationReportKey = res.data.meta["report_key"];
              });
            }
          } catch (e) {
            console.log(e);
          }
        }
      }
    );

    /**
     * This will start a hidden Cadaster Transfromation for unselected Passpoints if data in pointList is ok and changed or params or height_shift is changed
     */
    this.disposer4 = reaction(
      () => [
        this.pointList.filter((p) => p.hasValidEtrsCoordinates() && !p.isDeterminationSelected),
        this.params,
        this.height_shift,
      ],
      async (
        [unselectedPointList, params, height_shift],
        [unselectedPointListOld, paramsOld, height_shiftOld]
      ) => {
        if (
          unselectedPointList.length > 0 &&
          ((unselectedPointList.length !== unselectedPointListOld.length && !!params) ||
            params !== paramsOld ||
            height_shift !== height_shiftOld)
        ) {
          const hiddenTransformationGeoJson = this.getGeoJsonFromPointList(
            [this.filterHasEtrs, this.filterIsNotSelected],
            "etrs89"
          );
          const withHeight = false; // TODO add Options for Height check
          const meridian = this.meridian;

          let req = {
            meridian,
            helmert_parameter: params,
            use_height_grid: withHeight,
            height_shift: height_shift,
            etrs_points: hiddenTransformationGeoJson,
          };

          try {
            const res = await axios.post(constants.TRANSFORMATOR_API_URL_CAD, req, this.config);

            let x = res.data.result.points;
            if (this.pointList.length > 0) {
              this.setPointsKoordinatsFromGeoJson("calculatedMgi", x, false, true);
            }
          } catch (e) {
            console.log(e);
          }
        }
      }
    );

    /**
     * This reaction will be called every time the pointList gets updated and safe this in the Store.
     */
    this.disposer5 = reaction(
      () => JSON.stringify(this.pointList),
      (json) => localStorage.setItem("katasterStorePointList", json),
      { delay: 500 }
    );
    this.pointList =
      JSON.parse(localStorage.getItem("katasterStorePointList"))?.map((p) => new KatasterPoint(p)) ?? [];
  }

  /**
   * this funtion generates the Request object with the date from the Store variables.
   */
  getDeterminationRequestParamsFromStore = () => {
    return {
      meridian: this.meridian,
      fix_scale: this.fix_scale,
      height_shift_points: this.pointList
        .filter((p) => p.isPassPoint() && p.isDeterminationSelected && p.isHeightSelected)
        .map((p) => p.punktnummer),
      mgi_points: this.getGeoJsonFromPointList(
        [this.filterIsPassPoint, this.filterIsNotTransformationPoint, this.filterIsSelected],
        "gk"
      ),
      etrs_points: this.getGeoJsonFromPointList(
        [this.filterIsPassPoint, this.filterIsNotTransformationPoint, this.filterIsSelected],
        "etrs89"
      ),
    };
  };

  /**
   * This Function takes and Axios response Object and Reads the date from the Determinatio, and wirites
   * into the Store Variables.
   * @param {*} res must be the Axios response from the Determination Route.
   */

  handleResponseFromDetermination = (res) => {
    const { improvements, meridian, params, residuals, sigmas, height_shift } = res.data.result;
    if (this.pointList.length > 0) {
      this.pointList.forEach((point) => {
        point.writeImprovementsAndResiduals(
          improvements
            .filter(({ name }) => name === point.punktnummer)
            .map(({ name, ...improvements }) => improvements)[0] ?? null,
          residuals
            .filter(({ name }) => name === point.punktnummer)
            .map(({ name, ...residuals }) => residuals)[0] ?? null
        );
      });
      this.meridian = meridian;
      this.params = params;
      this.sigmas = sigmas;
      this.height_shift = height_shift;
      this.pointList = [...this.pointList];
      this.determinationReportKey = res.data.meta["report_key"];
      this.transformationReportKey = "";
      if (!this.didDeterminationOnce) this.didDeterminationOnce = true;
    }
  };

  /**
   * This function sends and Determination Check to the backend and write the Response Date in the Store Variables.
   * Only if 4 or more Passpoints are selected / given.
   * THROWS and ERROR on
   */
  startDetermination = () => {
    // Check if PointList is Ready for Determination
    this.determinationReportKey = "";
    if (this.isListReadyForDetermination()) {
      const reqParams = this.getDeterminationRequestParamsFromStore();
      this.determinationReportKey = "";
      // Send Post to dertermination url with request params and auth confid
      // TODO Refactor Config to get it from an Store after REdux is Removed.
      axios
        .post(constants.DETERMINATION_API_URL_CAD, reqParams, this.config)
        .then(this.handleResponseFromDetermination)
        .catch(errorHandler);
    } else {
      console.log("Zu wengie Punkte für eine Determination");
    }
  };

  /**
   * Create Request params for the Transfromation from ETRS to WGS
   */
  getMapTransformationRequestParamsEtrs = () => {
    return {
      geojson: this.getGeoJsonFromPointList([this.filterHasEtrs], "etrs89"),
      source_crs: {
        ellipsoid: "",
        epoch: 2002.56,
        frame: "209",
        heightsystem: "usage",
        prime_meridian: "",
        projection: "",
      },
      target_crs: {
        ellipsoid: "EPSG::7030",
        epoch: 2002.56,
        frame: "302",
        heightsystem: "usage",
        prime_meridian: "EPSG::8901",
        projection: "",
      },
    };
  };

  /**
   * This functions sends and Transformation Call to the
   * backend to get the WGS Coordinates for ETRS Coordiantes
   * for all Point from the Point list wit ETRS coordinats
   */
  mapTransformationEtrs = () => {
    const req = this.getMapTransformationRequestParamsEtrs();
    axios
      .post(constants.TRANSFORMATOR_API_URL_ADV, req, this.config)
      .then((res) => {
        let x = res.data.result.points;
        this.setPointsKoordinatsFromGeoJson("wgs", x);
      })
      .catch(errorHandler);
  };

  /**
   * Create Request params for the Transfromation from MGI to WGS
   */
  getMapTransformationRequestParamsMGI = () => {
    return {
      geojson: this.getGeoJsonFromPointList([this.filterHasMgiMissingEtrs], "gk"),
      source_crs: {
        frame: "301",
        projection: MERIDIAN_MAPPING[this.meridian],
        heightsystem: "usage",
        ellipsoid: "EPSG::7004",
        prime_meridian: "EPSG::8901",
        epoch: 2002.56,
      },
      target_crs: {
        ellipsoid: "EPSG::7030",
        epoch: 2002.56,
        frame: "302",
        heightsystem: "usage",
        prime_meridian: "EPSG::8901",
        projection: "",
      },
    };
  };

  /**
   * This functions sends and Transformation Call to the
   * backend to get the WGS Coordinates for MIG Coordiantes
   * for all Point from the Point list wit MGI coordinats
   */
  mapTransformationMgi = () => {
    const req = this.getMapTransformationRequestParamsMGI();
    axios
      .post(constants.TRANSFORMATOR_API_URL_ADV, req, this.config)
      .then((res) => {
        let x = res.data.result.points;
        this.setPointsKoordinatsFromGeoJson("wgs", x);
      })
      .catch(errorHandler);
  };

  /**
   * Takes and Point Object from the Point Class and sets the Selected Attribute to true or false,
   * depending on the previous state,
   * checks if enough points are selected for the Determination
   * @param {*} point An object from the Point Class
   */
  togglePoint = (point) => {
    if (point.isDeterminationSelected && !this.isListReadyAfterSelection()) {
      return toast.info(i18next.t("cadastre:store_toaster_minimum_points"), TOASTER_INFO_AUTOCLOSE);
    }

    this.pointList
      .find((p) => p.punktnummer === point.punktnummer)
      .setData({ isDeterminationSelected: !point.isDeterminationSelected });
    this.pointList = [...this.pointList];
  };

  /**
   * Takes and Point from the Point Class and change the HeichtSelected Attribute to true or false,
   * depending on the previous state.
   * @param {*} point An object from the Point Class
   */
  togglePointHeight = (point) => {
    this.pointList
      .find((p) => p.punktnummer === point.punktnummer)
      .setData({ isHeightSelected: !point.isHeightSelected });
    this.pointList = [...this.pointList];
  };

  /**
   * Takes a String and findes a Point Object from the pointList and sets the Selected Attribute to true or false,
   * depending on the previous state,
   * checks if enough points are selected for the Determination
   * @param {*} point An object from the Point Class
   */
  togglePointByName = (pointName) => {
    const point = this.pointList.find((p) => p.punktnummer === pointName);
    if (point.isDeterminationSelected && !this.isListReadyAfterSelection()) {
      return toast.info(i18next.t("cadastre:store_toaster_minimum_points"), TOASTER_INFO_AUTOCLOSE);
    }

    point.setData({ isDeterminationSelected: !point.isDeterminationSelected });
    this.pointList = [...this.pointList];
  };

  /**
   * Takes and Point from the Point Class and change the isTrafsformationPoint Attribute to true or false,
   * depending on the previous state.
   * @param {*} point An object from the Point Class
   */
  toggleIsTrafoPoint = (point) => {
    this.pointList
      .find((p) => p.punktnummer === point.punktnummer)
      .setData({ isTransformationpoint: !point.isTransformationpoint });
    this.pointList = [...this.pointList];
  };

  /**
   * Takes and Point Object from the Point Class and removes this element from the PointList,
   * checks if enough points are in the List for the Determinatio, beforehand.
   * @param {*} point An object from the Point Class
   */
  removePointFromList = (point) => {
    if (point.isDeterminationSelected && !this.isListReadyAfterSelection()) {
      return toast.info(i18next.t("cadastre:store_toaster_minimum_points"), TOASTER_INFO_AUTOCLOSE);
    }

    this.pointList = [...this.pointList.filter((p) => p.punktnummer !== point.punktnummer)];
  };

  /**
   * Check pointList of PassPoints for Determination
   */
  isListReadyForDetermination = () => {
    return this.pointList.filter(this.filterIsSelectedPassPoint).length >= 4;
  };

  /**
   * Check pointList of PassPoints for Selection
   */
  isListReadyAfterSelection = () => {
    return this.pointList.filter(this.filterIsSelectedPassPoint).length > 4;
  };

  /**
   * This function takes an Object with point data and 2 Booleans for Checks and Evaluate if Point Exist write
   * the data in the Existing Point, if not create a new Point Class Object. If evaluatePasspoint is True it will
   * set the selected attribute True if MGI and ETRS coordinates Exists.
   * @param {*} point an Object from Point Class
   * @param {*} evaluatePasspoint boolean if true start selected attribute check.
   * @param {*} override boolean if false data will only be created if point don't exist.
   */
  // INFO - KatasterPoint
  addPoint = (point, evaluatePasspoint, override = true) => {
    if (!point.punktnummer)
      return toast.info(i18next.t("cadastre:store_toaster_minimum_point_name"), TOASTER_INFO_AUTOCLOSE);
    const nextPoint = new KatasterPoint(point);
    const pointFromList = this.getPointFromListByObject(nextPoint);
    if (!!pointFromList) {
      return this.writePointData(pointFromList, point, evaluatePasspoint, override);
    }
    return this.writeNewPointData(nextPoint, evaluatePasspoint);
  };

  /**
   * Write the given Data to the Given Point and checks if selected attribute need's to be Evaluated
   * @param {Point Class} point, given Point
   * @param {Object} pointData, given Point data
   * @param {Boolean} evaluatePasspoint, if true will check  selected attribute
   * @param {Boolean} override, if false will do nothing,
   */
  // INFO - KatasterPoint
  writePointData = (point, pointData, evaluatePasspoint, override) => {
    if (!override) return;
    point.setData(pointData);
    if ((point.isPassPoint() && point.isDeterminationSelected) || !evaluatePasspoint) {
      return (this.pointList = [...this.pointList]);
    }
    point.setData({ isDeterminationSelected: point.isPassPoint() });
    return (this.pointList = [...this.pointList]);
  };

  /**
   * Takes an Object with Point Data create the Point and adds the Point to the pointList.
   * @param {*} point
   */
  // INFO - KatasterPoint
  writeNewPointData = (point, evaluatePasspoint) => {
    if (point.isPassPoint() && !point.isDeterminationSelected && evaluatePasspoint) {
      point.setData({ isDeterminationSelected: true });
    }
    this.pointList.push(point);
  };

  // INFO - KatasterPoint
  getPointFromListByObject = (point) => {
    return this.pointList.find((p) => p.isSamePointByObject(point));
  };

  // setter functions
  setConfig = (config) => (this.config = config);

  setMap = (map) => (this.map = map);

  setFixScale = (fixScale) => {
    this.fix_scale = fixScale;
  };

  setMeridian = (event) => {
    this.meridian = event.target.value;
  };

  setHoldDetermination = (bool) => (this.holdDetermination = bool);

  setMap = (map) => (this.map = map);

  /**
   * Reads features from Geojson an add's the information to the PointList.
   * if type is not in list, error will be thrown
   *
   * @param {STRING} type on of ['etrs', 'mgi', 'calculatedMgi', 'wgs']
   * @param {GEOJSON} geoJson
   * @param {BOOLEAN} isTransformationpoint
   */
  // INFO - KatasterPoint
  setPointsKoordinatsFromGeoJson = (type, geoJson, isTransformationpoint, stoppPassPointEvaluation) => {
    if (["etrs", "mgi", "calculatedMgi", "wgs"].indexOf(type) === -1)
      throw new Error("angegeben type muss einer von diesen sein ['etrs', 'mgi', 'calculatedMgi', 'wgs']");
    if (!geoJson) throw new Error("geojson muss mit angeführt werden");

    for (const feature of geoJson.features) {
      if (feature.geometry.type.toLowerCase() !== "point") continue;
      this.addPoint(
        {
          punktnummer: feature.properties.name,
          [TYPE_COORD_1_MAPPING[type]]: feature.geometry.coordinates[0],
          [TYPE_COORD_2_MAPPING[type]]: feature.geometry.coordinates[1],
          [TYPE_COORD_3_MAPPING[type]]: feature.geometry.coordinates[2],
          oek: feature.properties.oek ?? null,
          // TODO META check if kgNummer exists in Feature.properties
          nummer: feature.properties.nr?.toString() ?? null,
          punktArt: feature.properties.art ?? null,
          stabilisierung: feature.properties.stabilisierung ?? null, // TODO META check if stabilisierung exists in Feature.properties
          source: "bev-transformator",
          isTransformationpoint,
          punktType: feature.properties.type,
          etrs89Messdatum: feature.properties.messdatum,
        },
        type !== "wgs" && !stoppPassPointEvaluation
      );
    }
  };

  /**
   * This functions takes an type and array with old point data Objects and creates
   * new Point Data Objects and add's them to the pointList.
   *
   * @param {STRING} type on of ['etrs', 'mgi', 'calculatedMgi', 'wgs']
   * @param {Arry} list, array with old pointList data objects
   * @param {Boolean} isTransformationpoint
   * @param {boolean} evaluatePasspoint
   */
  // INFO - KatasterPoint
  setPointsKoordinatesFromPointList = (type, list, isTransformationpoint, evaluatePasspoint = true) => {
    if (!list) throw new Error("list muss mit angeführt werden");
    list.forEach((p) => {
      const coord = !!p.RW ? "mgi" : "etrs";
      const point = {
        punktnummer: p.name,
        punktType: type,
        [TYPE_COORD_1_MAPPING[coord]]: p.RW ? (isNaN(p.RW) ? 0 : p.RW) : isNaN(p.X) ? 0 : p.X,
        [TYPE_COORD_2_MAPPING[coord]]: p.HW ? (isNaN(p.HW) ? 0 : p.HW) : isNaN(p.Y) ? 0 : p.Y,
        [TYPE_COORD_3_MAPPING[coord]]: p.Hoehe ? (isNaN(p.Hoehe) ? 0 : p.Hoehe) : isNaN(p.Z) ? 0 : p.Z,
        isTransformationpoint,
      };
      if (p.messdatum) point.etrs89Messdatum = p.messdatum;
      this.addPoint(point, evaluatePasspoint);
    });
  };

  /**
   * This function takes an string and an Boolean and sets the Extend of the Map for Points filtered for the given fiter string.
   * @param {*} filter enum ['pp','mp','sp']
   * @param {*} selected if true olny selected points will be uses for pp search
   */
  setExtentByPointListAndFilter = (filter, selected) => {
    if (["pp", "mp", "sp"].indexOf(filter) === -1) {
      throw new Error("Filter muss einen dieser werte annehmen [pp, mp, sp]");
    }
    let filterFunction;
    if (filter === "pp")
      filterFunction = (p) =>
        !p.isTransformationpoint &&
        p.isPassPoint() &&
        p.isWgsValid() &&
        (selected ? p.isDeterminationSelected : true);
    if (filter === "mp") filterFunction = (p) => p.isTransformationpoint && p.isWgsValid();
    if (filter === "sp")
      filterFunction = (p) => !p.isPassPoint() && !p.isTransformationpoint && p.isWgsValid();
    const mapFunction = (p) => fromLonLat([p.wgs84Phi, p.wgs84Lam]);

    const pointsCoordinates = this.pointList.filter(filterFunction).map(mapFunction);

    let newExtent = createEmpty();
    const pointsExtends = pointsCoordinates.map((c) => [c[0], c[1], c[0], c[1]]);
    pointsExtends.forEach((e) => extend(newExtent, e));
    this.extent = newExtent;
  };

  /**
   * Creates a new empty GeoJson Object without Features
   */
  getEmptyGeoJson = () => JSON.parse(JSON.stringify(BASIC_GEOJSON));

  /**
   * Takes in an Array with Functions for PointList filtering, and the coordinateType from Point to map Object
   * Koordinates to Array Koordinates, and returns a GeoJson with this Points from The List
   *
   * @param {ARRAY} filterArry
   * @param {STRING} coordinateType
   */
  getGeoJsonFromPointList = (filterArry, coordinateType) => {
    let pointList = this.pointList;
    if (filterArry && filterArry.length > 0) {
      for (let filterFunction of filterArry) {
        pointList = pointList.filter(filterFunction);
      }
    }
    const geojson = this.getEmptyGeoJson();
    geojson.features = pointList.map((p) => p.getFeaturePoint(coordinateType));
    geojson.name = "NewKatasterFile";
    return geojson;
  };

  /**
   * This Function takes and Coordinate Array and and try's to get the
   *  nearst 5 Passpoint's from the Geoserver and adds them to the pointList and selects them.
   * If no Points are founde in an Area around 10 km the Search will be stopped.
   * @param {*} coord
   */
  selectPointsAroundCoord = async (coord) => {
     //transform coordinates for service
    const c = toLonLat(coord)
    const pointsMapFunction = f => { 

      // Get Correct Stabilisierung from list of Stabilisierung
      // Try to Get Stabilisierung with same kennzeichen as kennzeichen_darstellung from Point
      const stab = f.properties.stabilisierungen.find(stab => f.properties.kennzeichen_darstellung === stab.kennzeichen && !!stab.etrs[0] ) ?? 
      // IF null then sort Stabilisierung and find fist one with etrs attributes
      f.properties.stabilisierungen.sort((a,b) => a.kennzeichen.localeCompare(b.kennzeichen)).find(s => !!s.etrs[0]);

      if(!stab) return null;
    

      const pointArtText = f.properties.art === "E" ? `${f.properties.oek}-${f.properties.nr}` : `${f.properties.nr}-${f.properties.oek}`;
      f.properties.name = `${f.properties.art} ${pointArtText}${stab.kennzeichen}`;

      return {
        punktnummer: f.properties.name,
        oek: f.properties.art !== "E" ? f.properties.oek?.toString() ?? null : null,
        kgNummer: f.properties.art === "E" ? f.properties.oek?.toString() ?? null : null,
        nummer: f.properties.nr?.toString() ?? null,
        punktType: f.properties.art ?? null,
        [TYPE_COORD_1_MAPPING["wgs"]]: f.geometry.coordinates[0],
        [TYPE_COORD_2_MAPPING["wgs"]]: f.geometry.coordinates[1],
        [TYPE_COORD_3_MAPPING["wgs"]]: f.geometry.coordinates[2] || 0,
        [TYPE_COORD_1_MAPPING["etrs"]]: stab.etrs[0],
        [TYPE_COORD_2_MAPPING["etrs"]]: stab.etrs[1],
        [TYPE_COORD_3_MAPPING["etrs"]]: stab.etrs[2],
        [TYPE_COORD_1_MAPPING["mgi"]]: stab.mgi[0],
        [TYPE_COORD_2_MAPPING["mgi"]]: stab.mgi[1],
        [TYPE_COORD_3_MAPPING["mgi"]]: stab.mgi[2],
        source: "bev-transformator",
        etrs89Messdatum: f.properties.messdatum,
      }
    }
    
    toast.info(i18next.t("cadastre:toast_info_start_fetch"), TOASTER_INFO_AUTOCLOSE);
    const res5 = await axios.get(constants.KATASTER_SERVICE_API_URL + "fpt/getClosestPoints/?limit=5&center=" + c[0] + "," + c[1])
    let foundPoints = res5.data.points.map(pointsMapFunction).filter(f => !!f);
    if(foundPoints.length < 5) {
      const res10 = await axios.get(constants.KATASTER_SERVICE_API_URL + "fpt/getClosestPoints/?limit=10&center=" + c[0] + "," + c[1])
      foundPoints = res10.data.points.map(pointsMapFunction).filter(f => !!f);
    }

    foundPoints.forEach(pointObject => this.addPoint(pointObject, false, false));


    this.pointList.forEach((p) => {
      if (foundPoints.find((f) => {
        const { oek, nummer, punktType, punktnummer, kgNummer } = f; 
        const isSame = p.isSamePoint({ oek: (punktType !== "E" ? oek : kgNummer), nr: nummer, type: punktType, name: punktnummer });
        return isSame;
      })) {
        p.setData({ isDeterminationSelected: true });
      } else {
        p.setData({ isDeterminationSelected: false });
      }
    });

    runInAction(() => {
      this.centerCoordinates = coord;
      // Set the new Data in the Store.
      this.pointList = [...this.pointList];
    });

  };

  /**
   * This function starts an Download process for the saved ReportKey's in the Store.
   */

  startExportPDF = () => {
    /* ***** NEW START ***** */

    const req = {
      report_key_determination: this.determinationReportKey,
      report_key_transformation: this.transformationReportKey,
    };
    const map = this.map;
    map.once("rendercomplete", function () {
      var mapCanvas = document.createElement("canvas");
      var size = map.getSize();
      mapCanvas.width = size[0];
      mapCanvas.height = size[1];
      var mapContext = mapCanvas.getContext("2d");
      let i = 0;
      Array.prototype.forEach.call(
        document.querySelectorAll("#mapContainer .ol-layer canvas"),
        function (canvas) {
          if (canvas.width > 0) {
            i++;
            var opacity = canvas.parentNode.style.opacity;
            mapContext.globalAlpha = opacity === "" ? 1 : Number(opacity);
            var transform = canvas.style.transform;
            // Get the transform parameters from the style's transform matrix
            var matrix = transform
              .match(/^matrix\(([^\(]*)\)$/)[1]
              .split(",")
              .map(Number);
            // Apply the transform to the export map context
            CanvasRenderingContext2D.prototype.setTransform.apply(mapContext, matrix);
            mapContext.drawImage(canvas, 0, 0);
          }
        }
      );
      req.image = mapCanvas.toDataURL();
      const header = {
        ...this.config,
        responseType: "arraybuffer",
      };
      axios
        .post(constants.BASE_API_URL + "cadastre/report/", req, header)
        .then((res) => {
          const file = new Blob([res.data], { type: "application/pdf" });
          const fileURL = URL.createObjectURL(file);
          const ua = window.navigator.userAgent;
          const isIE = /MSIE|Trident|Edge\//.test(ua);
          if (isIE) return window.navigator.msSaveOrOpenBlob(file, "transformationsprotokoll.pdf");
          return window.open(fileURL);
        })
        .catch((e) => {
          console.log(e);
        });
    });
    map.renderSync();
  };

  startExportDownload = (id) => {

    //If id is frontend/json return params Json
    if (id.includes("frontend/json")) return this.getParamJson();

    // Else create Image from Map
    const map = this.map;
    map.once("rendercomplete", () => {
      const mapCanvas = this.createMapImage(map);
      // if id includes frontend then return only the PNG
      if (id.includes("frontend")) return this.getMapImagePng(mapCanvas);
      // else start backend call

      // creat request object
      let req = {};
      req.report_key_determination = this.determinationReportKey;
      req.report_key_transformation = this.transformationReportKey;
      const header = {
        ...this.config,
        responseType: "arraybuffer",
      };
      req.image = mapCanvas.toDataURL();

      //start axios backend post call
      axios
        .post(constants.BASE_API_URL + "cadastre/export/" + id + "/", req, header)
        .then((res) => {
          // some content checkst and then start the download or open in new Tap for pdf
          if (!!res?.headers?.["content-type"]?.includes("pdf")) {
            const file = new Blob([res.data], { type: "application/pdf" });
            const fileURL = URL.createObjectURL(file);
            const ua = window.navigator.userAgent;
            const isIE = /MSIE|Trident|Edge\//.test(ua);
            if (isIE) return window.navigator.msSaveOrOpenBlob(file, "transformationsprotokoll.pdf");
            return window.open(fileURL);
          } else {
            const blob = new Blob([res.data], {
              type: res?.headers?.["content-type"] ?? "text/plain",
            });
            let url = window.URL.createObjectURL(blob);
            let a = document.createElement("a");
            a.href = url;
            a.download = res?.headers?.["filename"] ?? "export.txt";
            a.click();
          }
        })
        .catch((e) => {
          console.log(e);
        });

    });

    // Start the Render to get the rendercomplete event
    map.renderSync();
  };

  //Helper functions for Export

  /**
   * Creates an JSON from the store params and makes a download
   */
  getParamJson = () => {
    try {
      const file = new Blob(
        [
          JSON.stringify(
            { params: this.params, sigmas: this.sigmas },
            null,
            1
          ),
        ],
        { type: "application/json" }
      );
      const fileURL = URL.createObjectURL(file);
      const ua = window.navigator.userAgent;
      const isIE = /MSIE|Trident|Edge\//.test(ua);

      if (isIE) return window.navigator.msSaveOrOpenBlob(file, "params.json");

      let element = document.createElement("a");
      element.href = fileURL;
      element.download = "params.json";

      element.style.display = "none";
      document.body.appendChild(element);

      element.click();

      document.body.removeChild(element);

    } catch (error) {
      errorHandler(error);
    }
  }


  /**
   * Makes a download for the canvas as png
   * @param {canvas} canvas from the map
   */
  getMapImagePng = (canvas) => {

    if (navigator.msSaveBlob) {
      // link download attribute does not work on MS browsers
      navigator.msSaveBlob(canvas.msToBlob(), "map.png");
    } else {
      const element = document.createElement("a");
      element.href = canvas.toDataURL();
      element.download = `Kataster_Karte_${format(new Date, 'yyyy.MM.dd')}.png`;
      element.style.display = "none";
      document.body.appendChild(element);

      element.click();

      document.body.removeChild(element);
    }


  }


  /**
   * Creates an Canvas from the layers of the given map reverenze
   * @param {map} an open layer map reference 
   */
  createMapImage = (map) => {
    var mapCanvas = document.createElement("canvas");
    var size = map.getSize();
    mapCanvas.width = size[0];
    mapCanvas.height = size[1];
    var mapContext = mapCanvas.getContext("2d");
    let i = 0;
    Array.prototype.forEach.call(
      document.querySelectorAll("#mapContainer .ol-layer canvas"),
      function (canvas) {
        if (canvas.width > 0) {
          i++;
          var opacity = canvas.parentNode.style.opacity;
          mapContext.globalAlpha = opacity === "" ? 1 : Number(opacity);
          var transform = canvas.style.transform;
          // Get the transform parameters from the style's transform matrix
          var matrix = transform
            .match(/^matrix\(([^\(]*)\)$/)[1]
            .split(",")
            .map(Number);
          // Apply the transform to the export map context
          CanvasRenderingContext2D.prototype.setTransform.apply(mapContext, matrix);
          mapContext.drawImage(canvas, 0, 0);
        }
      }
    );
    return mapCanvas;
  }

  getKvzFromStp = async (file) => {
    const req = new FormData();
    req.append("stp", file);
    try {
      const res = await axios.post(constants.BASE_API_URL + "cadastre/export/stp/", req, this.config)
      return (await res).data;
    } catch (e) {
      console.error(e);
      return { error: true }
    }
  };

  // Clear Store

  clearStore = () => {
    this.pointList = [];
    this.meridian = "GK_AUTO";
    this.fix_scale = false;
    this.params = null;
    this.sigmas = null;
    this.didDeterminationOnce = false;
    this.transformationReportKey = "";
    this.determinationReportKey = "";
  };

  //Filterfunctions

  filterHasWgs = (point) => {
    return point.isWgsValid();
  };

  filterHasEtrs = (point) => {
    return point.hasValidEtrsCoordinates();
  };

  filterIsPassPoint = (point) => {
    return point.isPassPoint();
  };

  filterIsSelectedPassPoint = (point) => {
    return point.isPassPoint() && !point.isTransformationpoint && point.isDeterminationSelected;
  };

  filterIsNotTransformationPoint = (point) => {
    return !point.isTransformationpoint;
  };

  filterIsSelected = (point) => {
    return !!point.isDeterminationSelected;
  };

  filterIsNotSelected = (point) => {
    return !point.isDeterminationSelected && !point.isTransformationpoint && point.isPassPoint();
  };

  filterHasMgiMissingEtrs = (point) => {
    return point.hasValidGkCoordinates() && !point.hasValidEtrsCoordinates();
  };

  filterHasMgiMissingEtrsWgs = (point) => {
    return point.hasValidGkCoordinates() && !point.hasValidEtrsCoordinates() && !point.isWgsValid();
  };

  filterMissingWgsHasMgi = (point) => {
    return !point.isWgsValid() && point.hasValidGkCoordinates();
  };

  filterIsTransformationPoint = (point) => {
    return !!point.isTransformationpoint;
  };

  filterPointsForTransformation = (point) => {
    return !!point.isTransformationpoint || (!point.isDeterminationSelected && !point.isTransformationpoint);
  };

  // Checks
  isGreen = () => {
    const check1 = Math.abs(this.params.m - 1) * 1e6 >= 100;
    const check2 = this.pointList.filter((p) => p.length > 100).length > 0;
    return !check1 && !check2;
  };
}

export default Kataster;
