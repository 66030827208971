import React, { useState } from "react";
import BevCard from "components/BevCard";
import { inject, observer } from "mobx-react";
import { PointInput } from "../PointInput";
import { PointsSelectionMap } from "./PointSelectionMap";
import { toLonLat } from "ol/proj";
import { Tabs, Tab, Divider, Grid, Box } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faMap,
  faMapMarker,
  faMapSigns,
} from "@fortawesome/free-solid-svg-icons";
import { useTranslation } from "react-i18next";
import { classes } from "./styling";
import { ParamsMapComponent } from "./paramsMapComponent";
import { ExtentMapComponent } from "./extentMapComponent";
import { TransformationPointInputModal } from "./transformationPointInputModal";
import { PassPointSearchModal } from "./passPointSearchModal";
import { PointTableOther as OtherPointTable } from "./PointTableOther";
import { PointTablePassPoint as PassPointTable } from "./PointTablePassPoint";
import { PointTableTrafo as TrafoPointTable } from "./PointTableTrafo";
import { DeterminationOptions } from "./DeterminationOptions";
import { TabPanel } from "components/TabPanel";

export const PointSelection = inject("store")(
  observer(({ store, toggleSolution }) => {
    const [isOpen, setIsOpen] = useState(true);
    const [tabType, setTabType] = useState("passPoint");
    const [isModeOn, setIsModeOn] = useState(false);
    const [isInputOpen, setIsInputOpen] = useState(false);
    const [isInputPassPointOpen, setIsInputPassPointOpen] = useState(false);
    const [isInputAndFinishOpen, setIsInputAndFinishOpen] = useState(false);
    const [isInputMgiOpen, setIsInputMgiOpen] = useState(false);
    const { t } = useTranslation();
    const { didDeterminationOnce, togglePointByName } = store.kataster;

    const toggleCard = () => setIsOpen(!isOpen);

    const handleChangeMode = (event) => setIsModeOn(event.target.checked);

    const handleOnClick = (f, what) => {
      if (what === "arrows") return togglePointByName(f.get("name"));
      if (what === "passPoints") {
        const coord = toLonLat(f.getGeometry().getCoordinates());
        const featureName = f.get("art") === "E" ? `${f.get("art")} ${f.get("oek")}-${f.get("nr")}${f.get("ue_kz")}` : `${f.get("art")} ${f.get("nr")}-${f.get("oek")}${f.get("ue_kz")}`
        const fWgs = {
          punktnummer:featureName,
          nummer: f.get("nr") ?? null,
          punktArt: f.get("art") ?? null,
          oek: f.get("oek"),
          kgNummer: f.get("oek"),
          stabilisierung: f.get("ue_kz"),
          wgs84Phi : coord[0],
          wgs84Lam: coord[1],
          wgs84Hoehe: coord[3] | 0,

          gkY: f.get("rw_mgi"),
          gkX: f.get("hw_mgi"),
          gkHoeheAdria: f.get("hh_mgi"),

          etrs89X: f.get("x3d_grs"),
          etrs89Y: f.get("y3d_grs"),
          etrs89Z: f.get("z3d_grs"),
          etrs89Messdatum: f.get('messdatum'),
          source: "bev-geo-server",
        };
        store.kataster.addPoint(fWgs, true, true);
      }
    };

    const handleClickNext = () => {
      const filteredList = store.kataster.pointList.filter(
        (p) => p.isTransformationpoint
      );
      if (filteredList.length > 0) return openSolution(true);
      setIsInputAndFinishOpen(true);
    };

    const handleCloseInput = () => {
      setIsInputMgiOpen(false);
      setIsInputAndFinishOpen(false);
      setIsInputOpen(false);
    };

    const openSolution = () => {
      setIsOpen(false);
      toggleSolution();
    };

    const loadDataToPointList = (type) => (data) => {
      store.kataster.setPointsKoordinatsFromGeoJson(
        type,
        data,
        type === "etrs"
      );
      handleCloseInput();
    };

    const loadDataToPointListAndFinish = (type) => (data) => {
      store.kataster.setPointsKoordinatsFromGeoJson(
        type,
        data,
        type === "etrs"
      );
      handleCloseInput();
      openSolution();
    };

    return (
      <BevCard
        padding={0}
        title={t('cadastre:selection_bev_card_title')}
        show
        open={isOpen}
        toggleOpen={toggleCard}
      >
        {!didDeterminationOnce && <PointInput />}
        {didDeterminationOnce && (
          <div>
            <Box sx={classes.mapWrapper}>
              <PointsSelectionMap
                classes={classes}
                pointsList={store.kataster.pointList}
                buildGeoJsonFunction={store.kataster.getGeoJsonFromPointList}
                handleOnClick={handleOnClick}
                onCenterSelect={store.kataster.selectPointsAroundCoord}
                extent={store.kataster.extent}
                heightMode={isModeOn}
                center={store.kataster.centerCoordinates}
                setMap={store.kataster.setMap}
                startSelectedMap={"kataster"}
              />
              <ExtentMapComponent />
              <ParamsMapComponent />
            </Box>

            <Divider />
            <Grid container alignItems={"center"} spacing={2}>
              <DeterminationOptions
                handleChangeMode={handleChangeMode}
                isModeOn={isModeOn}
                handleClickNext={handleClickNext}
              />
              <Grid item xs={12}>
                <Divider />
                <Tabs
                  sx={{
                    borderBottomColor: "primary.light",
                    borderBottomWidth: 1,
                    borderBottomStyle: "solid",
                  }}
                  indicatorColor="primary"
                  textColor="primary"
                  value={tabType}
                  onChange={(e, val) => setTabType(val)}
                >
                  <Tab
                    value={"passPoint"}
                    label={t('cadastre:selection_tab_passpoint')}
                    icon={<FontAwesomeIcon icon={faMapMarker} />}
                  />
                  <Tab
                    value={"trafoPoint"}
                    label={t('cadastre:selection_tab_trafopoint')}
                    icon={<FontAwesomeIcon icon={faMap} />}
                  />
                  <Tab
                    value={"otherPoints"}
                    label={t('cadastre:selection_tab_otherpoint')}
                    icon={<FontAwesomeIcon icon={faMapSigns} />}
                  />
                </Tabs>
                <TabPanel value={tabType} index={"passPoint"}>
                  <PassPointTable
                    handleOpenInput={() => setIsInputPassPointOpen(true)}
                  />
                </TabPanel>
                <TabPanel value={tabType} index={"trafoPoint"}>
                  <TrafoPointTable
                    handleOpenInput={() => setIsInputOpen(true)}
                  />
                </TabPanel>
                <TabPanel value={tabType} index={"otherPoints"}>
                  <OtherPointTable
                    handleOpenInput={() => setIsInputMgiOpen(true)}
                  />
                </TabPanel>
              </Grid>
            </Grid>
          </div>
        )}
        <TransformationPointInputModal
          open={isInputOpen}
          handleClose={handleCloseInput}
          title={t('cadastre:selection_trafopoint_input_title_etrs')}
          handleLoadData={loadDataToPointList("etrs")}
        />
        <TransformationPointInputModal
          open={isInputMgiOpen}
          handleClose={handleCloseInput}
          title={t('cadastre:selection_trafopoint_input_title_mgi')}
          handleLoadData={loadDataToPointList("mgi")}
        />
        <TransformationPointInputModal
          open={isInputAndFinishOpen}
          hasFinish
          handleClose={handleCloseInput}
          title={t('cadastre:selection_trafopoint_input_title_etrs')}
          handleLoadData={loadDataToPointList("etrs")}
          handleLoadDataAndFinish={loadDataToPointListAndFinish("etrs")}
        />
        <PassPointSearchModal
          open={isInputPassPointOpen}
          handleClose={() => setIsInputPassPointOpen(false)}
          title={t('cadastre:selection_passpoint_input_title')}
        />
      </BevCard>
    );
  })
);
