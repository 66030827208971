export const page = {
    "api_keys_copy" : "Successfully Copied",
    "api_keys_title" : "API Keys",
    "api_keys_desc" : "Here you can managae the API Keys.",
    "api_keys_new_key" : "Generate new Key",
    "api_keys_table_active_inactive" : "'active' : 'inactive'",
    "api_keys_user_active_inactive" : "Set user active/inactive",
    "api_keys_active_inactive" : "'Key is active' : 'Key is inactive'",

    "coord_format_desc" : "Please enter coordinates",
    
    "geometry_manager_title" : "Point manager",
    "geometry_manager_desc" : "Edit, delete, import and export files in different formats.",
    "geometry_manager_title2" : "Point manager",
    "geometry_manager_list_icon" : "Geometry List",
    "geometry_manager_import_icon" : "Import File",
    "geometry_manager_view_tip" : "Click to view geometry",

    "home_title_vid" : "Erklärvideo",
    "home_title_vid_text" : "New at Transformator? Take a look at our Explainer Video",
    "home_title_news_text" : "New: BEV Transformator 3.0. find information to BEV – Transformator <1> here </1>",
    "home_title_austrian" : "Austrian Mode",
    "home_desc_austrian" : "Transform coordinates within Austria with our products GIS-Grid as well as Heightgrid, Geoid and Heightgrid+Geoid for height .",
    "home_title_kataster" : "Cadastre Mode",
    "home_desc_kataster" : "Transform GNSS based coordinates in ETRS89 into the official system MGI/GK in relation to the nearest lying reference points.",
    "home_title_farming" : "Farming Mode",
    "home_desc_farming" : "Are your field boundaries in ITRF2014 and you want to work with APOS? With this tool you can easily convert them into the APOS ETRS89 coordinate system.",
    "home_title_advanced" : "Advanced Mode",
    "home_desc_advanced" : "Transform your coordinates from any geographic coordinate system. Here you can choose between systems in different reference frames, on different ellipsoids and with different projections. Different epochs and speeds can also be converted.",

    "impressum_title" : "Imprint",
    "impressum_organisation" : "Federal Office of Metrology and Surveying (BEV)",
    "impressum_department" : "Marketing and Sales Department",
    "impressum_street" : "Schiffamtsgasse 1-3",
    "impressum_post_code" : "1020 Wien",
    "impressum_regulatory_body": "Regulatory Body: Bundesministerium für Arbeit und Wirtschaft  (Federal Ministry of Labour and Economy)",
    "impressum_vat_number" : "UID: ATU 38473200",
    "impressum_terms_of_use" : "Terms of Use",
    "impressum_liability" : "All information is supplied without guarantee and BEV accepts no liability.",
    "impressum_bank" : "Bank account",
    "impressum_iban" : "IBAN: AT95 0100 0000 0519 0001",
    "impressum_bic" : "BIC: BUNDATWW",

    "maintenance" : "Due to maintenance work, the {{modus}} is currently not available. Thank you for your understanding.",

    "point_manager_title_geo" : "Geometries",
    "point_manager_title_coord" : "Coordinate system",
    "point_manager_title_transform" : "Transform",
    "point_manager_title_export" : "Export",
    "point_manager_title_delete" : "Delete",
    "point_manager_warning" : "Caution delete geometry",
    "point_manager_warning2" : "Are you sure you want to delete \"{{name}}\" ?",
    "point_manager_coord_from" : "Set \"{{name}}\" Coordinate system",
    "point_manager_coord_to" : "Transform to",
    "point_manager_coord" : "Transform",
    "point_manager_file_name" : "Choose file name",

    "point_source_single" : "Single Point",
    "point_source_list" : "Pointlist",
    "point_source_saved" : "Saved Geometries",
    "point_source_tip" : "Click to start the transformation",


}