import React, { Component } from 'react';
import { connect } from 'react-redux';

import AppBar from '@mui/material/AppBar';

import * as actions from '../store/actions';

import AppHeaderMenuListLogo from './AppHeaderMenuListLogo';
import AppHeaderMenuList from './AppHeaderMenuList';

const mapStateToProps = state => {
  return {
    auth: state.auth,
  }
}

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    logoutUser: () => dispatch(actions.auth.logoutUser()),
    checkToken: (token) => dispatch(actions.auth.checkToken(token)),
    getUser: () => dispatch(actions.auth.getCurrentUser()),
  }
}

class AppHeader extends Component {
    static propTypes = {
    }
    static defaultProps = {
    }
    state = {
      openAPIDialog: false,
      apikey: '',
    }
    componentDidMount() {
      this.props.getUser()
    }

    apiinsert = () => { 
      this.setState(prevState => { return { openAPIDialog: !prevState.openAPIDialog}});
      if(this.state.dropdownopen) this.setState(prevState => { return { dropdownopen: !prevState.dropdownopen}});
    }
    insertKey = () => {
      console.log("es wurde der key: " + this.state.apikey + " eingegeben")
      this.apiinsert();
      this.props.checkToken(this.state.apikey);
    }
    changeAPIKey = event => {
      this.setState({ apikey: event.target.value })
    }

    render() {
      const { openAPIDialog, apikey } = this.state;

      const { auth, logoutUser } = this.props;
      const { apiDown } = auth;

      const attr = {
        auth, logoutUser, openAPIDialog, apikey, apiDown, 
        apiinsert: this.apiinsert, 
        insertKey: this.insertKey, 
        changeAPIKey: this.changeAPIKey
      }

      return <AppBar className={'header-wrapper'} >
        <AppHeaderMenuListLogo {...attr}/>
        <AppHeaderMenuList path={this.props.path} {...attr} />
    </AppBar>
    }
}

export default connect(mapStateToProps,mapDispatchToProps)(AppHeader);
