import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { Modal, CardHeader, Box } from "@mui/material";
class BevModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
    }
  }

  getModalStyle = () => {
    const top = 50;
    const left = 50;
    const width = this.props.width;

    return {
      top: `${top}%`,
      left: `${left}%`,
      transform: `translate(-${left}%, -${top}%)`,
      width: `${width}px`,
      maxHeight: '100vh',
      overflow: 'auto',
    };
  }

  render() {
    return (
      <Modal open={this.props.open} onClose={this.props.onClose} style={{ overflow: 'auto' }}>
        <Box style={this.getModalStyle()} sx={{
          position: 'absolute',
          backgroundColor: t => t.palette.background.paper,
          boxShadow: t => t.shadows[5],
          padding: 4
        }}>
          <CardHeader title={this.props.title} />
          {this.props.children}
        </Box>
      </Modal>
    );
  }
}

/**
 * open {bool}: is a variable to switch the Open state of the Modal, required, no default
 * onClose {function}: is the function that will be called on the on Close Event, required, no default
 * title {string}: is a variabe to display a Title for the Modal, not required, no default
 * children {node}: is a variable with all the childe nodes of the html stuctur, not required, no default
 * width {number}: is a variable to set the width of the Modal, not required, default: 400
 */
BevModal.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  title: PropTypes.string, // TODO Talk if Title should be required
  children: PropTypes.node,
  width: PropTypes.number
}

BevModal.defaultProps = {
  width: 400
};

export default BevModal;