import React from 'react';
import PropTypes from 'prop-types';

import List from '@mui/material/List';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';

import CheckCRS from './CheckCRS';
import CRSListElement from './CRSListElement';
import { useTranslation } from 'react-i18next';


CRSLoadModal.propTypes = {
  isModalOpen: PropTypes.bool.isRequired, 
  toggleModal: PropTypes.func.isRequired, 
  referenceSystems: PropTypes.array.isRequired, 
  handleChangeCRS: PropTypes.func.isRequired
}
export default function CRSLoadModal ({isModalOpen, toggleModal, referenceSystems, handleChangeCRS, checkReferenceSystems }) {
    const handleClick = (i) => {handleChangeCRS(i)}; 
    const {t} = useTranslation();
    
    return (
      <Dialog open={isModalOpen} onClose={toggleModal} scroll={'paper'} aria-labelledby="scroll-dialog-title" >
      <DialogTitle id="scroll-dialog-title">{t('container_crs_select_text')}</DialogTitle>
      <DialogContent dividers={true}>
        <List>
          {referenceSystems.map((t,i) => (
            (checkReferenceSystems(t) &&
              <CRSListElement key={i} 
                              button={true} 
                              value={i} 
                              handlClick={handleClick.bind(this,i)}
                              avatarInput={t.name[0]} 
                              primary={t.name} 
                              secondary={<CheckCRS crs={t} isInSelect={true} />}/>)
          ))}
        </List>
      </DialogContent>
      <DialogActions>
        <Button variant='outlined' onClick={toggleModal} color="primary" >Abbrechen</Button>
      </DialogActions>
    </Dialog>






    );
}