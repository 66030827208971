import React from 'react';

import TextField from '@mui/material/TextField';

import BevDialog from '../components/BevDialog2';
import { useTranslation } from 'react-i18next';


export default function ApiKeyModal ({openAPIDialog,apiinsert,insertKey,apikey,changeAPIKey}){
  const {t} = useTranslation();
  return <BevDialog 
    open={openAPIDialog} 
    title={t('api_modal_title')} 
    toggleOpen={apiinsert} 
    dismiss={apiinsert} 
    submit={insertKey}>
    <TextField 
      autoFocus 
      margin="dense" 
      id="name" 
      label={t('api_modal_label')}  
      type="text" 
      value={apikey} 
      onChange={changeAPIKey} 
      fullWidth />
  </BevDialog>
    
}
