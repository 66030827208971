export const header = {
    "api_keys" : "API Keys",
    "farming": "Farming",
    "punktspeicher" : "Point Manager",
    "austrian" : "Austrian",
    "advanced" : "Advanced",
    "datumstransformation": "Date Transformation",
    "kataster" : "Cadastre",
    "helmert" : "Helmert",
    "helmert_parametern": "Helmert with Parameters",
    "parameterbestimmung" : "Determine Parameters",
    "transformator" :"Transformator",
    "contact": "Contact",
    "impressum" : "Imprint",
    "insert_key" : "Insert API Key",
    "logout" : "Logout",
    "api": "API",
    "wiki" : "WIKI",

}