import {advanced} from './advanced.js';
import {austrian} from './austrian.js';
import {bevTime} from './bevTime.js';
import {cadastre} from './cadastre.js';
import {container} from './container.js';
import {farming} from './farming.js';
import {header} from './header.js';
import {helmert} from './helmert.js';
import {helmert2} from './helmert2.js';
import {page} from './page.js';
import modulCadastre from './cadastre.json';
import modulCommon from './common.json';

export const json = {
    "translation": {
        ...advanced,
        ...austrian,
        ...cadastre,
        ...bevTime,
        ...container,
        ...farming,
        ...header,
        ...helmert,
        ...helmert2,
        ...page
    },
    "cadastre": modulCadastre,
    "common": modulCommon
}