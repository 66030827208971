export const austrian = {
    "austrian_title_card_title" : "Transformation zwischen ETRS und MGI",
    "austrian_title_card_text" : "Grid-Transformation zwischen dem europäischen Koordinatensystem ETRS89 und dem System der österreichischen Landesvermessung MGI mit GIS-Grid und Höhengrid. Beispiel ETRS89 λφh ellipsoidisch nach M34 Gebrauchshöhe mit GIS-Grid.",
    "austrian_parameter_card_title" : "Transformationsparameter festlegen",
    "austrian_parameter_card_von" : "Transformiern von",
    "austrian_parameter_card_nach" : "Transformiern nach",
    "austrian_parameter_card_lage" : "Transformationsart Lage",
    "austrian_parameter_card_warning1" : "Lage wird für die Transformation benötigt.",
    "austrian_parameter_card_warning2" : "Für die Transformation wird bei \"VON\" noch eine Höhenart benötit.",
    "austrian_parameter_card_warning3" : "Für die Transformation wird bei \"NACH\" noch eine Höhenart benötigt.",
    "austrian_parameter_card_warning4" : "Wenn die Frames von \"VON\" und \"NACH\" ident sind, wird die Transformationsart \"Lage\" nicht benötigt.",
    "austrian_parameter_card_warning5" : "HILFE !!!!",


    "austrian_input_system_label1" : "System",
    "austrian_input_system_label2" : "Höhenart",


    "austrian_point_source_card_title" : "Zu transformierende Punkte",
    
}