import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { ExpandableListElement, PointList } from './geometriesListHelper';
import { makePoint } from '../geojson_geometries';
import { DEFAULT_PAGE_SIZE } from '../constants';
import Lime from '@mui/material/colors/lime';


const classes = {
  subdivStyle: {
    paddingLeft: '40px',
  },
}

/**
   * feature: is the Geometry Object, no default, is required
   * pageSize: is the number of elements that will be displayed on one Pagination Page, default 3, is not required
 */
export default class Polygon extends Component {
  static propTypes = {
    feature: PropTypes.object.isRequired,
    pageSize: PropTypes.number,
  }
  static defaultProps = {
    pageSize:DEFAULT_PAGE_SIZE
  }
  state = {
    showPoly: false,
    showOuter: false,
    showInner: false,
    typeName : ['OP','IP'],
    primary : ['Äusseres Polygon', 'Inneres Polygon'],
    secondary : ['outer Polygon','inner Polygon'],
    showTable : [false,false],
    offset: [0,0],
  }

  togglePoly = (event) => {
    this.setState({ showPoly: !this.state.showPoly });
  }
  toggleTable(i,event){
    let showTable = this.state.showTable;
    showTable[i] = !this.state.showTable[i];
    this.setState({ showTable: showTable});
  }
  handleChangeOffset(i,offset){
    let f_offset = this.state.offset;
    f_offset[i] = offset;
    this.setState({offset: f_offset});
  }

  handlechangeOffset

  render() {
    const {feature, pageSize, color} = this.props;
    return (
      <div>
        <ExpandableListElement typeName="P" 
          primary={feature.properties.name} 
          secondary={"Polygon "} 
          style={{backgroundColor: color,cursor: 'pointer' , marginRight: '10px' }} 
          toggleList={this.togglePoly} 
          showList={this.state.showPoly}/>

        {this.state.showPoly && 
          <div style={classes.subdivStyle}>
            {feature.geometry.coordinates.map((g,i) => (
              <div key={i}>
                <ExpandableListElement typeName={this.state.typeName[i]} 
                  primary={this.state.primary[i]} 
                  secondary={this.state.secondary[i] + " ("+feature.geometry.coordinates[i].length+")"} 
                  style={{backgroundColor: Lime[200],cursor: 'pointer' }} 
                  toggleList={this.toggleTable.bind(this,i)} 
                  showList={this.state.showTable[i]}/>

                <PointList feature={makePoint(feature.properties.name + "_"+i, "Polygon", g, feature.geometry.velocities ? feature.geometry.velocities[i] : undefined )} 
                  offset={this.state.offset[i]} 
                  show={this.state.showTable[i]} 
                  pageSize={pageSize} 
                  listLength={feature.geometry.coordinates[i].length} 
                  handleChangeOffset={this.handleChangeOffset.bind(this,i)} />
              </div>
            ))}
        </div>}
  </div>)
    }
}