import { put } from 'redux-saga/effects';
import axios from 'axios';
import store from '../../store';
import * as actions from '../actions/auth';
import { BASE_API_URL } from '../../common/constants.js';
import { toast } from 'react-toastify';




export function* checkToken(token) {
    let data = {"token": token.token}

    yield put(actions.setTokenAuthentication(false))
    const res = yield axios.post(BASE_API_URL + 'stp/users/obtain/', data)
    if (res.status === 200) {   
        yield put(actions.setTokenAuthentication(true))
        yield put(actions.updateUser(res.data))
        toast.success("Login erfolgreich!", { autoClose: 4000 });

    }
}

export function* getCurrentUser() {
    let auth = store.getState().auth;
    yield put(actions.loadUser(true));
    yield put(actions.apiUp());

    try {
        const res = yield axios.get(BASE_API_URL + 'stp/users/obtain/')
        if (res.status === 200) {
            yield put(actions.setSTPAuthentication(true));
            yield put(actions.updateUser(res.data))
        } else if (res.status === 403 | res.status === 401) {
            yield put(actions.authenticationError(res.data))
        }
    } catch (err) { 
        if (!err.response){
            toast.error('Es kann zurzeit keine Verbinung zum Berechnungsserver aufgebaut werden',{autoClose: false, closeOnClick: true, pauseOnHover: true, draggable: false})
            yield put(actions.apiDown());
        } else if (err.response.status === 400) {
            yield put(actions.apiUp());
            let x = err.response.data;
            x.user.groups = []
            yield put(actions.anonymousUser(x))
            yield put(actions.setSTPAuthentication(false));

            if (auth.tokenAuthentication === true) {
                yield put(actions.checkToken(auth.token))
            }
        } else if (err.response.status === 500) {
            yield put(actions.apiDown());
            toast.error('Es kann zurzeit keine Verbinung zum Berechnungsserver aufgebaut werden',{autoClose: false, closeOnClick: true, pauseOnHover: true, draggable: false})
            yield put(actions.apiDown())
        }
    }
    yield put(actions.loadUser(false));
}

export function* logoutUser() {
    yield put(actions.unsetUserSettings());
    yield put(actions.getCurrentUser());
    toast.success("Sie wurden abgemeldet", { autoClose: 3000})
}