import React, { Component } from 'react';
import { connect } from 'react-redux';
import axios from 'axios';

import Tooltip from '@mui/material/Tooltip';
import Divider from '@mui/material/Divider';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';

import * as actions from 'store/actions';
import * as constants from 'common/constants.js';
import prepareFileName from 'common/prepareFileName';

import CRS from 'components/CRS/CRS';
import BevDialog2 from 'components/BevDialog2';
import ExportFiles from 'components/points/export';
import BevStandardSiteMap from 'components/BevStandardSiteMap';
import BevSaveDialog from 'components/BevSaveDialog';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { withTranslation } from 'react-i18next';

const mapStateToProps = state => {
  return {
    geometryList: state.points.geometryList,
    fileId: state.points.fileId,
    targetCRS: state.points.targetCRS,
    referenceSystems: state.base.referenceSystems,
    fileNameCounter: state.points.fileNameCounter,
    fileName: state.points.fileName,
    config: state.auth.config,
  }
}

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    addGeometryFile: (event) => dispatch( actions.points.addGeometryFile( event ) ),
    updateGeometryFile: (file, id) => dispatch( actions.points.updateGeometryFile( file, id ) ),
    updateTargetCRS: (crs) => dispatch( actions.points.updateTargetCRS( crs ) ),
    removeFile: (event) => dispatch( actions.points.removeFile( event ) ),
    setFileId: (event) => dispatch( actions.points.setFileId( event ) ),
    findNextFileNumber:() => dispatch(actions.points.findNextFileNumber()),
    setFileName: (fileName) => dispatch(actions.points.setFileName(fileName))
 }
}

const checkGeoJsonCadastre = (geoJson) => {
  const {name, properties} = geoJson.crs;
  if(!!name && name.includes('Cadastre')) return true;
  if(!!properties && !!geoJson.filetype && properties.frame === '301' && geoJson.filetype === 'csvega') return true;
  return false;
}



class PointManager2 extends Component {
  constructor(props) {
    super(props);
    this.onCRSChange = this.onCRSChange.bind(this);
    this.onTargetCRSChange = this.onTargetCRSChange.bind(this);
    this.onCRSChangeAll = this.onCRSChangeAll.bind(this);
    this.onTargetCRSChangeAll = this.onTargetCRSChangeAll.bind(this);
    this.handleDoWhat = this.handleDoWhat.bind(this);
    this.toggleDelete = this.toggleDelete.bind(this);
    this.startTransformation = this.startTransformation.bind(this);
    this.state = {
      loading: true,
      showCRS: false,
      openDelete: false,
      doWhat: 'sitemap',
      originaledit: false,
      offset: 0,
      steps: 1,
      isSaveDialogOpen: false,
      fullFileName: '',
    }
  }

  componentDidMount() {
    this.setState({loading: false});
    !this.props.targetCRS.name && this.props.updateTargetCRS(JSON.parse(JSON.stringify(this.props.referenceSystems[0])));
    this.props.setFileName(prepareFileName(this.props.file.name));
  }

  componentDidUpdate() {
    if (this.props.id[0] > -1) {
      let checkNumber = Math.ceil(this.props.file.features.length/this.props.pageSize);
      if(checkNumber !== this.state.steps){
      this.setState({steps: checkNumber, isStepsSet: true});
      }
    }
  }

  deleteGeometryList = (event, id) => {
    this.setState({ openDelete: false });
    this.props.removeFile(id);
    this.props.afterDelete();
  }

  setfileId = (event, id) => {
    this.setState({ showCRS: false });
    this.props.setFileId(id);
  }

  handleShowCRS = () => {
    this.setState({ showCRS: !this.state.showCRS, });
  }

  onCRSChange = (event, name) => {
    let g = this.props.file;
    g.crs.properties[name] = event.target.value;
    this.props.updateGeometryFile(g, this.props.id);
  }

  onTargetCRSChange = (event, name) => {
    let crs = this.props.targetCRS.name ? JSON.parse(JSON.stringify(this.props.targetCRS)) : JSON.parse(JSON.stringify(this.props.referenceSystems[0]));
    crs.properties[name] = event.target.value;
    this.props.updateTargetCRS(crs);
  }

  onCRSChangeAll = (value) => {
    let g = this.props.file;
    g.crs = JSON.parse(JSON.stringify(this.props.referenceSystems[value]));
    this.props.updateGeometryFile(g, this.props.id);
  }

  onTargetCRSChangeAll = (value) => {
    let crs = JSON.parse(JSON.stringify(this.props.referenceSystems[value]));
    this.props.updateTargetCRS(crs);
  }


  handleDoWhat = (was) => {
    this.setState({ doWhat: was});
  }
  toggleDelete() {
    this.setState({ openDelete: !this.state.openDelete });
  }

  startTransformation = () => {
    let req = {"geojson": this.props.file,
      "source_crs": this.props.file.crs.properties,
      "target_crs": this.props.targetCRS.properties
    }
    axios.post(constants.TRANSFORMATOR_API_URL_ADV, req, this.props.config).then(res => {
      let x = res.data.result.points;
      x.name = this.state.fullFileName;
      this.props.setFileName(prepareFileName(this.state.fullFileName));
      this.props.addGeometryFile(x);
      this.setState({
        openSolution: true,
        showSolution: true,
        openSource: false,
        openTarget: false,
        openPoints: false,
        solutionComputed: true,
        solution: x,
        doWhat: 'sitemap'
      })
    }).catch(
      error => { this.setState({ errorMessage: error.response.data })
    }).then(    
      this.props.afterTransfrom
    );
  }

  handleClickOffset(offset) {
    this.setState({ offset });
  }

  toggleSaveDialog = (e) => {
    if(!this.state.isSaveDialogOpen){
      this.props.findNextFileNumber();
      setTimeout(() => {
        this.setState({ fullFileName: this.props.fileName+'_'+this.props.fileNameCounter})
      },10);
    }
    this.setState({isSaveDialogOpen: !this.state.isSaveDialogOpen});
  }

  changeFileName = (event) => {
    this.setState({fullFileName: event.target.value});
  }

  render() {
    let g = false;
    if (this.props.id[0] > -1) {
      g = this.props.file;
    }
    const {t}=this.props

    return (<div>{ g &&  <div>
            <div style={{marginBottom: '10px'}}>
              <Tooltip disableFocusListener title={t("point_manager_title_geo")}>
                <IconButton style={{marginRight: 10, }}  color={this.state.doWhat === 'sitemap' ? 'primary' : 'default'}  onClick={() => this.handleDoWhat("sitemap")} >
                  <FontAwesomeIcon icon="sitemap"/>
                </IconButton>
              </Tooltip>

              <Tooltip disableFocusListener title={t("point_manager_title_coord")}>
              <IconButton style={{marginRight: 10 }} color={this.state.doWhat === 'sourceCRS' ? 'primary' : 'default'}  onClick={() => this.handleDoWhat("sourceCRS")} >
                <FontAwesomeIcon icon="globe"/>
              </IconButton>
              </Tooltip>
              <Tooltip disableFocusListener title={t("point_manager_title_transform")}>
                <IconButton style={{marginRight: 10 }} disabled={!(Object.keys(g.crs).length > 0)} color={this.state.doWhat === 'transformTo' ? 'primary' : 'default'}   onClick={() => this.handleDoWhat("transformTo")} >
                  <FontAwesomeIcon icon="sign-out-alt"/>
                </IconButton>
              </Tooltip>  
              <Tooltip disableFocusListener title={t("point_manager_title_export")}>
                <IconButton style={{marginRight: 10 }} color={this.state.doWhat === 'export' ? 'primary' : 'default'}   onClick={() => this.handleDoWhat("export")} >
                  <FontAwesomeIcon icon="file-export" />
                </IconButton>
              </Tooltip>  
              <Tooltip disableFocusListener title={t("point_manager_title_delete")}>
                <IconButton style={{marginRight: 10 }}  color="default"  key={this.props.id} onClick={this.toggleDelete} value={this.props.id} >
                  <DeleteIcon />
                </IconButton>
              </Tooltip>
              <BevDialog2 title={t("point_manager_warning")} submit={(e) => this.deleteGeometryList(e, parseInt(this.props.fileId, 10))} dismiss={this.toggleDelete} open={this.state.openDelete}>
                <p>{t('point_manager_warning2', {name: g.name})}</p>
              </BevDialog2>

              <Divider style={{marginBottom: '30px'}}/>

           </div>
           {this.state.doWhat === 'export' && <ExportFiles file={this.props.file}/> }

           { this.state.doWhat === 'sourceCRS' && <div>
             <h4>{t('point_manager_coord_from', {name: g.name})}</h4>
             <CRS key="1"
               showSaveCRS={true}
               crs={g.crs.properties}
               handleChangeEpoch={event => this.onCRSChange(event, "epoch")}
               handleChangeFrame={event => this.onCRSChange(event, "frame")}
               handleChangeEllps={event => this.onCRSChange(event, "ellipsoid")}
               handleChangeMeridian={event => this.onCRSChange(event, "prime_meridian")}
               handleChangeProjection={event => this.onCRSChange(event, "projection")}
               handleChangeCRS={event => this.onCRSChangeAll(event)}/>
           </div>  }

           {this.state.doWhat === 'transformTo' && <div>
              <h4>{t('point_manager_coord_to')}</h4>
              <CRS key="2"
                showSaveCRS={true}
                crs={this.props.targetCRS.properties}
                handleChangeEpoch={event => this.onTargetCRSChange(event, "epoch")}
                handleChangeFrame={event => this.onTargetCRSChange(event, "frame")}
                handleChangeEllps={event => this.onTargetCRSChange(event, "ellipsoid")}
                handleChangeMeridian={event => this.onTargetCRSChange(event, "prime_meridian")}
                handleChangeProjection={event => this.onTargetCRSChange(event, "projection")}
                handleChangeCRS={event => this.onTargetCRSChangeAll(event)}/>
              <BevSaveDialog dialogTitle={"File Name wählen"}
                              isDialogOpen={this.state.isSaveDialogOpen}
                              toggleDialog={this.toggleSaveDialog}
                              handleChange={this.changeFileName}
                              handleSave={this.startTransformation}
                              value={this.state.fullFileName}
              />
              <Button style={{marginRight: 10 }} color="primary" variant="contained" onClick={this.toggleSaveDialog}>{t('point_manager_coord')}</Button>
           </div> }

           { this.state.doWhat === 'sitemap' && <div>
              <BevStandardSiteMap hideSave={true} hideExport={true} pageSize={this.props.pageSize} fileId={this.props.fileId} correctCadastarPoints={checkGeoJsonCadastre(g)} geometry={g} updateGeometryFile={this.props.updateGeometryFile}/>
          </div> }

      </div>
    }</div>)
  }

}

PointManager2.defaultProps = {
  pageSize: constants.DEFAULT_PAGE_SIZE
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(PointManager2));