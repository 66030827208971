import React from 'react';
import PropTypes from 'prop-types';
import { Radio, RadioGroup, FormControl, FormControlLabel, FormLabel } from '@mui/material';
import { useTranslation } from 'react-i18next';

ImportTypeSelect.propTypes = {
  formats: PropTypes.array.isRequired,
  inputformat: PropTypes.string.isRequired,
  handleChange: PropTypes.func.isRequired
}

function ImportTypeSelect({ formats, inputformat, handleChange }) {
  const { t } = useTranslation();
  return <FormControl component="fieldset">
    <FormLabel >{t('point_import_type_selection_title')}</FormLabel>
    <RadioGroup style={{ display: "inline" }} aria-label="format" name="inputformat" value={inputformat} onChange={handleChange}>
      {formats.indexOf("json") > -1 && <FormControlLabel value="json" control={<Radio color={"primary"} />} label={t('point_import_type_selection_geojson')} />}
      {formats.indexOf("gpx") > -1 && <FormControlLabel value="gpx" control={<Radio color={"primary"} />} label={t('point_import_type_selection_gpx')} />}
      {formats.indexOf("kml") > -1 && <FormControlLabel value="kml" control={<Radio color={"primary"} />} label={t('point_import_type_selection_kml')} />}
      {formats.indexOf("csv") > -1 && <FormControlLabel value="csv" control={<Radio color={"primary"} />} label={t('point_import_type_selection_csv')} />}
      {formats.indexOf("csvega") > -1 && <FormControlLabel value="csvega" control={<Radio color={"primary"} />} label={t('point_import_type_selection_csvega')} />}
      {formats.indexOf("crd") > -1 && <FormControlLabel value="crd" control={<Radio color={"primary"} />} label={t('point_import_type_selection_crd')} />}
    </RadioGroup>
  </FormControl>
}

export default ImportTypeSelect;