import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';

import * as actions from 'store/actions';
import SinglePoint from 'components/SinglePoint';
import ImportFiles from 'components/points/import/import';
import GeometryTable from 'components/point-source/GeometryTable';
import prepareFileName from 'common/prepareFileName';

import { withTranslation } from 'react-i18next';

function mapStateToProps(state) {
  return {
    geometryList: state.points.geometryList,
    fileNameCounter: state.points.fileNameCounter,
    fileName: state.points.fileName,
    sourcePoints: state.points.sourcePoints,
  }
}

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    startProgress: () => dispatch(actions.base.setProgress(true)),
    stopProgress: () => dispatch(actions.base.setProgress(false)),
    findNextFileNumber: () => dispatch(actions.points.findNextFileNumber()),
    setFileName: (fileName) => dispatch(actions.points.setFileName(fileName)),
    updateProp: (type, value) => dispatch(actions.points.updateProp(type, value)),
  }
}

class PointSource extends Component {
  static propTypes = {
    tabindex: PropTypes.number,
    onStartTransformation: PropTypes.func.isRequired,
    handleChangeTabIndex: PropTypes.func,
    errorMessage: PropTypes.oneOfType([
      PropTypes.bool,
      PropTypes.string,
    ]),
    velocities: PropTypes.bool,
    coordType: PropTypes.string.isRequired,
    formats: PropTypes.array,
    inputformat: PropTypes.string,
    is2d: PropTypes.bool,
    useCompare: PropTypes.bool,
    loadedGeoJson: PropTypes.object,
    sourceCrs: PropTypes.object,
  }
  static defaultProps = {
    velocities: false,
    errorMessage: false,
    tabindex: 0,
    is2d: false,
    useCompare: true,
  }
  state = {
    tabindex: 1,
    fullFileName: '',
    missingInputError: false,
    isSaveDialogOpen: false,
    selectedGeometry: null,
  }

  componentDidMount() {
    if (!this.props.sourcePoints) this.updateProp('sourcePoints', {
      singlePoint: {
        x: "",
        y: "",
        z: "",
        vx: "",
        vy: "",
        vz: "",
      }, pointList: {}
    });
    if (this.props.hasOwnProperty('tabindex')) {
      this.setState({ tabindex: this.props.tabindex });
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.loadedGeoJson !== prevProps.loadedGeoJson) {
      this.setState({ pointList: this.props.loadedGeoJson, tabindex: 1 });
    }
  }

  changeFileName = (event) => {
    this.setState({ fullFileName: event.target.value });
  }

  startTransformation = () => {
    const { singlePoint, pointList } = this.props.sourcePoints
    let f = {};
    this.setState({ missingInputError: false })
    if (this.state.tabindex === 0) {
      const sp = singlePoint
      const { is2d } = this.props;
      f.type = 'FeatureCollection'
      f.name = 'SinglePoint'
      f.crs = {}
      f.filetype = 'csv'
      if (is2d ? !sp || !sp.x || !sp.y : !sp || !sp.x || !sp.y || !sp.z) {
        this.setState({ missingInputError: 'Es fehlen angaben' });
        return;
      }
      f.features = [{ type: 'Feature', properties: { name: 'SP1' }, geometry: { type: 'Point', coordinates: [sp.x, sp.y, sp.z] } }]
      if (!!sp.vx || !!sp.vy || !!sp.vz) {
        f.features[0].geometry.velocities = [sp.vx, sp.vy, sp.vz];
      }
    } else if (this.state.tabindex === 1) {
      f = pointList;
    } else {
      f = this.state.selectedGeometry
    }
    this.setState({ isSaveDialogOpen: false });
    this.props.setFileName(prepareFileName(this.state.fullFileName));
    this.props.findNextFileNumber();
    this.props.onStartTransformation(f, this.state.fullFileName);
  }

  handleClickGeometry = (i, e) => {
    this.setState({ selectedGeometry: null }, () => {
      this.setState({ selectedGeometry: this.props.geometryList[i] }, () => {
        this.props.setFileName(prepareFileName(this.props.geometryList[i].name));
        this.startTransformation();
      });
    });
  }

  handleChangeTabIndex = (e, i) => {
    if (!!this.props.handleChangeTabIndex) this.props.handleChangeTabIndex(i);
    this.setState({ tabindex: i });
  }

  setSinglePoint = (singlePoint) => { this.props.updateProp('sourcePoints', { pointList: this.props.sourcePoints.pointList, singlePoint }); }
  setPointList = (pointList) => { this.props.updateProp('sourcePoints', { singlePoint: this.props.sourcePoints.singlePoint, pointList }) }
  render() {
    const { errorMessage, coordType, velocities, formats, inputformat, fileName, geometryList, sourceCRS, is2d, useCompare } = this.props;
    const { missingInputError, tabindex } = this.state;
    const { sourcePoints, sourceCrs } = this.props;
    const { t } = this.props
    return (
      <div>
        {errorMessage && <h4>{JSON.stringify(errorMessage)}</h4>}
        {missingInputError && <h2>{missingInputError}</h2>}
        <Tabs
          sx={{
            borderBottomColor: "primary.light",
            borderBottomWidth: 1,
            borderBottomStyle: "solid",
          }}
          indicatorColor="primary"
          textColor="primary"
          value={tabindex}
          onChange={this.handleChangeTabIndex}
        >
          <Tab label={t("point_source_single")} />
          <Tab label={t("point_source_list")} />
          <Tab label={t("point_source_saved")} />
        </Tabs>
        {tabindex === 0 &&
          <SinglePoint
            singlePoint={sourcePoints.singlePoint}
            type={coordType}
            velocities={velocities}
            onChangePoint={this.setSinglePoint}
            is2d={is2d}
            startTransformation={this.startTransformation}
          />
        }
        {tabindex === 1 &&
          <ImportFiles
            pointList={sourcePoints.pointList}
            formats={formats}
            inputformat={inputformat}
            sourceCrs={sourceCrs}
            directBack={true}
            loadingDone={this.setPointList}
            what="string"
            fileName={fileName}
            is2d={is2d}
            loadedExample={this.props.loadedGeoJson}
            startTransformation={this.startTransformation} />

        }
        {tabindex === 2 &&
          <GeometryTable
            geometryList={geometryList}
            sourceCRS={sourceCRS}
            clickTooltip={t("point_source_tip")}
            handleClickGeometry={this.handleClickGeometry}
            showTransformButton={true}
            showDataModal={true}
            useCompare={useCompare} />
        }
      </div>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(PointSource));