import React from 'react';
import PropTypes from 'prop-types';

import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';

import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import { useTranslation } from 'react-i18next';

BevSaveDialog.propTypes = {
  dialogTitle: PropTypes.string.isRequired,
  isDialogOpen: PropTypes.bool.isRequired,
  toggleDialog: PropTypes.func.isRequired,
  handleChange: PropTypes.func.isRequired,
  handleSave: PropTypes.func.isRequired,
  value: PropTypes.string
}

export default function BevSaveDialog ({dialogTitle, isDialogOpen, toggleDialog, handleChange, handleSave, value}){
  const {t}= useTranslation();
  return(
  <Dialog open={isDialogOpen} onClose={toggleDialog} aria-labelledby="form-dialog-title">
    <DialogTitle id="form-dialog-title">{dialogTitle}</DialogTitle>
    <DialogContent>
      <TextField autoFocus value={value} onChange={handleChange} margin="dense" id="name" label="Name" type="text" fullWidth />
    </DialogContent>
    <DialogActions>
      <Button variant='outlined' onClick={toggleDialog} color="primary">{t('save_dialog_cancle')}</Button>
      <Button variant='outlined' onClick={handleSave} color="primary">{t('save_dialog_save')}</Button>
    </DialogActions>
  </Dialog>
  );
}