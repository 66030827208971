import TileLayer from "ol/layer/Tile";
import MapboxVectorLayer from 'ol/layer/MapboxVector.js';
import { TileWMS } from 'ol/source';
import { createXYZ } from 'ol/tilegrid'; 
import { register } from 'ol/proj/proj4';
import proj4 from 'proj4'
import { KATASTER_SERVICE_URL } from "common/constants";

proj4.defs(
    'EPSG:31287',
    '+proj=lcc +lat_1=49 +lat_2=46 +lat_0=47.5 +lon_0=13.33333333333333 +x_0=400000 +y_0=400000 ' +
    '+ellps=bessel +towgs84=577.326,90.129,463.919,5.137,1.474,5.297,2.4232 +units=m +axis=neu +no_defs'
);
register(proj4);

export const ortho = new TileLayer({
    source: new TileWMS({
        minZoom: 8,
        url: `${KATASTER_SERVICE_URL}ortho/ows`,
        projection: 'EPSG:31287',
        crossOrigin: 'anonymous',
        params: {
            'LAYERS': 'inspire:AT_BEV_OI',
            'FORMAT': 'image/jpeg',
        },
        //serverType: 'geoserver',
        tileGrid: createXYZ({ tileSize: [512, 512] }),
        visible: false,
        title: 'BEV Ortho',
    })
})

export const katasterLayer = katStyle => new MapboxVectorLayer({
    properties: { title: 'kataster' },
    styleUrl: createUrl('kataster', katStyle),
    visible: true,
    declutter: true,
    zIndex: 10
});

export const symboleLayer = (katStyle) => new MapboxVectorLayer({
    properties: {
        title: 'symbole'
    },
    styleUrl: createUrl('symbole', katStyle),
    visible: true,
    declutter: false,
    zIndex: 12
});

export const createUrl = (url, file) => {
    if(file === "") return  `${KATASTER_SERVICE_URL}styles/${url}/style.json`
    return `${KATASTER_SERVICE_URL}styles/${url}/style_${file}.json`
  }  