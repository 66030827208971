import React from 'react';
import PropTypes from 'prop-types';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import { useTranslation } from 'react-i18next';
/**
 * setDateTo, a function, to set the date
 */
GridExamples.propTypes = {
    setDateTo: PropTypes.func.isRequired, 
}

export default function GridExamples ({setDateTo}){
    const {t} = useTranslation();
    return <Grid item xs={12} sm={6} md={4} container spacing={3} justifyContent="center" alignItems="flex-end">
        <Grid item xs={10} sm={10}><Button variant="outlined" color="primary" onClick={() => setDateTo('yr', '', true)}>{t("bev_time_gird_examples_heute")}</Button></Grid>
        <Grid item xs={10} sm={10}><Button variant="outlined" color="primary" onClick={() => setDateTo('yr', '2002.56')}>{t("bev_time_gird_examples_epoche1")}</Button></Grid>
        <Grid item xs={10} sm={10}><Button variant="outlined" color="primary" onClick={() => setDateTo('yr', '2000.00')}>{t("bev_time_gird_examples_epoche2")}</Button> </Grid>
    </Grid>
}