export const helmert = {
    "helmert_title_card_title" : "Transformation with Helmert parameters",
    "helmert_title_card_info" : "Application of 2D or 3D Helmert Transformation with your own transformation parameters.",
    "helmert_title_card_2d" : "Example 2D",
    "helmert_title_card_3d" : "Example 3D",
    "helmert_title_card_info2d" : "More info 2D",
    "helmert_title_card_info3d" : "More info 3D",
    "helmert_title_card_restart" : "Reset",

    "helmert_helmert_parameter" : "Input parameters",
    "helmert_helmert_punkte" : "Points to transform",

    "helmert_trafo_load" : "Select transformation",
    "helmert_trafo_load_cancel" : "Cancel",

    "helmert_trafo_save_save" : "Save transformation parameters",
    "helmert_trafo_save_parameter" : "Helmert Parameter",
    "helmert_trafo_save_name" : "Name of transformation",

    "helmert_input_two_scale" : "Scale",
    
    "helmert_input_three_scale" : "Scale [°]",


}