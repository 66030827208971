import * as actionTypes from './actionTypes';


// sagas
export const addGeometryFile = (file) => ({ type: actionTypes.POINTS_ADD_GEOMETRY_FILE, file})
export const removeGeometryFile = (fileId) => ({ type: actionTypes.POINTS_REMOVE_GEOMETRY_FILE, fileId})

// reducer
export const addFile = (file) => ({ type: actionTypes.POINTS_ADD_FILE, file })
export const updateGeometryFile = (file, id) => ({ type: actionTypes.POINTS_UPDATE_GEOMETRY_FILE,file, id })
export const updateTargetCRS = (crs) => ({ type: actionTypes.POINTS_UPDATE_TARGET_CRS, crs, })
export const removeFile = (id) => ({ type: actionTypes.POINTS_REMOVE_GEOMETRY_LIST, id })
export const setFileId = (id) => ({ type: actionTypes.POINTS_SET_FILE_ID, id })

export const setFileName = (fileName) => ({ type: actionTypes.POINTS_SET_FILE_NAME, fileName })
export const findNextFileNumber = () => ({ type: actionTypes.POINTS_FINDE_NEXT_FILE_NUMBER }) 
export const updateProp = (prop, value) => ({type: actionTypes.POINTS_UPDATE_PROP, prop, value})
export const cleanInput = () => ({type: actionTypes.POINTS_CLEAN_INPUT})
