import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";

import Grid from "@mui/material/Grid";

import * as example from "common/examples";
import errorHandler from "common/errorHandler";
import parseFile from "common/parsers/fileParser";

import { ImportTypeSelector } from "./ImportTypeSelector";
import { ImportFileLoadButton } from "./ImportFileLoadButton";
import { TextField, Alert } from "@mui/material";
import i18next from "common/i18n";
import { useTranslation } from "react-i18next";
import { debounce } from "common/debounce";

const isValidCRDLoad = (files) => {
  if (files.length > 2) throw new Error(i18next.t("point_import_valid_error_length"));
  const file1 = files[0];

  if (!(file1.name.match(".crd") || file1.name.match(".CRD")))
    throw new Error(i18next.t("point_import_valid_error_crd"));
  if (files.length === 2) {
    const file2 = files[1];
    if (file1.name.split(".")[0] !== file2.name.split(".")[0])
      throw new Error(i18next.t("point_import_valid_error_name"));
    if (!(file2.name.match(".vel") || file2.name.match(".VEL")))
      throw new Error(i18next.t("point_import_valid_error_vel"));
  }
  return true;
};

const fileTypes = {
  json: [".json", ".geojson"],
  kml: [".kml", ".KML"],
  gpx: [".gpx", ".GPX"],
  csv: [".csv", ".CSV"],
  csvega: [".csv", ".CSV"],
  crd: [".crd", ".CRD", ".vel", ".VEL"],
};

const examples = {
  json: example.JSON,
  kml: example.KML,
  gpx: example.GPX,
  csv: example.CSV,
  csvega: example.CSVEGA,
  crd: example.CRD,
};

const ImportFiles = ({ loadingDone, sourceCrs, warning }) => {
  const { t } = useTranslation();
  const [inputFormat, setInputFormat] = useState("json");
  const [string, setString] = useState("");
  const [internalWarning, setInternalWarning] = useState(null);
  const formats = ["json", "gpx", "kml", "csv", "csvega", "crd"];
  const example = examples[inputFormat];

  useEffect(() => {
    if (warning) {
      setInternalWarning(warning);
    }
  }, [warning]);

  useEffect(() => {
    if (string !== "") {
      handleParseFile(string);
    }
  }, [string, inputFormat]);

  const handleParseFile = debounce(async (data) => {
    setInternalWarning(null);
    if (!!loadingDone) loadingDone(null);
    try {
      let x = parseFile(inputFormat, data);
      if (!!sourceCrs) x.crs = sourceCrs;
      if (!!loadingDone) loadingDone(x);
    } catch (error) {
        console.error(error)
        setInternalWarning(error.message)
        if (!!loadingDone) loadingDone(null);
    }
  }, 500);

  const importFiles = (event) => {
    setString("");

    const files = event.target.files;
    try {
      if (inputFormat === "crd" && isValidCRDLoad(files)) {
        this.readFile(files[0]);
        if (files.length > 1) setTimeout(readFile(files[1]), 10);
      } else {
        for (let file of files) {
          readFile(file);
        }
      }
    } catch (error) {
      errorHandler(error);
    }
  };

  const readFile = (file) => {
    const f = new FileReader();
    f.onloadend = (status) => {
      setString((s) => s + f.result);
    };
    f.readAsText(file);
  };

  const handleChangeType = (e) => {
    setInputFormat(e.target.value);
  };

  const handleChangeString = (event) => {
    setString(event.target.value);
  };

  const loadExample = () => {
    setString(examples[inputFormat]);
  };

  return (
    <div>
      <div>
        <Grid container spacing={4}>
          <Grid item sm={12} md={12}>
            <ImportTypeSelector
              formats={formats}
              inputformat={inputFormat}
              handleChange={handleChangeType}
            />
            <ImportFileLoadButton
              importFiles={importFiles}
              inputformat={inputFormat}
              clearInput={() => setString("")}
              fileTypes={fileTypes[inputFormat]}
              loadExample={loadExample}
            />
            <TextField
              id="pointlist1"
              label="Eingabe"
              multiline
              minRows={12}
              placeholder={'Beispiel: \n' +example}
              variant="outlined"
              value={string}
              onChange={handleChangeString}
              fullWidth
              autoFocus
              InputLabelProps={{
                shrink: true,
              }}
            />
            {internalWarning && (
              <Alert severity="warning" style={{ marginTop: "10px" }}>
                {internalWarning}
              </Alert>
            )}
          </Grid>
        </Grid>
      </div>
    </div>
  );
};

ImportFiles.propTypes = {
  loadingDone: PropTypes.func.isRequired,
  sourceCrs: PropTypes.object,
  warning: PropTypes.string,
};

export default ImportFiles;
