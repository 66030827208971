import React, { Component } from 'react';
import { withRouter, BrowserRouter } from "react-router-dom";
import { connect } from 'react-redux';

import CookieInfo from './common/cookieInfo';
import * as actions from './store/actions';
import * as constants from './common/constants.js';

import 'react-app-polyfill/ie9';
import 'react-app-polyfill/stable';

import { createTheme as createMuiTheme, ThemeProvider as MuiThemeProvider } from '@mui/material/styles';
import FontEot from 'fonts/SourceSansPro-Regular.eot';
import FontWoff2 from 'fonts/SourceSansPro-Regular.otf.woff2';
import FontWoff from 'fonts/SourceSansPro-Regular.otf.woff';
import FontTtf from 'fonts/SourceSansPro-Regular.ttf';


import 'react-toastify/dist/ReactToastify.css';
import './App.css';
import * as Sentry from '@sentry/browser';

import { library } from '@fortawesome/fontawesome-svg-core';
import {
  faTractor,
  faStroopwafel, faFileExport, faGlobe, faSave, faFolderOpen, faSlidersH, faMap, faMapPin, faList, faQuestion,
  faDatabase, faFileImport, faSitemap, faFile, faShoppingBag, faCode, faUser, faSignOutAlt, faSpinner, faExclamationTriangle,
  faLongArrowAltRight, faArrowsAltH, faEye, faSort, faSortUp, faSortDown, faKey, faInfo, faPlus, faFileAlt, faCalendarPlus,
  faToolbox, faHome, faRedo, faExternalLinkAlt, faCalendarAlt, faClipboard, faFileDownload, faBars, faAtlas, faPlug, faTimes,
  faAddressBook,
} from '@fortawesome/free-solid-svg-icons';
import AppHeader from './components/AppHeader';
import AppMainContent from './components/AppMainContent';
import { CssBaseline } from '@mui/material';

library.add(faTractor, faStroopwafel, faFileExport, faGlobe, faSave, faFolderOpen, faSlidersH, faMap, faMapPin, faList, faQuestion,
  faDatabase, faFileImport, faSitemap, faFile, faShoppingBag, faCode, faUser, faSignOutAlt, faSpinner, faExclamationTriangle,
  faLongArrowAltRight, faArrowsAltH, faEye, faSort, faSortUp, faSortDown, faKey, faInfo, faPlus, faFileAlt, faCalendarPlus,
  faToolbox, faHome, faRedo, faExternalLinkAlt, faCalendarAlt, faClipboard, faFileDownload, faBars, faAtlas, faPlug, faTimes,
  faAddressBook
);


const fontSourceSanPro = `
@font-face {
  font-family: 'Source San Pro';
  font-style: normal;
  font-weight: 400;
  src: url('${FontEot}');
  /* IE9 Compat Modes */
  src: local('Source San Pro'), local('Source San Pro'),
    url('${FontWoff2}') format('woff2'),
    url('${FontWoff}') format('woff'),
    url('${FontTtf}') format('truetype')
}
`

const themeMui = createMuiTheme({
  palette: {
    primary: {
      main: 'rgba(230, 50, 15, 1)',
      contrastText: '#fff',
    },
    neutral: {
      main: 'rgba(0, 0, 0, 0.64)',
      contrastText: '#fff',
    },
  },
  typography: {
    fontFamily: 'Source San Pro, sans-serif',
  },
  overrides: {
    typography: {
      fontFamily: [
        'Source San Pro',
        'sans-serif',
      ].join(','),
    }
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: fontSourceSanPro,
    },
  },
});

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    onInit: () => dispatch(actions.api.init())
  }
}

const sendERROR = () => {
  console.error('send sentry "Hello World" Error');
  Sentry.captureException(new Error('HELLO WORLD'));
}

class StartSeite extends Component {

  componentDidMount() {
    this.props.onInit();
  }

  render() {
    return <MuiThemeProvider theme={themeMui} >
      <CssBaseline />
      <BrowserRouter basename={constants.BASE_URL} />
      <AppHeader path={this.props.location.pathname} />
      <AppMainContent />
      <div style={{ position: 'fixed', bottom: '10px', right: '10px', fontSize: '0.5em' }} onDoubleClick={sendERROR}>{constants.VERSION_NAME}</div>
      <CookieInfo />
    </MuiThemeProvider>
  }
}

export default withRouter(connect(null, mapDispatchToProps)(StartSeite));
