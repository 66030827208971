import { toast } from 'react-toastify';

const errorHandler = error => { 
        let errorText = "";
        if(!!error){
                errorText = error.response ? error.response.data ? JSON.stringify(error.response.data ): JSON.stringify(error.response) : JSON.stringify(error);
                let { response, message} = error;
                if(!!response){
                        errorText = !!response.data ? JSON.stringify(response.data) : JSON.stringify(response);
                }else if(!!message) {
                        errorText = message;
                }else{
                        errorText = JSON.stringify(error);
                }
        }else{
                errorText = 'system got an unknown error' 
        }
        
        
        if(errorText.length > 200) errorText = errorText.substring(0,200);
        toast.error('Bei Ihrer Anfrage ist ein Fehler aufgetreten \n' + errorText,{autoClose: 10000})
        console.error(error);
}

export default errorHandler;