import React, { useState, useRef } from "react";
import { Tabs, Tab, Typography, Tooltip, Grid, Avatar, LinearProgress, Box, useTheme } from "@mui/material";

import { useDropzone } from "react-dropzone";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFile, faSearch, faMap, faMouse } from "@fortawesome/free-solid-svg-icons";
import { GpsFixed } from "@mui/icons-material";
import { inject, observer } from "mobx-react";
import OpenLayerMap from "./Map";
import { TabPanel } from "components/TabPanel";
import { FileInput } from "./FileInput";
import { useTranslation, Trans } from "react-i18next";
import { fromLonLat } from "ol/proj";
import { toast } from "react-toastify";
import { Search as SearchInputComponent } from '@bev-components/search-adress-component-react'


const watchPositionOptions = {
  enableHighAccuracy: true,
  timeout: 5000,
  maximumAge: 0,
};

export const PointInput = inject("store")(
  observer(({ store }) => {
    const { t } = useTranslation();
    const [tabType, setTabType] = useState("search");
    const [loading, setLoading] = useState(false);
    const { getRootProps, isDragActive } = useDropzone();
    const posid = useRef(null);
    const theme = useTheme();

    const stopWatching = () => {
      setLoading(false);
      navigator.geolocation.clearWatch(posid.current);
    };

    const startWatching = () => {
      if (loading) return toast.info(t("cadastre:input_position_is_loading"))
      setLoading(true);
      posid.current = navigator.geolocation.watchPosition(
        (position) => {
          const { coords } = position;
          const coord = fromLonLat([coords.longitude, coords.latitude]);
          store.kataster.selectPointsAroundCoord(coord);
          stopWatching();
        },
        (e) => console.error(e),
        watchPositionOptions
      );
    };

    const zoomData = (data) => {
      const { coordinates, type } = data.geometry;
      const { selectPointsAroundCoord } = store.kataster;

      if (type.toLowerCase() === "point") return selectPointsAroundCoord(fromLonLat([coordinates[0], coordinates[1]]));

      const coordsFirst = coordinates[0].map(c => c[0]).sort((a, b) => a - b);
      const coordsSecound = coordinates[0].map(c => c[1]).sort((a, b) => a - b);

      const lon = (coordsFirst.shift() + coordsFirst.pop()) / 2;
      const lat = (coordsSecound.shift() + coordsSecound.pop()) / 2;

      return selectPointsAroundCoord(fromLonLat([lon, lat]));

    }

    return (
      <Box {...getRootProps({ className: "dropzone" })}>
        <Tabs
          sx={{
            borderBottomColor: "primary.light",
            borderBottomWidth: 1,
            borderBottomStyle: "solid",
          }}
          indicatorColor="primary"
          textColor="primary"
          value={tabType}
          onChange={(e, val) => setTabType(val)}
        >
          <Tab
            value={"file"}
            label={t("cadastre:input_tab_file_upload")}
            icon={<FontAwesomeIcon icon={faFile} />}
          />
          <Tab
            value={"search"}
            label={t("cadastre:input_tab_search")}
            icon={<FontAwesomeIcon icon={faSearch} />}
          />
          <Tab
            value={"map"}
            label={t("cadastre:input_tab_map")}
            icon={<FontAwesomeIcon icon={faMap} />} />
          <Tab
            value={"position"}
            label={t("cadastre:input_tab_position")}
            icon={<GpsFixed />}
          />
        </Tabs>

        <TabPanel value={tabType} index={"file"}>
          <FileInput isDragActive={isDragActive} />
        </TabPanel>
        <TabPanel value={tabType} index={"search"}>
          <Box style={{ position: 'relative', width: '100%' }}>
            <SearchInputComponent
              handleSelectData={zoomData}
              size="big"
              closeable={false}
              fullWidth
              integratedStyle
              muiTheme={theme}
              description={<SearchDescription />}
              includeLayers={['BL', 'PG', 'KG', 'GST', 'GP', 'RN', 'ADR', 'STR', 'FP']}

            />
          </Box>
        </TabPanel>
        <TabPanel value={tabType} index={"map"} p={1}>
          <Typography variant={"h5"}>
            <Trans t={t} i18nKey={"cadastre:input_map_header"}>
              Durch Rechtsklick <FontAwesomeIcon icon={faMouse} /> die 5 nächstgelegenen Passpunkte auswählen.
            </Trans>
          </Typography>
          <Box sx={{
            width: "100%",
            height: 600,
          }}>
            <OpenLayerMap onCenterSelect={store.kataster.selectPointsAroundCoord} />
          </Box>
        </TabPanel>
        <TabPanel value={tabType} index={"position"} p={1}>
          <Typography variant={"h6"} style={{ textAlign: 'center', marginBottom: '2em' }}>{t("cadastre:input_position_header")} </Typography>
          <Box sx={{
            width: "100%",
            height: 600,
          }}>
            <Grid container justifyContent="center" spacing={4}>
              <Grid item xs={11} sx={{
                display: 'flex',
                justifyContent: 'center',
                p: 7,
              }}>
                <Tooltip title={t("cadastre:input_position_button")}>
                  <Avatar sx={{
                    backgroundColor: "primary.main",
                    width: t => t.spacing(7),
                    height: t => t.spacing(7),

                    '&:hover': {
                      backgroundColor: "primary.dark",
                      boxShadow: '0px 0px 5px 0px #000000',
                      cursor: 'pointer',
                    }
                  }}
                    onClick={startWatching}>
                    <GpsFixed style={{ color: '#eee' }} />
                  </Avatar>
                </Tooltip>
              </Grid>
              <Grid item xs={11}>
                {loading && <LinearProgress />}
              </Grid>
            </Grid>
          </Box>
        </TabPanel>
      </Box>
    );
  })
);


const SearchDescription = () => {
  return <table style={{ borderSpacing: "1em" }}>
    <thead style={{ textAlign: "left" }}>
      <tr>
        <th>Suchbegriff</th>
        <th>Beschreibung</th>
      </tr>
    </thead>
    <tbody>
      <tr>
        <td style={{ whiteSpace: "nowrap" }}>
          <code>Stephansplatz 1</code>
        </td>
        <td>Text mit Nummer sucht im <span style={{ color: "rgb(255, 136, 0)" }}> Adressregister</span>.
        </td>
      </tr>
      <tr>
        <td><code>49203-4</code></td>
        <td>Mit der Katastralgemeindenummer und einer Zahl kann nach <span style={{ color: "grey" }}> Grundstücken </span>,
          <span style={{ color: "red" }}> Grenzpunkten </span> und <span style={{ color: "black" }}> Einlagezahlen </span> gesucht werden.
        </td>
      </tr>
      <tr>
        <td>
          <code>49203</code>
        </td>
        <td>Mit einer 5stelligen Zahl kann nach <span style={{ color: "orange" }}> Katastralgemeinden </span> gesucht werden.
        </td>
      </tr>
      <tr>
        <td>
          <code>10-40</code>
        </td>
        <td>Die Eingabe einer zweiteiligen Nummer geteilt durch Bindestrich wird als <span style={{ color: "royalblue" }}> Festpunktnummer</span>
          erkannt.</td>
      </tr>
      <tr>
        <td><code>Villach</code>
        </td>
        <td>Ein reiner Text wird für die Suche in <span style={{ color: "purple" }}> Bundesland</span>,
          <span style={{ color: "red" }}> Politischer Gemeinde</span>,
          <span style={{ color: "orange" }}> Katastralgemeindenamen</span>,
          <span style={{ color: "rgb(255, 136, 0)" }}> Straßen</span>,
          <span style={{ color: "royalblue" }}> Festpunktname</span> sowie in den
          <span style={{ color: "darkgreen" }}> Riednamen</span> verwendet.</td></tr></tbody></table>
}