import React from 'react';
import PropTypes from 'prop-types';

import Button from '@mui/material/Button';
import {useTranslation} from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash } from '@fortawesome/free-solid-svg-icons';

ImportFileLoadButton.propTypes = {
    importFiles: PropTypes.func.isRequired,
    inputformat: PropTypes.string.isRequired,
    fileTypes: PropTypes.array.isRequired
  }

  export function ImportFileLoadButton({importFiles, inputformat, fileTypes, loadExample, clearInput}){
    const {t} = useTranslation();
    return <div style={{marginTop: '1em', marginBottom: '1em'}}>
      <input onChange={importFiles} 
              accept={fileTypes} 
              style={{ display: 'none' }} 
              id="raised-button-file" 
              multiple 
              type="file" />
      <label htmlFor="raised-button-file">
        <Button variant="contained" color="primary" component="span" >{t('point_import_file_load_btn',{inputformat})}</Button>
      </label>
      <Button variant="outlined" color="primary" style={{marginLeft: '0.5em'}} onClick={loadExample}>Beispiel laden</Button>
      <Button variant="outlined" color="primary" style={{marginLeft: '0.5em'}} onClick={clearInput}><FontAwesomeIcon icon={faTrash} style={{marginRight: '0.5em'}} /> Eingabe Löschen</Button>
    </div>
  }