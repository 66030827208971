import React, { Component } from "react";
import { connect } from "react-redux";
import axios from "axios";

import * as constants from "common/constants.js";
import errorHandler from "common/errorHandler";
import * as actions from "store/actions";

import ExtendedTitleCard from "components/ExtendedTitleCard";
import LoadSaveTrafo from "./LoadSaveTrafo";
import CrsPicker from "./CrsPicker";
import CoordInput from "./CoordInput";
import BevSolutionCard from "../../components/BevSolutionCard";
import {WIKI_LINK_ADVANCED} from "common/constants.js";
import { withTranslation } from "react-i18next";

const DEFAULT_CRS = {
  name: "default_crs",
  properties: {
    ellipsoid: "",
    epoch: 2002.56,
    frame: "",
    height_system: "",
    prime_meridian: "",
    projection: "",
    propTypes: {},
  },
  type: "BEV",
};

const CLEAR_STATE = {
  targetPointList: null,
  openInput: true,
  openPoints: true,
  openSolution: true,

  showSource: true,
  showTarget: false,
  showPoints: false,
  showSolution: false,

  solutionComputed: false,
  solution: null,
};

function mapStateToProps(state) {
  return {
    sourceCRS: state.advanced.sourceCRS,
    targetCRS: state.advanced.targetCRS,

    referenceSystems: state.base.referenceSystems,
    trafos: state.base.trafos,

    geometryList: state.points.geometryList,
    config: state.auth.config,
  };
}

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    updateTargetCRS: (obj) => dispatch(actions.advanced.setTargetCRS(obj)),
    updateSourceCRS: (obj) => dispatch(actions.advanced.setSourceCRS(obj)),
    addTrafo: (event) => dispatch(actions.base.addTrafo(event)),
    updateProp: (type, value) =>
      dispatch(actions.points.updateProp(type, value)),
    cleanInput: () => dispatch(actions.points.cleanInput()),
  };
};

class Advanced extends Component {
  state = CLEAR_STATE;

  componentDidMount() {
    this.updateVisibilities(this.props.sourceCRS, "source");
  }

  updateVisibilities = (crs, target) => {
    if (crs.properties.frame === "" && target !== "target") {
      this.setState({
        showTarget: false,
        showPoints: false,
        showSolution: false,
      });
    } else if (crs.properties.frame === "" && target === "target") {
      this.setState({ showPoints: false, showSolution: false });
    } else if (crs.properties.frame !== "" && target !== "target") {
      this.setState({ showTarget: true });
      if (this.props.targetCRS.properties.frame !== "") {
        this.setState({ showPoints: true });
      }
    } else if (crs.properties.frame !== "" && target === "target") {
      this.setState({ showPoints: true });
    }
  };

  componentWillUnmount() {
    this.props.updateProp("sourcePoints", {
      pointList: {},
      singlePoint: { x: "", y: "", z: "", vx: "", vy: "", vz: "" },
    });
    this.props.cleanInput();
  }

  startTransformation = (f, name) => {
    // First remove solution card
    this.setState({ showSolution: false });

    // Build new request body
    let req = {
      geojson: f,
      source_crs: this.props.sourceCRS.properties,
      target_crs: this.props.targetCRS.properties,
    };

    // Make API call with request body
    // TODO best practice add to saga
    axios
      .post(constants.TRANSFORMATOR_API_URL_ADV, req, this.props.config)
      .then((res) => {
        //fetch Data and save
        let x = res.data.result.points;
        x.name = name;
        // change state to disply Solution
        this.setState({
          openSolution: true,
          openInput: false,
          showSolution: true,
          openPoints: false,
          solutionComputed: true,
          solution: x,
        });
      })
      .catch(errorHandler);
  };

  /* with event, side and attr the new value from the target gets set */
  onHandleChange = (event, side, attr) => {
    console.log(event, side, attr)
    let crs = side === "target" ? this.props.targetCRS : this.props.sourceCRS;
    crs.properties["epoch"] =
      attr === "frame" && !crs.properties["epoch"] && event.target.value
        ? 2002.56
        : crs.properties["epoch"];
    
    crs.properties[attr] = attr === 'epoch' ? event : event.target.value;
    side === "target"
      ? this.props.updateTargetCRS(crs)
      : this.props.updateSourceCRS(crs);
    this.updateVisibilities(crs, side);
  };

  onCRSChangeAll = (value, side) => {
    let crs = JSON.parse(JSON.stringify(this.props.referenceSystems[value]));
    side === "target"
      ? this.props.updateTargetCRS(crs)
      : this.props.updateSourceCRS(crs);
    this.updateVisibilities(crs, side);
  };

  saveTrafo = (name) => {
    const x = {
      name,
      sourceCRS: JSON.parse(JSON.stringify(this.props.sourceCRS)),
      targetCRS: JSON.parse(JSON.stringify(this.props.targetCRS)),
    };
    this.props.addTrafo(x);
  };

  loadTrafo = (i) => {
    const t = JSON.parse(JSON.stringify(this.props.trafos[i]));
    this.props.updateSourceCRS(t.sourceCRS);
    this.props.updateTargetCRS(t.targetCRS);
    this.updateVisibilities(t.sourceCRS, "source");
    this.updateVisibilities(t.sourceCRS, "target");
  };

  toggleOpenPoints = () => {
    this.setState((prevState) => {
      return { openPoints: !prevState.openPoints };
    });
  };

  clearPage = () => {
    this.setState(CLEAR_STATE);
    this.props.updateSourceCRS(JSON.parse(JSON.stringify(DEFAULT_CRS)));
    this.props.updateTargetCRS(JSON.parse(JSON.stringify(DEFAULT_CRS)));
    this.updateVisibilities(DEFAULT_CRS, "source");
    this.props.updateProp("sourcePoints", {
      singlePoint: {
        x: "",
        y: "",
        z: "",
        vx: "",
        vy: "",
        vz: "",
      },
      pointList: {},
    });
  };

  toggleOpenSolution = () =>
    this.setState({ openSolution: !this.state.openSolution });

  render() {
    const { trafos, sourceCRS, targetCRS, t } = this.props;
    const {
      showSource,
      showTarget,
      showPoints,
      showSolution,
      openInput,
      openPoints,
      solution,
      openSolution,
      solutionComputed,
    } = this.state;

    return (
      <div>
        <ExtendedTitleCard
          clearPage={this.clearPage}
          title={t("advanced_title_card_title")}
          description={t("advanced_title_card")}
          wikiLink={WIKI_LINK_ADVANCED}
        />
        <LoadSaveTrafo
          trafos={trafos}
          handleLoadTrafo={this.loadTrafo}
          handleSaveTrafo={this.saveTrafo}
        />
        <CrsPicker
          showSource={showSource}
          showTarget={showTarget}
          sourceCRS={sourceCRS.properties}
          targetCRS={targetCRS.properties}
          isClosed={openInput}
          onHandleChange={this.onHandleChange}
          onCRSChangeAll={this.onCRSChangeAll}
        />
        <CoordInput
          isClosed={openPoints}
          sourceCRS={sourceCRS}
          startTransformation={this.startTransformation}
          showPoints={showPoints}
          toggleOpenPoints={this.toggleOpenPoints}
        />
        <BevSolutionCard
          isDisplayed={showSolution}
          isOpen={openSolution}
          isComputed={solutionComputed}
          solution={solution}
          toggleOpen={this.toggleOpenSolution}
        />
      </div>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(Advanced));
