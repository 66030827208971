import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import "./common/i18n/index.js";
import App from "./App.js";
import { unregister } from "./registerServiceWorker";
import { BrowserRouter } from "react-router-dom";

import { Provider } from "react-redux";
import { Provider as MobxProvider } from "mobx-react";
import transformatorStore from "./store";
import { SENTRY_URL, SENTRY_RELEASE } from "./common/constants.js";
import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";
import { store } from "common/store";

Sentry.init({
  dsn: SENTRY_URL,
  release: SENTRY_RELEASE,
  integrations: [new Integrations.BrowserTracing()],

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 1.0
});

const app = (
  <Provider store={transformatorStore}>
    <MobxProvider store={store}>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </MobxProvider>
  </Provider>
);

ReactDOM.render(app, document.getElementById("root"));
unregister();
