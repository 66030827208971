import * as actionTypes from '../actions/actionTypes';
import { updateObject } from 'common/utility';

const initialState = {
    transformation: {
      dreid: {
        dx: 14,
        dy: 15,
        dz: 16,
        m: 1.0004,
        rx: 0.1,
        ry: 0.2,
        rz: 0.3,
      },
      zweid: {
        de: 714,
        dn: 715,
        m: 1.000774,
        r: 0.77,

      }
    },
    params2d: [],
    params3d: [],
    isDreiD: false,
}

const updateTransformation = (state, action) => {
  return updateObject(state, {
    transformation: {...action.tra}
  });
}

const updateIsDreid = (state,action) => {
  return updateObject(state, {
    isDreiD: action.isDreiD,
  });
}

const saveParameter = (state, action) => {
  if (action.dreid) {
    const params = state.params3d
    params.push(action.params);
    return updateObject( state, {
      params3d: [
        ...state.params3d
      ]
    });
  } else {
    const params = state.params2d
    params.push(action.params);
    return updateObject( state, {
      params2d: [
        ...state.params2d
      ]
    });
  }
}

const setParameter = (state, action) => {
  let transformation = state.transformation
  if (action.dreid) {
    transformation.dreid = state.params3d[action.i].params
  } else {
    transformation.zweid = state.params2d[action.i].params
  }
  return updateObject( state, {
    transformation: {...transformation}
  });
}



const reducer = ( state = initialState, action) => {
    switch ( action.type ) {
        case actionTypes.HELMERT_UPDATE_TRA: return updateTransformation( state, action );
        case actionTypes.HELMERT_SAVE_PARAMS: return saveParameter( state, action );
        case actionTypes.HELMERT_SET_PARAMS: return setParameter( state, action );
        case actionTypes.HELMERT_UPDATE_ISDREID: return updateIsDreid( state, action);
        default:
            return state;
    }
}

export default reducer;
