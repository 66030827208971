import React, { Component } from 'react';
import { toast } from 'react-toastify';
import { connect } from 'react-redux';
import axios from 'axios';

import * as actions from 'store/actions';

import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import Tooltip from '@mui/material/Tooltip';
import TextField from '@mui/material/TextField';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch'

import TitleCard from 'components/TitleCard';
import BevCard from 'components/BevCard';
import BevSaveDialog from 'components/BevSaveDialog';
import BevModal from 'components/BevModal';

import { BASE_API_URL } from 'common/constants.js';
import errorHandler from 'common/errorHandler';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';



const columns =[
  {name: 'username', key:'username' },
  {name: 'api key', key:'token'},
  {name: 'first name', key:'first_name'},
  {name: 'last name', key:'last_name'},
  {name: 'active', key:'is_active'},
]


const mapStateToProps  = state => {
  return {
    timestamp: state.api.advancedListsTimestamp,
    notifications: state.base.notifications,
    user: state.auth.user,
    config: state.auth.config,
  }
}

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    onInit: () => dispatch(actions.api.init())
  }
}

class ApiKeys extends Component {
  constructor(props) {
    super(props);
    this.state = {
      openKeys: true,
      order: 'desc',
      orderBy: 'username',
      filterByName: '',
      keys: null,
      rowsPerPage: 20,
      offset: 0,
      page: 0,
      isModalOpen: false,
      username: '',
      isSwitchModalOpen: false,
      selectedUser: {},
      resultNums: 0,
    }
  }

  getData() {
    let url = BASE_API_URL+'stp/users/?limit=20000';
    axios.get(url, this.props.config).then(res => {
      this.setState({ keys: res.data.results, resultNums: res.data.count });
    })
  }

  componentDidMount() {
    this.getData();
  }

  handleSort = property => event => {
    let ord = 'desc'
    if (this.state.orderBy === property) {
         ord = (this.state.order === 'asc' ? 'desc' : 'asc')
    }
    this.setState({ orderBy: property, order: ord})
  }

  changeName = event => {
    this.setState({ filterByName: event.target.value });
    
  }

  handleChangePage = (event, page) => {
    this.setState({page: page, offset: this.state.rowsPerPage*page }, this.getData );
  }

  handleChangeRowsPerPage = event => {
    this.setState({ page: 0, rowsPerPage: event.target.value }, this.getData );
  }

  toggleModal = () => {
    this.setState(prevState => { return { isModalOpen: !prevState.isModalOpen}})
  }
  
  hanldeChangeNewUserName = (event) => {
    const username = event.target.value
    this.setState({ username })
  }

  addNewUser = (event) => {
    const {username} = this.state
    let url = BASE_API_URL+'stp/users/'
    axios.post(url, {username: username} ,this.props.config).then(res => {
      this.getData();
    }).catch(errorHandler);
    this.toggleModal();
  }

  handleClickUser = (user) => {
    this.setState({isSwitchModalOpen: true, selectedUser: user});
  }

  toggleSwitchModal = () => {
    this.setState( prevState => { return { isSwitchModalOpen : !prevState.isSwitchModalOpen} } );
  }

  handleChangeActive = () => {
    const {selectedUser} = this.state;
    selectedUser.is_active = !selectedUser.is_active;
    let url = BASE_API_URL+'stp/users/'
    axios.put(url, {id: selectedUser.id, is_active: selectedUser.is_active} ,this.props.config).then(res => {
      this.getData();
      this.setState({selectedUser});
    }).catch( (error) => { 
      selectedUser.is_active = !selectedUser.is_active;
      errorHandler(error);
    });
  }

  copyToClipboard = () => {
    toast.info("Erfolgreich Kopiert", {autoClose: 4000});
    setTimeout(() => {this.setState({isSwitchModalOpen: false})},10);
  }
 
  render() {
    const filterdList = !!this.state.keys ? this.state.keys.filter((element) => { 
      const filterString = this.state.filterByName.toLowerCase();
      return element.username.toLowerCase().includes(filterString) || element.first_name.toLowerCase().includes(filterString)  || element.last_name.toLowerCase().includes(filterString) ;
    }): [];
    const slicedList = filterdList.slice((this.state.offset),(this.state.offset + this.state.rowsPerPage));
    const userList = slicedList.sort((a,b) => {
      const {orderBy} = this.state;
      const order = this.state.order === 'desc' ? 1 : -1;
      if(a[orderBy] < b[orderBy]) return -1 * order;
      if(a[orderBy] > b[orderBy]) return 1 * order;
      return 0;
    })


    const count = filterdList.length
    return <div>
      <Grid container alignItems="center" justifyContent="center" spacing={1}>
          <Grid item xs={12} sm={12}>
            <TitleCard title="API KEYS">
              <p>Hier können dann die Api Keys verwaltet werden.</p>
            </TitleCard>

          </Grid>
          <Divider />

          <BevCard title="Available API Keys" show={true} open={this.state.openParams}>
            <Button style={{marginTop: '20px', marginBottom: '20px'}} variant="contained" color="primary" onClick={this.toggleModal}> Generate new Key</Button>
            <BevSaveDialog 
                dialogTitle={"User Name angeben"}
                isDialogOpen={this.state.isModalOpen}
                toggleDialog={this.toggleModal}
                handleChange={this.hanldeChangeNewUserName}
                handleSave={this.addNewUser}
                value={this.state.username} />
          <TextField style={{marginRight: '25px', float: 'right'}} id="standard-search" label="Search field" type="search" margin="normal" value={this.state.filterByName} onChange={this.changeName}/>
          <Paper>
            <Table>
              <TableHead>
                <TableRow>
                  {columns.map( (col,i) => (
                    <TableCell key={i}>
                      <Tooltip title="Sort" placement={'bottom-start'} enterDelay={300}>
                        <TableSortLabel active={col.key === this.state.orderBy} direction={this.state.order} onClick={this.handleSort(col.key)}>
                          {col.name}
                        </TableSortLabel>
                      </Tooltip>
                    </TableCell>
                  ))}
                  <TableCell>
                  </TableCell>
                </TableRow>
              </TableHead>

              <TableBody>
                { userList && (userList.map( row => (
                  <TableRow key={row.id} onClick={this.handleClickUser.bind(this,row)}>
                    <TableCell component="th" scope="row">
                      {row.username}
                    </TableCell>
                    <TableCell align="right">{row.token}</TableCell>
                    <TableCell align="right">{row.first_name}</TableCell>
                    <TableCell align="right">{row.last_name}</TableCell>
                    <TableCell align="right">{row.is_active ? 'AKTIV' : 'INAKTIV'}</TableCell>
                    <TableCell align="right">
                      <CopyToClipboard text={row.token} onCopy={this.copyToClipboard}>
                        <span>
                          <FontAwesomeIcon icon={'clipboard'} style={{marginRight: '5px'}} ></FontAwesomeIcon> token
                        </span>
                      </CopyToClipboard>
                    </TableCell>
                  </TableRow>
                )))
                }
              </TableBody>
            </Table>
            <TablePagination rowsPerPageOptions={[10, 20, 50]} component="div" count={count} rowsPerPage={this.state.rowsPerPage} page={this.state.page}
              backIconButtonProps={{ 'aria-label': 'Previous Page', }}
              nextIconButtonProps={{ 'aria-label': 'Next Page', }}
              onPageChange={this.handleChangePage}
              onRowsPerPageChange={this.handleChangeRowsPerPage}
            />

          </Paper>


          </BevCard>

      </Grid>
      <BevModal width={450} open={this.state.isSwitchModalOpen} title={'User Aktive / Inaktive setzen'} onClose={this.toggleSwitchModal}>
        <FormGroup row>
          <FormControlLabel 
              control={ <Switch
                  checked={this.state.selectedUser.is_active}
                  onChange={this.handleChangeActive}
                  value="innerGeometry"
                  color="primary"
              /> } 
              label={this.state.selectedUser.is_active ? 'API Key ist Aktive' : 'API Key ist Inaktive'} />
        </FormGroup>
        <Button variant='outlined' color="primary" onClick={this.toggleSwitchModal}>Ok</Button>
        </BevModal>
    </div>
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ApiKeys);
