import { csvError } from './parserErrors';
import * as constants from '../constants';

const reg101 = /([^,;\n]*,){2,6}[^,;\n]*;/;
const reg102 = /([^,;\n]*,){2,6}[^,;\n]*\n/;
const reg152 = /([^,;\n]*,){2,6}[^,;\n]*/;
const reg103 = /([^;\n]*;){2,6}[^;\n]*\n/;
const regNewLine = /\r?\n|\r/g;


export default function parseCSV(f, isMgi) {
    let parsedJson = {}
    parsedJson.type = "FeatureCollection";
    parsedJson.crs = {};
    parsedJson.features = [];

    let lineSplitChar = "\n";
    let colSplitChar =";";

    //check wich seporators are used
    if(f.match(reg101)){
        lineSplitChar = ";";
        colSplitChar = ",";
    }else if(f.match(reg102)){
        lineSplitChar = "\n";
        colSplitChar = ",";
    }else if(f.match(reg152)){
        lineSplitChar = "\n";
        colSplitChar = ",";
    }else if(f.match(reg103)){
        lineSplitChar = "\n";
        colSplitChar = ";";
    }

    //split Sting with saporators in lines
    f.split(lineSplitChar).forEach(function(stringLine) {
        let line = stringLine.split(colSplitChar);

        //check if Line is Empty
        if (stringLine.replace(regNewLine, "") === "" || stringLine[0] ==='#') return;

        // check if Line has to less information
        if (line.length <= 1) {
            throw new csvError(constants.ERROR_IMPORT_CSV_NOT_VALID);
        } 

        //check if Line for lenght, empty fields and string input
        checkLine(line,stringLine, isMgi);

        //parse line to Feature and add to GeoJson
        parsedJson.features.push(createFeatureFromLine(line));
    });

    return parsedJson;
  }

function checkLine(line,stringLine, isMgi){
    if(!hasValidLength(line, isMgi)){
        throw new csvError(constants.ERROR_IMPORT_CSV_WRONG_NUMBER_PARAMETER_1 + (line.length - 1) + constants.ERROR_IMPORT_CSV_WRONG_NUMBER_PARAMETER_2 + stringLine)
    }
    if(hasEmptyStrings(line)){
        throw new csvError(constants.ERROR_IMPORT_CSV_EMPTY_INPUT + stringLine)
    }
    if(hasAString([...line].splice(1, line.length-1))){
        throw new csvError(constants.ERROR_IMPORT_CSV_EMPTY_INPUT + stringLine)
    }
}

function hasValidLength(line, isMgi) {
    let length = line.length;
    if(isMgi && length >= 4 && line[3] === ""){
        line[3] = "0";
    }else if(isMgi && length === 3){
        line.push("0");
    }
    length = line.length;
    if((length === 5 && line[4] === "" ) ||  (length === 8 && line[7] === "" ) ){
        line.pop();
        length = line.length;
    } 
    return length === 4 || length === 7 ;
}

function hasEmptyStrings(arr){
    for(let element of arr){
        if(!element || element.replace(regNewLine,"") === '') return true;
    }
    return false;
}

function hasAString(arr){
    for(let element of arr){
        if(isNaN(element.replace(',','.'))) return true
    }
    return false;
}

function createFeatureFromLine(line){
    let f = {};
    f.type = "Feature";
    f.geometry = {'type': 'Point'};
    f.geometry.coordinates = [createNumber(line[1]), createNumber(line[2]), createNumber(line[3])];
    f.properties = {'name' : line[0] };
    f.geometry.velocities = line.length === 7 ? [createNumber(line[4]),createNumber(line[5]),createNumber(line[6])]: [];
    return f;
}

function createNumber(element){
    return parseFloat(element.replace(',','.'));
}


export const parseGeoJsonToCsv = (file) => {
    let csvFile = '';
    for(let feature of file.features){
        if(feature.type === "Feature" && feature.geometry.type === "Point"){
        csvFile +=  feature.properties.name
        for(let coord of feature.geometry.coordinates){
            csvFile += ';' + coord ;
        }
        if(feature.geometry.coordinates.length === 2){
            csvFile += ';0';
        }
        if(!!feature.geometry.velocities){
            for(let vel of feature.geometry.velocities){
            csvFile += ';' + vel;
            }
            if(feature.geometry.velocities.length === 2){
                csvFile += ';0';
            }
        }
        csvFile += '\n';
        }
    }

    return csvFile;
}