import React, { Component } from 'react';
import PropTypes from 'prop-types';

import Grid from '@mui/material/Grid';

import CRSLoadButton from './CRSLoadButton';
import CRSSaveButton from './CRSSaveButton';
import CRSInputForm from './CRSInputForm';
import * as constants from '../../common/constants.js';

class CRS extends Component {
  static propTypes ={
    crs: PropTypes.object.isRequired,
    handleChangeEpoch: PropTypes.func.isRequired,
    handleChangeFrame: PropTypes.func.isRequired,
    handleChangeEllps: PropTypes.func.isRequired,
    handleChangeMeridian: PropTypes.func.isRequired,
    handleChangeProjection: PropTypes.func.isRequired,
    handleChangeCRS: PropTypes.func.isRequired,
  }
  static defaultProps = {
    showSave: false
  }

  handleChangeEllps = (event) => {
    if(event.target.value && !this.props.crs.ellipsoid){
      this.props.handleChangeMeridian({target: { value: constants.MERIDIAN_DEFAULT}});
    }else if(!event.target.value && this.props.crs.ellipsoid){
      this.props.handleChangeMeridian({target: { value: ""}});
      this.props.handleChangeProjection({target: { value: ""}}); 
    }
    this.props.handleChangeEllps(event)
  }

  render() {
    return (
      <Grid container
        direction="row"
        justifyContent="flex-start"
        alignItems="flex-start" >
        <div style={{display: 'inline-block'}}>
          <CRSLoadButton handleChangeCRS={this.props.handleChangeCRS}></CRSLoadButton>
          <CRSSaveButton {...this.props.crs}></CRSSaveButton>
        </div>
        {/* <div style={{display: 'inline-block'}}> */}
        <div style={{display: 'inline-block',justifyContent:'space-between'}}>
          <CRSInputForm {...this.props.crs} {...this.props} handleChangeEllps={this.handleChangeEllps}></CRSInputForm>
        </div>
      </Grid>
    );
  }
}
export default CRS;