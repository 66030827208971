import * as actionTypes from './actionTypes';

// sagas
export const getCurrentUser = () => ({ type: actionTypes.AUTH_GET_CURRENT_USER })
export const checkToken = (token) => ({ type: actionTypes.AUTH_CHECK_TOKEN, token: token })
export const logoutUser = () => ({ type: actionTypes.AUTH_LOGOUT_CURRENT_USER })

// reducers
export const loadUser = (val) => ({ type: actionTypes.AUTH_LOAD_USER, val: val })
export const updateUser = (user) => ({ type: actionTypes.AUTH_UPDATE_USER, data: user })
export const authenticationError = () => ({ type: actionTypes.AUTH_AUTHENTICATION_ERROR })
export const apiDown = () => ({ type: actionTypes.AUTH_API_DOWN })
export const apiUp = () => ({ type: actionTypes.AUTH_API_UP })
export const setSTPAuthentication = (val) => ({ type: actionTypes.AUTH_SET_STP_AUTHENTICATION, val: val })
export const setTokenAuthentication = (val) => ({ type: actionTypes.AUTH_SET_TOKEN_AUTHENTICATION, val: val })
export const anonymousUser = (data) => ({ type: actionTypes.AUTH_SET_ANONYMOUS_USER, data: data })
export const unsetUserSettings = () => ({ type: actionTypes.AUTH_UNSET_USER_SETTINGS })
