import React, { useCallback, useState } from "react";
import { useDropzone } from "react-dropzone";
import { Collapse, IconButton, Box } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFile, faTimes } from "@fortawesome/free-solid-svg-icons";
import { useTranslation } from "react-i18next";


export const FileDropzone = ({ loadData, open, missingData, handleClose }) => {
  const { t } = useTranslation();
  const [files, setFiles] = useState([]);
  const onDrop = useCallback((acceptedFiles) => {
    setFiles(acceptedFiles);
    if (loadData) loadData(acceptedFiles);
  }, []);
  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop, noDragEventsBubbling: true });

  return <Collapse in={files.length <= 0 || isDragActive || open || missingData}>
    {open && !missingData && <IconButton onClick={handleClose}><FontAwesomeIcon icon={faTimes} /></IconButton>}
    <Box sx={{
      width: "100%",
      height: 250,
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      border: "3px #333 dashed",
      borderRadius: "0.25em",
      cursor: "pointer",
      marginBottom: '1em',

      "&:focus": {
        outline: "none",
        borderColor: "#2196f3",
      },

      "&:hover": {
        borderColor: "#2196f3",
      },
    }} {...getRootProps()}>
      <input {...getInputProps()} />
      {isDragActive ? (
        <Box components="p" sx={{ fontSize: '1.5em', padding: '2em', textAlign: 'center' }}>{t('cadastre:input_tab_upload_dropzone_on_drag')}</Box>
      ) : (
        <Box components="p" sx={{ fontSize: '1.5em', padding: '2em', textAlign: 'center' }}>
          <FontAwesomeIcon icon={faFile} style={{ marginRight: '0.5em' }} />{t('cadastre:input_tab_upload_dropzone_text')}
        </Box>
      )}
    </Box>
  </Collapse>
};
