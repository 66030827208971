import React from "react";

import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";

import TitleCard from "components/TitleCard";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useTranslation } from "react-i18next";

function ExtendedTitleCard({
  clearPage,
  loadExample,
  title,
  description,
  wikiLink,
}) {
  const toWiki = () => {
    window.open(wikiLink);
  };
  const { t } = useTranslation();

  return (
    <Grid container alignItems="center" justifyContent="center" spacing={1}>
      <Grid item xs={12} sm={12}>
        <TitleCard title={title}>
          <p>{description}</p>
          {!!loadExample && (
            <Button color="primary" onClick={loadExample}>
              <FontAwesomeIcon
                icon="folder-open"
                style={{ marginRight: "7px" }}
              />
              {t("extended_title_card_load")}
            </Button>
          )}
          <Button color="primary" onClick={clearPage}>
            <FontAwesomeIcon icon="redo" style={{ marginRight: "7px" }} />
            {t("extended_title_card_restart")}
          </Button>
          {wikiLink !== "" && (
            <Button color="primary" onClick={toWiki}>
              <FontAwesomeIcon icon="info" style={{ marginRight: "7px" }} />
              {t("extended_title_card_wiki")}
            </Button>
          )}
        </TitleCard>
      </Grid>
    </Grid>
  );
}

export default ExtendedTitleCard;
