import React, {Component} from 'react';
import PropTypes from 'prop-types';

import IconButton from '@mui/material/IconButton';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import SelcetTrafoModal from './SelectTrafoModal';
import BevDialog from 'components/BevDialog';

import {withTranslation} from 'react-i18next';


class LoadSaveTrafo extends Component  {
    /**
     * trafos, array of all transformations
     * handleLoadTrafo, function to load a saved Transformation
     * handleSaveTrafo, function save a selected Transformation
     */
    static propTypes = {
        trafos: PropTypes.array.isRequired,
        handleLoadTrafo: PropTypes.func.isRequired, 
        handleSaveTrafo: PropTypes.func.isRequired,
    }

    static defaultProps = {
        trafos: [],
    }
    state = {
        isModalOpen: false,
    }

    handleOnCloseModal = () => {this.setState({isModalOpen: false})};
    hanldeOnOpenModal = () => {this.setState({isModalOpen: true})};

    loadTrafo = (i) =>{
        this.handleOnCloseModal();
        this.props.handleLoadTrafo(i);
    }

    render = () => {
        const { trafos, handleSaveTrafo, t} = this.props;
        return <div>
            {/* Button to oben the select Modal */}
            <IconButton 
                style={{marginRight: 10 }} 
                variant="contained" 
                color="default"  
                onClick={this.hanldeOnOpenModal} >
                <FontAwesomeIcon icon="folder-open" />
            </IconButton>
            {/* Modal to select an saved Tansformation*/}
            <SelcetTrafoModal
                isModalOpen={this.state.isModalOpen}
                handleOnClose={this.handleOnCloseModal}
                trafos={trafos}
                handleLoadTrafo={this.loadTrafo} />
            {/*Button + Dialog to save current Transformation*/}    
            <BevDialog 
                button={t('advanced_load_save_dialog_save')} 
                title={t('advanced_load_save_dialog_title')} 
                label={t('advanced_load_save_dialog_label')}  
                saveFunction={handleSaveTrafo} />
        </div>
    }
}

export default withTranslation()(LoadSaveTrafo);