import React, { Component } from 'react';
import Grid from '@mui/material/Grid';

import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';

import i18next from 'common/i18n';
import {withTranslation} from 'react-i18next';

const pointlabels = {
  geocentric: {
    labelX: i18next.t('single_point_geographic_label_x'),
    placeholderX: i18next.t('single_point_geographic_placeholder_x'),

    labelY: i18next.t('single_point_geographic_label_y'),
    placeholderY: i18next.t('single_point_geographic_placeholder_y'),

    labelZ: i18next.t('single_point_geographic_label_z'),
    placeholderZ: i18next.t('single_point_geographic_placeholder_z'),

    labelvX: i18next.t('single_point_geographic_label_vx'),
    placeholdervX: i18next.t('single_point_geographic_placeholder_vx'),

    labelvY: i18next.t('single_point_geographic_label_vy'),
    placeholdervY: i18next.t('single_point_geographic_placeholder_vy'),

    labelvZ: i18next.t('single_point_geographic_label_vz'),
    placeholdervZ: i18next.t('single_point_geographic_placeholder_vz'),
  },
  geographic: {
    labelX: i18next.t('single_point_geocentric_label_x'),
    placeholderX: i18next.t('single_point_geocentric_placeholder_x'),

    labelY: i18next.t('single_point_geocentric_label_y'),
    placeholderY: i18next.t('single_point_geocentric_placeholder_y'),

    labelZ: i18next.t('single_point_geocentric_label_z'),
    placeholderZ: i18next.t('single_point_geocentric_placeholder_z'),

    labelvX: i18next.t('single_point_geocentric_label_vx'),
    placeholdervX: i18next.t('single_point_geocentric_placeholder_vx'),

    labelvY: i18next.t('single_point_geocentric_label_vy'),
    placeholdervY: i18next.t('single_point_geocentric_placeholder_vy'),

    labelvZ: i18next.t('single_point_geocentric_label_vz'),
    placeholdervZ: i18next.t('single_point_geocentric_placeholder_vz'),
  },
  projected: {
    labelX: i18next.t('single_point_projected_label_x'),
    placeholderX: i18next.t('single_point_projected_placeholder_x'),

    labelY: i18next.t('single_point_projected_label_y'),
    placeholderY: i18next.t('single_point_projected_placeholder_y'),

    labelZ: i18next.t('single_point_projected_label_z'),
    placeholderZ: i18next.t('single_point_projected_placeholder_z'),

    labelvX: i18next.t('single_point_projected_label_vx'),
    placeholdervX: i18next.t('single_point_projected_placeholder_vx'),

    labelvY: i18next.t('single_point_projected_label_vy'),
    placeholdervY: i18next.t('single_point_projected_placeholder_vy'),

    labelvZ: i18next.t('single_point_projected_label_vz'),
    placeholdervZ: i18next.t('single_point_projected_placeholder_vz'),
  },
}


const checkSinglePoint = (f, is2d) => {
  const checkX = !!f.x || f.x === 0; 
  const checkY = !!f.y || f.y === 0;
  const checkZ = !!f.z || f.z === 0;
  const checkVX = !!f.vx || f.vx === 0;
  const checkVY = !!f.vy || f.vy === 0;
  const checkVZ = !!f.vz || f.vz === 0;
  const checkInputPoints = is2d ? ( checkX || checkY ) : ( checkX || checkY || checkZ );
  const checkInputVel = is2d ? ( checkVX || checkVY ) : ( checkVX || checkVY || checkVZ );
  const isInputPointsComplete = is2d ? ( checkX && checkY ) : ( checkX && checkY && checkZ );
  const isInputVelComplete = is2d ? ( checkVX && checkVY ) : ( checkVX && checkVY && checkVZ) ;
  return !checkInputVel ? checkInputPoints && isInputPointsComplete : checkInputPoints && isInputPointsComplete && isInputVelComplete;
}

const pointCheck  = (is2d,point) => {
  if(is2d){
    return !!point.x || point.x === 0 || !!point.y || point.y === 0;
  }else{
    return !!point.x || point.x === 0 || !!point.y || point.y === 0 || !!point.z || point.z === 0;
  }
}

const pointVelCheck = (is2d,point) => {
  if(is2d){
    return !!point.vx || point.vx === 0 || !!point.vy || point.vy === 0;
  }else{
    return !!point.vx || point.vx === 0 || !!point.vy || point.vy === 0 || !!point.vz || point.vz === 0;
  }
}


class SinglePoint extends Component {
    constructor(props) {
        super(props);
        this.state = {
            open: this.props.open,
            show: this.props.show,
            point: {
              x: "",
              y: "",
              z: "",
              vx: "",
              vy: "",
              vz: "",
            }
        }
    }

    componentDidMount(){
      const { singlePoint } = this.props
      if(singlePoint){
        this.setState({point: singlePoint})
      }
    }

    componentWillReceiveProps(nextProps) {
      this.setState({ show: nextProps.show, open: nextProps.open });
    }

    toggleOpen = () => {
      this.setState({ open: !this.state.open})
    }

    handleChange = (name, event) => {
      let point = this.state.point
      point[name] = parseFloat(event.target.value);
      if(isNaN(point[name])) point[name] = "";
      this.setState({point: point, })
      this.props.onChangePoint(point);
    }

    isTransformReady = () => {
      const { point } = this.state;
      return !!point && checkSinglePoint(point, this.props.is2d);
    }

    render() {
      let l = pointlabels[this.props.type];
      const { point } = this.state;
      const { is2d, startTransformation, t } = this.props;
      const isInput = pointCheck(is2d,point) || pointVelCheck(is2d,point);
      const isInputVelo = pointVelCheck(is2d,point);
      const formGroupeStyle = {marginBottom:'0px', marginTop: '0px'};
      const textFieldStyle = {marginTop:'7px'};

        return <Grid container spacing={4}>
            <Grid item sm={3}>
              <Box style={formGroupeStyle}>
                <TextField
                  style={textFieldStyle}
                  error={!!point && isInput && !point.x && point.x !== 0}
                  required
                  id="xcoord"
                  name="x"
                  type="number"
                  label={<span dangerouslySetInnerHTML={{__html: `${l.labelX}`}}></span>}
                  value={point.x}
                  onChange={this.handleChange.bind(this,'x')}
                  placeholder={l.placeholderX}
                  margin="normal"
                />
              </Box>
              <Box style={formGroupeStyle}>
                <TextField
                  style={textFieldStyle}
                  error={!!point && isInput && !point.y && point.y !== 0}
                  required
                  id="ycoord"
                  name="y"
                  type="number"
                  label={<span dangerouslySetInnerHTML={{__html: `${l.labelY}`}}></span>}
                  value={point.y}
                  onChange={this.handleChange.bind(this,'y')}
                  placeholder={l.placeholderY}
                  margin="normal"
                  />
              </Box>
              { !is2d && <Box style={formGroupeStyle}>
                <TextField
                  style={textFieldStyle}
                  error={!!point && isInput && !point.z && point.z !== 0 }
                  required
                  id="zcoord"
                  name="z"
                  type="number"
                  label={l.labelZ}
                  value={point.z}
                  onChange={this.handleChange.bind(this,'z')}
                  placeholder={l.placeholderZ}
                  margin="normal"
                  />
              </Box>}
            </Grid>

            {this.props.velocities && <Grid item sm={3}>
                <Box style={formGroupeStyle}>
                  <TextField
                    style={textFieldStyle}
                    error={!!point && isInputVelo && !point.vx && point.vx !== 0}
                    required={isInputVelo}
                    id="xvel"
                    name="vx"
                    type="number"
                    label={<span dangerouslySetInnerHTML={{__html: `${l.labelvX}`}}></span>}
                    value={point.vx}
                    onChange={this.handleChange.bind(this,'vx')}
                    placeholder={l.placeholdervX}
                    margin="normal"
                    />
                </Box>
                <Box style={formGroupeStyle}>
                  <TextField
                    style={textFieldStyle}
                    error={!!point && isInputVelo && !point.vy && point.vy !== 0}
                    required={isInputVelo}
                    id="yvel"
                    name="vy"
                    type="number"
                    label={<span dangerouslySetInnerHTML={{__html: `${l.labelvY}`}}></span>}
                    value={point.vy}
                    onChange={this.handleChange.bind(this,'vy')}
                    placeholder={l.placeholdervY}
                    margin="normal"
                    />
                </Box>
                { !is2d && <Box style={formGroupeStyle}>
                  <TextField
                    style={textFieldStyle}  
                    error={!!point && isInputVelo && !point.vz && point.vz !== 0}
                    required={isInputVelo}
                    id="zvel"
                    name="vz"
                    type="number"
                    label={l.labelvZ}
                    value={point.vz}
                    onChange={this.handleChange.bind(this,'vz')}
                    placeholder={l.placeholdervZ}
                    margin="normal"
                    />
                </Box>}
              </Grid>}
            <Grid item sm={12}>
              <Button 
                color="primary" 
                variant="contained" 
                onClick={startTransformation} 
                style={{marginTop: '10px'}} 
                disabled={!this.isTransformReady()}>
                  {t('single_point_start_btn')}
              </Button> 
            </Grid>
          
          </Grid>;
    }
}

export default withTranslation()(SinglePoint);
