export const isResidualToLong = (residuals) => {
    const {length} = getModifiedResiduals(residuals);
    return length > 100;
};

export const getModifiedResiduals = (residuals ) => {
    let north= !!residuals ? residuals.north*1000 : 0;
    let east= !!residuals ? residuals.east*1000 : 0;
    let length = !!residuals ? parseInt(Math.sqrt( Math.pow(north,2)+Math.pow(east,2)),10)*2 :0;
    let angle = !!residuals ? Math.atan2(north, east): 0;
    return {north,east,length,angle};
};