import React, { Component } from "react";
import { Link } from "react-router-dom";
import ExternalLink from "@mui/material/Link";

import Collapse from "@mui/material/Collapse";
import MenuList from "@mui/material/MenuList";
import Toolbar from "@mui/material/Toolbar";

import { BASE_URL } from "../common/constants.js";

import BevMenuListItem from "./BevMenuListItem";

import logo from "../BEV-Logo-paddingless.png";
import logo2 from "../BEV-Eichamt_Logo_EN_srgb.png";
import ApiKeyModal from "./ApiKeyModal";
import {
  Hidden,
  MenuItem,
  FormControl,
  Select,
  ListItemIcon,
} from "@mui/material";
import { Language } from "@mui/icons-material";
import { withTranslation } from "react-i18next";

class AppHeaderMenuListLogo extends Component {
  static propTypes = {};
  static defaultProps = {};
  state = {
    dropdownopen: false,
  };

  changeLanguage = (lng) => {
    this.props.i18n.changeLanguage(lng);
  };

  toggleDropdown = () => {
    this.setState((prevState) => {
      return { dropdownopen: !prevState.dropdownopen };
    });
  };

  render() {
    const { dropdownopen } = this.state;
    const { auth, logoutUser, openAPIDialog, apikey } = this.props;
    const { apiinsert, insertKey, changeAPIKey, apiDown, t, i18n } = this.props;
    const { username } = auth.user_loading
      ? { username: "user loading" }
      : auth.user;
 
    return (
      <Toolbar className={"header-logo"} variant="dense" disableGutters={true}>
        <ExternalLink style={{position: 'relative', zIndex: 2}} target="_blank" href={"https://www.bev.gv.at/"}>
          {i18n.language.toLowerCase().includes("de") ? (
            <img className={"logo"} src={logo} alt=""></img>
          ) : (
            <img className={"logo"} src={logo2} alt=""></img>
          )}
        </ExternalLink>
        <Hidden xsDown>
          <Link to={BASE_URL}>
            <h1 className={"header-title"}>{t("transformator")}</h1>
          </Link>
        </Hidden>
        <Hidden xsDown>
          <MenuList id={"header-logo-menu-list"} className={"menu-list"}>
            <MenuItem className={"menu-item"}>
              <ListItemIcon className={"menu-icon"}>
                <Language
                  style={{ fontSize: "1rem", marginRight: "0.25rem" }}
                />
              </ListItemIcon>
              <FormControl>
                <Select
                  native
                  value={
                    i18n.language.toLowerCase().includes("de") ? "de" : "en"
                  }
                  onChange={(event) => this.changeLanguage(event.target.value)}
                  variant={"standard"}
                  inputProps={{
                    name: "i18n",
                    sx : {py:0}
                  }}
                  
                >
                  <option value="de">DE</option>
                  <option value="en">EN</option>
                </Select>
              </FormControl>
            </MenuItem>
            <BevMenuListItem
              label={t("api")}
              icon={"plug"}
              newTab
              url={BASE_URL + "swagger/"}
            />
            <BevMenuListItem
              label={t("wiki")}
              icon={"atlas"}
              newTab
              url={BASE_URL + "wiki/"}
            />
            <BevMenuListItem
              url={"https://www.bev.gv.at/Das-BEV/Kontakte-und-Standorte.html"}
              icon={"address-book"}
              external
              newTab
              label={t("contact")}
            />
            <BevMenuListItem
              url={BASE_URL + "impressum"}
              icon={"info"}
              label={t("impressum")}
            />
            <BevMenuListItem icon={"user"} label={username} isLast/>
          </MenuList>
        </Hidden>
        <ApiKeyModal
          openAPIDialog={openAPIDialog}
          apiinsert={apiinsert}
          insertKey={insertKey}
          apikey={apikey}
          changeAPIKey={changeAPIKey}
        />
      </Toolbar>
    );
  }
}

export default withTranslation()(AppHeaderMenuListLogo);
