import { Map, View } from "ol";
import { fromLonLat } from "ol/proj";
import { ScaleLine, ZoomSlider, Zoom } from "ol/control";
import { TileWMS } from 'ol/source';
import TileLayer from "ol/layer/Tile";
import VectorLayer from "ol/layer/Vector";
import { createXYZ } from 'ol/tilegrid';
import VectorTileLayer from 'ol/layer/VectorTile';
import ImageLayer from "ol/layer/Image";
import OSM from "ol/source/OSM";
import ImageWMS from "ol/source/ImageWMS";
import { Circle, Fill, Style, Stroke, RegularShape, Icon } from "ol/style";
import { stylingOtherPoints, stylingNewPoints, stylingPassPoint, stylingArrow } from "./styling";

import { DEVICE_PIXEL_RATIO } from "ol/has";
import XYZ from "ol/source/XYZ";

import MapboxVectorLayer from 'ol/layer/MapboxVector.js';
import { createUrl } from 'containers/Cadastre/PointInput/Map/layer';
import { KATASTER_SERVICE_URL } from "common/constants";

export const basicView = () => new View({
    center: fromLonLat([15, 47]),
    zoom: 7,
    maxZoom: 21,
});

export const basicMap = ({ view }) => new Map({
    view: view,
    controls: [
        new ScaleLine(),
        new ZoomSlider(),
        new Zoom(),
    ],
});

export const openStreetMapLayer = ({ zIndex = 10, visible = true, showInLegend = true } = {}) => new TileLayer({
    source: new OSM({
        crossOrigin: "anonymous",
    }),
    properties: {
        title: 'OpenStreetMap'
    },
    showInLegend,
    visible,
    zIndex,
});

export const orthophotoLayer = ({ zIndex = 12, visible = false, showInLegend = true } = {}) => new TileLayer({
    source: new TileWMS({
        minZoom: 8,
        url: `${KATASTER_SERVICE_URL}ortho/ows`,
        projection: 'EPSG:31287',
        crossOrigin: 'anonymous',
        params: {
            'LAYERS': 'inspire:AT_BEV_OI',
            'FORMAT': 'image/jpeg',
        },

        tileGrid: createXYZ({ tileSize: [512, 512] }),
    }),
    properties: {
        title: 'BEV Ortho',
    },
    visible,
    zIndex,
    showInLegend,
})

//ToDO
export const aMapLayer = ({ zIndex = 11, visible = false, showInLegend = true } = {}) => new TileLayer({
    source: new XYZ({
        crossOrigin: 'Anonymous',
        maxZoom: 15,
        //url: 'https://vdedev.bev.gv.at/tiles/dop/gip_cache_EPSG3857/new/{z}/{x}/{y}.png',
        tileUrlFunction: function (coord) {
            const z = coord[0].toString().padStart(2, 0)
            let x = coord[1].toString().padStart(9, 0)
            let y = coord[2].toString().padStart(9, 0)

            x = x.substr(0, 3) + '/' + x.substr(3, 3) + '/' + x.substr(6, 3)
            y = y.substr(0, 3) + '/' + y.substr(3, 3) + '/' + y.substr(6, 3)

            return 'https://maps.bev.gv.at/tiles/' + z + '/' + x + '/' + y + '.png'
        },
    }),
    properties: {
        title: 'BEV Karte',
    },
    zIndex,
    showInLegend,
    visible,
})


export const arrowLayer = ({ zIndex = 50, theme } = {}) => new VectorLayer({
    style: stylingArrow(theme, false, false),
    zIndex,
    properties: {
        title: 'Arrows'
    },
    name: "arrows",
});

export const passPointLayer = ({ zIndex = 49, pointsList, theme } = {}) => new VectorLayer({
    style: stylingPassPoint(pointsList, theme, false, false),
    zIndex,
    properties: {
        title: 'PassPoints'
    },
    name: "passPoints",
});

export const trafoPointsLayer = ({ zIndex = 48 } = {}) => new VectorLayer({
    style: stylingNewPoints,
    zIndex,
    properties: {
        title: 'TrafoPoints'
    },
    name: "trafoPoints",
});

export const otherPointsLayer = ({ zIndex = 47 } = {}) => new VectorLayer({
    style: stylingOtherPoints,
    zIndex,
    properties: {
        title: 'OtherPoints'
    },
    name: "otherPoints",
});

// TODO remove later
export const meridianLayer = ({ zIndex = 42 } = {}) => new ImageLayer({
    zIndex,
    projection: "EPSG:4326",
    source: new ImageWMS({
        url: process.env.REACT_APP_GEOSERVER_API_URL + "wms",
        params: {
            layers: `${process.env.REACT_APP_GEOSERVER_LAYER_WORKSPACE}:${process.env.REACT_APP_GEOSERVER_LAYER_MERIDIANE}`,
        },
        projection: "EPSG:4326",
        ratio: 1,
        serverType: "geoserver",

        crossOrigin: "anonymous",
    }),
});

export const katasterLayer = ({ visible = true, declutter = true, opacity = 0.55, zIndex = 45 } = {}) => new MapboxVectorLayer({
    properties: {
        title: 'kataster'
    },
    styleUrl: createUrl('kataster', 'vermv'),
    visible,
    declutter,
    opacity,
    zIndex
});

export const festpunktLayer = ({ visible = true, declutter = false, zIndex = 46 } = {}) => new MapboxVectorLayer({
    properties: {
        title: 'symbole'
    },
    styleUrl: createUrl('fpt', ''),
    visible,
    declutter,
    zIndex
});

export const hoverFpLayer = (source, ids) => new VectorTileLayer({
    source,
    renderMode: 'vector',
    style: (feature) => {
        
        // Filter if Feature is hovered
        if(ids.indexOf(feature.getId()) === -1) return undefined;
        // Get Info as String
        const has_etrs = feature.get("has_etrs");
        const is_rutschpunkt = feature.get("is_rutschpunkt");
        // Parse info to Boolean
        const hasEtrs = (has_etrs == "false") != Boolean(has_etrs);
        const isRutschpunkt = (is_rutschpunkt == "false") != Boolean(is_rutschpunkt);

        // Select Icon based on Etrs and Rutschpunkt data
        const rutschpunktPath = isRutschpunkt ?  'rp' : 'fp';
        const etrsPath = hasEtrs ? '' : 'ohne';
        const url = `${KATASTER_SERVICE_URL}styles/icons/${rutschpunktPath}_mit_${etrsPath}etrs.svg`;

        //Set Style with Icon Url
        const style = new Style({
            image: new Icon({
                opacity: 1,
                src: url,
                crossOrigin: 'anonymous',
                scale: 1
            })
        })

        // Return style when hover over fetaure
        return style;
    }
    ,
    zIndex: 50,
    minZoom: source.getTileGrid().getMinZoom()
})

export const centerPointLayer = ({ zIndex = 43 } = {}) => new VectorLayer({
    zIndex,
    style: new Style({
        image: new Circle({
            radius: 4,
            fill: new Fill({ color: "red" }),
        }),
    }),
});

export const baseMapLayer = ({ zIndex = 20, visible = false, showInLegend = true } = {}) => {
    const hiDPI = DEVICE_PIXEL_RATIO > 1;
    const layer = hiDPI ? "bmaphidpi" : "geolandbasemap";
    const tilePixelRatio = hiDPI ? 2 : 1;

    const basemap = new TileLayer({
        source: new XYZ({
            url: `https://maps{1-4}.wien.gv.at/basemap/${layer}/normal/google3857/{z}/{y}/{x}.png`,
            maxZoom: 20,
            tilePixelRatio,
            crossOrigin: 'anonymous',
        }),
        title: "BaseMap",
        showInLegend,
        zIndex,
        visible,
    });
    return basemap;
};
