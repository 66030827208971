import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { useTheme } from '@mui/material';

import { DEFAULT_PAGE_SIZE } from './constants';

import { makePoint } from './geojson_geometries';
import Point from './geometriesCollection/point';
import MultiPoint from './geometriesCollection/multiPoint';
import MultiLineString from './geometriesCollection/multiLineString';
import Polygon from './geometriesCollection/polygon';
import MultiPolygon from './geometriesCollection/multiPolygon';
import { ExpandableListElement } from './geometriesCollection/geometriesListHelper';
import { Box } from '@mui/material';

/**
 * src: Is the Geometry Object wich will select wich component will be renderd, is an Object, is required, no default
 * pageSize: How many Geometries will be displayed on one Page, is a Number, is not required, default is 3
 */
const Geometry = ({ src, pageSize = DEFAULT_PAGE_SIZE }) => {
  const theme = useTheme();
  const { main } = theme.palette.primary;
  if (!src.hasOwnProperty('properties')) { src.properties = {}; }
  if (!src.properties.hasOwnProperty('name')) { src.properties.name = "undefined" }

  if (src.type === "GeometryCollection") {
    return <div><GeometryCollection feature={src} pageSize={pageSize} /></div>;
  } else {
    if (src.geometry.type === "Point") {
      return <div><Point feature={src} /></div>;

    } else if (src.geometry.type === "MultiPoint") {
      return <div><MultiPoint feature={src}
        pageSize={pageSize}
        typeName="MP"
        secondary="MultiPoint"
        backgroundColor={main} />
      </div>;

    } else if (src.geometry.type === "LineString") {
      return <div><MultiPoint feature={src}
        pageSize={pageSize}
        typeName="L"
        secondary="LineString"
        backgroundColor={main} />
      </div>;

    } else if (src.geometry.type === "MultiLineString") {
      return <div><MultiLineString feature={src} pageSize={pageSize} color={main} /></div>;

    } else if (src.geometry.type === "Polygon") {
      return <div><Polygon feature={src} pageSize={pageSize} color={main} /></div>;

    } else if (src.geometry.type === "MultiPolygon") {
      return <div><MultiPolygon feature={src} pageSize={pageSize} color={main} /></div>;

    } else {
      return "nothing here";
    }
  }
};

Geometry.propTypes = {
  src: PropTypes.object.isRequired,
  pageSize: PropTypes.number
}

export default Geometry;

/**
 * feature: Is the Geometry Object wich will select, wich component will be renderd, is an Object, is required, no default
 */
const GeometryCollectionClass = ({ feature }) => {
  const [showPoints, setShowPoints] = useState(false);
  const theme = useTheme();
  const { main } = theme.palette.primary;

  const togglePoints = () => setShowPoints(b => !b);

  if (!feature.hasOwnProperty('properties')) { feature.properties = {}; }
  if (!feature.properties.hasOwnProperty('name')) { feature.properties.name = "GeometryCollection" };
  return <Box>
    <ExpandableListElement typeName="GC"
      primary={feature.properties.name}
      secondary={"GeometryCollection (" + feature.geometries.length + ")"}
      style={{ backgroundColor: main, cursor: 'pointer', marginRight: '10px' }}
      toggleList={togglePoints}
      showList={showPoints} />

    {showPoints &&
      <Box sx={{ pl: 4 }}>
        {feature.geometries.map((g, i) => (
          <Geometry key={i} src={makePoint(feature.properties.name + '_' + i, g.type, g.coordinates, g.velocities)} />
        ))
        }
      </Box>
    }
  </Box>
}
export const GeometryCollection = GeometryCollectionClass;
