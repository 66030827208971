import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Alert, Box } from '@mui/material';

import * as actions from 'store/actions';
import BevCard from 'components/BevCard';

import InputTransformMethod from './InputTransformMethod';
import InputSystem from './InputSystem';
import { getSelection, isSameSystem } from './AustrianHelperFunctions';

import { useTranslation } from 'react-i18next';

const mapStateToProps = state => {
  return {
    crs: state.austrian.crs,
    sourceCRS: state.austrian.sourceCRS,
    sourceHeight: state.austrian.sourceHeight,
    targetCRS: state.austrian.targetCRS,
    targetHeight: state.austrian.targetHeight,
    transformationMethod: state.austrian.transformationMethod,

    crsList: state.api.austrianList,
    heightsList: state.api.heightSystemList,
    transfromations_methods: state.api.transformationMethodList,
  }
}

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    changeSourceHeight: (event) => dispatch(actions.austrian.changeAutValue("sourceHeight", event.target.value)),
    changeTargetHeight: (event) => dispatch(actions.austrian.changeAutValue("targetHeight", event.target.value)),
  }
}

/**
 * openParams, a function, to load a Example on the Parrent Component
 * transformation_method, a String to display the curretn Transformation Method
 * isTargetHeightDisabled, a boolean to disable hight in target
 * isSourceHeightDisabled, a  boolean to disable hight in Source
 * changeCRS, a function, to set the CRS of the Parrent Component
 * onChangeHandleTransformationMethod, a function, that triggers when the Transfomraiton Method is changed
 * toggleOpenParams, a function, to toggle the Card open
 */

ParamsCardFunc.propTypes = {
  openParams: PropTypes.bool,
  transformation_method: PropTypes.string,
  isTargetHeightDisabled: PropTypes.bool,
  isSourceHeightDisabled: PropTypes.bool,
  changeCRS: PropTypes.func.isRequired,
  onChangeHandleTransformationMethod: PropTypes.func.isRequired,
  toggleOpenParams: PropTypes.func.isRequired,
}

ParamsCardFunc.defaultProps = {
  openParams: true,
  isTargetHeightDisabled: false,
  isSourceHeightDisabled: false,
}

function ParamsCardFunc(props) {
  const { openParams, transformation_method, isTargetHeightDisabled, isSourceHeightDisabled, classes } = props; // Component Props Variables
  const { changeCRS, onChangeHandleTransformationMethod, toggleOpenParams } = props; // Component Props Functions

  const { sourceCRS, targetCRS, crsList, sourceHeight, targetHeight, transfromations_methods } = props; //Store Variabels
  const { changeSourceHeight, changeTargetHeight } = props; // Store Functions

  const changeCRSSystem = (target, event) => { changeCRS(target, event) }

  const { t } = useTranslation();

  return !!crsList &&
    <BevCard title={t("austrian_parameter_card_title")} show={true} open={openParams} toggleOpen={toggleOpenParams}>
      <Box sx={{
        display: 'flex',
        justifyContent: 'center',
        flexDirection: {
          sm: 'column',
          md: 'row'
        }
      }}>
        <InputSystem
          label={t("austrian_parameter_card_von")}
          system={sourceCRS}
          handleChangeSystem={changeCRSSystem.bind(this, 'sourceCRS')}
          height={sourceHeight}
          handleChangeHeigth={changeSourceHeight}
          isHeightDisabled={isSourceHeightDisabled}
          heightError={getSelection(props).indexOf('sourceHeight') > -1} />
        <InputSystem
          label={t("austrian_parameter_card_nach")}
          system={targetCRS}
          handleChangeSystem={changeCRSSystem.bind(this, 'targetCRS')}
          isSystemDisabled={sourceCRS === ''}
          height={targetHeight}
          handleChangeHeigth={changeTargetHeight}
          isHeightDisabled={isTargetHeightDisabled}
          heightError={getSelection(props).indexOf('targetHeight') > -1} />
        <InputTransformMethod
          onChangeHandler={onChangeHandleTransformationMethod}
          getSelection={getSelection(props)}
          transformation_method={transformation_method}
          transfromations_methods={transfromations_methods}
          isDisabled={isSameSystem(sourceCRS, targetCRS) || sourceCRS === '' || targetCRS === ''} />
      </Box>
      {getSelection(props).map((info, i) => (
        <TransformInfo style={{ width: '100%' }} key={i} selection={info} />
      ))}
    </BevCard>
}
const ParameterInput = connect(mapStateToProps, mapDispatchToProps)(ParamsCardFunc);
export default ParameterInput;


TransformInfo.propTypes = {
  selection: PropTypes.string.isRequired
}

function TransformInfo({ selection }) {
  const { t } = useTranslation();
  switch (selection) {
    case 'ready': return null;
    case 'missing': return <Alert color="warning">{t("austrian_parameter_card_warning1")}</Alert>;
    case 'sourceHeight': return <Alert color="warning">{t("austrian_parameter_card_warning2")}</Alert>;
    case 'targetHeight': return <Alert color="warning">{t("austrian_parameter_card_warning3")}</Alert>;
    case 'same': return <Alert color="warning">{t("austrian_parameter_card_warning4")}</Alert>
    default: return <Alert color="error">{t("austrian_parameter_card_warning5")}</Alert>;
  }
}