import React from "react";

import { Card, Box } from "@mui/material";
import CardContent from "@mui/material/CardContent";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import GetAppIcon from "@mui/icons-material/GetApp";

export const ExportEntry = ({ title, description, icon, dataType, startExport }) => {

  return (
    <Card sx={{
      display: "flex",
      cursor: 'pointer',
      flexDirection: 'column',
      '&:hover': {
        backgroundColor: '#eee',
      },
      minWidth: 250,
      width: '100%',
      maxWidth: t => `calc(25% - 2 * ${t.spacing(2)})`,
      m: 2,
    }} raised onClick={startExport}>
      <Box sx={{
        display: "flex",
        flexDirection: "column"
      }}>
        <CardContent sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center'
        }}>
          <Box>{icon}</Box>
          <Typography variant="subtitle1" color="textSecondary">
            {dataType}
          </Typography>
        </CardContent>
        <CardContent sx={{ flex: "1 0 auto" }}>
          <Typography component="h5" variant="h5" style={{ fontSize: '1.3rem' }}>
            {title}
          </Typography>
          <Typography variant="subtitle1" color="textSecondary" style={{ fontSize: '0.9rem' }}>
            {description}
          </Typography>
        </CardContent>
        <Box sx={{
          display: "flex",
          alignItems: "center",
          pl: 1,
          pb: 1,
        }}>
          <IconButton aria-label="download">
            <GetAppIcon sx={{ height: 28, widt: 28 }} />
          </IconButton>
        </Box>
      </Box>

    </Card>
  );
};
