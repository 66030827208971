import React, { useState, useEffect } from "react";
import {
  Button,
  Collapse,
  RadioGroup,
  FormControlLabel,
  Radio,
  TextField,
  InputAdornment,
  IconButton,
  Tooltip,
  Table,
  TableContainer,
  TableHead,
  TableCell,
  TableBody,
  TableRow,
  ButtonGroup,
  LinearProgress,
} from "@mui/material";
import { FileDropzone } from "./DropZone";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFileExport, faFileUpload, faTrash } from "@fortawesome/free-solid-svg-icons";
import { inject, observer } from "mobx-react";
import parseKatasterCSV from "common/parsers/csvKatasterParser";
import parseFile from "common/parsers/fileParser";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import { prettifyBigNumber } from "common/utility";

export const FileInput = inject("store")(
  observer(({ store, isDragActive }) => {
    const { t } = useTranslation();
    const [importType, setImportType] = useState();
    const [data, setData] = useState(null);
    const [loading, setLoading] = useState(false);
    const [inputOpen, setInputOpen] = useState(false);
    const {
      setPointsKoordinatesFromPointList,
      setHoldDetermination,
      setPointsKoordinatsFromGeoJson,
    } = store.kataster;

    /* When Data change check if Data is Koordinatenverzeichnis or classic CSV and change Displayed Options for Radio Buttons */
    useEffect(() => {
      if (!!data) {
        const match = data
          .trim()
          .match(/^\s*(((FP|MP|GP|SO);.*;.*;.*;.*;.*;.*;.*;.*;.*;.*;.*;.*;.*;.*;.*;.*\s*)|(\#.*\s*))+$/);
        if (!!match) {
          setImportType("ega");
        } else {
          setImportType(!importType || importType === "ega" ? "etrs" : importType);
        }
      } else {
        setImportType(null);
      }
    }, [data]);

    const handleChangeImport = (event) => setImportType(event.target.value);

    /* Read File data from Upload and save it in data variable */
    const handleLoadData = (data) => {
      const readFile = (file) => {
        console.log(file);
        if (file.type.includes("pdf")) {
          setLoading(true);
          if (data.error) return toast.warn(t("cadastre:input_stp_info"), { autoClose: 7000 });

          return store.kataster.getKvzFromStp(file).then((data) => {
            setLoading(false);
            if (data.error) return toast.warn(t("cadastre:input_stp_warning"), { autoClose: 6000 });
            setData((d) => (!!d ? d + "\n" + data : data));
            importEga(data);
          });
        }
        const fileReader = new FileReader();
        fileReader.onloadend = (e) => {
          const x = fileReader.result;
          setData((d) => (!!d ? d + "\n" + x : x));
        };
        fileReader.readAsText(file);
      };
      data.forEach((d) => readFile(d));
    };

    /* Read Data and Parse it to PointList Or GeoJson to add Data to Kataster Store */
    const readData = () => {
      if (importType === "ega") return importEga();
      return importCsv();
    };

    const importEga = (localData) => {
      setHoldDetermination(true);
      const [fpM, fpE, mpM, mpE, gpM, gpE, soM, soE] = parseKatasterCSV(localData ?? data);
      setPointsKoordinatesFromPointList("fp", fpM);
      setPointsKoordinatesFromPointList("fp", fpE);
      setPointsKoordinatesFromPointList("mp", mpM, false);
      setPointsKoordinatesFromPointList("mp", mpE, mpE.length > 0);
      setPointsKoordinatesFromPointList("gp", gpM, false);
      setPointsKoordinatesFromPointList("gp", gpE, gpE.length > 0);
      setPointsKoordinatesFromPointList("so", soM, false);
      setPointsKoordinatesFromPointList("so", soE, soE.length > 0);
      setHoldDetermination(false);
    };

    const importCsv = () => {
      let GeoJson;
      try {
        GeoJson = parseFile("csvega", data);
      } catch (err) {
        console.error(err);
        toast.info(err.message, { autoClose: 4000 });
        try {
          GeoJson = parseFile("csv", data, null, importType === "mgi");
        } catch (errCsv) {
          console.error(errCsv);
          toast.error(`CSV: ${errCsv.message}`, { autoClose: 6000 });
          return;
        }
      }
      setData(null);
      if (!!GeoJson.crs.coordType) {
        return setPointsKoordinatsFromGeoJson(GeoJson.crs.coordType, GeoJson);
      }
      return setPointsKoordinatsFromGeoJson(importType, GeoJson);
    };

    const isLoading =
      loading ||
      store.kataster.pointList.filter((p) => p.isPassPoint() && !p.isTransformationpoint).length > 4;
    return (
      <div>
        <FileDropzone
          loadData={handleLoadData}
          open={(isDragActive || inputOpen) && !isLoading}
          missingData={!data && !isLoading}
          handleClose={() => setInputOpen(false)}
        />
        <Collapse in={isLoading}>{isLoading && <LinearProgress />}</Collapse>
        <Collapse in={!!data && !isLoading}>
          {!!data && (
            <>
              <RadioGroup
                aria-label="anonymous"
                name="anonymous"
                value={importType}
                row
                disabled={!importType}
                onChange={handleChangeImport}
              >
                <FormControlLabel
                  value="ega"
                  disabled={importType !== "ega"}
                  control={<Radio color={"primary"} />}
                  label={t("cadastre:input_tab_upload_radio_group_label_ega")}
                />
                <FormControlLabel
                  value="etrs"
                  disabled={importType === "ega"}
                  control={<Radio color={"primary"} />}
                  label={t("cadastre:input_tab_upload_radio_group_label_etrs")}
                />
                <FormControlLabel
                  value="mgi"
                  disabled={importType === "ega"}
                  control={<Radio color={"primary"} />}
                  label={t("cadastre:input_tab_upload_radio_group_label_mgi")}
                />
              </RadioGroup>
              <TextField
                style={{ marginBottom: "1em" }}
                id="outlined-multiline-static"
                label={t("cadastre:input_tab_upload_input_label")}
                multiline
                minRows={8}
                fullWidth
                value={data}
                onChange={(e) => setData(e.target.value)}
                variant="outlined"
              />
              <div style={{ display: "flex", justifyContent: "flex-end" }}>
                <Tooltip title={t("cadastre:input_tab_upload_btn_trash_tooltip")}>
                  <IconButton onClick={() => setData(null)}>
                    <FontAwesomeIcon icon={faTrash} />
                  </IconButton>
                </Tooltip>
                <Tooltip title={t("cadastre:input_tab_upload_btn_add_tooltip")}>
                  <IconButton onClick={() => setInputOpen(true)}>
                    <FontAwesomeIcon icon={faFileUpload} />
                  </IconButton>
                </Tooltip>
                <Tooltip title={t("cadastre:input_tab_upload_btn_start_tooltip")}>
                  <Button variant={"contained"} color={"primary"} onClick={readData}>
                    <FontAwesomeIcon icon={faFileExport} /> {t("cadastre:input_tab_upload_btn_start")}
                  </Button>
                </Tooltip>
              </div>
            </>
          )}
        </Collapse>
        <Collapse in={store.kataster.pointList.length > 0 && !isLoading}>
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>{t("cadastre:input_tab_upload_table_header_type")}</TableCell>
                  <TableCell>{t("cadastre:input_tab_upload_table_header_name")}</TableCell>
                  <TableCell align="right">{t("cadastre:input_tab_upload_table_header_etrs")}</TableCell>
                  <TableCell align="right">{t("cadastre:input_tab_upload_table_header_mgi")}</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {store.kataster.pointList.map((p, i) => (
                  <TableRow key={p.punktnummer + i}>
                    <TableCell>{p.punktArt}</TableCell>
                    <TableCell>{p.punktnummer}</TableCell>
                    <TableCell align="right">
                      <div>{prettifyBigNumber(p.etrs89X)}</div>
                      <div>{prettifyBigNumber(p.etrs89Y)}</div>
                      <div>{prettifyBigNumber(p.etrs89Z)}</div>
                    </TableCell>
                    <TableCell align="right">
                      <div>{prettifyBigNumber(p.gkY)}</div>
                      <div>{prettifyBigNumber(p.gkX)}</div>
                      <div>{prettifyBigNumber(p.gkHoeheAdria)}</div>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Collapse>
      </div>
    );
  })
);
