import React from 'react';
import PropTypes from 'prop-types';

CheckCRSDisplay.propTypes = {
  crs: PropTypes.object.isRequired,
  handleClick: PropTypes.func.isRequired
}
export default function CheckCRSDisplay({crs, handleClick}) {
  return <span style={{cursor: 'pointer'}} onClick={handleClick}>
            <span className={crs.properties.frame ? 'set' : 'notset' }>F</span>
            <span className={crs.properties.ellipsoid ? 'set' : 'notset' }>E</span>
            <span className={crs.properties.projection ? 'set' : 'notset' }>P</span>
          </span>
}