import { getCoordType } from 'common/utility';
import { AUSTRIAN_CRS } from 'common/austrianCrsList';

export const isSameSystem = (sys1,sys2) => {
  return sys1.split('_')[0] === sys2.split('_')[0];
}

export const checkIfTransformReady = ({sourceCRS, targetCRS, sourceHeight, targetHeight, transformationMethod}) => {
  if(sourceCRS === '' && targetCRS === '') return false
  else if(!isSameSystem(sourceCRS, targetCRS) && transformationMethod === '') return false;
  else if(getCoordType(AUSTRIAN_CRS[sourceCRS]) !== 'geocentric' && sourceHeight === '') return false;
  else if(getCoordType(AUSTRIAN_CRS[targetCRS]) !== 'geocentric' && targetHeight === '') return false;
  return true;
}

export const getSelection = ({sourceCRS, targetCRS, crs, sourceHeight, targetHeight, transformationMethod}) => {
  let list = [];
  const isSameSystemBool = isSameSystem(sourceCRS,targetCRS);
  if(isSameSystemBool) list.push('same');
  if(!isSameSystemBool && transformationMethod === '') list.push('missing');
  if(sourceCRS !== '' && getCoordType(crs[sourceCRS]) !== 'geocentric' && sourceHeight === '') list.push('sourceHeight');
  if(targetCRS !== '' && getCoordType(crs[targetCRS]) !== 'geocentric' && targetHeight === '') list.push('targetHeight');
  if(list.length <= 0) list.push('ready');
  return list;
}