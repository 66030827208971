import React, { Component } from 'react';
import PropTypes from 'prop-types';

import Table from '@mui/material/Table';

import GeometryTableHeader from './GeometryTableHeader';
import GeometryTableBody from './GeometryTableBody';

import { compareCRS } from '../CRS/compareCRS';
import BevModal from '../BevModal';
import BevStandardSiteMap from '../BevStandardSiteMap'
import { memorySizeOf } from '../../shared/memorySizeOf';
import BevPagination from '../BevPagination';
import { DEFAULT_PAGE_SIZE } from '../../common/constants.js';
import { withTranslation } from 'react-i18next';

class GeometryTable extends Component {
  static propTypes = {
    geometryList: PropTypes.array.isRequired,
    sourceCRS: PropTypes.object,
    clickTooltip: PropTypes.string.isRequired,
    showTransformButton: PropTypes.bool,
    showDataModal: PropTypes.bool,
    handleClickGeometry: PropTypes.func.isRequired,
    hideName: PropTypes.bool,
    hideGeometriesCount: PropTypes.bool,
    hideFileSize: PropTypes.bool,
    hideFileType: PropTypes.bool,
    hideCRS: PropTypes.bool,
    useCompare: PropTypes.bool,
    transformButtonText: PropTypes.string,
  }
  static defaultProps = {
    showCRSCheck: false,
    showBtns: false,
    hideName: false,
    hideGeometriesCount: false,
    hideFileSize: false,
    hideFileType: false,
    hideCRS: false,
    pageSize: DEFAULT_PAGE_SIZE,
    useCompare: true,
  }
  state = {
    selectedGeometry: null,
    isModalOpen: false,
    offset:0,
    sort: {
      name: 0,
      filetype: 0,
      fileSize: 0,
      geometry: 0,
    }
  }

  openModal = (g) => {
    this.setState({
      selectedGeometry: g,
      isModalOpen: true,
    })
  }
  closeModal = () => {
    this.setState({
      selectedGeometry: null,
      isModalOpen: false,
    })
  }

  getSortedBy = () =>{
    const sort = this.state.sort;
    if(sort.name !== 0) return 'name';
    else if(sort.filetype !== 0) return 'filetype';
    else if(sort.fileSize !== 0) return 'fileSize';
    else if(sort.geometry !== 0) return 'geometry';
    return 'name';
  }

  compareGeom = (a,b) => {
    const sortedBy = this.getSortedBy();
    let aObj, bObj;
    if(sortedBy === 'name' || sortedBy === 'filetype'){  
      aObj = a[sortedBy].toUpperCase();
      bObj = b[sortedBy].toUpperCase();
    }else if(sortedBy === 'geometry'){
      aObj = a.features.length;
      bObj = b.features.length;
    }else if(sortedBy === 'fileSize'){
      aObj = memorySizeOf(a);
      bObj = memorySizeOf(b);
    }
    if(aObj > bObj) return this.state.sort[sortedBy];
    if(aObj < bObj) return -1 * this.state.sort[sortedBy];
    return 0;

  }

  changeSort = (attr) => {
    const sort = this.state.sort[attr];
    this.setState((prevState) => { return {sort: { name: 0, filetype: 0, fileSize: 0, geometry: 0 }}});
    if(sort === 0){
      this.setState((prevState) => { return {sort: {...prevState.sort, [attr]: 1}}});
    }else if(sort > 0){
      this.setState((prevState) => { return {sort: {...prevState.sort, [attr]: -1}}});
    }else{
      this.setState((prevState) => { return {sort: {...prevState.sort, [attr]: 0}}});
    }
  }

  getIndexOfElment = (g) => {
    const {geometryList} = this.props;
    return geometryList.findIndex((element) => {
      return element.name === g.name &&
              compareCRS(element.crs.properties, g.crs.properties) &&
              element.features.length === g.features.length &&
              memorySizeOf(element) === memorySizeOf(g) &&
              element.filetype === g.filetype;
    })
  } // TODO CheckIf Polyfill is needed.

  handleChangeOffset = (offset) => {
    this.setState({offset});
  }

  render(){
    const { geometryList,sourceCRS, clickTooltip, pageSize, useCompare, transformButtonText } = this.props; //GeometryList & sourceCRS vielleicht besser aus dem Store
    const { showTransformButton, showDataModal } = this.props;
    const { hideName, hideGeometriesCount, hideFileSize, hideFileType, hideCRS } = this.props;
    const { handleClickGeometry, t } = this.props;
    const { sort,  offset } = this.state;

    return (<div style={{overflow: 'auto'}}>
      <BevPagination setPagination={(offset) => this.handleChangeOffset(offset)} stepSize={pageSize} length={geometryList.length}>
        <Table>
          <GeometryTableHeader sort={sort} showCRSCheck={showTransformButton} showBtns={showDataModal} changeSort={this.changeSort}/>
          <GeometryTableBody geometryList={geometryList}
                              sourceCRS={sourceCRS}
                              clickTooltip={clickTooltip}
                              showTransformButton={showTransformButton}
                              showDataModal={showDataModal}
                              handleClickGeometry={handleClickGeometry}
                              handleOpenModal={this.openModal} 
                              compareGeom={this.compareGeom}
                              getElementId={this.getIndexOfElment}
                              hideName={hideName}
                              hideGeometriesCount={hideGeometriesCount}
                              hideFileSize={hideFileSize}
                              hideFileType={hideFileType}
                              hideCRS={hideCRS}
                              offset={offset}
                              pageSize={pageSize}
                              useCompare={useCompare}
                              transformButtonText={transformButtonText}/>
        </Table>
      </BevPagination>
      <BevModal
        open={this.state.isModalOpen}
        onClose={this.closeModal}
        title={t('point_source_geometry_table_modal_title')}
        width={800}>
        <BevStandardSiteMap
          geometry={this.state.selectedGeometry}
          pageSize={DEFAULT_PAGE_SIZE}
          ></BevStandardSiteMap>
       </BevModal>
    </div>
    )
  }
}

export default withTranslation()(GeometryTable);