import React from 'react';
import {Card, CardContent, useTheme} from '@mui/material';

const TitleCard = ({title, children}) => {
  const theme = useTheme();
  return ( <Card style={{ marginBottom: '20px' }}>
      <CardContent>
        <h4 style={{ color: theme.palette.primary.main }}>{title}</h4>
        {children}
      </CardContent>
    </Card>
  )
}

export default TitleCard;