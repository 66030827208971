import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { ExpandableListElement } from './geometriesListHelper';
import { makePoint } from '../geojson_geometries';
import Polygon from './polygon';
import BevPagination from '../../components/BevPagination';
import { DEFAULT_PAGE_SIZE } from '../constants';

const classes = {
  subdivStyle: {
    paddingLeft: '40px',
  },
}

/**
   * feature: is the Geometry Object, no default, is required
   * pageSize: is the number of elements that will be displayed on one Pagination Page, default 3, is not required
 */
export default class MultiPolygon extends Component {
  static propTypes = {
    feature: PropTypes.object.isRequired,
    pageSize: PropTypes.number,
  }
  static defaultProps = {
    pageSize:DEFAULT_PAGE_SIZE
  }
  state = {
    showPoints: false,
    offset:0
  }

  togglePoints = (event) => {
    this.setState({ showPoints: !this.state.showPoints });
  }

  handleChangeOffset = (offset) => {
    this.setState({offset});
  }

  render() {
    const {feature, pageSize, color} = this.props;

    return (
      <div>
        <ExpandableListElement typeName="MP" 
          primary={feature.properties.name} 
          secondary={"MultiPolygon "} 
          style={{backgroundColor: color,cursor: 'pointer', marginRight: '10px' }} 
          toggleList={this.togglePoints} 
          showList={this.state.showPoints}/>

        {this.state.showPoints && 
          <div style={classes.subdivStyle}>
            <BevPagination setPagination={this.handleChangeOffset} stepSize={pageSize} length={feature.geometry.coordinates.length}>
              {feature.geometry.coordinates.map((g, i) =>(
                (this.state.offset <= i && i < this.state.offset+pageSize) &&
                  <Polygon key={i} feature={makePoint("poly", "Polygon", g, feature.geometry.velocities ? feature.geometry.velocities[i] : undefined )} /> 
              ))}
            </BevPagination>
          </div>}
      </div>
    );
  }
}
