import * as actionTypes from '../actions/actionTypes';
import { updateObject } from 'common/utility';

export const initialState = {
    progress: false,
    showSolution: true,
    parameterExpand: false,
    pointExpand: false,
    solutionExpand: false,
    isCookieAkzepted: false,
    referenceSystems: [
    {"type":"BEV","name":"blank","properties":{"frame":"","ellipsoid":"","prime_meridian":"","epoch":"2002.56","projection":""}},
    {"type":"BEV","name":"CRS 1","properties":{"ellipsoid":"EPSG::7030","frame":"103","prime_meridian":"EPSG::8901","epoch":2002.56}},
    {"type":"BEV","name":"CRS 2","properties":{"ellipsoid":"EPSG::7030","frame":"113","prime_meridian":"EPSG::8909","epoch":2002.56,"projection":"BEV::10034"}},
    {"type":"BEV","name":"ITRF 2000 (2000.0)","properties":{"frame":"110","ellipsoid":"","prime_meridian":"","projection":"","epoch":2000.00,"name":"ITRF 2000 (2000.0)"}},
    {"type":"BEV","name":"ITRF 2014 (2000.0)","properties":{"frame":"113","ellipsoid":"","prime_meridian":"","projection":"","epoch":2000.00,"name":"ITRF 2014 (2000.0)"}},{"type":"BEV","name":"WGS84 - Greenwich","properties":{"frame":"302","ellipsoid":"EPSG::7030","prime_meridian":"EPSG::8901","projection":"","epoch":2002.56,"name":"WGS84 - Greenwich"}},{"type":"BEV","name":"MGI - Austria GK West -EPSG::31254","properties":{"frame":"301","ellipsoid":"EPSG::7004","prime_meridian":"EPSG::8901","projection":"EPSG::18004","epoch":2002.56,"name":"MGI - Austria GK West -EPSG::31254"}},
    {"type":"BEV","name":"MGI - Austria GK Central - EPSG::31255","properties":{"frame":"301","ellipsoid":"EPSG::7004","prime_meridian":"EPSG::8901","projection":"EPSG::18005","epoch":2002.56,"name":"MGI - Austria GK Central - EPSG::31255"}},
    {"type":"BEV","name":"MGI - AUSTRIA GK East - EPSG::31256","properties":{"frame":"301","ellipsoid":"EPSG::7004","prime_meridian":"EPSG::8901","projection":"EPSG::18006","epoch":2002.56,"name":"MGI - AUSTRIA GK East - EPSG::31256"}},
    {"type":"BEV","name":"ETRS - UTM Zone 32N - EPSG::25832","properties":{"frame":"209","ellipsoid":"EPSG::7019","prime_meridian":"EPSG::8901","projection":"EPSG::16032","epoch":2002.56,"name":"ETRS - UTM Zone 32N - EPSG::25832"}},
    {"type":"BEV","name":"ETRS89 - UTM Zone 33N - ESPG::25833","properties":{"frame":"209","ellipsoid":"EPSG::7019","prime_meridian":"EPSG::8901","projection":"EPSG::16033","epoch":2002.56,"name":"ETRS89 - UTM Zone 33N - ESPG::25833"}},
    {"type":"BEV","name":"WGS 84 - UTM Zone 32N - EPSG::32632","properties":{"frame":"302","ellipsoid":"EPSG::7030","prime_meridian":"EPSG::8901","projection":"EPSG::16032","epoch":2002.56,"name":"WGS 84 - UTM Zone 32N - EPSG::32632"}},
    {"type":"BEV","name":"WGS 84 - UTM Zone 33N - EPSG::32633","properties":{"frame":"302","ellipsoid":"EPSG::7030","prime_meridian":"EPSG::8901","projection":"EPSG::16033","epoch":2002.56,"name":"WGS 84 - UTM Zone 33N - EPSG::32633"}}
    ],
    trafos: [
      {"name":"trafo1","sourceCRS": {type: 'BEV', name:'', properties: {"frame":"201","projection":"","heightsystem":"ellipsoid","ellipsoid":"EPSG::7019","prime_meridian":"EPSG::8901","epoch":2002.56}},"targetCRS":{type: 'BEV', name:'', properties: {"frame":"301","projection":"BEV::10034","heightsystem":"usage","ellipsoid":"EPSG::7004","prime_meridian":"EPSG::8909","epoch":2002.56}}},
      {"name":"etrsTowgs84","sourceCRS": {type: 'BEV', name:'', properties: {"frame":"201","projection":"","heightsystem":"ellipsoid","ellipsoid":"EPSG::7019","prime_meridian":"EPSG::8909","epoch":"2012.56"}},"targetCRS":{type: 'BEV', name:'', properties: {"frame":"302","projection":"BEV::10034","heightsystem":"usage","ellipsoid":"EPSG::7030","prime_meridian":"EPSG::8903","epoch":"2018.56"}}}
    ],
    cg_open: false,
    sg_show: true,
}

const baseAddCRS = (state, action) => {
    const rs = state.referenceSystems;
    rs.push(action.crs);
    return updateObject( state, {
        referenceSystems: [
          ...state.referenceSystems
        ]
      });
}

const baseAddTrafo = (state, action) => {
    const tra = state.trafos;
    tra.push(action.trafo);
    return updateObject( state, {
        trafos: [
          ...state.trafos
        ]
      });
}



const baseSetSolution = (state, action) => {
    return updateObject( state, {
        showSolution: action.showSolution
    });
}

const baseChangeParameterExpand = (state, action) => {
    return updateObject( state, {
        parameterExpand: action.parameterExpand
    });
}

const baseChangePointExpand = (state, action) => {
    return updateObject( state, {
        pointExpand: action.pointExpand
    });
}

const baseChangeSolutionExpand = (state, action) => {
    return updateObject( state, {
        solutionExpand: action.solutionExpand
    });
}



const reducer = ( state = initialState, action) => {
    switch ( action.type ) {
        case actionTypes.BASE_ADD_CRS: return baseAddCRS( state, action );
        case actionTypes.BASE_ADD_TRAFO: return baseAddTrafo( state, action );
        case actionTypes.BASE_SET_SOLUTION: return baseSetSolution( state, action );
        case actionTypes.BASE_CHANGE_PARAMETER_EXPAND: return baseChangeParameterExpand( state, action );
        case actionTypes.BASE_CHANGE_POINT_EXPAND: return baseChangePointExpand( state, action );
        case actionTypes.BASE_CHANGE_SOLUTION_EXPAND: return baseChangeSolutionExpand( state, action );
        case actionTypes.BASE_SET_PROGRESS: return updateObject( state, { progress: action.value } );
        case actionTypes.BASE_TOGGLE_CG_OPEN: return updateObject( state, { cg_open: !state.cg_open } );
        case actionTypes.BASE_SET_COOKIE: return updateObject ( state, {isCookieAkzepted: !state.isCookieAkzepted })
        default:
            return state;
    }
}

export default reducer;
