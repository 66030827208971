export const advanced = {
    "advanced_title_card" : "Offers the professional user multiple settings and maximum flexibility for source and target systems for transformation. Different coordinate systems, ellipsoids and projections can be set individually. An epoch transformation can also be carried out at known coordinate velocities. ",
    "advanced_title_card_title" : "Advanced Mode",
    "advanced_load_save_dialog_save" : "Save Transformation",
    "advanced_load_save_dialog_title" : "Transformation",
    "advanced_load_save_dialog_label" : "Transformation Name",
    "advanced_crs_picker_source" : "Source CRS",
    "advanced_crs_picker_target" : "Target CRS",
    "advanced_coord_input_title" : "Points to transform",
    "advanced_select_trafo_title" : "Select Transformation",
    "advanced_select_trafo_cancel" : "Cancel",
    
}