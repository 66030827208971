export const container = {
    "container_crs_frame" : "Frame",
    "container_crs_epoch" : "Epoch",
    "container_crs_epoch_edit" : "Edit epoch",
    "container_crs_epoch_choos" : "Choose epoch",
    "container_crs_ellipsoid" : "Ellipsoid",
    "container_crs_meridian" : "PrimeMeridian",
    "container_crs_projection" : "Projection",
    "container_crs_height" : "HeightSystem",
    "container_crs_height_system" : "Height System",
    "container_crs_trans_method" : "Transformation Method",
    "container_crs_select_text" : "Select CRS",
    "container_crs_save_title" : "Save CRS format as",

    "point_source_geometry_header_name" : "Name" ,
    "point_source_geometry_header_geometries" : "# Geometries" ,
    "point_source_geometry_header_file_size" : "FileSize" ,
    "point_source_geometry_header_file_type" : "FileType" ,
    "point_source_geometry_header_crs" : "CRS" ,
    "point_source_geometry_body_tooltip" : "The chosen CRS does not match the CRS of the dataset." ,
    "point_source_geometry_body_tooltip_point" : "Open point manager" ,
    "point_source_geometry_body_tooltip_geometrie" : "View geometry data" ,
    "point_source_geometry_table_modal_title" : "Geometry data" ,

    "point_import_valid_error_length" : "Only 2 files can be loaded when importing CRD,CRD- and VEL-Files" ,
    "point_import_valid_error_crd" : "First file must be a CRD file." ,
    "point_import_valid_error_name" : "The files have different names. Make sure that they really do belong to each other." ,
    "point_import_valid_error_vel" : "The second file must be a VEL file." ,

    "point_import_alert" : "2D has been selected, the z coordinates will be ingnored." ,
    "point_import_dialog_title" : "Choose file name" ,

    "point_import_buttons_start_calc" : "Calculate" ,
    "point_import_buttons_select_points" : "Apply points" ,
    "point_import_buttons_save" : "Save points" ,
    "point_import_buttons_look_at" : "View geometry" ,
    "point_import_buttons_load" : "Load example" ,
    "point_import_buttons_cancle" : "Cancel" ,

    "point_import_file_load_btn" : "Load a {{inputformat}} file",
    "point_import_geometry_title" : "Geometry data",
    "point_import_save_dialog_title" : "Name of file",

    "point_import_type_selection_title" : "Choose FileFormat",
    "point_import_type_selection_geojson" : "GeoJSON",
    "point_import_type_selection_gpx" : "GPX",
    "point_import_type_selection_kml" : "KML",
    "point_import_type_selection_csv" : "CSV",
    "point_import_type_selection_csvega" : "CSVEGA",
    "point_import_type_selection_crd" : "CRD",

    "point_export_export_btn" : "PDF protocol",
    "point_export_file_format" : "Choose file format",
    "point_export_btn_copy" : "Copy",
    "point_export_btn_save_file" : "Save file",
    "point_export_alert_info_one" : "Only points will be exported. All other geometries will be skipped.",
    "point_export_alert_info_two" : "Standard CRS for GPX and KML does not match with chosen CRS from the selected geometries. Please convert files correctly.",
    
    "site_map_dialog_file_name_title" : "Choose File Name",
    "site_map_toaster_success" : "Geometry: {{fileName}} was saved successfully",
    "site_map_tootltip_save_geo" : "Save geometry",
    "site_map_tootltip_to_manager" : "Open point manager",

    "site_map_tablist_tab_geome":"Geometries",
    "site_map_tablist_tab_geojson":"GeoJSON",
    "site_map_tablist_tab_geometext":"Geometry Text",
    "site_map_tablist_tab_map":"Map",
    "site_map_tablist_tab_export":"Export",

    "save_dialog_save" : "Save",
    "save_dialog_cancle" : "Cancel",

    "solution_card_title" : "Solution",

    "bev_dialog_ok": "OK",
    "bev_dialog_cancle": "Cancel",
    "bev_dialog_save_as": "{{title}} save as",

    "api_banner_text" : "Sorry, the API server is unavailable right now",

    "api_modal_title" :"Insert API Key",
    "api_modal_label" :"API Key",

    "extended_title_card_load" : "Load an example",
    "extended_title_card_restart" : "Reset values",
    "extended_title_card_wiki" : "More info",
    
    "single_point_start_btn" : "calculate",
    "single_point_geographic_label_x" : "X-Koordinate",
    "single_point_geographic_placeholder_x" : "X-Koordinate [m]",
    "single_point_geographic_label_y" : "Y-Koordinate",
    "single_point_geographic_placeholder_y" : "Y-Koordinate [m]",
    "single_point_geographic_label_z" : "Z-Koordinate",
    "single_point_geographic_placeholder_z" : "Z-Koordinate [m]",
    "single_point_geographic_label_vx" : "v_X",
    "single_point_geographic_placeholder_vx" : "Geschwindigkeit X-Richtung [m/Jahr]",
    "single_point_geographic_label_vy" : "v_Y",
    "single_point_geographic_placeholder_vy" : "Geschwindigkeit Y-Richtung [m/Jahr]",
    "single_point_geographic_label_vz" : "v_Z",
    "single_point_geographic_placeholder_vz" : "Geschwindigkeit Z-Richtung [m/Jahr]",

    "single_point_geocentric_label_x" : "&lambda;-Koordinate",
    "single_point_geocentric_placeholder_x" : "lam-Koordinate [°]",
    "single_point_geocentric_label_y" : "&phi;-Koordinate",
    "single_point_geocentric_placeholder_y" : "phi-Koordinate [°]",
    "single_point_geocentric_label_z" : "Höhe",
    "single_point_geocentric_placeholder_z" : "Höhe [m]",
    "single_point_geocentric_label_vx" : "v_&lambda;",
    "single_point_geocentric_placeholder_vx" : "lam-Geschwindigkeit [°]",
    "single_point_geocentric_label_vy" : "v_&phi;",
    "single_point_geocentric_placeholder_vy" : "phi-Geschwindigkeit [°]",
    "single_point_geocentric_label_vz" : "v_Höhe",
    "single_point_geocentric_placeholder_vz" : "Höhengeschwindigkeit [°]",

    "single_point_projected_label_x" : "y (east)",
    "single_point_projected_placeholder_x" : "Ost-Komponente [m]",
    "single_point_projected_label_y" : "x (north)",
    "single_point_projected_placeholder_y" : "Nord-Komponente [m]",
    "single_point_projected_label_z" : "Höhe",
    "single_point_projected_placeholder_z" : "Höhe [m]",
    "single_point_projected_label_vx" : "v_y (east)",
    "single_point_projected_placeholder_vx" : "Ost-Geschwindigkeit [m/Jahr]",
    "single_point_projected_label_vy" : "v_x (north)",
    "single_point_projected_placeholder_vy" : "Nord-Geschwindigkeit [m/Jahr]",
    "single_point_projected_label_vz" : "v_Höhe",
    "single_point_projected_placeholder_vz" : "Höhengeschwindigkeit [°]",
    
    "import_example_field_title": "Example",
    "import_example_field_btn_load": "load example ",
}