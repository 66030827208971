export function compareCRS(f,g) {
  let retr = false;
  if(!f || !f.frame){
    console.error("CompareCRS, missing Geometrie Data to Compare (First Parameter)");
    return true;
  }else if(!g){
    console.error("CompareCRS, missing Geometrie Data to Compare (Second Parameter)");
    return true;
  }
  if (f.frame === g.frame && f.epoch === g.epoch && f.ellipsoid === g.ellipsoid && f.prime_meridian === g.prime_meridian && f.projection === g.projection) {
    retr = true;
  }
  return retr
}

export function compareWithKmlCrs(crs){
  return crs.frame === '302' && crs.ellipsoid ==='EPSG::7030' && crs.prime_meridian === 'EPSG::8901'
}
