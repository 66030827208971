export const container = {
    "container_crs_frame" : "Referenzrahmen",
    "container_crs_epoch" : "Epoche",
    "container_crs_epoch_edit" : "Epoche bearbeiten",
    "container_crs_epoch_choos" : "Epoche auswählen",
    "container_crs_ellipsoid" : "Ellipsoid",
    "container_crs_meridian" : "Bezugsmeridian",
    "container_crs_projection" : "Projektion",
    "container_crs_height" : "Höhensystem",
    "container_crs_height_system" : "Höhensystem",
    "container_crs_trans_method" : "Transformationsmethode",
    "container_crs_select_text" : "CRS auswählen",
    "container_crs_save_title" : "CRS speichern als",

    "point_source_geometry_header_name" : "Name" ,
    "point_source_geometry_header_geometries" : "# Geometrien" ,
    "point_source_geometry_header_file_size" : "Dateigröße" ,
    "point_source_geometry_header_file_type" : "Dateiart" ,
    "point_source_geometry_header_crs" : "CRS" ,
    "point_source_geometry_body_tooltip" : "Das gewählte CRS stimmt nicht mit dem CRS des Datensatzes überein." ,
    "point_source_geometry_body_tooltip_point" : "Zum Punktspeicher" ,
    "point_source_geometry_body_tooltip_geometrie" : "Geometriedaten visualisieren",
    "point_source_geometry_table_modal_title" : "Geometriedaten",

    "point_import_valid_error_length" : " Beim Import von CRD/VEL dürfen maximal 2 Dateien ausgewählt werden" ,
    "point_import_valid_error_crd" : "Die erste Datei muss eine CRD-Datei sein" ,
    "point_import_valid_error_name" : "Die beiden Dateien haben unteschiedliche Bezeichnungen, bitte achten Sie darauf, dass die Dateien auch wirklich zusammengehören" ,
    "point_import_valid_error_vel" : "Die zweite Datei muss eine VEL-Datei sein",

    "point_import_alert" : "Es ist 2D ausgewählt, die Z-Koordinaten werden beim berechnen verworfen." ,
    "point_import_dialog_title" : "Dateinamen wählen" ,

    "point_import_buttons_start_calc" : "Berechnung starten" ,
    "point_import_buttons_select_points" : "Punkte übernehmen" ,
    "point_import_buttons_save" : "Punkte speichern" ,
    "point_import_buttons_look_at" : "Geometriedaten visualisieren" ,
    "point_import_buttons_load" : "Beispiel laden" ,
    "point_import_buttons_cancle" : "Abbrechen" ,

    "point_import_file_load_btn" : "Lade eine {{inputformat}} Datei",
    "point_import_geometry_title" : "Geometriedaten",
    "point_import_save_dialog_title" : "Dateiname",

    "point_import_type_selection_title" : "FileFormat auswählen",
    "point_import_type_selection_geojson" : "GeoJSON",
    "point_import_type_selection_gpx" : "GPX",
    "point_import_type_selection_kml" : "KML",
    "point_import_type_selection_csv" : "CSV",
    "point_import_type_selection_csvega" : "CSVEGA",
    "point_import_type_selection_crd" : "CRD",

    "point_export_export_btn" : "PDF-Transformationsprotokoll",
    "point_export_file_format" : "Dateiformat wählen",
    "point_export_btn_copy" : "Kopieren",
    "point_export_btn_save_file" : "in Datei speichern",
    "point_export_alert_info_one" : "Es werden nur Punkte exportiert. Alle anderen Geometrien werden beim CSV-Export übersprungen.",
    "point_export_alert_info_two" : "Standard CRS für GPX und KML stimmt nicht mit dem CRS der ausgewählten Geometrie überein. Bitte Daten zuerst richtig transformieren.",
    
    "site_map_dialog_file_name_title" : "File Name wählen",
    "site_map_toaster_success" : "Geometrie: {{fileName}} wurde erfolgreich gespeichert",
    "site_map_tootltip_save_geo" : "Geometrie speichern",
    "site_map_tootltip_to_manager" : "Zum Punktspeicher",

    "site_map_tablist_tab_geome":"Geometrien",
    "site_map_tablist_tab_geojson":"GeoJSON",
    "site_map_tablist_tab_geometext":"GeometryText",
    "site_map_tablist_tab_map":"Karte",
    "site_map_tablist_tab_export":"Export",

    "save_dialog_save" : "Speichern",
    "save_dialog_cancle" : "Abbrechen",

    "solution_card_title" : "Ergebnis",

    "bev_dialog_ok": "OK",
    "bev_dialog_cancle": "Abbrechen",
    "bev_dialog_save_as": "{{title}} speichern unter",

    "api_banner_text" : "Der API-Server ist zurzeit nicht erreichbar!",

    "api_modal_title" :"API Key eingeben",
    "api_modal_label" :"API Key",

    "extended_title_card_load" : "Lade ein Beispiel",
    "extended_title_card_restart" : "Berechnung neustarten",
    "extended_title_card_wiki" : "Weitere Informationen",


    "single_point_start_btn" : "Berechnung starten",
    "single_point_geographic_label_x" : "X-Koordinate",
    "single_point_geographic_placeholder_x" : "X-Koordinate [m]",
    "single_point_geographic_label_y" : "Y-Koordinate",
    "single_point_geographic_placeholder_y" : "Y-Koordinate [m]",
    "single_point_geographic_label_z" : "Z-Koordinate",
    "single_point_geographic_placeholder_z" : "Z-Koordinate [m]",
    "single_point_geographic_label_vx" : "v_X",
    "single_point_geographic_placeholder_vx" : "Geschwindigkeit X-Richtung [m/Jahr]",
    "single_point_geographic_label_vy" : "v_Y",
    "single_point_geographic_placeholder_vy" : "Geschwindigkeit Y-Richtung [m/Jahr]",
    "single_point_geographic_label_vz" : "v_Z",
    "single_point_geographic_placeholder_vz" : "Geschwindigkeit Z-Richtung [m/Jahr]",

    "single_point_geocentric_label_x" : "&lambda;-Koordinate",
    "single_point_geocentric_placeholder_x" : "lam-Koordinate [°]",
    "single_point_geocentric_label_y" : "&phi;-Koordinate",
    "single_point_geocentric_placeholder_y" : "phi-Koordinate [°]",
    "single_point_geocentric_label_z" : "Höhe",
    "single_point_geocentric_placeholder_z" : "Höhe [m]",
    "single_point_geocentric_label_vx" : "v_&lambda;",
    "single_point_geocentric_placeholder_vx" : "lam-Geschwindigkeit [°]",
    "single_point_geocentric_label_vy" : "v_&phi;",
    "single_point_geocentric_placeholder_vy" : "phi-Geschwindigkeit [°]",
    "single_point_geocentric_label_vz" : "v_Höhe",
    "single_point_geocentric_placeholder_vz" : "Höhengeschwindigkeit [°]",

    "single_point_projected_label_x" : "y (east)",
    "single_point_projected_placeholder_x" : "Ost-Komponente [m]",
    "single_point_projected_label_y" : "x (north)",
    "single_point_projected_placeholder_y" : "Nord-Komponente [m]",
    "single_point_projected_label_z" : "Höhe",
    "single_point_projected_placeholder_z" : "Höhe [m]",
    "single_point_projected_label_vx" : "v_y (east)",
    "single_point_projected_placeholder_vx" : "Ost-Geschwindigkeit [m/Jahr]",
    "single_point_projected_label_vy" : "v_x (north)",
    "single_point_projected_placeholder_vy" : "Nord-Geschwindigkeit [m/Jahr]",
    "single_point_projected_label_vz" : "v_Höhe",
    "single_point_projected_placeholder_vz" : "Höhengeschwindigkeit [°]",

    "import_example_field_title": "Beispiel",
    "import_example_field_btn_load": "Beispiel laden",
    
}