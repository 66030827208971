import React, { Component } from 'react';
import { Link, Redirect, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import * as actions from 'store/actions';
import { DEFAULT_PAGE_SIZE } from 'common/constants.js';
import { Grid, Breadcrumbs, Typography  } from '@mui/material';

import Divider from '@mui/material/Divider';
import PointManager from './PointManager';
import Tooltip from '@mui/material/Tooltip';
import TitleCard from 'components/TitleCard';
import ImportFiles from 'components/points/import/import';
import IconButton from '@mui/material/IconButton';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import GeometryTable from 'components/point-source/GeometryTable';
import { BASE_URL } from 'common/constants.js';

import { withTranslation } from 'react-i18next';


const mapStateToProps = state => {
  return {
    geometryList: state.points.geometryList,
    fileId: state.points.fileId,
    referenceSystems: state.base.referenceSystems,
  }
}

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    updateGeometryFile: (file, id) => dispatch(actions.points.updateGeometryFile(file, id)),
    removeFile: (event) => dispatch(actions.points.removeFile(event)),
    setFileId: (event) => dispatch(actions.points.setFileId(event)),
  }
}

class GeometryManager extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      addFile: false,
      addGeometryType: false,
      showCRS: false,
      showExport: false,
      transformTo: false,
      geomManager: true,
      doWhat: 'list',
      redirect: false,
      openGeomenty: false,
    }
  }

  componentDidMount() {
    this.setState({ loading: false });
    if (this.props.match.params.id) {
      const id = this.props.match.params.id;
      if (!isNaN(id) && this.props.geometryList.length > parseInt(id, 10)) {
        this.setState({
          geomManager: false,
        });
        this.props.setFileId(id);
      } else {
        this.setState({
          redirect: true
        })
      }
    }
  }

  handleDoWhat = (what) => {
    this.setState({ doWhat: what });
  }

  closeAddGeom = (event) => {
    this.setState({
      addGeom: null,
      addGeometryType: event.target.getAttribute('value'),
    });
  }

  deleteGeometryList = (event, id) => {
    this.props.removeFile(id);
  }

  setfileId = (event, id) => {
    event.preventDefault();
    this.setState({
      geomManager: false,
    });
    this.props.setFileId(id);
  }

  handleShowCRS = () => {
    this.setState({ showCRS: !this.state.showCRS, });
  }

  goBack = () => {
    this.setState({ doWhat: 'list' });
  }

  onCRSChange = (event, name) => {
    let g = this.props.geometryList[this.props.fileId]
    g.crs[name] = event.target.value;
    this.props.updateGeometryFile(g, this.props.fileId);
  }

  onCRSChangeAll = (value) => {
    let g = this.props.geometryList[this.props.fileId]
    g.crs = this.props.referenceSystems[value];
    this.props.updateGeometryFile(g, this.props.fileId);
  }

  getstyle = (i) => {
    if (this.props.fileId[0] === i) {
      return { fontWeight: 'bold', color: 'blue' }
    } else {
      return {}
    }
  }

  toggleTransformTo = () => {
    this.setState({ transformTo: !this.state.transformTo });
  }

  setgeomManager = (e) => {
    e.preventDefault();
    this.setState({ geomManager: true, });
  }

  handleRedirect = () => {
    this.props.setFileId(this.props.geometryList.length - 1);
    this.setState(prevState => { return { openGeomenty: true } });
  }
  handleRedirectBack = () => {
    this.props.history.push(BASE_URL + `geommanager`);
  }

  handleClickGeometry = (i, e) => {
    this.props.setFileId(i);
    this.props.history.push(BASE_URL + `geommanager/` + i);
  }

  handleRedirectToLast = () => {
    const i = this.props.geometryList.length - 1;
    this.props.setFileId(i);
    this.props.history.push(BASE_URL + `geommanager/` + i);
  }

  handleLoadGeometry = () => {
    console.log('test');
  }

  render() {
    const { fileId, t } = this.props;
    const { loading, redirect, openGeomenty } = this.state;

    if (redirect) return <Redirect to={BASE_URL + 'geommanager'} />
    if (openGeomenty) return <Redirect to={BASE_URL + 'geommanager/' + fileId} />

    return (<div>
      {loading ? '' :
        <div>
          <TitleCard title={t("geometry_manager_title")}>
            <p>{t("geometry_manager_desc")}</p>
          </TitleCard>
          <Breadcrumbs aria-label="breadcrumb">
            <Link to={BASE_URL + "geommanager"}>{t("geometry_manager_title2")}</Link>
            {!this.state.geomManager && <Typography >{this.props.geometryList[fileId].name}</Typography >}
          </Breadcrumbs>
          {this.state.geomManager &&
            <Grid container spacing={3} >
              <Grid item xs={12}>
                <Tooltip disableFocusListener title={t("geometry_manager_list_icon")}>
                  <IconButton variant='outlined' color={this.state.doWhat === 'list' ? 'primary' : 'default'} onClick={() => this.handleDoWhat("list")}>
                    <FontAwesomeIcon icon="list" />
                  </IconButton>
                </Tooltip>
                <Tooltip disableFocusListener title={t("geometry_manager_import_icon")}>
                  <IconButton variant='outlined' color={this.state.doWhat === 'import' ? 'primary' : 'default'} onClick={() => this.handleDoWhat("import")}>
                    <FontAwesomeIcon icon="file-import" />
                  </IconButton>
                </Tooltip>
                <Divider style={{ marginBottom: '20px' }} />
                {this.state.doWhat === 'list' &&
                  <GeometryTable
                    geometryList={this.props.geometryList}
                    handleClickGeometry={this.handleClickGeometry}
                    clickTooltip={t("geometry_manager_view_tip")} />
                }
                {this.state.doWhat === 'import' &&
                  <ImportFiles
                    isSave={true}
                    sendHandleLoadGeometry={this.handleLoadGeometry}
                    redirectAfterSave={true}
                    handleRedirect={this.handleRedirectToLast}
                    isGeomManager={true} />
                }
              </Grid>
            </Grid>
          }
          {!this.state.geomManager &&
            <PointManager
              file={this.props.geometryList[this.props.fileId]}
              id={this.props.fileId}
              afterDelete={this.handleRedirectBack}
              afterTransfrom={this.handleRedirectToLast}
              pageSize={DEFAULT_PAGE_SIZE} />
          }
        </div>}
    </div>);
  }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(withTranslation()(GeometryManager)));
