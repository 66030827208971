import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';

import CRSInputSelect from 'components/CRS/CRSInputSelect';
import CheckCRS from 'components/CRS/CheckCRS';

import { useTranslation } from 'react-i18next';

const mapStateToProps = state => {
    return {
      crs: state.austrian.crs,
      systemList: state.api.austrianList,
      heightList: state.api.heightSystemList,
    }
  }

  /**
 * system, a String to display the current selected System
 * height, a String to display the curretn selected height
 * isSystemDisabled, a boolean to disable System input
 * isHeightDisabled, a  boolean to disable hight input
 * handleChangeSystem, a function that will be called on change system
 * handleChangeHeigth, a function that will be called on change height
 * heightError, a boolean to display an error
 */
  InputSystemFunc.propTypes = {
    system: PropTypes.string.isRequired, 
    height: PropTypes.string.isRequired,
    isSystemDisabled: PropTypes.bool,
    isHeightDisabled: PropTypes.bool,
    handleChangeSystem: PropTypes.func.isRequired,
    handleChangeHeigth: PropTypes.func.isRequired,
    heightError: PropTypes.bool,
  }

  InputSystemFunc.defaultProps = {
    isSystemDisabled: false,
    isHeightDisabled: false,
    heightError: false,
  }
  
  function InputSystemFunc ({crs, label, system, height, systemList, heightList, isSystemDisabled, isHeightDisabled,
                                         handleChangeSystem,handleChangeHeigth, heightError}) {
    const styleError = heightError ?  {textDecoration: 'underline'}: {};
    const {t} = useTranslation();
    return <div style={{flexGrow: '2', margin:'30px'}}>
      <FormControl component="fieldset" error style={{width: '100%'}}> 
      <FormLabel component="legend" style={styleError}>{label}</FormLabel>
      <CRSInputSelect 
        label={t("austrian_input_system_label1")}
        value={system} 
        options={systemList} 
        selectId="select-base" 
        handleChange={handleChangeSystem}
        isDisabled={isSystemDisabled} 
        focusInput={!isSystemDisabled}
        isRequired
        hideNoValue/>
      <CRSInputSelect 
        label={t("austrian_input_system_label2")}
        value={height} 
        options={heightList} 
        selectId="select-height" 
        handleChange={handleChangeHeigth} 
        isDisabled={isHeightDisabled}
        focusInput={!isHeightDisabled}
        hasError={heightError}
        hideNoValue/>
    </FormControl>
    {system !== '' && <div>
      <CheckCRS crs={crs[system]} extra_height_system={height} />
    </div>}
  </div>
  }

  const InputSystem = connect(mapStateToProps)(InputSystemFunc)
  export default InputSystem;