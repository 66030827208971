import React from 'react';
import PropTypes from 'prop-types';
import OpenLayerMap from "components/OpenLayerMap-points";
import { connect } from 'react-redux';
import Grid from '@mui/material/Grid';

function mapStateToProps(state) {
    return {
        config: state.auth.config,
    }
}

export const PointsSelectionMap = connect(mapStateToProps)(({ classes, pointsList, buildGeoJsonFunction, handleOnClick, onCenterSelect, extent, heightMode, center, setMap, startSelectedMap }) => {

    return <Grid item container spacing={0} xs={12} sm={12} sx={classes.gridWrapper} style={{ height: '600px' }}>
        {!!pointsList && <OpenLayerMap pointsList={pointsList}
            buildGeoJsonFunction={buildGeoJsonFunction}
            handleOnClick={handleOnClick}
            onCenterSelect={onCenterSelect}
            extent={extent}
            heightMode={heightMode}
            center={center}
            setStoreMap={setMap}
            startSelectedMap={startSelectedMap} />}

    </Grid>
})

/**
 * classes, an Object, with gridWrapper class for CSS styling
 * mapBound, an array, with two arrays of coordinataes
 * zoom, number to spezifie the zoom factor
 * points, an object, with etrs and mgi data
 * extraPoints, an object with a feature collection of some Points
 * bounds, an array, twith to arrays of coordinates to set Bounds
 * getResiduals, a function, get the Residuals from an Point with name and Attribute
 * handleTableRowClick, a function, to call on an elment
 * isUnselected, a function, that gives back an boolean with the Point as input
 */
PointsSelectionMap.propTypes = {
    classes: PropTypes.object.isRequired,
    pointsList: PropTypes.array.isRequired,
    handleClickElement: PropTypes.func,
    config: PropTypes.object,
};
