import * as actionTypes from './actionTypes';


/*
 * action creators
 */

//  const extractData = (data) => {
//     const apiChoices = [...data];
//     const choices = [];
//     apiChoices.forEach( obj => {
//         choices.push({id: obj.value, name: obj.display_name});
//     })
//     return choices;
//  }


// sagas
export const updateAdvancedList = (timestamp) => ({ type: actionTypes.API_UPDATE_ADVANCED, timestamp })
export const updateAustrianList = (timestamp) => ({ type: actionTypes.API_UPDATE_AUSTRIAN, timestamp })
export const init = () => ({ type: actionTypes.API_INIT })


// reducer
export const updateAdvancedOptions = (data) => ({ type: actionTypes.API_UPDATE_ADVANCED_OPTIONS, data })
export const updateAustrianOptions = (data) => ({ type: actionTypes.API_UPDATE_AUSTRIAN_OPTIONS, data })
export const updateTimestampOptions = () => ({ type: actionTypes.API_UPDATE_TIMESTAMP})
export const start = () => ({ type: actionTypes.API_START })
export const success = () => ({ type: actionTypes.API_SUCCESS })
export const done = () => ({ type: actionTypes.API_DONE })
export const fail = (err) => ({type: actionTypes.API_FAIL, error: err })




export const updateVerticalGridList = (vGridList) => ({ type: actionTypes.API_UPDATE_VERTICAL_GRID_LIST, verticalGridList: vGridList })
export const updateHeightSystemList = (hSysList) => ({ type: actionTypes.API_UPDATE_HEIGHT_SYSTEM_LIST, heightSystemList: hSysList })



// export const updateHeightList = () => {
//     return dispatch => {
//         dispatch(start());
//         axios.options(
//             TRANSFORMATOR_API_URL_HGT, apiConfig
//         ).then(res => {
//             console.log(res.data.actions.POST)
//             const choicesVerticalGrids = extractData(res.data.actions.POST.vertical_grid.choices);
//             dispatch(updateVerticalGridList(choicesVerticalGrids));
//             dispatch(success());
//         }).catch(err => {
//             dispatch(fail(err));
//         });
//     }
// }

// export const updateAustrianLists = () => {
//     return dispatch => {
//         dispatch(start());
//         axios.options(
//             TRANSFORMATOR_API_URL_AUT, apiConfig
//         ).then(res => {
//             console.log(res.data.actions.POST)
//             const choicesHeightSystem = extractData(res.data.actions.POST.height_out.choices);
//             dispatch(updateHeightSystemList(choicesHeightSystem));
//             dispatch(success());
//         }).catch(err => {
//             dispatch(fail(err));
//         });
//     }
// }
