import React, { useState } from "react";
import { inject, observer } from "mobx-react";
import { MenuListComposition } from "../MenuListComposition";
import {
  Avatar,
  TableRow,
  TableContainer,
  Table,
  TableHead,
  TableBody,
  TableFooter,
  TableCell,
  Tooltip,
  TablePagination,
  IconButton,
  Box,
} from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus, faCogs, faExpand } from "@fortawesome/free-solid-svg-icons";
import { useTranslation } from "react-i18next";
import { classes } from "../styling";
import { prettifyBigNumber } from "common/utility";

export const PointTableOther = inject("store")(
  observer(({ store, handleOpenInput }) => {
    const { t } = useTranslation();
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [page, setPage] = useState(0);
    const filteredPointList = store.kataster.pointList.filter(
      (p) => !p.isTransformationpoint && !p.isPassPoint()
    );

    const handleChangePage = (event, newPage) => {
      setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
      setRowsPerPage(parseInt(event.target.value));
      setPage(0);
    };

    const sortByPunktnummer = (a, b) => {
      return a.punktnummer.toLowerCase().localeCompare(b.punktnummer.toLowerCase());
    }

    const emptyRows =
      rowsPerPage -
      Math.min(rowsPerPage, filteredPointList.length - page * rowsPerPage);
    return (
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell
                onClick={() =>
                  store.kataster.setExtentByPointListAndFilter("sp")
                }
              >
                <Tooltip
                  title={t("cadastre:selection_table_header_type_tooltip")}
                >
                  <span>{t("cadastre:selection_table_header_type")}</span>
                </Tooltip>
              </TableCell>
              <TableCell>
                <Tooltip
                  title={t("cadastre:selection_table_header_name_tooltip")}
                >
                  <span>{t("cadastre:selection_table_header_name")}</span>
                </Tooltip>
              </TableCell>
              <TableCell align="right">
                <Tooltip
                  title={t("cadastre:selection_table_header_etrs_tooltip")}
                >
                  <span>{t("cadastre:selection_table_header_etrs")}</span>
                </Tooltip>
              </TableCell>
              <TableCell align="right">
                <Tooltip
                  title={t("cadastre:selection_table_header_mgi_tooltip")}
                >
                  <span>{t("cadastre:selection_table_header_mgi")}</span>
                </Tooltip>
              </TableCell>
              <TableCell>
                <Tooltip
                  title={t("cadastre:selection_table_header_option_tooltip")}
                >
                  <span>
                    <FontAwesomeIcon icon={faCogs} />
                  </span>
                </Tooltip>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {filteredPointList.length === 0 && (
              <TableRow>
                <TableCell />
                <TableCell colSpan={4}>
                  {t("cadastre:selection_table_body_empty")}
                </TableCell>
              </TableRow>
            )}
            {filteredPointList
              .sort(sortByPunktnummer)
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((point) => (
                <TableRow key={point.punktnummer}>
                  <TableCell>
                    <Avatar sx={classes.yellowAvatar}>
                      {point.punktType?.toUpperCase() ?? point.punktArt?.toUpperCase() ?? "SO"}
                    </Avatar>
                  </TableCell>
                  <TableCell>{point.punktnummer}</TableCell>
                  <TableCell align="right">
                    {Math.abs(point.etrs89X) > 0 &&
                      Math.abs(point.etrs89Y) > 0 ? (
                      <>
                        <Box>{prettifyBigNumber(point.etrs89X)} X</Box>
                        <Box>{prettifyBigNumber(point.etrs89Y)} Y</Box>
                        <Box>{prettifyBigNumber(point.etrs89Z)} Z</Box>
                      </>
                    ) : (
                      <Box>
                        {t("cadastre:selection_table_body_no_coordinates")}
                      </Box>
                    )}
                  </TableCell>
                  <TableCell align="right">
                    {Math.abs(point.gkX) > 0 && Math.abs(point.gkY) > 0 ? (
                      <>
                        <Box>{prettifyBigNumber(point.gkY)} y</Box>
                        <Box>{prettifyBigNumber(point.gkX)} x</Box>
                        <Box>{prettifyBigNumber(point.gkHoeheAdria)} h</Box>
                      </>
                    ) : (
                      <Box>
                        {t("cadastre:selection_table_body_no_coordinates")}
                      </Box>
                    )}
                  </TableCell>
                  <TableCell>
                    <MenuListComposition
                      small
                      point={point}
                      handleDelete={store.kataster.removePointFromList}
                    />
                  </TableCell>
                </TableRow>
              ))}

            {emptyRows > 0 && (
              <TableRow style={{ height: 81 * emptyRows }}>
                <TableCell colSpan={5} />
              </TableRow>
            )}

            <TableRow sx={classes.hoverRow} onClick={handleOpenInput}>
              <TableCell>
                <Avatar sx={classes.smallAvatar}>
                  <FontAwesomeIcon icon={faPlus} />
                </Avatar>
              </TableCell>
              <TableCell colSpan={4}>
                {t("cadastre:selection_table_body_add_points_other")}
              </TableCell>
            </TableRow>
          </TableBody>
          <TableFooter>
            <TableRow>
              <TableCell>
                {filteredPointList.length !== 0 && (
                  <Tooltip
                    title={t("cadastre:selection_extent_otherpoint_tooltip")}
                  >
                    <IconButton
                      onClick={() => {
                        store.kataster.setExtentByPointListAndFilter("sp");
                        window.scrollTo(0, 250);
                      }}
                    >
                      <FontAwesomeIcon icon={faExpand} />
                    </IconButton>
                  </Tooltip>
                )}
              </TableCell>
              <TableCell colSpan={4}>
                <TablePagination
                  rowsPerPageOptions={[5, 10, 25, 50]}
                  component="Box"
                  count={filteredPointList.length}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  onPageChange={handleChangePage}
                  onChangonRowsPerPageChangeeRowsP={handleChangeRowsPerPage}
                />
              </TableCell>
            </TableRow>
          </TableFooter>
        </Table>
      </TableContainer>
    );
  })
);
