import React from 'react';
import PropTypes from 'prop-types';

import BevCard from './BevCard';
import BevStandardSiteMap from './BevStandardSiteMap';

import { DEFAULT_PAGE_SIZE } from '../common/constants.js';
import {useTranslation} from 'react-i18next';

BevSolutionCard.propTypes = {
  isDisplayed: PropTypes.bool,
  isOpen: PropTypes.bool,
  isComputed: PropTypes.bool,
  solution: PropTypes.object,
  toggleOpen: PropTypes.func,
  hideMap: PropTypes.bool,
};

BevSolutionCard.defaultProps = {
  isDisplayed: false,
  isOpen: false,
  isComputed: false,
  hideMap: false,
  hideExport: false,
  correctCadastarPoints: false,
  hasProtokoll: false,
  hideSave: false,
};

export default function BevSolutionCard ({isDisplayed, hideSave, isOpen, isComputed, solution, hideMap, hideExport, hasProtokoll, handleOnExport, toggleOpen, arrowPoints, extraPoints}) {
    const {t} = useTranslation();
    return <BevCard title={t('solution_card_title')} show={isDisplayed} open={isOpen} toggleOpen={toggleOpen}>
      {isComputed && hasProtokoll ? <BevStandardSiteMap 
                        pageSize={DEFAULT_PAGE_SIZE} 
                        geometry={solution}
                        arrowPoints={arrowPoints}
                        extraPoints={extraPoints}
                        hideMap={hideMap} 
                        hideExport={hideExport}
                        hideSave={hideSave}
                        handleOnExport={handleOnExport}/> :
                        <BevStandardSiteMap 
                          pageSize={DEFAULT_PAGE_SIZE} 
                          geometry={solution}
                          arrowPoints={arrowPoints}
                          extraPoints={extraPoints}
                          hideMap={hideMap} 
                          hideExport={hideExport}
                          hideSave={hideSave}/>
                         }
    </BevCard>
  }