export const AUT_CHANGE_SYSTEM = 'AUT_CHANGE_SYSTEM';

export const HELMERT_UPDATE_TRA = 'HELMERT_UPDATE_TRA';
export const HELMERT_SAVE_PARAMS = 'HELMERT_SAVE_PARAMS';
export const HELMERT_SET_PARAMS = 'HELMERT_SET_PARAMS';
export const HELMERT_UPDATE_ISDREID = 'HELMERT_UPDATE_ISDREID';

export const BASE_SET_SOLUTION = 'BASE_SET_SOLUTION';
export const BASE_CHANGE_PARAMETER_EXPAND = 'BASE_CHANGE_PARAMETER_EXPAND';
export const BASE_CHANGE_POINT_EXPAND = 'BASE_CHANGE_POINT_EXPAND';
export const BASE_CHANGE_SOLUTION_EXPAND = 'BASE_CHANGE_SOLUTION_EXPAND';
export const BASE_ADD_CRS = 'BASE_ADD_CRS';
export const BASE_ADD_TRAFO = 'BASE_ADD_TRAFO';
export const BASE_REMOVE_CRS = 'BASE_REMOVE_CRS';
export const BASE_SET_PROGRESS = 'BASE_SET_PROGRESS';
export const BASE_TOGGLE_CG_OPEN = 'BASE_TOGGLE_CG_OPEN';
export const BASE_SET_COOKIE = 'BASE_SET_COOKIE';

export const AUTH_GET_CURRENT_USER = 'AUTH_GET_CURRENT_USER';
export const AUTH_LOGOUT_CURRENT_USER = 'AUTH_LOGOUT_CURRENT_USER';
export const AUTH_CHECK_TOKEN = 'AUTH_CHECK_TOKEN';
export const AUTH_LOAD_USER = 'AUTH_LOAD_USER';
export const AUTH_UPDATE_USER = 'AUTH_UPDATE_USER';
export const AUTH_AUTHENTICATION_ERROR = 'AUTH_AUTHENTICATION_ERROR';
export const AUTH_API_DOWN = 'AUTH_API_DOWN';
export const AUTH_API_UP = 'AUTH_API_UP';
export const AUTH_SET_STP_AUTHENTICATION = 'AUTH_SET_STP_AUTHENTICATION';
export const AUTH_SET_ANONYMOUS_USER = 'AUTH_SET_ANONYMOUS_USER';
export const AUTH_SET_TOKEN_AUTHENTICATION = 'AUTH_SET_TOKEN_AUTHENTICATION';
export const AUTH_UNSET_USER_SETTINGS = 'AUTH_UNSET_USER_SETTINGS';

export const AUT_SET_SOURCE_CRS = 'AUT_SET_SOURCE_CRS';
export const AUT_SET_SOURCE_FRAME = 'AUT_SET_SOURCE_FRAME';
export const AUT_SET_SOURCE_ELLIPSOID = 'AUT_SET_SOURCE_ELLIPSOID';
export const AUT_SET_SOURCE_MRDN = 'AUT_SET_SOURCE_MERIDIAN';
export const AUT_SET_SOURCE_CR = 'AUT_SET_SOURCE_COORDINATE_REPRESENTATION';
export const AUT_SET_SOURCE_PRJ = 'AUT_SET_SOURCE_PROJECTION';
export const AUT_SET_SOURCE_HS = 'AUT_SET_SOURCE_HEIGHT_SYSTEM';

export const AUT_SET_TARGET_CRS = 'AUT_SET_TARGET_CRS';
export const AUT_SET_TARGET_FRAME = 'AUT_SET_TARGET_FRAME';
export const AUT_SET_TARGET_ELLIPSOID = 'AUT_SET_TARGET_ELLIPSOID';
export const AUT_SET_TARGET_MRDN = 'AUT_SET_TARGET_MERIDIAN';
export const AUT_SET_TARGET_CR = 'AUT_SET_TARGET_COORDINATE_REPRESENTATION';
export const AUT_SET_TARGET_PRJ = 'AUT_SET_TARGET_PROJECTION';
export const AUT_SET_TARGET_HS = 'AUT_SET_TARGET_HEIGHT_SYSTEM';

export const AUT_SET_TM = 'AUT_SET_HORIZONTAL_TRANSFORMATION_METHOD';

export const CADASTRE_UPDATE_PARAMETER = 'CADASTRE_UPDATE_PARAMETER';
export const CADASTRE_CLEAR_PAGE = 'CADASTRE_CLEAR_PAGE';
export const CADASTRE_LOAD_EXAMPLE = 'CADASTRE_LOAD_EXAMPLE';

export const POINTS_ADD_FILE = 'POINTS_ADD_FILE';
export const POINTS_ADD_GEOMETRY_FILE = 'POINTS_ADD_GEOMETRY_FILE';
export const POINTS_REMOVE_GEOMETRY_FILE = 'POINTS_REMOVE_GEOMETRY_FILE';
export const POINTS_UPDATE_GEOMETRY_FILE = 'POINTS_UPDATE_GEOMETRY_FILE';
export const POINTS_UPDATE_TARGET_CRS = 'POINTS_UPDATE_TARGET_CRS';
export const POINTS_REMOVE_GEOMETRY_LIST = 'POINTS_REMOVE_GEOMETRY_LIST';
export const POINTS_SET_FILE_ID = 'POINTS_SET_FILE_ID';
export const POINTS_SET_FILE_NAME = 'POINTS_SET_FILE_NAME';
export const POINTS_UPDATE_PROP = 'POINT_UPDATE_PROP';
export const POINTS_FINDE_NEXT_FILE_NUMBER = 'POINT_FINDE_NEXT_FILE_NUMBER';
export const POINTS_CLEAN_INPUT = 'POINTS_CLEAN_INPUT';


export const ADV_SET_SOURCE_CRS = 'ADV_SET_SOURCE_CRS';
export const ADV_SET_TARGET_CRS = 'ADV_SET_TARGET_CRS';

export const API_INIT = 'API_INIT';
export const API_START = 'API_START';
export const API_DONE = 'API_DONE';
export const API_FAIL = 'API_FAIL';
export const API_SUCCESS = 'API_SUCCESS';
export const API_UPDATE_TIMESTAMP = 'API_UPDATE_TIMESTAMP';
export const API_UPDATE_ADVANCED = 'API_UPDATE_ADVANCED';
export const API_UPDATE_ADVANCED_OPTIONS = 'API_UPDATE_ADVANCED_OPTIONS';
export const API_UPDATE_AUSTRIAN = 'API_UPDATE_AUSTRIAN';
export const API_UPDATE_AUSTRIAN_OPTIONS = 'API_UPDATE_AUSTRIAN_OPTIONS';

export const API_UPDATE_VERTICAL_GRID_LIST = 'API_UPDATE_VERTICAL_GRID_LIST';
export const API_UPDATE_HEIGHT_SYSTEM_LIST = 'API_UPDATE_HEIGHT_SYSTEM_LIST';
export const API_UPDATE_TIMESTAMP_ADVANCED = 'API_UPDATE_TIMESTAMP_ADVANCED';
export const API_UPDATE_AUSTRIAN_CRS = 'API_UPDATE_AUSTRIAN_CRS';
export const API_UPDATE_AUSTRIAN_TRANSFORMATION = 'API_UPDATE_AUSTRIAN_TRANSFORMATION';