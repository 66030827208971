
const geometryFunctions = {
    Point: CheckPointCoordinatesforCadastre,
    MultiPoint: CheckMultiPointCoordinatesforCadastre,
    LineString: CheckMultiPointCoordinatesforCadastre,
    MultiLineString: CheckMultiLineCoordinatesforCadastre,
    Polygon: CheckMultiLineCoordinatesforCadastre,
    MultiPolygon: CheckMultiPolygonCoordinatesforCadastre
}

const geometryTypeList = ['Point', 'MultiPoint', 'LineString','MultiLineString','Polygon','MultiPolygon'];

export function CheckGeoJsonMGICoordinatsForCadastre(geoJson) {
    if(!!geoJson){
        if(geoJson.hasOwnProperty('features')){
            geoJson['features'] = CheckGeometriesForCadaster(geoJson['features']);
        }else{
            console.error('CheckMGICoordinatsForCadastre: GeoJson is missing Features');
        }
    }else{
        console.error('CheckMGICoordinatsForCadastre: Missing GeoJson');
    }
    return geoJson;

}

export function CheckGeometriesForCadaster(geometries,index){
    return geometries.map( geometry => {
        const hasType = geometry.hasOwnProperty('type');
        if(hasType && geometry.type === 'Feature'){
            geometry.geometry = CheckGeometryForCadastre(geometry.geometry, index);
        }else if(hasType && geometry.type === 'GeometryCollection'){
            geometry.geometries = CheckGeometriesForCadaster(geometry.geometries);
        }else if(hasType && geometryTypeList.indexOf(geometry.type) > -1 ){
            geometry = CheckGeometryForCadastre(geometry);
        }else{
            console.error('CheckMGICoordinatsForCadastre: Feature Type not Valid on index ' + index);
        }
        return geometry;
    })
}

export function CheckGeometryForCadastre(geometry, index){
    const hasGeometryType = !!geometry && !!geometry.type;
    if(hasGeometryType && geometryTypeList.indexOf(geometry.type) > -1 ){
        geometry = geometryFunctions[geometry.type](geometry);
    }else{
        console.error('CheckMGICoordinatsForCadastre: Geometry or Geometry-Type is missing on Feature on index ' + index); 
    }
    return geometry;
}

export function CheckPointCoordinatesforCadastre(point){
    const coordinates = point.coordinates;
    if(!!coordinates && coordinates.length > 1){
        coordinates[1] = CheckCoordinatForCadastre(coordinates[1]);
        point.coordinates = coordinates;
    }
    return point;
}

export function CheckMultiPointCoordinatesforCadastre(multiPoints){
    if(!!multiPoints.coordinates && multiPoints.coordinates.length > 0){
        multiPoints.coordinates = multiPoints.coordinates.map( point => {
            if(point.length > 1){
                point[1] = CheckCoordinatForCadastre(point[1]);
            }
            return point;
        })
    }
    
    return multiPoints;
}

export function CheckMultiLineCoordinatesforCadastre(multiLine){
    if(!!multiLine.coordinates && multiLine.coordinates.length > 0){
        multiLine.coordinates = multiLine.coordinates.map( line => {
            line = line.map( point => {
                if(point.length > 1){
                    point[1] = CheckCoordinatForCadastre(point[1]);
                }
                return point;
            })
            return line;
        })
    }
    return multiLine;
}

export function CheckMultiPolygonCoordinatesforCadastre(multiPolygon){
    if(!!multiPolygon.coordinates && multiPolygon.coordinates.length > 0){
        multiPolygon.coordinates = multiPolygon.coordinates.map( polygon => {
            polygon = polygon.map( line => {
                line = line.map( point => {
                    if(point.length > 1){
                        point[1] = CheckCoordinatForCadastre(point[1]);
                    }
                    return point;
                })
                return line;
            })
            return polygon
        })
    }
    return multiPolygon;
}

export function CheckCoordinatForCadastre(coordinate){
    if(!isNaN(coordinate)){
        const cache = parseFloat(coordinate)
        return cache < 1000000 ? cache + 5000000 : cache;
    }else{
        return coordinate;
    }
}