export const cadastre = {
    "cadastre_error_four_ponts" : "Enter at least 4 {{type}} points.",
    "cadastre_error_points_dont_match" : "The pass points do not match.",
    "cadastre_url_params" : "Settings for GET-parameters applied.",
    "cadastre_url_params_success" : "All parameters are present.",
    "cadastre_url_params_error_missing" : "Entry for mgi_points or etrs_points incomplete",
    "cadastre_url_params_error_format" : "Parameters are not in JSON format.",

    "cadastre_title_card_title" : "Cadastre mode",
    "cadastre_title_card_text" : "Calculation of a GNSS fixed point connection in accordance with the guidelines of the current surveying regulations. Determine transformation parameters for a two-step Helmert transformation and subsequent transformation of the new points. The result is output in the form of a log, which can be attached directly to your surveying certificate",

    "cadastre_point_input_title" : "Input for determination",
    "cadastre_point_input_btn_koordinatenverzeichnis" : "Load CSV coordinates according to VermV §8 Z6",
    "cadastre_point_input_field_etrs_load" : "Load ETRS89 pass points",
    "cadastre_point_input_field_mgi_load" : "Load MGI pass points",
    "cadastre_point_input_btn_start" : "Calculate",

    "cadastre_point_input_modal_btn_import" : "Import files",
    "cadastre_point_input_modal_btn_load" : "Load points",

    "cadastre_point_edit_modal_title": "Edit point {{pointName}}",
    "cadastre_point_edit_modal_label_point": "Point name",
    "cadastre_point_edit_modal_label_point_placeholder": "Point name...",
    "cadastre_point_edit_modal_label_coord1_placeholder": "Coordinate 1",
    "cadastre_point_edit_modal_label_coord2_placeholder": "Coordinate 2",
    "cadastre_point_edit_modal_label_coord3_placeholder": "Coordinate 3",
    "cadastre_point_edit_modal_btn_save": "Save",
    "cadastre_point_edit_modal_btn_delete": "Delete",

    "cadastre_point_selection_title": "Choose the basis for calculation",
    "cadastre_point_selection_btn": "Apply",

    "cadastre_transformation_params_title": "Parameter:",
    "cadastre_transformation_params_btn_pdf": "PDF protocol",
    "cadastre_transformation_params_btn_json": "Parameter JSON",

    "cadastre_point_table_container_switch_label": "m = 1, preserve interior geometry",
    "cadastre_point_table_label_name": "Point Name",
    "cadastre_point_table_label_r_east": "r_Rechtswert [cm]",
    "cadastre_point_table_label_r_north": "r_Hochwert [cm]",
    "cadastre_point_table_label_r_lage": "r_Lage [cm]",
    "cadastre_point_table_label_r_hight": "r_Höhe [cm]",
    "cadastre_point_table_label_east": "nv_Rechtswert []",
    "cadastre_point_table_label_north": "nv_Hochwert []",
    "cadastre_point_table_label_use": "select",

    "cadastre_point_trafo_input_title": "Input for Transformation",
    "cadastre_point_trafo_input_btn_load": "Load points",
    "cadastre_point_trafo_input_btn_start": "Calculate",

    "cadastre_system_import_modal_title": "Choose the first system",
    "cadastre_system_import_modal_tab": "Point list",
    
}