import React, { Component } from 'react';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';
import { prepareNumber } from 'common/utility';

import { withTranslation } from 'react-i18next';


function parseCoordFormat(x) {
  let dms = /^([N|S]\s)?([-|+]?\d+)[°|d|:|\s]+(\d+)[m|'|:|\s]+(\d+\.?\d*)'*\s*([N|S|E|W]\s)?([-|+]?\d+)[°|d|:|\s]+(\d+)[m|'|:|\s]+(\d+\.?\d*)'*\s*([E|W])?/
  // eslint-disable-next-line
  let dmm = /^([N|S]\s)?([-|+]?\d+)[°|d|:|\s]+(\d+\.?\d*)[m|'|:|\s]\'?\s*([N|S|E|W]\s)?([-|+]?\d+)[°|d|:|\s]+(\d+\.?\d*)[m|'|:|\s]?\s?([N|S|E|W]\s)?/g
  let ddd = /^([N|S]\s)?([-|+]?\d+\.?\d*)[°|d|:|\s]?\s*([N|S|E|W]\s)?([-|+]?\d+\.?\d*)[°|d|:|\s]?([E|W])?/

  var coords = false;
  let phi, lam, phidir, lamdir;
  let coordarray = ddd.exec(x);
  if (coordarray) {
    phi = coordarray[2]
    lam = coordarray[4]
    if (coordarray[1] && coordarray[3]) {
      phidir = coordarray[1].trim()
      lamdir = coordarray[3].trim()
    } else if ( coordarray[3] && coordarray[5]) {
      phidir = coordarray[3].trim()
      lamdir = coordarray[5].trim()
    }
    if (phidir === 'S') { phi = phi*(-1); }
    if (lamdir === 'W') { lam = lam*(-1); }
    coords = [phi, lam];
  }
  if (!coordarray) {
    coordarray = dmm.exec(x);
    if (coordarray) {
      if (coordarray[1] && coordarray[4]) {
        phidir = coordarray[1].trim()
        lamdir = coordarray[4].trim()
      } else if ( coordarray[4] && coordarray[7]) {
        phidir = coordarray[4].trim()
        lamdir = coordarray[7].trim()
      }
    phi = parseFloat(coordarray[2]) + parseFloat(coordarray[3])/60;
    lam = parseFloat(coordarray[5]) + parseFloat(coordarray[6])/60;
    }
  }
  if (!coordarray) {
    coordarray = dms.exec(x);
    if (coordarray) {
      if (coordarray[1] && coordarray[5]) {
        phidir = coordarray[1].trim()
        lamdir = coordarray[5].trim()
      } else if ( coordarray[5] && coordarray[9]) {
        phidir = coordarray[5].trim()
        lamdir = coordarray[9].trim()
      }
    phi = parseFloat(coordarray[2]) + parseFloat(coordarray[3])/60 + parseFloat(coordarray[4])/3600;
    lam = parseFloat(coordarray[6]) + parseFloat(coordarray[7])/60 + parseFloat(coordarray[8])/3600;
    }
  }

  if (phidir === 'S') { phi = phi*(-1); }
  if (lamdir === 'W') { lam = lam*(-1); }
  coords = [phi, lam];

  return coords;
}

function CoordObject(x, direction) {
  let c = {}
  let nks = 3 //Nachkommastellen
  let gn = (x>0 ? true : false)
  c.float = x;
  c.floatabs = Math.abs(x);

  if (direction === "north") {
    c.direction = (gn ? 'N' : 'S')
  } else {
    c.direction = (gn ? 'E' : 'W')
  }
  c.deg = parseInt( c.floatabs , 10 );
  c.minfloat = parseFloat( (c.floatabs-c.deg)*60 )
  c.min = parseInt( c.minfloat ,10 );
  c.secfloat = prepareNumber(parseFloat( (c.minfloat-c.min)*60 ), nks);
  c.sec = Math.round(c.secfloat);

  c.dms = {}
  c.dms.a = c.direction + " "+c.deg+"° "+c.min+"' "+c.sec+"''";
  c.dms.b = c.direction + " "+c.deg+"° "+c.min+"' "+c.secfloat+"''";
  c.dms.c = c.deg+"° "+c.min+"' "+c.secfloat+"'' "+c.direction;
  c.dms.d = (!gn && '-') + c.deg+"° "+c.min+"' "+c.secfloat+"'' ";
  c.dms.e = c.deg+"d "+c.min+"' "+c.secfloat+"'' "+c.direction;
  c.dms.f = c.deg+":"+c.min+":"+c.secfloat+" "+c.direction;
  c.dms.g = c.deg+" "+c.min+"'"+c.secfloat+"'' "+c.direction;

  c.dmm = {}
  c.dmm.a = c.direction + " " +c.deg+"° "+prepareNumber(c.minfloat, nks)+"' ";
  c.dmm.b = c.direction + " " +c.deg+"° "+prepareNumber(c.minfloat, nks);
  c.dmm.c = c.deg+"° "+prepareNumber(c.minfloat, nks)+"' "+c.direction;
  c.dmm.d = (!gn && '-') + c.deg+"° "+prepareNumber(c.minfloat, nks)+"' ";

  c.ddd = {}
  c.ddd.a = c.direction + " " +prepareNumber(c.floatabs,5)+"° ";
  c.ddd.b = c.direction + " " +prepareNumber(c.floatabs,5);
  c.ddd.c = prepareNumber(c.floatabs,5)+"° "+ c.direction;
  c.ddd.d = prepareNumber(c.float,5)+"° ";


  return c
}

class CoordFormat extends Component {
    constructor(props) {
      super(props);
      this.state = {
        coords: '40.4462 -79.9489',
        north: false,
        east: false,
      };
    }

    componentDidMount() {
      this.computeFormats();
    }

    setCoords = (e) => {
      this.setState({ coords: e.target.value });
    }

    computeFormats = () => {
      let x = parseCoordFormat(this.state.coords);
      let north = false, east=false;
      if (x[0]) {
        north = CoordObject(x[0], "north")
        east = CoordObject(x[1], "east")
      }
      this.setState({
        north: north,
        east: east,
       });
    }

    
    render() {
       let { north, east} = this.state
       const {t}=this.props
        return (
            <div>
              <TextField value={this.state.coords} onBlur={this.computeFormats} onChange={this.setCoords} id="coords" name="coords" helperText={t("coord_format_desc")} margin="normal"/>
              { !north ? <h3>Eingabe konnte nicht erkannt werden.</h3> : <div>
                <Grid container spacing={1}>
                  <Grid item xs={4}>
                    <Card>
                      <CardHeader title="DDD" />
                      <CardContent>
                        <p>{north.ddd.a} {east.ddd.a}</p>
                        <p>{north.ddd.b} {east.ddd.b}</p>
                        <p>{north.ddd.c} {east.ddd.c}</p>
                        <p>{north.ddd.d} {east.ddd.d}</p>
                      </CardContent>
                    </Card>
                  </Grid>

                  <Grid item xs={4}>
                    <Card>
                      <CardHeader title="DMM" />
                      <CardContent>
                        <p>{north.dmm.a} {east.dmm.a}</p>
                        <p>{north.dmm.b} {east.dmm.b}</p>
                        <p>{north.dmm.c} {east.dmm.c}</p>
                        <p>{north.dmm.d} {east.dmm.d}</p>
                      </CardContent>
                    </Card>
                  </Grid>

                  <Grid item xs={4}>
                    <Card>
                      <CardHeader title="DMS" />
                      <CardContent>
                        <p>{north.dms.a} {east.dms.a}</p>
                        <p>{north.dms.b} {east.dms.b}</p>
                        <p>{north.dms.c} {east.dms.c}</p>
                        <p>{north.dms.d} {east.dms.d}</p>
                        <p>{north.dms.e} {east.dms.e}</p>
                        <p>{north.dms.f} {east.dms.f}</p>
                        <p>{north.dms.g} {east.dms.g}</p>
                      </CardContent>
                    </Card>
                  </Grid>


                </Grid>

                </div>
              }
            </div>
        );
    }
}

CoordFormat.defaultProps = {
  value: 2002.56
};

export default withTranslation()(CoordFormat);
