import { Circle as CircleStyle, Fill, Icon, Stroke, Style, RegularShape, Text } from "ol/style";


// ----- Helper functions ----- //

const stroke = new Stroke({
  color: "#333",
  width: 1.25,
});

const createStroke = (color, width) => {
  return new Stroke({
    color: color,
    width: width,
  });
};

const fill_np = new Fill({ color: "#303f9f" }); // blue
const fill_op = new Fill({ color: "#fbc02d" }); // yellow

const stylingLabel = (label) =>
  new Text({
    font: "12px Calibri,sans-serif",
    fill: new Fill({ color: "#333" }),
    stroke: createStroke("#fff", 4),
    offsetY: -10,
    text: label,
  });

const stylingPoint = (label, fill, stroke) =>
  new Style({
    image: new CircleStyle({
      fill: fill,
      stroke: stroke,
      radius: 4,
    }),
    fill: fill,
    stroke: stroke,
    text: !!label ? stylingLabel(label) : null,
  });

const createPassPointStyle = (label, fill, stroke, radius) => {
  return new Style({
    image: new RegularShape({
      fill: fill,
      stroke: stroke,
      points: 3,
      radius: radius,
    }),
    text: label ? stylingLabel(label) : null,
  });
};

const createRutschPointStyle = (fill, stroke, radius) => {
  return new Style({
    image: new RegularShape({
      fill: fill,
      stroke: stroke,
      anchor: [0, 1],
      points: 2,
      radius: radius,
      rotation: 1.5708
    }),
  });
};

const createArrowSvg = (backgroundColor, strokeColor, length) => {
  const width = length + 45;
  let arrowSvg = `<svg version="1.1" baseProfile="full" width="${width}" height="50" xmlns="http://www.w3.org/2000/svg">`;
  arrowSvg += `<rect style="fill:${backgroundColor};fill-opacity:1" stroke="${strokeColor}" stroke-width="1" width="${length}" height="8" x="0" y="20" />`;
  arrowSvg += `<path style="fill:${backgroundColor};fill-opacity:1" stroke="${strokeColor}" stroke-width="1" d="m${length},24 0,-10 30,10 -30,10 z" /></svg>`;
  return arrowSvg;
};

// ----- Styling Functions for Point Types and effects ----- //

export const stylingOtherPoints = (f) => {
  return [stylingPoint(f.get("name"), fill_op, stroke)];
};
export const stylingNewPoints = (f) => {
  return [stylingPoint(f.get("name"), fill_np, stroke)];
};

export const stylingPassPoint = (pointList, theme, hover, selected) => (f) => {

  const index = pointList.findIndex((p) =>
    p.isSamePoint({ type: f.get("art"), oek: f.get("oek").toString(), nr: f.get("nr").toString() })
  );
  // IF Point ist in point list return no styling
  if (index > -1) return [];
  const radius = hover ? 12 : 8;
  const label =
    f.get("art") === "E"
      ? `${f.get("art")} ${f.get("oek")}-${f.get("nr")}`
      : `${f.get("art")} ${f.get("nr")}-${f.get("oek")}`;

  if (f.get("rutschpunkt"))
    return [
      createPassPointStyle(
        label,
        new Fill({ color: "#333" }),
        createStroke("#eee", 1.25),
        radius
      ),
      createRutschPointStyle(new Fill({ color: "#111" }), createStroke("#111", 2), radius*1.15),
    ];

  if (!f.get("has_etrs"))
    return [
      createPassPointStyle( 
        label,
        new Fill({ color: "#fff" }),
        createStroke("#333", 1.25),
        radius
      )
    ];

  return [
    createPassPointStyle(
      label,
      new Fill({ color: "#333" }),
      createStroke("#eee", 1.25),
      radius
    ),
  ];
};

export const stylingArrow = (theme, isHover, heightMode) => (feature) => {

  const isSelected = feature.get("selected");
  const height = feature.get("height") || 0;
  const length = heightMode ? Math.abs(height * 1250) : (feature.get("length") || 0) * 1.5;
  const angle = feature.get("angle") || 0;

  const rotation = height > 0 ? -1.5708 : 1.5708;

  const color = heightMode
    ? height > 0
      ? theme.palette.error.dark
      : theme.palette.info.dark
    : isSelected
    ? !!length && length / 1.5 > 100
      ? theme.palette.error.dark
      : theme.palette.success.dark
    : isHover
    ? theme.palette.success.light
    : "#333";

  const strokeColor = heightMode
    ? height > 0
      ? theme.palette.error.light
      : theme.palette.info.light
    : isHover
    ? "#eee"
    : isSelected
    ? !!length && length / 1.5 > 100
      ? theme.palette.error.light
      : theme.palette.success.light
    : "#eee";

  const strokeStyle = createStroke(strokeColor, 1.25);

  const arrowStyle = new Style({
    image: new Icon({
      src: "data:image/svg+xml;base64," + window.btoa(createArrowSvg(color, strokeColor, length )),
      anchor: [0, 0.5],
      anchorOrigin: "bottom-left",
      scale: 0.5,
      rotation: heightMode ? rotation : (angle * -1),
    }),
  });

  const [punktArt, region, nr] = splitName(feature.get("name"));
  const label = punktArt.toLowerCase() === "e" || punktArt.toLowerCase() === "ep" ? 
    `${punktArt} ${region}-${nr}` :
    `${punktArt} ${nr}-${region}`;

  const pointStyle = createPassPointStyle(label, new Fill({ color }), strokeStyle, isHover ? 12 : 8);
  return (height > 0 || length > 0) && isSelected ? [arrowStyle, pointStyle] : [pointStyle];
};

export const centerInteractionStyling = new Style({
  image: new CircleStyle({
    radius: 4,
    fill: new Fill({ color: "red" }),
  }),
  text: new Text({
    font: "12px Calibri,sans-serif",
    fill: new Fill({ color: "#808" }),
    stroke: new Stroke({
      color: "#fff",
      width: 2,
    }),
    offsetY: -15,
    text: "last center",
  }),
})

  /**
   * This function trys to Splitt the given string after the Punktnummern format and returns the data as an Array
   * @param {string} name
   */
  // ToDo create Helper function from this
   function splitName(name) {
    const match_tp = name?.match(/^(T|t|tp|TP)?\s?\-?\s?0*(\d{1,4})\s?\-\s?(\d+)(.*)$/);
    const match_ep = name?.match(/^(E|e|ep|EP)?\s?\-?\s?(\d{5})\s?\-?\s?0*(\d+)(.*)$/);

    if (!match_tp && !match_ep) return [null, null, null, null];
    const clean = (str) => str === "" ? null : str;

    if (match_tp) {
      return [clean(match_tp[1] ?? "T"), clean(match_tp[3]), clean(match_tp[2]), clean(match_tp[4])];
    }
    return [clean(match_ep[1] ?? "E"), clean(match_ep[2]), clean(match_ep[3]), clean(match_ep[4])];
  }