export const helmert2 = {
    "helmert2_title_card_title" : "Determination of Helmert Parameters",
    "helmert2_title_card_info" : "Application of 2D or 3D Helmert transformation with identical points. Please enter at least 2 points for 2D and 3 points for 3D.",
    "helmert2_title_card_2d" : "Example 2D",
    "helmert2_title_card_3d" : "Example 3D",
    "helmert2_title_card_info2d" : "More info 2D",
    "helmert2_title_card_info3d" : "More info 3D",
    "helmert2_title_card_restart" : "Reset",

    "helmert2_alert_danger" : "3 points per system must be available",

    "helmert2_point_source_card_title" : "Points to transform",
    "helmert2_point_source_card_alert1" : "When selecting, only points will be used. No other geometries.",
    "helmert2_point_source_card_alert2" : "Only the first 2 coordinate values will be used when using 2D.",
    "helmert2_point_source_card_system1" : "Select System 1",
    "helmert2_point_source_card_system2" : "Select System 2",
    "helmert2_point_source_card_berechnung" : "Calculate",

    "helmert2_solution_card_title" : "Result",
    "helmert2_solution_card_warning" : "All information is supplied without guarantee and BEV accepts no liability.",
    "helmert2_solution_card_apply" : "Apply",

    "helmert2_choose_system_modal_titel" : "Select the first system",
    "helmert2_choose_system_modal_point_list" : "Point list",
    "helmert2_choose_system_modal_saved_points" : "Saved geometries",
    "helmert2_choose_system_modal_tip" : "Select this geometry",
    "helmert2_choose_system_modal_select" : "Select",

}