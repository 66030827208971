import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import Grid from '@mui/material/Grid';

import CRSInputSelect from './CRSInputSelect';
import CRSInputBevTimePicker from './CRSInputBevTimePicker';
import {useTranslation} from 'react-i18next';

const mapStateToProps = (state) => {
  return {
    frameList: state.api.frameList,
    ellipsoidList: state.api.ellipsoidList,
    prime_meridianList: state.api.prime_meridianList,
    projectionList: state.api.projectionList,
  };
}

//Type Definition
CRSInputForm.propTypes = {
  frame: PropTypes.string,
  ellipsoid: PropTypes.string,
  prime_meridian: PropTypes.string,
  projection: PropTypes.string,
  epoch: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number]),

  frameList: PropTypes.array,
  ellipsoidList: PropTypes.array,
  prime_meridianList: PropTypes.array,
  projectionList: PropTypes.array,

  handleChangeFrame: PropTypes.func.isRequired,
  handleChangeEpoch: PropTypes.func.isRequired,
  handleChangeEllps: PropTypes.func.isRequired,
  handleChangeMeridian: PropTypes.func.isRequired,
  handleChangeProjection: PropTypes.func.isRequired,
}

function CRSInputForm ({frame, ellipsoid, prime_meridian, projection, epoch,
                        frameList, ellipsoidList, prime_meridianList, projectionList,
                        handleChangeFrame, handleChangeEpoch, handleChangeEllps,handleChangeMeridian, handleChangeProjection}) {
  const isFrameEmpty = !frame || ( frame && frame === '' );
  const isEllipsoidEmpty = !ellipsoid || ( ellipsoid && ellipsoid === '');
  const {t} = useTranslation();
  return (
      <div style={{flexGrow: '2', margin:'30px'}}>
      <Grid item xs={12} style={{minWidth: '250px'}}>
        <CRSInputSelect label={t('container_crs_frame')}
                        value={frame ? frame : ''} 
                        options={frameList} 
                        selectId="select-frame" 
                        handleChange={handleChangeFrame}
                        focusInput/>
      </Grid> 

      <Grid item xs={12} style={{minWidth: '250px'}}>
        <CRSInputBevTimePicker value={epoch ? parseFloat(epoch) : 2002.56} 
                                handleChangeEpoch={handleChangeEpoch} 
                                isDisabled={isFrameEmpty}
                                focusInput={!isFrameEmpty}
                                onClick={handleChangeEpoch} 
                                />
      </Grid> 

      <Grid item xs={12} style={{minWidth: '250px'}}>
        <CRSInputSelect label={t('container_crs_ellipsoid')}
                        value={ellipsoid ? ellipsoid : ''} 
                        options={ellipsoidList} 
                        selectId="select-frame" 
                        handleChange={handleChangeEllps} 
                        isDisabled={isFrameEmpty}
                        focusInput={!isFrameEmpty}
                        />
      </Grid> 

      <Grid item xs={12} style={{minWidth: '250px'}}>
        <CRSInputSelect label={t('container_crs_meridian')}
                        value={prime_meridian ? prime_meridian : ''} 
                        options={prime_meridianList} 
                        selectId="select-frame" 
                        handleChange={handleChangeMeridian} 
                        isDisabled={isFrameEmpty || isEllipsoidEmpty}
                        focusInput={!isFrameEmpty && !isEllipsoidEmpty}
                        hideNoValue={true} />
      </Grid> 
      
      <Grid item xs={12} style={{minWidth: '250px'}}>
        <CRSInputSelect label={t('container_crs_projection')}
                        value={projection ? projection : ''} 
                        options={projectionList}  
                        selectId="select-frame" 
                        handleChange={handleChangeProjection} 
                        isDisabled={isFrameEmpty || isEllipsoidEmpty}
                        focusInput={!isFrameEmpty && !isEllipsoidEmpty}/>
        </Grid> 
    </div>
  );
}
export default connect(mapStateToProps)(CRSInputForm);