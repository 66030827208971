import { faSleigh } from "@fortawesome/free-solid-svg-icons";
import { BasePoint } from "./basePoint";
//import { round } from "float";

const myRound = (coord, digit) => {
  if (coord < 0) {
    return Math.round(-coord*(10**digit))/(10**digit)*-1
  } else {
    return Math.round(coord*(10**digit))/(10**digit)
  }
}

const coordinatesTypes = ["gk", "etrs89", "wgs84", "gk_calculated"];
export class KatasterPoint extends BasePoint {
  constructor(data) {
    super(data);
  }

  /**
   * returns the coordiante Params in form of an Array, to selecte the coordinate Params, a type as a Param is needed.
   * @param {*} coordinatesType 
   */
  getCoordinates(coordinatesType) {
    if (coordinatesTypes.indexOf(coordinatesType) === -1) {
      throw new Error(
        "coordinatesType must be on of these ['gk','etrs89','wgs84','gk_calculated'], not " + coordinatesType 
      );
    }
    switch (coordinatesType) {
      case "gk":
        return [
          myRound(this.gkY , 2) ?? 0, 
          myRound(this.gkX , 2) ?? 0, 
          myRound(this.gkHoeheAdria, 2) ?? 0
        ];
      case "etrs89":
        return [this.etrs89X ?? 0, this.etrs89Y ?? 0, this.etrs89Z ?? 0];
      case "wgs84":
        return [this.wgs84Phi, this.wgs84Lam, this.wgs84Hoehe];
      case "gk_calculated":
        return [
          myRound(this.gkYCalculated, 2) ?? 0,
          myRound(this.gkXCalculated, 2) ?? 0,
          myRound(this.gkHoeheAdriaCalculated, 2) ?? 0
        ];
    }
  }

  /**
   * This function takes and String and splitt it in 4 Components and then checks
   * if this components matches the data of this Point
   * @param {String} name
   */
  isSameName(name) {
    const [type, region, nr, stabilisierung] = this.splitName(name);
    return this.isSamePointByAttributes(type, region, nr, stabilisierung, name);
  } 
  
  /**
   * This function takes and String and splitt it in 3 Components and then checks
   * if this components matches the data of this Point
   * @param {String} name
   */
  isSameNameWithoutStabilisierung(name) {
    const [type, region, nr] = this.splitName(name);
    return this.isSamePointByAttributes(type, region, nr, null, name);
  }

  /**
   * This function takes and old Point Object and deconstruct it in 4 Components and then checks
   * if this components matches the data of this Point
   * @param {Object} point
   */
  isSamePoint(point) {
    const { type, oek, nr, stabilisierung, name } = point;
    return this.isSamePointByAttributes(type, oek, nr, stabilisierung, name);
  }

  /**
   * This functions takse the ole Point Object Parameters and checks if the 4 Attributes are matching,
   * if region and nummer is missing it will check if punktnummer is matching.
   * @param {*} punktArt
   * @param {*} region is KgNummer or Ok
   * @param {*} nummer
   * @param {*} stabilisierung
   * @param {*} punktnummer
   */
  isSamePointByAttributes(
    punktArt,
    region,
    nummer,
    stabilisierung,
    punktnummer
  ) {
    const nummerSameAndNotNull = !!this.nummer ? this.nummer === nummer : false;
    const stabilisierungSameAndNotNull = !!this.stabilisierung ? this.stabilisierung === stabilisierung : false;
    const isThisEp = this.isGivenTypeEp(this.punktArt);
    const isGivenEp = this.isGivenTypeEp(punktArt);
    const isPunktArtSame = isThisEp === isGivenEp;
    const isOekSameAndNotNull = !!this.oek ? this.oek === region : false;
    const isKgNummerSameAnNotNull = !!this.kgNummer ? this.kgNummer === region : false;
    const isPunktnummerSameAndNotNull = !!this.punktnummer ? this.punktnummer === punktnummer : false;

    if(!region && !nummer) return isPunktnummerSameAndNotNull;
    if(!isPunktArtSame) return false;
    if(!!stabilisierung) return nummerSameAndNotNull && (isOekSameAndNotNull || isKgNummerSameAnNotNull) && stabilisierungSameAndNotNull;
    return nummerSameAndNotNull && (isOekSameAndNotNull || isKgNummerSameAnNotNull);
  }

  /**
   * This function takes and old Point Object and deconstruct it in 4 Components and then checks
   * if this components matches the data of this Point
   * @param {Object} point
   */
  isSamePointByObject(point) {
    const {
      punktArt,
      oek,
      kgNummer,
      nummer,
      stabilisierung,
      punktnummer,
    } = point;

    
    //Diff to isSamePointByAtttributes Oek and KgNumber are seperated;
    const nummerSameAndNotNull = !!this.nummer ? this.nummer === nummer : false;
    const stabilisierungSameAndNotNull = !!this.stabilisierung ? this.stabilisierung === stabilisierung : false;
    const isThisEp = this.isGivenTypeEp(this.punktArt);
    const isGivenEp = this.isGivenTypeEp(punktArt);
    const isPunktArtSame = isThisEp === isGivenEp;
    const isOekSameAndNotNull = !!this.oek ? this.oek === oek : false;
    const isKgNummerSameAnNotNull = !!this.kgNummer ? this.kgNummer === kgNummer : false;
    const isPunktnummerSameAndNotNull = !!this.punktnummer ? this.punktnummer === punktnummer : false;

    if(!kgNummer && !oek && !nummer) return isPunktnummerSameAndNotNull;
    if(!isPunktArtSame) return false;
    if(!!stabilisierung) return nummerSameAndNotNull && (isOekSameAndNotNull || isKgNummerSameAnNotNull) && stabilisierungSameAndNotNull;
    return nummerSameAndNotNull && (isOekSameAndNotNull || isKgNummerSameAnNotNull);
  }

  /**
   * Returns true if ETRS coordiantes exist on this Point
   */
  hasValidEtrsCoordinates() {
    const X = this.etrs89X ?? "missing";
    const Y = this.etrs89Y ?? "missing";
    const Z = this.etrs89Z ?? "missing";
    if (X === "missing" || Y === "missing" || Z === "missing") return false;
    return !isNaN(X) || !isNaN(Y) || !isNaN(Z);
  }

  /**
   * Returns true if MGI coordiantes exist on this Point
   */
  hasValidGkCoordinates() {
    const X = this.gkX ?? "missing";
    const Y = this.gkY ?? "missing";
    if (X === "missing" || Y === "missing") return false;
    if (isNaN(X) || isNaN(Y)) return false;
    return X >= 5e6;
  }

  /**
   * Returns true if MGI coordiantes exist on this Point
   */
  hasValidCalculatedGkCoordinates() {
    const X = this.gkXCalculated ?? "missing";
    const Y = this.gkYCalculated ?? "missing";
    if (X === "missing" || Y === "missing") return false;
    if (isNaN(X) || isNaN(Y)) return false;
    return X >= 5e6;
  }

  /**
   * Returns true if WGS coordiantes exist on this Point
   */
  isWgsValid() {
    const phi = this.wgs84Phi ?? "missing";
    const lam = this.wgs84Lam ?? "missing";
    if (phi === "missing" || lam === "missing") return false;
    return !isNaN(phi) || !isNaN(lam);
  }

  /**
   * Returns true if ETRS and MGI coordiantes exist on this Point
   */
  isPassPoint() {
    return this.hasValidEtrsCoordinates() && this.hasValidGkCoordinates();
  }

  /**
   * Creates a Feature Object for the given type, add's
   * selected, angle, lenght and height if data exist on Point
   */
  getFeaturePoint(type) {
    return {
      type: "Feature",
      geometry: {
        type: "Point",
        coordinates: this.getCoordinates(type),
      },
      properties: {
        name: this.punktnummer,
        selected: this.isDeterminationSelected,
        angle: this.residualsAngle,
        length: this.residualsLength,
        height: this.residualsHeight,
        measurement_date: this.etrs89Messdatum,
        layer: 'fpt'
      },
    };
  }
}
