import React from "react";
import { inject, observer } from "mobx-react";
import {
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Checkbox,
  Button,
  FormControlLabel,
  Grid,
  Switch,
  Tooltip,
  ButtonGroup,
} from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDownload } from "@fortawesome/free-solid-svg-icons";
import { faCheckCircle } from "@fortawesome/free-regular-svg-icons";
import { classes } from "../styling";
import { useTranslation } from "react-i18next";
import { ExportDialog } from "containers/Cadastre/ExportDialog";

export const DeterminationOptions = inject("store")(
  observer(({ store, handleClickNext, handleChangeMode, isModeOn }) => {
    const { t } = useTranslation();
    const [isExportDialogOpen, setIsExportDialogOpen] = React.useState(false);

    const toggleExportDialog = () => {
      setIsExportDialogOpen(!isExportDialogOpen);
    }

    return (
      <Grid
        container
        style={{ padding: "1rem 1rem" }}
        alignItems="center"
        spacing={2}
      >
        <Grid item xs={3}>
          <FormControl variant="outlined" fullWidth>
            <InputLabel id="meridian-selection">
              {t("cadastre:selection_options_select_label")}
            </InputLabel>
            <Select
              labelId="meridian-selection-label"
              id="meridian-selection"
              label={t("cadastre:selection_options_select_label")}
              value={store.kataster.meridian}
              onChange={store.kataster.setMeridian}
            >
              <MenuItem value="GK_AUTO">
                {t("cadastre:selection_options_select_auto")}
              </MenuItem>
              <MenuItem value={"GK_M28"}>
                {t("cadastre:selection_options_select_m28")}
              </MenuItem>
              <MenuItem value={"GK_M31"}>
                {t("cadastre:selection_options_select_m31")}
              </MenuItem>
              <MenuItem value={"GK_M34"}>
                {t("cadastre:selection_options_select_m34")}
              </MenuItem>
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={3}>
          <Tooltip
            title={t("cadastre:selection_options_switch_geometry_tooltip")}
          >
            <FormControlLabel
              control={
                <Checkbox
                  value={store.kataster.fix_scale}
                  onChange={(e) => store.kataster.setFixScale(e.target.checked)}
                  name="m1"
                  color="primary"
                />
              }
              label={t("cadastre:selection_options_switch_geometry_label")}
            />
          </Tooltip>
          <Tooltip
            title={t("cadastre:selection_options_switch_height_tooltip")}
          >
            <FormControlLabel
              control={
                <Switch
                  checked={isModeOn}
                  onChange={handleChangeMode}
                  name="height"
                  color="primary"
                />
              }
              label={t("cadastre:selection_options_switch_height_label")}
            />
          </Tooltip>
        </Grid>
        <Grid
          item
          xs={6}
          style={{ display: "flex", justifyContent: "flex-end" }}
        >
          <ButtonGroup style={{ marginRight: 8 }}>
            <Tooltip
              title={t("cadastre:selection_options_btn_protokoll_tooltip")}
            >
              <Button
                variant="outlined"
                color="primary"
                onClick={toggleExportDialog}
              >
                <FontAwesomeIcon icon={faDownload} />
                <span style={{ marginLeft: 8 }}>
                  {t("cadastre:selection_options_btn_protokoll_text")}
                </span>
              </Button>
            </Tooltip>
          </ButtonGroup>
          <ExportDialog toggleOpen={toggleExportDialog} isOpen={isExportDialogOpen} />
          <Tooltip title={t("cadastre:selection_options_btn_next_tooltip")}>
            <Button
              variant="contained"
              color="primary"
              sx={
                store.kataster.isGreen()
                  ? classes.btnNextSuccess
                  : classes.btnNextError
              }
              onClick={handleClickNext}
            >
              <FontAwesomeIcon icon={faCheckCircle} />{" "}
              {store.kataster.pointList.filter((p) => p.isTransformationpoint)
                .length > 0 ? (
                <span style={{ marginLeft: 8 }}>
                  {t("cadastre:selection_options_btn_next_text_one")}
                </span>
              ) : (
                <span style={{ marginLeft: 8 }}>
                  {t("cadastre:selection_options_btn_next_text_two")}
                </span>
              )}
            </Button>
          </Tooltip>
        </Grid>
      </Grid>
    );
  })
);
