import React from 'react';
import { connect } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useTranslation } from 'react-i18next';

const mapStateToProps = (state) => {
  return {
    apiDown: state.auth.apiDown,
  };
}

function AppAPIDownBanner ({apiDown}) {
  const {t} = useTranslation();
      return apiDown ? 
        <div className={'api-error-banner'}>
        <FontAwesomeIcon icon={'exclamation-triangle'} />{t('api_banner_text')}</ div> : null;
}

export default connect(mapStateToProps)(AppAPIDownBanner);
