import axios from 'axios';
import * as constants from '../../common/constants.js';
import errorHandler from '../../common/errorHandler';
import VectorSource from "ol/source/Vector";
import GeoJSON from "ol/format/GeoJSON";
import { Select } from "ol/interaction";

import { pointerMove } from "ol/events/condition";

export const isMapConform = (geoJSON) => {
    const { ellipsoid, frame, heightsystem, prime_meridian, projection } = geoJSON.crs.properties;
    return !!frame && frame !== "" ? (frame === "302" && ellipsoid === "EPSG::7030" && heightsystem === "usage" && prime_meridian === "EPSG::8901" && projection === "") : true;
};

export const correctCadastrePoints = (g) => {
    const { features } = g;
    features.forEach((f) => {
        f.geometry.coordinates[1] = f.geometry.coordinates[1] < 5000000 ? f.geometry.coordinates[1] + 5000000 : f.geometry.coordinates[1];
    });
    return g;
};

export const getTransformedGeoJson = async (g, config) => {
    let req = {
        "geojson": !!checkGeoJsonCadastre(g) ? correctCadastrePoints(JSON.parse(JSON.stringify(g))) : g,
        "source_crs": g.crs.properties,
        "target_crs": {
            frame: "302",
            epoch: 2002.56,
            ellipsoid: "EPSG::7030",
            prime_meridian: "EPSG::8901",
            projection: "",
            heightsystem: "usage",
        }
    };

    try {
        const response = await axios.post(constants.TRANSFORMATOR_API_URL_ADV, req, config);
        return response.data.result.points;
    } catch (error) {
        errorHandler(error);
    }

};

export const checkGeoJsonCadastre = (geoJson) => {
    const { name, properties } = geoJson.crs;
    if (!!name && name.includes('Cadastre')) return true;
    return !!properties && !!geoJson.filetype && properties.frame === '301' && geoJson.filetype === 'csvega';
};

/**
 * checks if json exist and json ist a geoJson and can displayed on Map
 * @param {OpenLayer Layer} layer 
 * @param {GEOJSON} json 
 * @param {function} centerFunction 
 * @param {OpenLayer Map} map 
 */
export const setSource = (layer, json, centerFunction, map) => {
    if (!!json) {
        if (isMapConform(json)) {
            _setSource(layer, json, centerFunction, map);
        }
    }
};

/**
 * roundes up the GEOJson with some props and creates new VectorSource and sets the source of the layer new,
 * if center function exists it will center the layer on the map
 * @param {OpenLayer Layer} layer 
 * @param {GEOJSON} json 
 * @param {function} centerFunction 
 * @param {OpenLayer Map} map 
 */
export const _setSource = (layer, json, centerFunction, map) => {
    json.crs.type = "name";
    json.crs.properties.name = "EPSG:3857";
    const vectorSource = new VectorSource({
        features: new GeoJSON({ featureProjection: "EPSG:3857" }).readFeatures(json),
    });
    if (centerFunction) {
        centerFunction(map, vectorSource);
    }
    layer.setSource(vectorSource);
};

/**
 * Adds Hover effect for layers with attribute name ==== "arrows" || "passPoints"
 * @param {OpenLayer Map} map 
 */
export const _addHoverEffect = (map) => {
    map.on("pointermove", function (event) {
        const mouseCoordInMapPixels = [event.originalEvent.offsetX, event.originalEvent.offsetY];

        //detect feature at mouse coords
        const hit = map.forEachFeatureAtPixel(mouseCoordInMapPixels, function (feature, layer) {
            //Only if Feature is in one of these layers
            return layer && ["arrows", "passPoints"].indexOf(layer.get("name")) > -1;
        });
        const container = map.getTargetElement();
        if (hit) {
            container.style.cursor = "pointer";
        } else {
            container.style.cursor = "";
        }
    });
};

/**
 * Create an Interaction for pointer move on given layer and adds the styling to it.
 * Then adds the Interaction to the Map
 * @param {OpenLayer Layer} layer 
 * @param {OpenLayer Style} style 
 * @param {OpenLayer Map} map 
 */
export const _addInteractionHandlerHoverLabel = (layer, style, map) => {
    const hoverInteraction = new Select({
        condition: pointerMove,
        style: style,
        layers: [layer], //Setting layers to be hovered
        hitTolerance: 5,
    });

    map.addInteraction(hoverInteraction);
};

/**
 * Creates Click Interaction with Style and Layer and adds this to the map.
 * On Select checkst Feature and calls handleOnClick
 * @param {OpenLayer Layer} layer 
 * @param {OpenLayer Style} style 
 * @param {OpenLayer Map} map 
 * @param {function} handleOnClick 
 */
export const _addInteractionHandlerClickPoint = (layer, style, map, handleOnClick) => {
    const clickInteraction = new Select({
        style: style,
        layers: [layer], //Setting layers to be hovered
        hitTolerance: 0,
    });

    clickInteraction.on("select", function (event) {
        let featureSelected = event.selected[0];
        if (!!featureSelected) {
            let layer = clickInteraction.getLayer(featureSelected);
            const feature = event.target.getFeatures().item(0);
            if (feature.getKeys().indexOf("selected") > -1) {
                return handleOnClick(event.target.getFeatures().item(0), layer.get("name"));
            }
        }
    });
    map.addInteraction(clickInteraction);
};

export const getFestpunktById = async (id, config) => {
    try {
        const response = await axios.get(`${constants.KATASTER_SERVICE_API_URL}fpt/${id}?type=small`, config);
        return response.data;
    } catch (error) {
        errorHandler(error);
    }

};