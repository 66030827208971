import { put } from 'redux-saga/effects';
import store from '../../store';
import * as actions from '../actions/points';

export function* addGeometryFile({file}) {
    let geometryList = yield store.getState().points.geometryList;
    yield put(actions.addFile(file));
    yield put(actions.setFileId(geometryList.length - 1));

}

export function* removeGeometryFile(fileId) {
    let x = fileId-1;

    yield put(actions.removeFile(fileId));
    yield put(actions.setFileId(x));
}