import React, { Component } from 'react'
import PropTypes from 'prop-types';

import BevCard from 'components/BevCard';
import PointSource from 'components/PointSource';

import { getCoordType } from 'common/utility';
import {withTranslation} from 'react-i18next';

/* This Component is used to Display the input of Points */
class CoordInput extends Component {

    /**
     * sourceCRS, boolean to check if SourceCrsInput should be diplayed
     * startTransformation, boolean to check if TargetCrsInput should be diplayed
     * showPoints, the CRS object for target
     */
    static propTypes = {
        sourceCRS: PropTypes.object.isRequired, 
        startTransformation: PropTypes.func.isRequired, 
        showPoints: PropTypes.bool.isRequired, 
        isClosed: PropTypes.bool.isRequired,
    }

    /**
     * showPoints, set to true just in case
     */
    static defaultProps = {
        showPoints: true,
    }

    state = {
        tabindex: 1,
    }

    
    handleChangeTabIndex = i => this.setState({tabindex: i});

    render () {
        const { isClosed, sourceCRS, startTransformation, showPoints, toggleOpenPoints, t } = this.props
        const { tabindex } = this.state;

        return <BevCard title={t('farming_coord_input_title')} show={showPoints} open={isClosed} toggleOpen={toggleOpenPoints}>
            <PointSource
                coordType={getCoordType(sourceCRS)} 
                sourceCRS={sourceCRS} 
                onStartTransformation={startTransformation} 
                velocities={true}
                tabindex={tabindex}
                handleChangeTabIndex={this.handleChangeTabIndex}/>
        </BevCard>
    }
    

}

export default withTranslation()(CoordInput);