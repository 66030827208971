export const bevTime = {
    "bev_time_title_card_title" : "Datumstransformation",
    "bev_time_title_card_desc" : "Umrechnung unterschiedlicher Datumsformate.",

    "bev_time_gird_examples_heute" : "Heute",
    "bev_time_gird_examples_epoche1" : "BEV Epoche 2002.56",
    "bev_time_gird_examples_epoche2" : "Epoche 2000.0",

    "bev_time_index_title" : "Datumstransformation",

    "bev_time_grid_input_label_mjd" : "Modifiziertes Julianisches Datum (MJD)",
    "bev_time_grid_input_helper_mjd" : "MJD als Float eingeben" ,

    "bev_time_grid_input_label_bdat" : "Bürgerliches Datum",
    "bev_time_grid_input_helper_bdat" : "YYYY-MM-DD" ,

    "bev_time_grid_input_label_gps" : "GPS Week",
    "bev_time_grid_input_helper_gps" : "WWWW.D" ,

    "bev_time_grid_input_label_doy" : "Year.DOY",
    "bev_time_grid_input_helper_doy" : "YYYY.DOY" ,

    "bev_time_grid_input_label_floating" : "Dezimaljahr",
    "bev_time_grid_input_helper_floating" : "Jahr als Float eingeben" ,
}