export const austrian = {
    "austrian_title_card_title" : "Transformation between ETRS and MGI",
    "austrian_title_card_text" : "Grid transformation between the European coordinate system ETRS89 and the Austrian national surveying system MGI with GIS-Grid and Heightgrid. For example ETRS89 λφh ellipsoidal according to M34 Gebrauchshöhe with GIS-Grid",
    "austrian_parameter_card_title" : "Define parameters for transformation",
    "austrian_parameter_card_von" : "Transform from",
    "austrian_parameter_card_nach" : "Transform to",
    "austrian_parameter_card_lage" : "Transformation of horizontal position",
    "austrian_parameter_card_warning1" : "In order to transform a horizontal position is needed.",
    "austrian_parameter_card_warning2" : "In order to transform a height system is needed in \"FROM\".",
    "austrian_parameter_card_warning3" : "In order to transform a height system is needed in \"TO\".",
    "austrian_parameter_card_warning4" : "Transformation of horizontal position is not needed if the frames in \"FROM\" and \"TO\" are identical",
    "austrian_parameter_card_warning5" : "HELP !!!!",


    "austrian_input_system_label1" : "System",
    "austrian_input_system_label2" : "Height System",


    "austrian_point_source_card_title" : "Points to transform",
    
}