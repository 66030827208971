import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";

import { BASE_URL } from "../../common/constants.js";

import { compareCRS } from "../CRS/compareCRS";
import CheckCRS from "../CRS/CheckCRS";
import { memorySizeOf } from "../../shared/memorySizeOf";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { withTranslation } from "react-i18next";
import { Tooltip, IconButton, Button, TableBody, TableCell, TableRow } from '@mui/material'

GeometryTableBody.propTypes = {
  geometryList: PropTypes.array.isRequired,
  sourceCRS: PropTypes.object,
  clickTooltip: PropTypes.string.isRequired,
  showDataModal: PropTypes.bool,
  showTransformButton: PropTypes.bool,
  handleClickGeometry: PropTypes.func.isRequired,
  handleOpenModal: PropTypes.func.isRequired,
  compareGeom: PropTypes.func.isRequired,
  getElementId: PropTypes.func.isRequired,
  hideName: PropTypes.bool,
  hideGeometriesCount: PropTypes.bool,
  hideFileSize: PropTypes.bool,
  hideFileType: PropTypes.bool,
  hideCRS: PropTypes.bool,
  useCompare: PropTypes.bool,
  transformButtonText: PropTypes.string,
};

export default function GeometryTableBody({
  geometryList,
  sourceCRS,
  clickTooltip,
  offset,
  pageSize,
  useCompare = true,
  transformButtonText,
  handleClickGeometry,
  handleOpenModal,
  compareGeom,
  getElementId,
  showTransformButton,
  showDataModal,
  hideName,
  hideGeometriesCount,
  hideFileSize,
  hideFileType,
  hideCRS,
}) {
  const handleOpenGeometryModal = (g) => {
    handleOpenModal(g);
  };
  const sortedList = [...geometryList];
  return (
    <TableBody>
      {sortedList
        .filter((geometry) => {
          return !!geometry.crs.properties;
        })
        .sort(compareGeom)
        .map(
          (g, i) =>
            offset <= i &&
            i < offset + pageSize && (
              <GeometryTableElement
                key={i}
                id={getElementId(g)}
                CRSProps={g.crs.properties}
                name={g.name}
                geometriesCount={g.features.length}
                sourceCRSProps={sourceCRS ? sourceCRS.properties : null}
                fileSize={memorySizeOf(g)}
                fileType={g.filetype}
                crs={g.crs}
                clickTooltip={clickTooltip}
                showTransformButton={showTransformButton}
                showDataModal={showDataModal}
                hideName={hideName}
                hideGeometriesCount={hideGeometriesCount}
                hideFileSize={hideFileSize}
                hideFileType={hideFileType}
                hideCRS={hideCRS}
                handleClickGeometry={handleClickGeometry.bind(
                  this,
                  getElementId(g)
                )}
                openModal={handleOpenGeometryModal.bind(this, g)}
                useCompare={useCompare}
                transformButtonText={transformButtonText}
              />
            )
        )}
    </TableBody>
  );
}

GeometryTableBody.propTypes = {
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  CRSProps: PropTypes.object,
  name: PropTypes.string,
  geometriesCount: PropTypes.number,
  fileSize: PropTypes.string,
  fileType: PropTypes.string,
  crs: PropTypes.object,
  sourceCRSProps: PropTypes.object,
  clickTooltip: PropTypes.string.isRequired,
  handleClickGeometry: PropTypes.func.isRequired,
  openModal: PropTypes.func,
  showTransformButton: PropTypes.bool,
  showDataModal: PropTypes.bool,
  hideName: PropTypes.bool,
  hideGeometriesCount: PropTypes.bool,
  hideFileSize: PropTypes.bool,
  hideFileType: PropTypes.bool,
  hideCRS: PropTypes.bool,
  transformButtonText: PropTypes.string,
  useCompare: PropTypes.bool,
};

export const GeometryTableElement = withTranslation()(
  ({
    id,
    CRSProps,
    name,
    geometriesCount,
    fileSize,
    fileType,
    crs,
    sourceCRSProps,
    clickTooltip,
    transformButtonText = "Transform",
    useCompare = true,
    handleClickGeometry,
    openModal,
    showTransformButton,
    showDataModal,
    hideName,
    hideGeometriesCount,
    hideFileSize,
    hideFileType,
    hideCRS,
    t,
  }) => {
    return (
      <TableRow>
        {showTransformButton && (
          <TableCell padding="checkbox">
            {useCompare && !compareCRS(CRSProps, sourceCRSProps) && (
              <Tooltip title={t("point_source_geometry_body_tooltip")}>
                <span>
                  <FontAwesomeIcon
                    style={{ margin: "0 0 -7px 7px", fontSize: "23px" }}
                    icon="exclamation-triangle"
                  />
                </span>
              </Tooltip>
            )}
          </TableCell>
        )}
        {showTransformButton && (
          <TableCell padding="checkbox">
            <Tooltip title={clickTooltip}>
              <Button
                variant="outlined"
                color="primary"
                onClick={handleClickGeometry}
              >
                {transformButtonText}
                <FontAwesomeIcon
                  style={{ margin: "0 0 0 5px" }}
                  icon="sign-out-alt"
                />
              </Button>
            </Tooltip>
          </TableCell>
        )}
        {!hideName && (
          <Tooltip title={t("point_source_geometry_body_tooltip_point")}>
            <TableCell>
              <Button
                sx={{
                  root: {
                    width: "100%",
                    justifyContent: "flex-start",
                    paddingLeft: "0",
                    textTransform: "none",
                    "&:hover": {
                      textDecoration: "underline",
                    },
                  },
                }}
                color="primary"
                component={Link}
                to={BASE_URL + "geommanager/" + id}
              >
                {name}
              </Button>
            </TableCell>
          </Tooltip>
        )}
        {!hideGeometriesCount && <TableCell>{geometriesCount}</TableCell>}
        {!hideFileSize && <TableCell>{fileSize}</TableCell>}
        {!hideFileType && <TableCell>{fileType}</TableCell>}
        {!hideCRS && (
          <TableCell>
            <CheckCRS crs={crs} />
          </TableCell>
        )}
        {showDataModal && (
          <TableCell>
            <Tooltip title={t("point_source_geometry_body_tooltip_geometrie")}>
              <IconButton aria-label="display" onClick={openModal}>
                <FontAwesomeIcon icon="eye" />
              </IconButton>
            </Tooltip>
          </TableCell>
        )}
      </TableRow>
    );
  }
);
