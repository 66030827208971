import React from 'react';
import PropTypes from 'prop-types';

import IconButton from '@mui/material/IconButton';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

CRSIconButton.propTypes = {
  icon: PropTypes.string.isRequired,
  handleClick: PropTypes.func.isRequired,
}

export default function CRSIconButton ({handleClick, icon}) {
  return (
  <IconButton variant='outlined' color="default" onClick={handleClick}>
    <FontAwesomeIcon icon={icon} />
  </IconButton>
  );
}