import * as actionTypes from '../actions/actionTypes';
import { updateObject } from 'common/utility';

const initialState = {
    sourceCRS : '',
    targetCRS : '',

    sourceHeight: '',
    targetHeight: '',

    transformationMethod: '',

    pointKeys: {
      "geocentric": ['X', 'Y', 'Z'],
      "geographic": ['lam', 'phi', 'ele'],
      "projected": ['east', 'north', 'height'],
    },

    crs: {
      mgi_gk28: {type: 'BEV', properties: {frame: "301", ellipsoid: "EPSG::7004", prime_meridian: "EPSG::8901", epoch: 2002.56, projection: "BEV::10028"}},
      mgi_gk31: {type: 'BEV', properties: {frame: "301", ellipsoid: "EPSG::7004", prime_meridian: "EPSG::8901", epoch: 2002.56, projection: "BEV::10031"}},
      mgi_gk34: {type: 'BEV', properties: {frame: "301", ellipsoid: "EPSG::7004", prime_meridian: "EPSG::8901", epoch: 2002.56, projection: "BEV::10034"}},
      mgi_lcc: {type: 'BEV', properties: {frame: "301", ellipsoid: "EPSG::7004", prime_meridian: "EPSG::8901", epoch: 2002.56, projection: "EPSG::19947"}},
      mgi_lph: {type: 'BEV', properties: {frame: "301", ellipsoid: "EPSG::7004", prime_meridian: "EPSG::8901", epoch: 2002.56, projection: ""}},
      mgi_xyz: {type: 'BEV', properties: {frame: "301", ellipsoid: "", prime_meridian: "", epoch: 2002.56, projection: ""}},
      etrs89_utm32n: {type: 'BEV', properties: {frame: "209", ellipsoid: "EPSG::7019", prime_meridian: "EPSG::8901", epoch: 2002.56, projection: "EPSG::16032"}},
      etrs89_utm33n: {type: 'BEV', properties: {frame: "209", ellipsoid: "EPSG::7019", prime_meridian: "EPSG::8901", epoch: 2002.56, projection: "EPSG::16033"}},
      etrs89_lcc: {type: 'BEV', properties: {frame: "209", ellipsoid: "EPSG::7019", prime_meridian: "EPSG::8901", epoch: 2002.56, projection: "EPSG::19947"}},
      etrs89_lph: {type: 'BEV', properties: {frame: "209", ellipsoid: "EPSG::7019", prime_meridian: "EPSG::8901", epoch: 2002.56, projection: ""}},
      etrs89_xyz: {type: 'BEV', properties: {frame: "209", ellipsoid: "", prime_meridian: "", epoch: 2002.56, projection: ""}},
    }
}

const changeSystem = (state, action) => {
  const key = action.key;
  const value = action.value;
  return updateObject(state, {
    [key]: value,
  });
};

const setAutTransformationMethod = (state, action) => {
    return updateObject( state, {
        transformationMethod: action.transformationMethod
    })
}

const reducer = ( state = initialState, action) => {
    switch ( action.type ) {
        case actionTypes.AUT_SET_SOURCE_CRS: return updateObject( state, { sourceCRS: action.sourceCRS });
        case actionTypes.AUT_SET_TARGET_CRS: return updateObject( state, { targetCRS: action.targetCRS });
        case actionTypes.AUT_SET_SOURCE_HS: return updateObject( state, { sourceCRS: action.sourceCRS });
        case actionTypes.AUT_SET_TARGET_HS: return updateObject( state, { targetCRS: action.targetCRS });
        case actionTypes.AUT_SET_TM: return setAutTransformationMethod( state, action );
        case actionTypes.AUT_CHANGE_SYSTEM: return changeSystem(state, action);

        default:
            return state;
    }
}

export default reducer;
