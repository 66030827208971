import * as actionTypes from '../actions/actionTypes';
import * as constants from '../../common/constants.js';

const initialState = {
    optionsTimestamp: 0,
    frameList : [
        { value: constants.FRAME_ETRS89, display_name: 'ETRS89' },
        { value: constants.FRAME_ETRS2000, display_name: 'ETRS2000' },
        { value: constants.FRAME_MGI, display_name: 'MGI' },
    ],
    ellipsoidList : [
        { value: constants.ELLIPSOID_BESSEL, display_name: 'Bessel'},
        { value: constants.ELLIPSOID_GRS80, display_name: 'GRS80'}
    ],
    prime_meridianList : [
        { value: constants.MERIDIAN_GREENWICH, display_name: "Greenwhich"},
        { value: constants.MERIDIAN_FERRO, display_name: 'Ferro'}
    ],
    projectionList : [
        { value: constants.PROJ_MGI_GK28, display_name: 'MGI_GK_M28'},
        { value: constants.PROJ_MGI_GK31, display_name: 'MGI_GK_M31'},
        { value: constants.PROJ_MGI_GK34, display_name: 'MGI_GK_M34'},
    ],
    heightSystemList: [
        // { value: constants.HEIGHT_ELLIPSOID, display_name: 'Ellipsoidic'},
        // { value: constants.HEIGHT_ORTHO, display_name: 'Orthometric'},
        // { value: constants.HEIGHT_USAGE, display_name: 'Common used'},
    ],
    transformationMethodList: [
        { value: constants.TM_GIS_GRID, display_name: 'GIS Grid (sigma: 0.15m)'},
        { value: constants.TM_SEVEN_PARAM, display_name: '7-Parameter (sigma: 1.50m)'},
    ],
    verticalGridList: [
        // { value: constants.VG_GEOID_GRS80, display_name: 'Ell. -> Ortho. GRS80'},
        // { value: constants.VG_GEOID_BESSEL, display_name: 'Ell. -> Ortho. Bessel'},
        // { value: constants.VG_HEIGHT, display_name: 'Orth. -> Gebr. GRS80'},
        // { value: constants.VG_HEIGHT_PLUS, display_name: 'Ell. -> Gebr. GRS80'},
    ],
    austrianList: []
}

const reducer = ( state = initialState, action) => {
    switch ( action.type ) {

        // case actionTypes.API_UPDATE_VERTICAL_GRID_LIST: return apiUpdateVerticalGridList( state, action );
        // case actionTypes.API_UPDATE_HEIGHT_SYSTEM_LIST: return apiUpdateHeightSystemList( state, action );

        case actionTypes.API_UPDATE_ADVANCED_OPTIONS: 
            return {...state, frameList: action.data.frame.choices, ellipsoidList: action.data.ellipsoid.choices,
                prime_meridianList: action.data.prime_meridian.choices, projectionList: action.data.projection.choices }
        
        case actionTypes.API_UPDATE_TIMESTAMP: 
            return {...state, optionsTimestamp: new Date().getTime() }

        case actionTypes.API_UPDATE_AUSTRIAN_OPTIONS:
            return { ...state, heightSystemList: action.data.source_height_system.choices, transformationMethodList: action.data.transformation_method.choices, 
                austrianList: action.data.source_crs.choices }

        default:
            return state;
    }
}

export default reducer;
