import axios from 'axios';
import * as constants from '../../common/constants.js';
import errorHandler from '../../common/errorHandler';

export const isMapConform = (geoJSON) => {
    const {ellipsoid,frame,heightsystem,prime_meridian, projection} = geoJSON.crs.properties;
    return !!frame && frame !== "" ? ( frame === "302" && ellipsoid === "EPSG::7030" && heightsystem === "usage" && prime_meridian === "EPSG::8901" && projection === "" ) : true;
};

export const correctCadastrePoints = (g) => {
    const {features} = g;
    features.forEach((f) => {
        f.geometry.coordinates[1] = f.geometry.coordinates[1] < 5000000 ? f.geometry.coordinates[1] + 5000000: f.geometry.coordinates[1];
    });
    return g;
};


export const getTransformedGeoJson = async (g, config) => {
    let req = {
        "geojson": !!checkGeoJsonCadastre(g) ? correctCadastrePoints(JSON.parse(JSON.stringify(g))) : g,
        "source_crs": g.crs.properties,
        "target_crs": {
            frame: "302",
            epoch: 2002.56,
            ellipsoid: "EPSG::7030",
            prime_meridian: "EPSG::8901",
            projection: "",
            heightsystem: "usage",
        }
    };

    try{
        const response = await axios.post(constants.TRANSFORMATOR_API_URL_ADV, req, config);
        return response.data.result.points;
    }catch (error){
        errorHandler(error);
    }

};

export const checkGeoJsonCadastre = (geoJson) => {
    const {name, properties} = geoJson.crs;
    if(!!name && name.includes('Cadastre')) return true;
    return !!properties && !!geoJson.filetype && properties.frame === '301' && geoJson.filetype === 'csvega';
};