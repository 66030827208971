import React from "react";
import {Box} from "@mui/material";

export function TabPanel({children, value, index, p, ...other}) {
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`scrollable-force-tabpanel-${index}`}
        aria-labelledby={`scrollable-force-tab-${index}`}
        {...other}
      >
        {value === index && <Box p={p || 3}>{children}</Box>}
      </div>
    );
  }