import React from 'react';
import PropTypes from 'prop-types';

import {ListItem, ListItemText, Avatar, useTheme} from '@mui/material';

import { prettifyBigNumber } from "common/utility";


/**
 * feature: Is the Geometry Object wich will be rendered, is an Object, is required, no default
 */
Point.propTypes = {
    feature: PropTypes.object.isRequired
}

function Point({feature}) {
    const theme = useTheme();
    const {main} = theme.palette.primary;
    let coordTextSpan = <span>
          Point coordinates: [{feature.geometry.coordinates.map((c,i) => <span key={i}>{prettifyBigNumber(c,3)}{feature.geometry.coordinates.length-1 !== i && <span>,{" "}</span> }</span>)}]
        { feature.geometry?.velocities?.length > 0 ? <br/> : null}
        { feature.geometry?.velocities?.length > 0 ? <span>Point velocities: [{feature.geometry.velocities.map((c,i) => <span key={i}>{prettifyBigNumber(c, 4)}{feature.geometry.velocities.length-1 !== i && <span>,{" "}</span> }</span>)}]</span>
        : null}
        </span>
    return <ListItem>
        <Avatar style={{backgroundColor: main, marginRight: '10px'}}>{!!feature.properties.type ? feature.properties.type : 'P'}</Avatar>
        <ListItemText primary={feature.properties.name} secondary={coordTextSpan}/>
    </ListItem>;
}

export default Point;