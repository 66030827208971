export const cadastre = {
    "cadastre_error_four_ponts" : "Geben Sie mindestens 4 {{type}} Punkte ein.",
    "cadastre_error_points_dont_match" : "Die Passpunkte stimmen nicht überein.",
    "cadastre_url_params" : "Einstellungen aus GET-Parametern übernommen.",
    "cadastre_url_params_success" : "Alle Parameter vorhanden.",
    "cadastre_url_params_error_missing" : "Eingabe bei mgi_points oder etrs_points unvollständig.",
    "cadastre_url_params_error_format" : "Parameter sind nicht im JSON-Format",

    "cadastre_title_card_title" : "Kataster Mode",
    "cadastre_title_card_text" : "Berechnung eines GNSS-Festpunktanschlusses gemäß den Richtlinien der aktuellen Vermessungsverordnung. Bestimmung von Transformationsparametern für eine Zwei-Schritt Helmert-Transformation und anschliessender Transformation der Neupunkte. Das Ergebnis wird in Form eines Protokolls ausgegeben, welches direkt Ihrer Vermessungsurkunde beigelegt werden kann",

    "cadastre_point_input_title" : "Eingabe der Passpunkte",
    "cadastre_point_input_btn_koordinatenverzeichnis" : "CSV-Koordinatenverzeichnis lt VermV §8 Z6 laden",
    "cadastre_point_input_field_etrs_load" : "ETRS89-Passpunkte laden",
    "cadastre_point_input_field_mgi_load" : "MGI-Passpunkte laden",
    "cadastre_point_input_btn_start" : "Berechnung starten",

    "cadastre_point_input_modal_btn_import" : "Dateien importiern",
    "cadastre_point_input_modal_btn_load" : "Punkte laden",

    "cadastre_point_edit_modal_title": "Bearbeite Punkt {{pointName}}",
    "cadastre_point_edit_modal_label_point": "Punktname",
    "cadastre_point_edit_modal_label_point_placeholder": "Punktname...",
    "cadastre_point_edit_modal_label_coord1_placeholder": "Koordinate 1",
    "cadastre_point_edit_modal_label_coord2_placeholder": "Koordinate 2",
    "cadastre_point_edit_modal_label_coord3_placeholder": "Koordinate 3",
    "cadastre_point_edit_modal_btn_save": "Speichern",
    "cadastre_point_edit_modal_btn_delete": "Löschen",

    "cadastre_point_selection_title": "Auswahl der Berechnungsgrundlage",
    "cadastre_point_selection_btn": "Übernehmen",

    "cadastre_transformation_params_title": "Parameter:",
    "cadastre_transformation_params_btn_pdf": "PDF-Transformationsprotokoll",
    "cadastre_transformation_params_btn_json": "Parameter (JSON)",

    "cadastre_point_table_container_switch_label": "m = 1, Innere Geometrie erhalten",
    "cadastre_point_table_label_name": "Punktname",
    "cadastre_point_table_label_r_east": "r_Rechtswert [cm]",
    "cadastre_point_table_label_r_north": "r_Hochwert [cm]",
    "cadastre_point_table_label_r_lage": "r_Lage [cm]",
    "cadastre_point_table_label_r_hight": "r_Höhe [cm]",
    "cadastre_point_table_label_east": "nv_Rechtswert []",
    "cadastre_point_table_label_north": "nv_Hochwert []",
    "cadastre_point_table_label_use": "verwenden",

    "cadastre_point_trafo_input_title": "Eingabe für Transformation",
    "cadastre_point_trafo_input_btn_load": "Punkte laden",
    "cadastre_point_trafo_input_btn_start": "Berechnung starten",

    "cadastre_system_import_modal_title": "Wähle das erste System",
    "cadastre_system_import_modal_tab": "Punktliste",
    
}