export function memorySizeOf(obj) {
    var bytes = 0;

    function sizeOf(obj) {
        if(obj !== null && obj !== undefined) {
            switch(typeof obj) {
            case 'number':
                bytes += 8;
                break;
            case 'string':
                bytes += obj.length * 2;
                break;
            case 'boolean':
                bytes += 4;
                break;
            case 'object':
                var objClass = Object.prototype.toString.call(obj).slice(8, -1);
                if(objClass === 'Object' || objClass === 'Array') {
                    for(var key in obj) {
                        if(!obj.hasOwnProperty(key)) continue;
                        sizeOf(obj[key]);
                    }
                } else bytes += obj.toString().length * 2;
                break;
            default:
                return null;
            }
            
        }
        return bytes;
    };

    function formatByteSize(bytes) {
        if(bytes < 1048576) return(bytes / 1024).toFixed(1) + " KB";
        else if(bytes < 1073741824) return(bytes / 1048576).toFixed(1) + " MB";
        else return(bytes / 1073741824).toFixed(1) + " GB";
    };

    return formatByteSize(sizeOf(obj));
};
