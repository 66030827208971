import * as actionTypes from './actionTypes';



// reducers
export const setProgress = (value) => ({ type: actionTypes.BASE_SET_PROGRESS, value: value })
export const addCRS = (crs) => ({ type: actionTypes.BASE_ADD_CRS, crs: crs })
export const addTrafo = (trafo) => ({ type: actionTypes.BASE_ADD_TRAFO,trafo: trafo })
export const setSolution = (sol) => ({ type: actionTypes.BASE_SET_SOLUTION,showSolution: sol })
export const baseChangeParameterExpand = (value) => ({ type: actionTypes.BASE_CHANGE_PARAMETER_EXPAND,parameterExpand: value })
export const changePointExpand = (value) => ({ type: actionTypes.BASE_CHANGE_POINT_EXPAND,pointExpand: value })
export const changeSolutionExpand = (value) => ({ type: actionTypes.BASE_CHANGE_SOLUTION_EXPAND,solutionExpand: value })
export const toggleCGOpen = () => ({ type: actionTypes.BASE_TOGGLE_CG_OPEN, })
export const setCookie = (value) =>({ type: actionTypes.BASE_SET_COOKIE, isCookieAkzepted: value})

