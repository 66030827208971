import React, {useEffect, useRef, useState} from 'react';

import 'ol/ol.css';
import {Map, View} from 'ol';
import {fromLonLat} from "ol/proj";
import {Select} from "ol/interaction";
import {pointerMove} from "ol/events/condition";
import TileLayer from 'ol/layer/Tile';
import VectorLayer from 'ol/layer/Vector';
import OSM from 'ol/source/OSM';
import VectorSource from 'ol/source/Vector';
import GeoJSON from 'ol/format/GeoJSON';
import {ScaleLine, Attribution, ZoomSlider, Zoom} from 'ol/control';

import {json} from './austiranGeoJson';

import getBaseMap from "./getBaseMap";
import {GeoJsonStyleFunction, ArrowStyleFunction, ExtraJsonStyleFunction, ExtraJsonStyleFunctionHover} from "./styling";

import {isMapConform, getTransformedGeoJson} from "./helperFunctions";

export default function OpenLayerMap({solutionJson, arrowJson, extraJson, config, getResiduals, isUnselected, handleOnClick}) {

    const [map, setMap] = useState(null);
    const [vectorLayerSolutionJson, setVectorLayerSolutionJson] = useState(null);
    const [vectorLayerExtraJson, setVectorLayerExtraJson] = useState(null);
    const [vectorLayerArrow, setVectorLayerArrow] = useState(null);
    const [vectorLayerAustria, setVectorLayerAustria] = useState(null);
    const [nextTrySolution, setNextTrySolution] = useState(true);
    const [nextTryExtra, setNextTryExtra] = useState(true);
    const [nextTryArrow, setNextTryArrow] = useState(true);
    const mapContainer = useRef(null);

    // OnInit set init map an layers,
    useEffect(() => {

        const view = new View({
            center: fromLonLat([15, 47]),
            zoom: 7,
            maxZoom: 21,
        });

        const titleLayer = new TileLayer({source: new OSM(), title: 'OpenStreetMap', showInLegend: true, visible: true, zIndex: 20});

        const newMap = new Map({
            layers: [titleLayer],
            target: mapContainer.current,
            view: view,
            controls: [new ScaleLine(), new Attribution(), new ZoomSlider(), new Zoom()]
        });

        const vectorLayerSolutionInit = new VectorLayer({
            style: GeoJsonStyleFunction,
            zIndex: 48
        });

        const vectorLayerArrowInit = new VectorLayer({
            style: ArrowStyleFunction(getResiduals, isUnselected),
            zIndex: 50
        });

        const vectorLayerExtraInit = new VectorLayer({
            style: ExtraJsonStyleFunction,
            zIndex: 49
        });

        const vectorLayerAustriaInit = new VectorLayer({style: GeoJsonStyleFunction, visible: false, zIndex: 1});

        setVectorLayerAustria(vectorLayerAustriaInit);
        setVectorLayerExtraJson(vectorLayerExtraInit);
        setVectorLayerSolutionJson(vectorLayerSolutionInit);
        setVectorLayerArrow(vectorLayerArrowInit);

        getBaseMap(newMap, (nextMap) => {
            newMap.addLayer(vectorLayerAustriaInit);
            newMap.addLayer(vectorLayerExtraInit);
            newMap.addLayer(vectorLayerSolutionInit);
            newMap.addLayer(vectorLayerArrowInit);
            setLayer(vectorLayerAustriaInit, json, setVectorLayerAustria, nextMap);
            setMap(nextMap);
        });

        return () => {setMap(null)};
    }, []);

    // On Map change add Control and PointerMove event
    useEffect(() => {
            if (!!map) {
                _addHoverEffect(map);
                _addInteractionHandlerArrowLayer(map, vectorLayerArrow);
                _addInteractionHandlerDisplayLabel(map, vectorLayerExtraJson);
                map.on('moveend', function() {
                        const center = map.getView().getCenter();
                        const inAustria = vectorLayerAustria.getSource().getFeatures().reduce((a, b) => a ? true : b.getGeometry().intersectsCoordinate(center), false);
                        const newZoom = map.getView().getZoom();
                        map.getLayerGroup().getLayers().forEach(element => {
                            if (element.get('title') === 'Orthophoto') {
                                element.setZIndex(newZoom >= 15 && inAustria ? 40 : 10);
                            }
                        });
                    }
                )
            }
        },
        [map]
    );

    // On Prop arrowJson change render new arrowJson
    useEffect(() => {
        let timer;
        if(!!map){
            setSource(vectorLayerArrow, arrowJson, _centerOnLayer('Arrow'));
        }else{
            timer = setTimeout(() => setNextTryArrow(!nextTryArrow),500);
        }
        return () => clearTimeout(timer);
    }, [arrowJson, nextTryArrow]);

    // On Prop extraJson change render new extraJson
    useEffect(() => {
        let timer;
        if(!!map){
            setSource(vectorLayerExtraJson, extraJson, _centerOnLayer('Extra'));
        }else{
            timer = setTimeout(() => setNextTryExtra(!nextTryExtra),500);
        }
        return () => clearTimeout(timer);
    }, [extraJson, nextTryExtra]);

    // On Prop solutionJson change render new solutionJson
    useEffect(() => {
        let timer;
        if (!!map) {
            setSource(vectorLayerSolutionJson, solutionJson, _centerOnLayer('Solution'));
        }else{
            timer = setTimeout(() => setNextTrySolution(!nextTrySolution),500);
        }
        return () => clearTimeout(timer);
    }, [solutionJson, nextTrySolution]);

    const centerMapView = (mapObject, vectorSource) => {
        mapObject.getView().fit(vectorSource.getExtent(), {padding: [50, 100, 50, 100]});
    };

    const setLayer = (layer, json, setter, mapObject, center) => {
        const changedMap = mapObject;
        if (!!changedMap && !!json) {
            if (isMapConform(json)) {
                _setLayer(layer, json, setter, changedMap, center);
            } else {
                getTransformedGeoJson(json, config).then(transformedJson => {
                    _setLayer(layer, transformedJson, setter, changedMap, center);
                })
            }
        }
    };

    const setSource = (layer, json, center) => {
        if (!!json) {
            if (isMapConform(json)) {
                _setSource(layer, json, center);
            } else {
                getTransformedGeoJson(json, config).then(transformedJson => {
                    _setSource(layer, transformedJson, center);
                })
            }
        }
    };

    const _setLayer = (layer, json, setter, mapObject, center) => {
        json.crs.type = "name";
        json.crs.properties.name = "EPSG:3857";
        const vectorSource = new VectorSource({
            features: (new GeoJSON({featureProjection: 'EPSG:3857'})).readFeatures(json),
        });
        if (center) {centerMapView(mapObject, vectorSource);}
        layer.setSource(vectorSource);
        //mapObject.addLayer(layer);
    };

    const _setSource = (layer, json, center) => {
        json.crs.type = "name";
        json.crs.properties.name = "EPSG:3857";
        const vectorSource = new VectorSource({
            features: (new GeoJSON({featureProjection: 'EPSG:3857'})).readFeatures(json),
        });
        if (center) {centerMapView(map, vectorSource);}
        layer.setSource(vectorSource);
    };

    const _addInteractionHandlerArrowLayer = (mapObject, layer) => {
        if (!!handleOnClick) {
            const hoverInteraction = new Select({
                condition: pointerMove,
                style: ArrowStyleFunction(getResiduals, isUnselected, true),
                layers: [layer],  //Setting layers to be hovered
                hitTolerance: 10
            });

            const clickInteraction = new Select({
                style: ArrowStyleFunction(getResiduals, isUnselected, true),
                layers: [layer],  //Setting layers to be hovered
                hitTolerance: 10
            });

            clickInteraction.on('select', function(event) {
                handleOnClick(event.target.getFeatures().item(0).get('name'));
            });

            mapObject.addInteraction(hoverInteraction);
            mapObject.addInteraction(clickInteraction);
        }
    };

    const _addInteractionHandlerDisplayLabel = (mapObject, layer) => {
        const hoverInteraction = new Select({
            condition: pointerMove,
            style: ExtraJsonStyleFunctionHover,
            layers: [layer],  //Setting layers to be hovered
            hitTolerance: 2
        });

        mapObject.addInteraction(hoverInteraction);
    };


    const _addHoverEffect = (mapObject) => {
        if (!!handleOnClick) {
            mapObject.on("pointermove", function(event) {
                const mouseCoordInMapPixels = [event.originalEvent.offsetX, event.originalEvent.offsetY];

                //detect feature at mouse coords
                const hit = mapObject.forEachFeatureAtPixel(mouseCoordInMapPixels, function(feature, layer) {
                    return true;
                });

                if (hit) {
                    mapContainer.current.style.cursor = "pointer";
                } else {
                    mapContainer.current.style.cursor = "";
                }
            });
        }
    };

    const _centerOnLayer = (layerName) => {
        if(layerName === 'Solution' && !!solutionJson){
            return true;
        }else if(layerName === 'Arrow' && !solutionJson && !!arrowJson){
            return true;
        }else{
            return layerName === 'Extra' && !solutionJson && !arrowJson && !!extraJson;
        }
    }


    return <div id="mapContainer" ref={mapContainer} style={{width: '100%', height: '100%'}}/>
};