import React from 'react';

import * as actions from '../store/actions';

import { connect } from 'react-redux';
import { Box, Typography, Button } from '@mui/material';

const mapStateToProps = state => {
  return {
    cookie: state.base.isCookieAkzepted,
  }
}

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    setCookie: () => dispatch(actions.base.setCookie(true))
  }
}

function CookieInfoFunc({ classes, cookie, setCookie }) {
  return !cookie && <Box sx={{
    position: 'fixed',
    bottom: '0',
    left: '0',
    right: '0',
    padding: '40px 100px',
    zIndex: '99999',
    color: '#ddd',
    borderTop: '2px solid red',
    backgroundColor: 'rgba(0,0,0,0.8)',
    fontSize: '16px'
  }} >
    <Typography>
      Um alle Funktionen des BEV - Transformators verwenden zu können, werden
      <b>Cookies</b> und der <b>LocalStorage</b> Ihres Browsers eingesetzt.
      Beim LocalStorage werden benutzerbezogene Daten (Punktliste) im Browser
      des Benutzers abgelegt. Die Daten bleiben lokal und werden nicht vom BEV
      weiter verwendet.
    </Typography>
    <Button variant="contained" color="primary" sx={{ fontSize: '16px' }} onClick={setCookie}>Cookieverwendung akzeptieren!</Button>
  </Box >
}

const CookieInfo = connect(mapStateToProps, mapDispatchToProps)(CookieInfoFunc);
export default CookieInfo;
