import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { ExpandableListElement, PointList } from './geometriesListHelper';
import { DEFAULT_PAGE_SIZE } from '../constants';

import deepPurple from '@mui/material/colors/deepPurple';

/**
 * feature: is the Geometry Object, no default, is required
 * pageSize: is the number of elements that will be displayed on one Pagination Page, default 3, is not required
 * typeName: is the String that will be displayed in the Avatar Icon, default is 'MP', is not required
 * secoundary: is the String that will be diplayed in the First line of the Element, default is 'MultiPoint', is not required
 * backgroundColor: is an Style string for the Background of the Avatar Icon, default deepPurple[400], is not required
 */
export default class MultiPoint extends Component {

  static propTypes = {
    feature: PropTypes.object.isRequired,
    pageSize: PropTypes.number,
    typeName: PropTypes.string,
    secondary: PropTypes.string,
    backgroundColor: PropTypes.string
  }
  static defaultProps = {
    pageSize:DEFAULT_PAGE_SIZE,
    typeName:'MP',
    secondary: 'MultiPoint',
    backgroundColor: deepPurple[400]
  }

  state = {        
    showPoints: false,
    offset: 0
  }

  togglePoints = (event) => {
    this.setState({ showPoints: !this.state.showPoints });
  }

  handleChangeOffset = (offset) => {
    this.setState({offset});
  }

  render() {
    const { feature, typeName, secondary, backgroundColor, pageSize } = this.props;
    return (
      <div>
        <ExpandableListElement 
          typeName={typeName} 
          primary={feature.properties.name} 
          secondary={secondary +" ("+feature.geometry.coordinates.length+")"} 
          style={{backgroundColor: backgroundColor,cursor: 'pointer', marginRight: '10px' }} 
          toggleList={this.togglePoints} showList={this.state.showPoints}/>
        <PointList 
          feature={feature} 
          offset={this.state.offset} 
          show={this.state.showPoints} 
          pageSize={pageSize}  
          listLength={feature.geometry.coordinates.length} 
          handleChangeOffset={this.handleChangeOffset} />
        </div>
      );
    }
}

