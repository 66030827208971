import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Alert } from "@mui/material";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { toast } from "react-toastify";

import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import Button from "@mui/material/Button";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import * as actions from "../../store/actions";

import {
  getFile,
  getFileName,
  parseToString,
} from "../../common/parsers/stringParser";
import { compareWithKmlCrs } from "../CRS/compareCRS";
import { withTranslation } from "react-i18next";

const mapStateToProps = (state) => {
  return {
    geometryList: state.points.geometryList,
    fileId: state.points.fileId,
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    addGeometryFile: (event) => dispatch(actions.points.addGeometryFile(event)),
  };
};

const style = {
  pre: {
    maxHeight: "300px",
    margin: "10px",
    border: "solid 1px #ccc",
    padding: "10px",
  },
  btnWrapper: {},
  btn: {
    marginLeft: "10px",
  },
  icon: { marginRight: "5px" },
  label: { color: "black" },
};

class ExportFiles extends Component {
  static propTypes = {
    inputformat: PropTypes.string,
    formats: PropTypes.array,
    file: PropTypes.object.isRequired,
  };
  static defaultProps = {
    inputformat: "json",
  };
  state = {
    inputformat: "json",
  };

  componentDidMount() {
    this.setState((prevState) => {
      return { inputformat: this.props.inputformat };
    });
  }

  startExport = () => {
    const { file } = this.props;
    const { inputformat } = this.state;

    let element = document.createElement("a");
    element.href = URL.createObjectURL(getFile(file, inputformat));
    element.download = getFileName(file.name, inputformat);

    element.style.display = "none";
    document.body.appendChild(element);

    element.click();

    document.body.removeChild(element);
  };

  addString = (event) => {
    this.setState((prevState) => {
      return { string: event.target.value };
    });
  };
  addFileName = (event) => {
    this.setState((prevState) => {
      return { filename: event.target.value };
    });
  };
  handleChangeType = (element, value) => {
    this.setState((prevState) => {
      return { inputformat: value };
    });
  };

  getOutput = () => {
    const { file } = this.props;
    const { inputformat } = this.state;
    if (!!file && !!inputformat) return parseToString(file, inputformat);
    return "";
  };

  copyToClipboard = () => {
    toast.info("Erfolgreich Kopiert", { autoClose: 4000 });
  };

  render() {
    const formats = this.props.formats
      ? this.props.formats
      : ["json", "kml", "csv"];
    const { crs } = this.props.file;
    const { inputformat } = this.state;
    const {t} =this.props;
    const output = this.getOutput();
    return (
      <div>
        {this.props.hasOwnProperty("handleOnExport") && (
          <div style={{ margin: "15px 0" }}>
            {" "}
            <Button
              variant="contained"
              color="primary"
              onClick={this.props.handleOnExport}
            >
              {t("point_export_export_btn")}
            </Button>
          </div>
        )}
        <FormControl component="fieldset">
          <FormLabel component="legend" style={style.label}>
            {t("point_export_file_format")}
          </FormLabel>
          <RadioGroup
            style={{ display: "inline" }}
            aria-label="format"
            name="inputformat"
            value={inputformat}
            onChange={this.handleChangeType}
          >
            {formats.indexOf("json") > -1 && (
              <FormControlLabel
                value="json"
                control={<Radio color={"primary"} />}
                label={t("point_import_type_selection_geojson")}
              />
            )}
            {formats.indexOf("gpx") > -1 && (
              <FormControlLabel
                value="gpx"
                control={<Radio color={"primary"} />}
                label={t("point_import_type_selection_gpx")}
              />
            )}
            {formats.indexOf("kml") > -1 && (
              <FormControlLabel
                value="kml"
                control={<Radio color={"primary"} />}
                label={t("point_import_type_selection_kml")}
              />
            )}
            {formats.indexOf("csv") > -1 && (
              <FormControlLabel
                value="csv"
                control={<Radio color={"primary"} />}
                label={t("point_import_type_selection_csv")}
              />
            )}
          </RadioGroup>
        </FormControl>
        {inputformat === "csv" && (
          <Alert color="warning" style={{ marginTop: "10px" }}>
            {t("point_export_alert_info_one")}
          </Alert>
        )}
        {!compareWithKmlCrs(crs.properties) &&
          (inputformat === "gpx" || inputformat === "kml") && (
            <Alert color="warning" style={{ marginTop: "10px" }}>
              {t("point_export_alert_info_two")}
            </Alert>
          )}
        <div style={style.btnWrapper}>
          <CopyToClipboard text={output} onCopy={this.copyToClipboard}>
            <Button
              variant="contained"
              color="primary"
              component="span"
              size={"small"}
            >
              <FontAwesomeIcon
                icon={"clipboard"}
                style={style.icon}
              ></FontAwesomeIcon>
              {t("point_export_btn_copy")}
            </Button>
          </CopyToClipboard>
          <Button
            variant="contained"
            color="primary"
            component="span"
            style={style.btn}
            size={"small"}
            onClick={this.startExport}
          >
            <FontAwesomeIcon
              icon={"file-download"}
              style={style.icon}
            ></FontAwesomeIcon>
            {t('point_export_btn_save_file')}
          </Button>
        </div>
        <pre style={style.pre}>{output}</pre>
      </div>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(ExportFiles));
