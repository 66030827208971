import React, { Component } from "react";
import { connect } from "react-redux";
import axios from "axios";

import * as constants from "common/constants.js";
import errorHandler from "common/errorHandler";
import { WIKI_LINK_FARMING } from "common/constants.js";

import * as actions from "store/actions";

import CoordInput from "./CoordInput";
import BevSolutionCard from "components/BevSolutionCard";
import ExtendedTitleCard from "components/ExtendedTitleCard";
import { withTranslation } from "react-i18next";

const CLEAR_STATE = {
  openPoints: true,
  openSolution: true,

  showTarget: false,
  showPoints: true,
  showSolution: false,

  solutionComputed: false,
  solution: null,
};

function mapStateToProps(state) {
  return {
    geometryList: state.points.geometryList,
    config: state.auth.config,
  };
}

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    updateProp: (type, value) =>
      dispatch(actions.points.updateProp(type, value)),
    cleanInput: () => dispatch(actions.points.cleanInput()),
  };
};

class Farming extends Component {
  state = CLEAR_STATE;
  constructor() {
    super();
    this.sourceCRS = {
      properties: {
        frame: "113",
        epoch: 2010.0,
        ellipsoid: "",
        prime_meridian: "",
        projection: "",
        height_system: "ellps",
        propTypes: {},
      },
    };
    this.target_crs = {
      properties: {
        frame: "209",
        epoch: 2002.56,
        ellipsoid: "",
        prime_meridian: "",
        projection: "",
        heightsystem: "usage",
        propTypes: {},
      },
    };
    this.startTransformation = this.startTransformation.bind(this);
  }

  updateVisibilities = (crs, target) => {
    if (crs.properties.frame === "" && target !== "target") {
      this.setState({
        showTarget: false,
        showPoints: false,
        showSolution: false,
      });
    } else if (crs.properties.frame === "" && target === "target") {
      this.setState({ showPoints: false, showSolution: false });
    } else if (crs.properties.frame !== "" && target !== "target") {
      this.setState({ showTarget: true });
      if (this.props.targetCRS.properties.frame !== "") {
        this.setState({ showPoints: true });
      }
    } else if (crs.properties.frame !== "" && target === "target") {
      this.setState({ showPoints: true });
    }
  };

  componentWillUnmount() {
    this.props.updateProp("sourcePoints", {
      pointList: {},
      singlePoint: { x: "", y: "", z: "", vx: "", vy: "", vz: "" },
    });
    this.props.cleanInput();
  }

  startTransformation = (f, name) => {
    // First remove solution card
    this.setState({ showSolution: false });

    // Build new request body
    let req = {
      geojson: f,
      source_crs: {
        frame: "113",
        epoch: 2010.0,
        ellipsoid: "",
        prime_meridian: "",
        projection: "",
        height_system: "ellps",
        propTypes: {},
      },
      target_crs: {
        frame: "209",
        epoch: 2002.56,
        ellipsoid: "",
        prime_meridian: "",
        projection: "",
        heightsystem: "usage",
        propTypes: {},
      },
    };

    // Make API call with request body
    // TODO best practice add to saga
    axios
      .post(constants.TRANSFORMATOR_API_URL_ADV, req, this.props.config)
      .then((res) => {
        //fetch Data and save
        let x = res.data.result.points;
        x.name = name;
        // change state to disply Solution
        this.setState({
          openSolution: true,
          showSolution: true,
          openPoints: false,
          solutionComputed: true,
          solution: x,
        });
      })
      .catch(errorHandler);
  };

  toggleOpenPoints = () => {
    this.setState((prevState) => {
      return { openPoints: !prevState.openPoints };
    });
  };

  clearPage = () => {
    this.setState({
      openPoints: true,
      showSolution: false,
      solution: null,
      solutionComputed: false,
      openSolution: false,
    });
    this.props.updateProp("sourcePoints", {
      singlePoint: {
        x: "",
        y: "",
        z: "",
        vx: "",
        vy: "",
        vz: "",
      },
      pointList: {},
    });
  };

  toggleOpenSolution = () =>
    this.setState({ openSolution: !this.state.openSolution });

  render() {
    const {
      showPoints,
      showSolution,
      openPoints,
      solution,
      openSolution,
      solutionComputed,
    } = this.state;
    const {t} = this.props;

    return (
      <div>
        <ExtendedTitleCard
          clearPage={this.clearPage}
          wikiLink={WIKI_LINK_FARMING}
          title={t("farming_titel_card_title")}
          description={t("farming_titel_card_text")}
        />
        <CoordInput
          isClosed={openPoints}
          sourceCRS={this.sourceCRS}
          startTransformation={this.startTransformation}
          showPoints={showPoints}
          toggleOpenPoints={this.toggleOpenPoints}
        />
        <BevSolutionCard
          isDisplayed={showSolution}
          isOpen={openSolution}
          isComputed={solutionComputed}
          solution={solution}
          toggleOpen={this.toggleOpenSolution}
        />
      </div>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(Farming));
