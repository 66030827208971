import React from "react";
import { inject, observer } from "mobx-react";
import BevModal from "components/BevModal";
import {
  Button,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import { Search } from "@bev-components/search-adress-component-react";
import { Typography, useTheme } from "@mui/material";
import Axios from "axios";
import errorHandler from "common/errorHandler";
import { TOASTER_INFO_AUTOCLOSE, KATASTER_SERVICE_API_URL } from "common/constants";

const getFestpunktById = async (id, config) => {
  try {
    const response = await Axios.get(`${KATASTER_SERVICE_API_URL}fpt/${id}?type=small`, config);
    return response.data;
  } catch (error) {
    errorHandler(error);
  }
};

export const PassPointSearchModal = inject("store")(
  observer(({ store, open, handleClose, title }) => {
    const { t } = useTranslation();
    const theme = useTheme();

    const addPointToList = (point) => {
      //Close Modal ans scrollTop
      window.scrollTo(0, 250);
      handleClose();

      // Read has_etrs and is_rutschpunkt as string and convert to boolena
      const has_etrs = point.properties.has_etrs;
      const is_rutschpunkt = point.properties.is_rutschpunkt;
      const hasEtrs = (has_etrs == "false") != Boolean(has_etrs);
      const isRutschpunkt = (is_rutschpunkt == "false") != Boolean(is_rutschpunkt);

      //Check if has etrs or is rutschpunkt and return
      if (!hasEtrs || isRutschpunkt) {
        return toast.warning(t("cadastre:map_missing_etrs_waring"), { autoClose: 7000 });
      }

      // Start fetch
      toast.info(t("cadastre:toast_info_start_fetch"), TOASTER_INFO_AUTOCLOSE);
      const fp_id = point.id + 600000000;
      getFestpunktById(fp_id).then(data => {
        //Create Feature Nname

        const featureName =
          data.properties.art === "E"
            ? `${data.properties.art} ${data.properties.oek}-${data.properties.nr}`
            : `${data.properties.art} ${data.properties.nr}-${data.properties.oek}`;
            //Selecte stabilisierung
                    // Get Correct Stabilisierung from list of Stabilisierung
      // Try to Get Stabilisierung with same kennzeichen as kennzeichen_darstellung from Point
      console.log(data.properties)
      const selectedStab = data.properties.stabilisierungen.find(stab => data.properties.kennzeichen_darstellung === stab.kennzeichen && !!stab.etrs[0]) ?? 
      // IF null then sort Stabilisierung and find fist one with etrs attributes
      data.properties.stabilisierungen.sort((a,b) => a.kennzeichen.localeCompare(b.kennzeichen)).find(s => !!s.etrs[0]);
        if(!selectedStab) return toast.error(t("cadastre:toast_info_missing_stab"), TOASTER_INFO_AUTOCLOSE);

        // If stab  exists create point object and add it to store
        if (selectedStab) {
          const fWgs = {
            punktnummer: featureName,
            oek: data.properties.oek?.toString() ?? null,
            nummer: data.properties.nr?.toString() ?? null,
            punktArt: data.properties.art,
            stabilisierung: selectedStab.kennzeichen,

            wgs84Phi: data.x,
            wgs84Lam: data.y,
            wgs84Hoehe: 0,

            gkY: selectedStab.mgi[0],
            gkX: selectedStab.mgi[1],
            gkHoeheAdria: selectedStab.mgi[2],

            etrs89X: selectedStab.etrs[0],
            etrs89Y: selectedStab.etrs[1],
            etrs89Z: selectedStab.etrs[2],
            etrs89Messdatum: data.properties.messdatum,
            source: "bev-kataster-service",
          };

          store.kataster.addPoint(fWgs, true, true);
        }else{
        toast.info(t("cadastre:toast_info_missing_stab"), TOASTER_INFO_AUTOCLOSE);
      }

      })
    };

    return (
      <BevModal title={title} open={open} onClose={handleClose} width={940}>
        <Search
          handleSelectData={addPointToList}
          size="big"
          closeable={false}
          fullWidth
          integratedStyle
          muiTheme={theme}
          description={<Typography>Festpunktsuche bsp: 49203-17</Typography>}
          includeLayers={['FP']}
        />
        <div
          style={{
            display: "flex",
            justifyContent: "flex-start",
            marginTop: "0.5em",
          }}
        >
          <Button variant={"outlined"} color={"primary"} onClick={handleClose}>
            {t("common:cancel_btn")}
          </Button>
        </div>
      </BevModal>
    );
  })
);
