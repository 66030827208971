import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import Typography from '@mui/material/Typography';

import getItemName from '../../common/getItemNameFromList';

import {useTranslation} from 'react-i18next';

const mapStateToProps = state => {
  return {
    frameList: state.api.frameList,
    ellipsoidList: state.api.ellipsoidList,
    prime_meridianList: state.api.prime_meridianList,
    projectionList:  state.api.projectionList,
    heightSystemList: state.api.heightSystemList,
  }
}

CheckCRSOutput.propTypes = {
  frame: PropTypes.string,
  epoch: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number
  ]),
  ellipsoid: PropTypes.string,
  prime_meridian: PropTypes.string,
  projection: PropTypes.string,
  height_system: PropTypes.string,
  inPopover: PropTypes.bool,
}

function CheckCRSOutput ({ frame, epoch, ellipsoid, prime_meridian, projection , height_system, inPopover,
                                frameList, ellipsoidList, projectionList, prime_meridianList, heightSystemList}){
  const style = inPopover ? {margin:'20px'}: {margin:'0px'};
  const {t} = useTranslation();
  return <div style={style}>
              <Typography>{t('container_crs_frame')}: <b>{getItemName(frame,frameList)}</b></Typography>
              <Typography>{t('container_crs_epoch')}: <b>{epoch}</b></Typography>
              <Typography>{t('container_crs_ellipsoid')}: <b>{getItemName(ellipsoid,ellipsoidList)}</b></Typography>
              <Typography>{t('container_crs_meridian')}: <b>{getItemName(prime_meridian,prime_meridianList)}</b></Typography>
              <Typography>{t('container_crs_projection')}: <b>{getItemName(projection,projectionList)}</b></Typography>
              <Typography>{t('container_crs_height')}: <b>{getItemName(height_system,heightSystemList)}</b></Typography>
            </div>
}

export default connect(mapStateToProps)(CheckCRSOutput);