import React, { useState } from "react";
import { inject, observer } from "mobx-react";
import { MenuListComposition } from "../MenuListComposition";
import {
  Avatar,
  TableRow,
  TableContainer,
  Table,
  TableHead,
  TableBody,
  TableFooter,
  TableCell,
  Tooltip,
  TablePagination,
  IconButton,
} from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus, faCogs, faExpand } from "@fortawesome/free-solid-svg-icons";
import { useTranslation } from "react-i18next";
import { classes } from "../styling";
import { prettifyBigNumber } from "common/utility";
import ChangeHistoryIcon from "@mui/icons-material/ChangeHistory";

export const PointTableTrafo = inject("store")(
  observer(({ store, handleOpenInput }) => {
    const { t } = useTranslation();
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [page, setPage] = useState(0);

    const filteredPointList = store.kataster.pointList.filter((p) => p.isTransformationpoint);

    const handleChangePage = (event, newPage) => {
      setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
      setRowsPerPage(parseInt(event.target.value));
      setPage(0);
    };


    const sortByPunktnummer= (a,b) => {
      return a.punktnummer.toLowerCase().localeCompare(b.punktnummer.toLowerCase());
    }

    const emptyRows = rowsPerPage - Math.min(rowsPerPage, filteredPointList.length - page * rowsPerPage);
    return (
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell onClick={() => store.kataster.setExtentByPointListAndFilter("mp")}>
                <Tooltip title={t("cadastre:selection_table_header_type_tooltip")}>
                  <span>{t("cadastre:selection_table_header_type")}</span>
                </Tooltip>
              </TableCell>
              <TableCell>
                <Tooltip title={t("cadastre:selection_table_header_name_tooltip")}>
                  <span>{t("cadastre:selection_table_header_name")}</span>
                </Tooltip>
              </TableCell>
              <TableCell align="right">
                <Tooltip title={t("cadastre:selection_table_header_etrs_tooltip")}>
                  <span>{t("cadastre:selection_table_header_etrs")}</span>
                </Tooltip>
              </TableCell>
              <TableCell align="right">
                <Tooltip title={t("cadastre:selection_table_header_mgi_tooltip_given")}>
                  <div>
                    <span
                      style={{
                        color: "#777",
                        fontSize: "0.8em",
                        marginRight: 5,
                      }}
                    >
                      ({t("cadastre:selection_table_header_mgi_given")})
                    </span>
                    <span>{t("cadastre:selection_table_header_mgi")}</span>
                  </div>
                </Tooltip>
              </TableCell>
              <TableCell align="right">
                <Tooltip title={t("cadastre:selection_table_header_mgi_tooltip_calculated")}>
                  <div>
                    <span
                      style={{
                        color: "#777",
                        fontSize: "0.8em",
                        marginRight: 5,
                      }}
                    >
                      ({t("cadastre:selection_table_header_mgi_calculated")})
                    </span>
                    <span>{t("cadastre:selection_table_header_mgi")}</span>
                  </div>
                </Tooltip>
              </TableCell>
              <TableCell align="right">
                <Tooltip title={t("cadastre:selection_table_header_mgi_tooltip_delta")}>
                  <div>
                    <span>
                      <ChangeHistoryIcon />
                    </span>
                  </div>
                </Tooltip>
              </TableCell>
              <TableCell>
                <Tooltip title={t("cadastre:selection_table_header_option_tooltip")}>
                  <span>
                    <FontAwesomeIcon icon={faCogs} />
                  </span>
                </Tooltip>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {filteredPointList.length === 0 && (
              <TableRow>
                <TableCell />

                <TableCell colSpan={4}>{t("cadastre:selection_table_body_empty")}</TableCell>
              </TableRow>
            )}
            {filteredPointList
              .sort(sortByPunktnummer)
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((point) => (
              <TableRow key={point.punktnummer}>
                <TableCell>
                  <Avatar sx={classes.blueAvatar}>
                    {point.punktType?.toUpperCase() ?? point.punktArt?.toUpperCase() ?? "SO"}
                  </Avatar>
                </TableCell>
                <TableCell>{point.punktnummer}</TableCell>
                <TableCell align={"right"}>
                  {Math.abs(point.etrs89X) > 0 && Math.abs(point.etrs89Y) > 0 ? (
                    <>
                      <div>{prettifyBigNumber(point.etrs89X)} X</div>
                      <div>{prettifyBigNumber(point.etrs89Y)} Y</div>
                      <div>{prettifyBigNumber(point.etrs89Z)} Z</div>
                    </>
                  ) : (
                    <div>{t("cadastre:selection_table_body_no_coordinates")}</div>
                  )}
                </TableCell>
                {point.hasValidGkCoordinates() ? (
                  <TableCell align={"right"}>
                    {Math.abs(point.gkX) > 0 && Math.abs(point.gkY) > 0 ? (
                      <>
                        <div>{prettifyBigNumber(point.gkY)} y</div>
                        <div>{prettifyBigNumber(point.gkX)} x</div>
                        <div>{prettifyBigNumber(point.gkHoeheAdria)} h</div>
                      </>
                    ) : (
                      <div>{t("cadastre:selection_table_body_no_coordinates")}</div>
                    )}
                  </TableCell>
                ) : (
                  <TableCell style={{ color: "lightgray" }}>
                    {t("cadastre:selection_table_body_no_coordinates")}
                  </TableCell>
                )}
                <TableCell align="right">
                  {Math.abs(point.gkXCalculated) > 0 && Math.abs(point.gkYCalculated) > 0 ? (
                    <>
                      <div>{prettifyBigNumber(point.gkYCalculated)} y</div>
                      <div>{prettifyBigNumber(point.gkXCalculated)} x</div>
                      <div>{prettifyBigNumber(point.gkHoeheAdriaCalculated)} h</div>
                    </>
                  ) : (
                    <div>{t("cadastre:selection_table_body_no_coordinates")}</div>
                  )}
                </TableCell>
                {point.hasValidGkCoordinates() && point.hasValidCalculatedGkCoordinates() ? (
                  <TableCell align="right">
                    <Tooltip title={t("cadastre:tooltip_delta")}>
                      <div>
                        <div>
                          {prettifyBigNumber((point.gkY - point.gkYCalculated).toFixed(3))}{" "}
                          <ChangeHistoryIcon sx={classes.delta} />y
                        </div>
                        <div>
                          {prettifyBigNumber((point.gkX - point.gkXCalculated).toFixed(3))}{" "}
                          <ChangeHistoryIcon sx={classes.delta} />x
                        </div>
                        <div>
                          {prettifyBigNumber((point.gkHoeheAdria - point.gkHoeheAdriaCalculated).toFixed(3))}{" "}
                          <ChangeHistoryIcon sx={classes.delta} />h
                        </div>
                      </div>
                    </Tooltip>
                  </TableCell>
                ) : (
                  <TableCell style={{ color: "lightgray" }}>
                    {t("cadastre:selection_table_body_no_coordinates")}
                  </TableCell>
                )}
                <TableCell>
                  <MenuListComposition
                    small
                    point={point}
                    handleDelete={store.kataster.removePointFromList}
                    handleToggleIsTrafoPoint={store.kataster.toggleIsTrafoPoint}
                  />
                </TableCell>
              </TableRow>
            ))}

            {emptyRows > 0 && (
              <TableRow style={{ height: 81 * emptyRows }}>
                <TableCell colSpan={7} />
              </TableRow>
            )}

            <TableRow sx={classes.hoverRow} onClick={handleOpenInput}>
              <TableCell>
                <Avatar sx={classes.smallAvatar}>
                  <FontAwesomeIcon icon={faPlus} />
                </Avatar>
              </TableCell>
              <TableCell colSpan={6}>{t("cadastre:selection_table_body_add_trafo_points")}</TableCell>
            </TableRow>
          </TableBody>
          <TableFooter>
            <TableRow>
              <TableCell>
                {filteredPointList.length !== 0 && (
                  <Tooltip title={t("cadastre:selection_extent_transfromationpoint_tooltip")}>
                    <IconButton
                      onClick={() => {
                        store.kataster.setExtentByPointListAndFilter("mp");
                        window.scrollTo(0, 250);
                      }}
                    >
                      <FontAwesomeIcon icon={faExpand} />
                    </IconButton>
                  </Tooltip>
                )}
              </TableCell>
              <TableCell colSpan={6}>
                <TablePagination
                  rowsPerPageOptions={[5, 10, 25, 50]}
                  component="div"
                  count={filteredPointList.length}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                />
              </TableCell>
            </TableRow>
          </TableFooter>
        </Table>
      </TableContainer>
    );
  })
);
