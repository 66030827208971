const getItemName = (filter,list) => {
  const filteredItem = getItem(filter,list);
  return !!filteredItem ? filteredItem.display_name : '-';
}
export default getItemName;

export const getItem = (filterItem,list) => {
  if ( !list || list.length <= 0) return null;
  const filteredList = list.filter(e => { return e.value === filterItem});
  return filteredList.length > 0 ? filteredList[0] : null;
}


