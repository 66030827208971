import React from "react";
import { inject, observer } from "mobx-react";
import { Tooltip, IconButton, Box } from "@mui/material";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faExpand,
} from "@fortawesome/free-solid-svg-icons";
import { useTranslation } from "react-i18next";


export const ExtentMapComponent = inject("store")(
  observer(({ store }) => {
    const { t } = useTranslation()

    return (
      <Box sx={{
        position: "absolute",
        top: t => t.spacing(8),
        right: t => t.spacing(1),
        backgroundColor: "rgba(255,255,255, 0.5)",
        borderRadius: t => t.spacing(1),

        '&:hover': {
          backgroundColor: "rgba(255,255,255, 0.9)",
        }
      }}>
        <Tooltip title={t('cadastre:selection_extent_passpoint_tooltip')}>
          <IconButton
            onClick={() => store.kataster.setExtentByPointListAndFilter("pp", true)}
            sx={{
              m: 1,
              color: "success.main",
            }}
            size={"small"}
          >
            <FontAwesomeIcon icon={faExpand} />
          </IconButton>
        </Tooltip>

        {store.kataster.pointList.filter((p) => p.isTransformationpoint).length > 0 && <Tooltip title={t('cadastre:selection_extent_transfromationpoint_tooltip')}>
          <IconButton
            onClick={() => store.kataster.setExtentByPointListAndFilter("mp", true)}
            sx={{
              m: 1,
              color: '#303f9f',
            }}
            size={"small"}
          >
            <FontAwesomeIcon icon={faExpand} />
          </IconButton>
        </Tooltip>}

        {store.kataster.pointList.filter((p) => !p.isTransformationpoint && !p.isPassPoint()).length > 0 && <Tooltip title={t('cadastre:selection_extent_otherpoint_tooltip')}>
          <IconButton
            onClick={() => store.kataster.setExtentByPointListAndFilter("sp", true)}
            sx={{
              m: 1,
              color: "#fbc02d",
            }}
            size={"small"}
          >
            <FontAwesomeIcon icon={faExpand} />
          </IconButton>
        </Tooltip>}
      </Box>
    );
  })
);
