import React from 'react';
import PropTypes from 'prop-types';

import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormLabel from '@mui/material/FormLabel';

import { useTranslation } from 'react-i18next';

/**
 * onChangeHandler, a function, that will be called on change handler 
 * getSelection, an Array that displays all inputs
 * isDisabled, a boolean to disable the inputs
 */
InputTransformMethod.propTypes = {
  onChangeHandler: PropTypes.func.isRequired,
  getSelection: PropTypes.array.isRequired,
  isDisabled: PropTypes.bool.isRequired
}
function InputTransformMethod ({transformation_method, transfromations_methods, onChangeHandler, getSelection, isDisabled}) {
  const labelErrorStyle = getSelection.indexOf('missing') > -1 ? {textDecoration: 'underline'} : {};
  const {t} = useTranslation();
  return <FormControl component="fieldset" 
                required={!isDisabled} 
                error={!isDisabled} 
                disabled={isDisabled}
                style={{flexGrow: '2', margin:'30px', maxWidth: '33%'}}>
    <FormLabel component="legend" style={labelErrorStyle}>{t("austrian_parameter_card_lage")}</FormLabel>
    <RadioGroup aria-label="transformation_method" 
      name="transformation_method" 
      value={transformation_method} 
      onChange={onChangeHandler}>

        { transfromations_methods.map((e,i)=>(
          <FormControlLabel key={i} value={e.value} control={<Radio color={'primary'}/>} label={e.display_name} />
        ))}

    </RadioGroup>
  </FormControl>
}

export default InputTransformMethod;