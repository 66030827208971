import { pointlist_mgi, pointlist_3d } from '../BaseGeometries';
import { katasterError } from './parserErrors';
import { toast } from 'react-toastify';

export default function parseKatasterCSV(f) {
    let v = f.split("\n");
    let fp_mgi = "";
    let fp_etrs = "";
    let mp_mgi = "";
    let mp_etrs = "";
    let gp_mgi = "";
    let gp_etrs = "";
    let so_mgi = "";
    let so_etrs = "";
    let pktnr;
    v.forEach(function(vv, ii) {
        if(vv.length === 0 || vv[0] === '#') return;
        let l = vv.split(";");
        if (l !== "") {
            if (l[1].trim() !== "") {
                pktnr=l[1].trim()+"-"+l[2].trim();
            } else {
                pktnr=l[2].trim();
            }
            if (l[0] === "FP") {
                if (l[10].trim() !== "" && l[4].trim() !== "") {
                    fp_mgi = fp_mgi + getGauskruegerCsvLineFromData(pktnr, l);
                    fp_etrs = fp_etrs + getEtrsCsvLineFromData(pktnr, l);
                }else {
                    toast.info('Es fehlen für den Festpunkt ' + pktnr + ' die ETRS (spalte 11) oder Gauskrüger Koordinaten (spalte 4) dieser wird übersprungen', {autoClose: false})
                }
            }else if (l[0] === "MP") {
                if(l[4].trim() !== ""){
                    mp_mgi = mp_mgi + getGauskruegerCsvLineFromData(pktnr, l);
                }
                if(l[10].trim() !== ""){
                    mp_etrs = mp_etrs + getEtrsCsvLineFromData(pktnr, l);
                }
            }else if(l[0] === "GP"){
                if(l[4].trim() !== ""){
                    gp_mgi = gp_mgi + getGauskruegerCsvLineFromData(pktnr, l);
                }
                if(l[10].trim() !== ""){
                    gp_etrs = gp_etrs + getEtrsCsvLineFromData(pktnr, l);
                }
            }else if (l[0] === "SO") {
                if(l[4].trim() !== ""){
                    so_mgi = so_mgi + getGauskruegerCsvLineFromData(pktnr, l);
                }
                if(l[10].trim() !== ""){
                    so_etrs = so_etrs + getEtrsCsvLineFromData(pktnr, l);
                }
            }
        }
    });

    if(fp_mgi.split("\n").length < 4 || fp_etrs.length<4){
        throw new katasterError('Im File gibt es zu wenige gleiche Festpunkte, min 4 stk.! Mögliche Gründe, zuwenige Festpunkte im File vohanden oder nicht alle Koordinaten angegeben.')
    }
    
    return [ pointlist_mgi(fp_mgi), pointlist_3d(fp_etrs),
             pointlist_mgi(mp_mgi), pointlist_3d(mp_etrs),
             pointlist_mgi(gp_mgi), pointlist_3d(gp_etrs),
             pointlist_mgi(so_mgi), pointlist_3d(so_etrs)]
}

/**
 * Generate an CSV string, from FP points Data the Gauskrueger coordinates
 * @param {string} punktnummer 
 * @param {array} data 
 */
function getGauskruegerCsvLineFromData(punktnummer, data){
   return punktnummer + ", " + data[4].replace(",",".") + "," + data[5].replace(",",".") + ";\n";
}

/**
 * Generate an CSV string, from FP points Data the ETRS coordinates
 * @param {string} punktnummer 
 * @param {array} data 
 */
function getEtrsCsvLineFromData ( punktnummer, data){
    return punktnummer +", "+data[10].replace(",",".") + ", " + data[11].replace(",",".") + ", " + data[12].replace(",",".") + ", " + data[13] + ";\n";
}