import React from 'react';
import PropTypes from 'prop-types';
import Grid from '@mui/material/Grid';
import { useTranslation } from 'react-i18next';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { DateCalendar } from '@mui/x-date-pickers';
import {de, enGB} from 'date-fns/locale'

/**
 * setDateFromDatePicker, a function, to set the date
 * datePicker, date thate will be displayed
 * classes, an Object to set the classes of a Component
 */
GridCalendar.propTypes = {
    setDateFromDatePicker: PropTypes.func.isRequired,
    datePicker: PropTypes.instanceOf(Date),
}

export default function GridCalendar({ setDateFromDatePicker, bevTime }) {
    const { i18n } = useTranslation();
    const tmpBdat = bevTime.bdat.split('-');
    let resBdat = new Date();
    resBdat.setFullYear(tmpBdat[0],parseInt(tmpBdat[1],10)-1,tmpBdat[2]);

    return <Grid item xs={12} sm={12} md={4} container justifyContent="flex-start">
        <Grid item xs={12} sm={12}>
            <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={i18n.language.toLowerCase() === "de" ? de : enGB}>
                <DateCalendar showDaysOutsideCurrentMonth value={resBdat} onChange={setDateFromDatePicker} />
            </LocalizationProvider>
        </Grid>
    </Grid>
}