import React, { Component } from "react";
import { Route, withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { ToastContainer } from "react-toastify";

import * as actions from "../store/actions";

import { hasGroup } from "../common/roles";
import {
  BASE_URL,
  MAINTENANCE_ADVANCED,
  MAINTENANCE_FARMING,
  MAINTENANCE_AUSTRIAN,
  MAINTENANCE_CADASTRE,
  MAINTENANCE_DATE,
  MAINTENANCE_HELMERT_DETERMINATION,
  MAINTENANCE_HELMERT_TRANSFORMATION,
} from "../common/constants.js";

import ApiKeys from "../containers/ApiKeys";
import GeometryManager from "../containers/GeometryManager";
//import Kafka from "../containers/kafka";
import Home from "../containers/Home";
import Advanced from "containers/Advanced";
import Austrian from "containers/Austrian";
import Cadastre from "containers/Cadastre";
import Farming from "containers/Farming";
//import Helmert from "../containers/Helmert/Helmert";
//import Helmert2 from "../containers/Helmert2/Helmert2";
import BevTime from "containers/BevTime";
import Impressum from "../containers/Impressum";
import CoordFormat from "../containers/Coordformat";
import AppAPIDownBanner from "./AppAPIDownBanner";
import Maintenance from "components/Maintenance";
import * as Sentry from "@sentry/browser";
import { Container, Paper } from "@mui/material";

const infoBannerPaperMargin = {
  paddingTop: 160,
};

const infoBannerStyle = {
  padding: "1rem",
  fontSize: "2em",
  backgroundColor: "#e6320f",
  color: "white",
  fontWeight: "bold",
};

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    logoutUser: () => dispatch(actions.auth.logoutUser()),
    // apiDown: () => dispatch(actions.auth.apiDown()),
    apiUp: () => dispatch(actions.auth.apiUp()),
  };
};

class AppMainContent extends Component {
  static propTypes = {};
  static defaultProps = {};
  state = {
    dropdownopen: false,
    openAPIDialog: false,
    apikey: "",
  };

  componentDidUpdate() {
    console.log(this.props.location);
    if (this.props.location.pathname.includes("send/anerrortosentry")) {
      console.log("Should send");
      Sentry.captureMessage("HELLO WORLD");
    }
  }

  render() {
    const { user } = this.props.auth;

    return (
      <main className={"main-container"}>
        <div style={{ paddingTop: "173px", position: "relative" }} />
        <AppAPIDownBanner />
        <Route exact path={BASE_URL + "send/anerrortosentry"}>
          <Container style={infoBannerPaperMargin} maxWidth={"md"}>
            <Paper style={infoBannerStyle} elevation={3}>
              Generische 'HELLOR WOLRD' Fehlermeldung zu Sentry gesendet!
            </Paper>
          </Container>
        </Route>
        <Route exact path={BASE_URL} component={Home} />
        <Route exact path={BASE_URL + "geommanager"} component={GeometryManager} />
        <Route exact path={BASE_URL + "geommanager/:id"} component={GeometryManager} />
        {!MAINTENANCE_ADVANCED ? (
          <Route path={BASE_URL + "advanced"} component={Advanced} />
        ) : (
          <Route
            path={BASE_URL + "advanced"}
            render={(props) => <Maintenance {...props} modus={"Advanced Mode"} />}
          />
        )}
        {!MAINTENANCE_FARMING ? (
          <Route path={BASE_URL + "farming"} component={Farming} />
        ) : (
          <Route
            path={BASE_URL + "farming"}
            render={(props) => <Maintenance {...props} modus={"Precise Farming Mode"} />}
          />
        )}
        {!MAINTENANCE_AUSTRIAN ? (
          <Route path={BASE_URL + "austrian"} component={Austrian} />
        ) : (
          <Route
            path={BASE_URL + "austrian"}
            render={(props) => <Maintenance {...props} modus={"Austrian Mode"} />}
          />
        )}
        {!MAINTENANCE_CADASTRE ? (
          <Route path={BASE_URL + "cadastre"} component={Cadastre} />
        ) : (
          <Route
            path={BASE_URL + "cadastre"}
            render={(props) => <Maintenance {...props} modus={"Kataster Mode"} />}
          />
        )}
        {!MAINTENANCE_DATE ? (
          <Route path={BASE_URL + "calendar"} component={BevTime} />
        ) : (
          <Route
            path={BASE_URL + "calendar"}
            render={(props) => <Maintenance {...props} modus={"Datumstransformationsmodus"} />}
          />
        )}
        {/*!MAINTENANCE_HELMERT_TRANSFORMATION ? (
          <Route path={BASE_URL + "helmert"} render={(props) => <Helmert {...props} />} />
        ) : (
          <Route
            path={BASE_URL + "helmert"}
            render={(props) => <Maintenance {...props} modus={"Helmert Transformationsmodus"} />}
          />
        )*/}
        {/*!MAINTENANCE_HELMERT_DETERMINATION ? (
          <Route path={BASE_URL + "helmert2"} component={Helmert2} />
        ) : (
          <Route
            path={BASE_URL + "helmert2"}
            render={(props) => <Maintenance {...props} modus={"Helmert Parameterbestimmungsmodus"} />}
          />
        )*/}
        <Route path={BASE_URL + "format"} component={CoordFormat} />
        <Route path={BASE_URL + "impressum"} component={Impressum} />
        {hasGroup(user, "manager") && <Route path={BASE_URL + "apikeys"} component={ApiKeys} />}
        {/*hasGroup(user, "manager") && <Route exact path={BASE_URL + "kafka/admin"} component={Kafka} />*/}
        <div />
        <ToastContainer position={"bottom-right"} hideProgressBar={true} />
      </main>
    );
  }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(withRouter(AppMainContent)));
