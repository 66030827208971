import React, { useEffect, useState } from "react";


import BevModal from "../BevModal";
import BevTimeTransformator from "containers/BevTime/BevTimeTransformator";
import { Button, TextField, useMediaQuery, useTheme, Box } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useTranslation } from "react-i18next";

function useIsWidthUp(breakpoint) {
  const theme = useTheme();
  return useMediaQuery(theme.breakpoints.up(breakpoint));
}

const CRSInputBevTimePicker = ({ isDisabled = false, focusInput = false, value, handleChangeEpoch }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [yr, setYr] = useState(value)
  const { t } = useTranslation();
  const isSmUp = useIsWidthUp("sm");
  const isMdUp = useIsWidthUp("md");

  // useEffect(() => {
  //   if (value && value !== 0) setYr(value);
  // }, [value]);

  const toggleModal = () => {
    if (isDisabled) return;
    setIsModalOpen(b => !b);
  }

  const submitEpoch = () => {
    toggleModal();
  }

  const handleChange = (nextYr) => {
    console.log(nextYr);
    setYr(nextYr);
    handleChangeEpoch(nextYr);
  }

  return <Box>
    <TextField
      disabled={isDisabled}
      id="standard-date"
      label={t("container_crs_epoch")}
      value={yr}
      onClick={toggleModal}
      fullWidth
      color="primary"
      margin="normal"
      variant={"outlined"}
    />
    <Button
      size="small"
      variant="outlined"
      color="primary"
      onClick={toggleModal}
    >
      <FontAwesomeIcon icon="calendar-alt" style={{ marginRight: '8px' }} />{" "}
      {t("container_crs_epoch")}
    </Button>
    <BevModal
      open={isModalOpen}
      width={isSmUp ? (isMdUp ? 940 : 580) : 350}
      onClose={toggleModal}
    >
      <BevTimeTransformator
        value={yr}
        today={false}
        handleChange={handleChange}
      />

      <Button
        variant="contained"
        color="primary"
        onClick={submitEpoch}
      >
        {t("container_crs_epoch")}
      </Button>
    </BevModal>
  </Box>


}

export default CRSInputBevTimePicker;
