export const helmert2 = {
    "helmert2_title_card_title" : "Helmert Parameterbestimmung",
    "helmert2_title_card_info" : "Berechnung eigener Transformationsparameter für die ebene oder räumliche Helmert-Transformation aus Identpunkten. Bitte geben Sie mindestens 2 Punkte für die Bestimmung der Parameter für 2D ein. Sowie mindestens 3 Punkte für 3D.",
    "helmert2_title_card_2d" : "Beispiel 2D",
    "helmert2_title_card_3d" : "Beispiel 3D",
    "helmert2_title_card_info2d" : "Weitere Informationen 2D",
    "helmert2_title_card_info3d" : "Weitere Informationen 3D",
    "helmert2_title_card_restart" : "Berechnung neustarten",

    "helmert2_alert_danger" : "Es müssen mindestens 3 Punkte pro System vorhanden sein",

    "helmert2_point_source_card_title" : "Zu transformierende Punkte",
    "helmert2_point_source_card_alert1" : "Es werden nur Punkte ausgewählt, keine anderen Geometrien",
    "helmert2_point_source_card_alert2" : "Bei 2D werden nur die ersten beiden Koordinatenwerte der Punkte verwendet.",
    "helmert2_point_source_card_system1" : "System 1 wählen",
    "helmert2_point_source_card_system2" : "System 2 wählen",
    "helmert2_point_source_card_berechnung" : "Berechnung starten",

    "helmert2_solution_card_title" : "Ergebnis",
    "helmert2_solution_card_warning" : "Alle Angaben (Berechnungsergebnisse) sind trotz sorgfältigster Bearbeitung/Verarbeitung ohne Gewähr. Eine Haftung des BEV ist ausgeschlossen.",
    "helmert2_solution_card_apply" : "Übernehmen",

    "helmert2_choose_system_modal_titel" : "Wähle das erste System",
    "helmert2_choose_system_modal_point_list" : "Punktliste",
    "helmert2_choose_system_modal_saved_points" : "Gespeicherte Geometrien",
    "helmert2_choose_system_modal_tip" : "Wähle diese Geometrie aus!",
    "helmert2_choose_system_modal_select" : "Auswählen",

}