import React, { useState } from "react";
import BevModal from "components/BevModal";
import ImportFiles from "components/importFiles";
import { Button } from "@mui/material";
import { useTranslation } from "react-i18next";

export const TransformationPointInputModal = ({
  open,
  handleClose,
  hasFinish,
  title,
  handleLoadData,
  handleLoadDataAndFinish,
}) => {
  const [geoJson, setGeoJson] = useState(null);
  const { t } = useTranslation();

  const handleClickLoad = (e) => handleLoadData(geoJson);
  const handleClickLoadAndFinish = (e) => handleLoadDataAndFinish(geoJson);

  return (
    <BevModal title={title} open={open} onClose={handleClose} width={940}>
      <ImportFiles loadingDone={(geoJson) => setGeoJson(geoJson)} />
      <div
        style={{
          display: "flex",
          justifyContent: "flex-start",
          marginTop: "0.5em",
        }}
      >
        {hasFinish && (
          <Button
            variant={"contained"}
            color={"primary"}
            style={{ marginRight: "0.5em" }}
            disabled={!geoJson}
            onClick={handleClickLoadAndFinish}
          >
            {t("common:read_and_finish_btn")}
          </Button>
        )}
        <Button
          variant={hasFinish ? "outlined" : "contained"}
          color={"primary"}
          style={{ marginRight: "0.5em" }}
          disabled={!geoJson}
          onClick={handleClickLoad}
        >
          {t("common:read_btn")}
        </Button>
        <Button variant={"outlined"} color={"primary"} onClick={handleClose}>
          {t("common:cancel_btn")}
        </Button>
      </div>
    </BevModal>
  );
};
