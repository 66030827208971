
export const classes = {
  wrapper: {
    display: "flex",
    flexWrap: "wrap",
  },
  tableWrapper: {
    paddingTop: "48px",
  },
  gridWrapper: {
    padding: 0,
    maxHeight: "700px",
  },
  buttonMargin: {
    p: 3,
    mt: 3,
  },
  switchButtonWrapper: {
    width: "100%",
    position: "relative",
  },
  switchButtonLabel: {
    position: "absolute",
    left: 10,
    top: "-33px",
  },
  highlight: {
    fontWeight: "bold",
    color: "#E6320F",
  },
  hoverRow: {
    cursor: "pointer",
    "&:hover": {
      backgroundColor: "#ccc",
    },
    "&:hover td": {
      color: '#333',
    }
  },
  unSelected: {
    color: "#aaa",
  },
  unSelectedAvatar: {
    backgroundColor: '#999',
    color: ' #333',
  },
  toLongAvatar: {
    backgroundColor: "error.main",
    color: "error.contrastText",
  },
  toLong: {
    '& $greenAvatar': {
      backgroundColor: "error.main",
      color: "error.contrastText",
    },
    '& td': {
      fontWeight: 'bold',
      color: "error.main",
    }
  },
  mapWrapper: {
    position: "relative",
  },
  paramOverlay: {
    position: "absolute",
    bottom: t => t.spacing(1),
    right: t => t.spacing(1),
    backgroundColor: "rgba(255,255,255, 0.4)",
    p: 1,
    borderRadius: t => t.spacing(1),

    '&:hover': {
      backgroundColor: "rgba(255,255,255, 0.9)",
    }
  },
  paramsLableStyle: {
    fontWeight: "bold",
    fontSize: "0.9em",
    minWidth: 85,
    mr: 1,
  },
  paramDataStyle: {
    fontSize: "0.9em",
    pl: 1,
  },
  paramBoxStyle: {
    transition: "all ease 250ms",
    width: 200,
  },
  highlight: {
    fontWeight: "bold",
    color: "error.contrastText",//"#E6320F",
  },
  highlightBox: {
    backgroundColor: 'rgba(244, 67, 54, 0.6)',
    '&:hover': {
      backgroundColor: 'rgba(244, 67, 54, 0.9)',

    }
  },
  btnNextSuccess: {
    backgroundColor: "success.main",
    '&:hover': {
      backgroundColor: "success.dark",
    }
  },
  btnNextError: {
    backgroundColor: "error.light",
  },
  tabsStyle: {
    borderBottomColor: "secondary.main",
    borderBottomWidth: 1,
    borderBottomStyle: "solid",
  },
  yellowAvatar: {
    width: t => t.spacing(4),
    height: t => t.spacing(4),
    backgroundColor: '#fbc02d',
    color: '#333',
    fontSize: '0.8em',
    fontWeight: 'bold'
  },
  blueAvatar: {
    width: t => t.spacing(4),
    height: t => t.spacing(4),
    backgroundColor: '#303f9f',
    fontSize: '0.8em',
    fontWeight: 'bold'
  },
  greenAvatar: {
    width: t => t.spacing(4),
    height: t => t.spacing(4),
    backgroundColor: "success.main",
    fontSize: '0.8em',
    fontWeight: 'bold'
  },
  smallAvatar: {
    width: t => t.spacing(4),
    height: t => t.spacing(4),
  },
  root: {
    display: 'flex',
  },
  paper: {
    mr: 2,
  },
  messdatum: {
    fontSize: '0.75em',
    fontWeight: 'normal',
  },
  delta: {
    fontSize: '1rem',
    marginBottom: '-0.15rem',
  },
};