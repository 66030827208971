import {isValid, parse, parseISO, formatISO} from 'date-fns'

const coordinatesTypes = ["gk", "etrs89", "wgs84", "gk_calculated"];
export class BasePoint {
  punktnummer; // kaKvPunktNr
  kgNummer; // kaKvKgNr
  punktType; // kaKvPunktTyp (MP | FP | SO | etc)
  punktArt; // Kann nur T/TP oder E/EP annehmen (t|tp|e|ep|T|TP|E|EP)
  oek;
  nummer; // Fortlaufenden nummer des Punktes
  stabilisierung;
  source;

  gkX; //kaKvXgk;
  gkY; //kaKvYgk;
  gkHoeheAdria; //kaKvHoeheAdria

  etrs89X; // kaKvXetrs89
  etrs89Y; // kaKvYetrs89
  etrs89Z; // kaKvZetrs89
  etrs89Messdatum; // kaKvMessdatumEtrs89

  gkXCalculated;
  gkYCalculated;
  gkHoeheAdriaCalculated;

  wgs84Phi;
  wgs84Lam;
  wgs84Hoehe;

  residualsLage;
  residualsNorth;
  residualsEast;
  residualsAngle;
  residualsLength;
  residualsHeight;

  improvementsNorth;
  improvementsEast;

  isDeterminationSelected;
  isHeightSelected;
  isTransformationpoint;
  isMesspoint;

  constructor(point = {}) {
    //Deconstruct Point for number kg typ art oek stab name info
    const { punktnummer, kgNummer, punktType, punktArt, oek, nummer, stabilisierung } = point;
    //check if punktnummer exist
    if (!punktnummer || punktnummer === "") throw new Error("Es wird zumindestens eine Punktnummer benötigt");

    // Decunstuct punktnummer string for art, region, number,stab info;
    const [punktArtFromPunktnummer, regionFromPunktnummer, nrFromPunktnummer, stabilisierungFromPunktnummer] = this.splitName(punktnummer);

    // Type can only be given 
    this.punktType = this.parseString(punktType); // string

    // Check if given point has info of art an art is ep | e
    const isGivenTypeEp = this.isGivenTypeEp(punktArtFromPunktnummer ?? punktArt ?? "");
    // Short writting
    const pars = this.parseString;

    // Setter of the point data, the punktnummer is more important than the given attributes, given attributes are fallbacks
    this.punktArt = pars(punktArtFromPunktnummer) ?? pars(punktArt);
    this.kgNummer = isGivenTypeEp ? pars(regionFromPunktnummer) ?? pars(kgNummer) : pars(kgNummer);
    this.oek = isGivenTypeEp ? pars(oek) : pars(regionFromPunktnummer) ?? pars(oek);
    this.nummer = pars(nrFromPunktnummer) ?? pars(nummer);
    this.stabilisierung = pars(stabilisierungFromPunktnummer) ?? pars(stabilisierung);
    //Try to recreate punktnummer to check if every thing went fine. 
    this.punktnummer = this.createName(
      this.punktArt,
      this.isGivenTypeEp(this.punktArt) ? this.kgNummer : this.oek,
      this.nummer,
      this.stabilisierung,
      punktnummer
    )

    if (!this.punktnummer || this.punktnummer === "")
      throw new Error(
        "Ein punkt braucht eine Punktnummer um ihn zu identifizeren, dafür mindestens Punktnummer oder oek mit nummer angeben"
      );

    // Set other data of the point
    this.setData(point);
  }

  /**
   * Function to set multiple and individual Data with one call, can set every thing expect punktnummer, punkttype, punktnummertype, oek, nummer, stabilisierung.
   * @param {*} param0
   */
  setData({
    source,
    gkX,
    gkY,
    gkHoeheAdria,
    etrs89X,
    etrs89Y,
    etrs89Z,
    etrs89Messdatum,
    gkXCalculated,
    gkYCalculated,
    gkHoeheAdriaCalculated,
    wgs84Phi,
    wgs84Lam,
    wgs84Hoehe,
    residualsNorth,
    residualsLage,
    residualsEast,
    residualsAngle,
    residualsLength,
    residualsHeight,
    improvementsNorth,
    improvementsEast,
    isDeterminationSelected,
    isHeightSelected,
    isTransformationpoint,
    isMesspoint,
  } = {}) {
    this.source = this.parseString(source) ?? this.source ?? null; // string
    this.gkX = this.parseNumber(gkX) ?? this.gkX ?? null; // number
    if (this.gkX && this.gkX < 5e6) this.gkX += 5e6;
    this.gkY = this.parseNumber(gkY) ?? this.gkY ?? null; // number
    this.gkHoeheAdria = this.parseNumber(gkHoeheAdria) ?? this.gkHoeheAdria ?? null; // number
    this.etrs89X = this.parseNumber(etrs89X) ?? this.etrs89X ?? null; // number
    this.etrs89Y = this.parseNumber(etrs89Y) ?? this.etrs89Y ?? null; // number
    this.etrs89Z = this.parseNumber(etrs89Z) ?? this.etrs89Z ?? null; // number
    this.etrs89Messdatum = this.parseString(etrs89Messdatum) ?? this.etrs89Messdatum ?? null; // string

    if(isValid(parse(this.etrs89Messdatum?.trim(), "dd.MM.yyyy", new Date()))) this.etrs89Messdatum = formatISO(parse(this.etrs89Messdatum?.trim(), "dd.MM.yyyy", new Date()));
    else if(isValid(parse(this.etrs89Messdatum?.trim(), "yyyy-MM-dd", new Date()))) this.etrs89Messdatum = formatISO(parse(this.etrs89Messdatum?.trim(), "yyyy-MM-dd", new Date()));
    else if(isValid(parseISO(this.etrs89Messdatum?.trim()))) this.etrs89Messdatum = formatISO(parseISO(this.etrs89Messdatum?.trim()));
    else this.etrs89Messdatum = null;

    this.gkXCalculated = this.parseNumber(gkXCalculated) ?? this.gkXCalculated ?? null; // number
    this.gkYCalculated = this.parseNumber(gkYCalculated) ?? this.gkYCalculated ?? null; // number
    this.gkHoeheAdriaCalculated =
      this.parseNumber(gkHoeheAdriaCalculated) ?? this.gkHoeheAdriaCalculated ?? null; // number
    this.wgs84Phi = this.parseNumber(wgs84Phi) ?? this.wgs84Phi ?? null; // number
    this.wgs84Lam = this.parseNumber(wgs84Lam) ?? this.wgs84Lam ?? null; // number
    this.wgs84Hoehe = this.parseNumber(wgs84Hoehe) ?? this.wgs84Hoehe ?? null; // number
    this.residualsLage = this.parseNumber(residualsLage) ?? this.residualsLage ?? null; // number
    this.residualsNorth = this.parseNumber(residualsNorth) ?? this.residualsNorth ?? null; // number
    this.residualsEast = this.parseNumber(residualsEast) ?? this.residualsEast ?? null; // number
    this.residualsAngle = this.parseNumber(residualsAngle) ?? this.residualsAngle ?? null; // number
    this.residualsLength = this.parseNumber(residualsLength) ?? this.residualsLength ?? null; // number
    this.residualsHeight = this.parseNumber(residualsHeight) ?? this.residualsHeight ?? null; // number
    this.improvementsNorth = this.parseNumber(improvementsNorth) ?? this.improvementsNorth ?? null; // number
    this.improvementsEast = this.parseNumber(improvementsEast) ?? this.improvementsEast ?? null; // number
    // TODO add check for undefinded and give old data
    this.isDeterminationSelected = isDeterminationSelected ?? this.isDeterminationSelected ?? false; //boolean
    this.isHeightSelected = isHeightSelected ?? this.isHeightSelected ?? false; //boolean
    this.isTransformationpoint = isTransformationpoint ?? this.isTransformationpoint ?? false; //boolean
    this.isMesspoint = isMesspoint ?? this.isMesspoint ?? false; //boolean
  }

  /**
   * Parse the given parameter to a number if it is an number else return null;
   * @param {*} num
   */
  parseNumber(num) {
    if (isNaN(num)) return null;
    return parseFloat(num);
  }

  /**
   * Parse the given parameter to an string
   * @param {*} num
   */
  parseString(str) {
    return !!str ? `${str}` : null;
  }

  /**
   * This function Creates a Punktnummer or Name from 4 Parameters and if some of them is missing the fallback will be used
   * @param {*} type
   * @param {*} region
   * @param {*} nr
   * @param {*} stabilisierung
   * @param {*} fallback
   */
  createName(type, region, nr, stabilisierung, fallback) {
    if (!type || !region || !nr) return fallback;
    if (!!type && (type.toLowerCase() === "e" || type.toLowerCase() === "ep"))
      return `${type} ${region}-${nr}${stabilisierung ?? ""}`;
    if (!!type && (type.toLowerCase() === "t" || type.toLowerCase() === "tp"))
      return `${type} ${nr}-${region}${stabilisierung ?? ""}`;
    return fallback;
  }

  /**
   * This function trys to Splitt the given string after the Punktnummern format and returns the data as an Array
   * @param {string} name
   */
  splitName(name) {
    const match_tp = name?.match(/^(T|t|tp|TP)?\s?\-?\s?0*(\d{1,4})\s?\-\s?(\d+)(.*)$/);
    const match_ep = name?.match(/^(E|e|ep|EP)?\s?\-?\s?(\d{5})\s?\-?\s?0*(\d+)(.*)$/);

    if (!match_tp && !match_ep) return [null, null, null, null];
    const clean = this.emptyStringToNull;

    if (match_tp) {
      return [clean(match_tp[1] ?? "T"), clean(match_tp[3]), clean(match_tp[2]), clean(match_tp[4])];
    }
    return [clean(match_ep[1] ?? "E"), clean(match_ep[2]), clean(match_ep[3]), clean(match_ep[4])];
  }

  /**
   * This function checks if the gien string is empty and if then it returns null
   * @param {string} str
   */
  emptyStringToNull(str) {
    return str === "" ? null : str;
  }

  /**
   * This functions takes improvements and redisuals and sets the date of this Point
   * @param {Object} improvements point data
   * @param {Object} residuals point data
   */
  writeImprovementsAndResiduals(improvements, residuals) {
    this.improvementsEast = improvements?.east ?? null;
    this.improvementsNorth = improvements?.north ?? null;
    if (residuals) {
      this.residualsHeight = residuals.height;
      this.residualsLage = Math.sqrt(Math.pow(residuals.north, 2) + Math.pow(residuals.east, 2));
      this.residualsNorth = residuals.north * 1000;
      this.residualsEast = residuals.east * 1000;
      this.residualsAngle = Math.atan2(this.residualsNorth, this.residualsEast);
      this.residualsLength =
        parseInt(Math.sqrt(Math.pow(this.residualsNorth, 2) + Math.pow(this.residualsEast, 2)), 10) * 2;
      return;
    }
    this.residualsLage = null;
    this.residualsNorth = null;
    this.residualsEast = null;
    this.residualsAngle = null;
    this.residualsLength = null;
    this.residualsHeight = null;
  }

  writeCoordinatesFromArray(array, coordinatesType) {
    if (coordinatesTypes.indexOf(coordinatesType) === -1) {
      throw new Error("coordinatesType must be on of these ['gk','etrs89','wgs84','gk_calculated']");
    }
    if (!Array.isArray(array) || array.length < 2) {
      throw new Error("array must be an Array with 2-3 numbers");
    }
    if (coordinatesTypes === "gk") {
      this.gkY = this.parseNumber(array[0]);
      this.gkX = this.parseNumber(array[1]) ? (array[1] < 5e6 ? array[1] + 5e6 : array[1]) : null;
      this.gkHoeheAdria = this.parseNumber(array[2]);
      return;
    }
    if (coordinatesTypes === "etrs89") {
      this.etrs89X = this.parseNumber(array[0]);
      this.etrs89Y = this.parseNumber(array[1]);
      this.etrs89Z = this.parseNumber(array[2]);
      return;
    }
    if (coordinatesTypes === "wgs84") {
      this.wgs84Phi = this.parseNumber(array[0]);
      this.wgs84Lam = this.parseNumber(array[1]);
      this.wgs84Hoehe = this.parseNumber(array[2]);
      return;
    }
    if (coordinatesTypes === "gk_calculated") {
      this.gkYCalculated = this.parseNumber(array[0]);
      this.gkXCalculated = this.parseNumber(array[1]) ? (array[1] < 5e6 ? array[1] + 5e6 : array[1]) : null;
      this.gkHoeheAdriaCalculated = this.parseNumber(array[2]);
      return;
    }
  }

  /**
   * Checks if given Type is 'Einschaltpunkt'
   * @param {*} type
   */
  isGivenTypeEp(type) {
    return type && (type.toLowerCase() === "e" || type.toLowerCase() === "ep");
  }
}
