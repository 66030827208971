import React, { useEffect, useState } from 'react';
import { PointSelection } from './PointSelection';
import { TitleCardCadastre } from './TitleCard';
import {inject, observer} from 'mobx-react'
import { KatasterExample } from 'common/modeExamples';
import {useSelector} from 'react-redux';
import {useLocation} from 'react-router-dom';
import { fromLonLat } from 'ol/proj';
import BevSolutionCard from 'components/BevSolutionCard';
import {useTranslation} from 'react-i18next';
import { toast } from 'react-toastify';


const Cadastre = inject("store")(observer(({store}) => {
    const {search} = useLocation();
    const {t} = useTranslation();
    const config = useSelector(state => state.auth.config);
    const [showSolution, setShowSolution] = useState(false);

    useEffect(() => {
        store.kataster.setConfig(config);
      }, [config]);

    useEffect(() => {
        const query = new URLSearchParams(search);
        const center = query.get('center');
        if(!center) return;
        try{
            const coord = JSON.parse(center);
            if(Array.isArray(coord)){
                store.kataster.selectPointsAroundCoord(fromLonLat(coord));
            }
        }catch(e){
            console.error(e)
            toast.info( t('cadastre:toaster_url_center_error'), { autoClose: 4000 });
        }
    }, [search])

    const loadExample = () => {
        store.kataster.clearStore();
        store.kataster.setHoldDetermination(true);
        store.kataster.setPointsKoordinatsFromGeoJson('etrs', KatasterExample.etrs);
        store.kataster.setPointsKoordinatsFromGeoJson('mgi', KatasterExample.mgi);
        store.kataster.setPointsKoordinatsFromGeoJson('etrs', KatasterExample.transformationsGeoJson, true, true);
        store.kataster.setHoldDetermination(false);
    };

    const clearPage = () => {
        store.kataster.clearStore();
    };

    const toggleSolution = () => {
        setShowSolution(true);
    }

    return <div>
        <TitleCardCadastre loadExample={loadExample} clearPage={clearPage} />
        <PointSelection toggleSolution={toggleSolution} />
        <BevSolutionCard
            isDisplayed={store?.kataster?.pointList?.filter(store.kataster.filterIsTransformationPoint).length > 0}
            isOpen={showSolution}
            isComputed
            hideMap
            toggleOpen={() => setShowSolution(!showSolution)}
            solution={store.kataster.getGeoJsonFromPointList(
                [
                    store.kataster.filterIsTransformationPoint,
                ],
                "gk_calculated"
              )}
        />
    </div>
}))

export default Cadastre;