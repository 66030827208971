import {useState} from 'react';
import { Button, ButtonGroup } from "@mui/material";
import LayersIcon from '@mui/icons-material/Layers';
import CloseIcon from '@mui/icons-material/Close';


export const OverlayLayerSwitcher = ({ handleSwitchLayerTo, selectedMap }) => {
    const [open, setOpen] = useState(true);

    const switchLayerTo = (layer) => () => {
        setOpen(false);
        handleSwitchLayerTo(layer)
    }

    return <div style={{
        position: 'absolute',
        zIndex: 1,
        top: 8,
        right: 8,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    }}>
        <ButtonGroup style={{ padding: 8, backgroundColor: "rgba(255,255,255, 0.5)" }}>
            {open && <Button disabled={selectedMap === "kataster"} size="small" variant="outlined" color="primary" onClick={switchLayerTo("kataster")} >Kataster</Button>}
            {open && <Button disabled={selectedMap === "orthoKataster"} size="small" variant="outlined" color="primary" onClick={switchLayerTo("orthoKataster")} >Kataster + Orthofoto</Button>}
            {open && <Button disabled={selectedMap === "ortho"} size="small" variant="outlined" color="primary" onClick={switchLayerTo("ortho")} >Orthofotos</Button>}
            {open && <Button disabled={selectedMap === "amap"} size="small" variant="outlined" color="primary" onClick={switchLayerTo("amap")} >Map</Button>}
            {open && <Button disabled={selectedMap === "baseMap"} size="small" variant="outlined" color="primary" onClick={switchLayerTo("baseMap")} >Base-Map</Button>}
            {open && <Button disabled={selectedMap === "osm"} size="small" variant="outlined" color="primary" onClick={switchLayerTo("osm")} >Open Street Map</Button>}
            <Button size="small" variant="outlined" color="primary" onClick={() => setOpen(b => !b)} >
                {open ? <CloseIcon /> : <LayersIcon />}
            </Button>
        </ButtonGroup>
    </div>
}