import * as actionTypes from '../actions/actionTypes';
import { updateObject } from 'common/utility';

const initialState = {
    input:{
        etrs: {},
        mgi: {},
    },
    send:{
        etrs: {},
        mgi: {}
    },
    passPoints:{
        etrs: {},
        mgi: {}
    },
    extraPoints:{},
    transformationsGeoJson: {},
    innerGeometry: false,
    withHeight: false,
    solution: {},
    selected: [],
    params: {
        scale: null,
        centroid_east: null,
        rotation:null,
        translation_east: null,
        translation_north: null,
        centroid_north: null,
    },
    sigmas:{
        scale: null,
        centroid_east: null,
        rotation: null,
        translation_east: null,
        translation_north: null,
        centroid_north: null,
    },
    residuals:[],
    improvements:[],
    determinationReportKey: "",
    transformationReportKey: "",
    meridian: "",
}

const updateParam = (state, action) => {
  return updateObject(state, {
      [action.param]: JSON.parse(JSON.stringify(action.value)),
  });
}

const clearPage = (state) => {
    return updateObject(state,initialState);
}

const reducer = ( state = initialState, action) => {
    switch ( action.type ) {
        case actionTypes.CADASTRE_UPDATE_PARAMETER: return updateParam( state, action );
        case actionTypes.CADASTRE_CLEAR_PAGE: return clearPage(state);
        default:
            return state;
    }
}

export default reducer;
