import parseCSV from './csvParser';
import { cvsEgaError } from './parserErrors';
import * as constants from '../constants';

export default function parseEgaCSV(file) {
    const inputs = getAllInputsFromFile(file);

    if(inputs.length > 1 && !hasSameCrsFrame(inputs)) throw new cvsEgaError(constants.ERROR_IMPORT_CSVEGA_WRONG_TYPE);
    let linesObjectArray = [];
    let keys = null;
    //Generate LinesObjectArray
    for( let fileLineArray of inputs){
        keys = fileLineArray.splice(0,1)[0].split(";");
        const coordLines = fileLineArray.splice(0);
        linesObjectArray = linesObjectArray.concat(generateLinesObjectArray(coordLines,keys))
    }
    const filetype = getFileType(keys);

    if( filetype !== 'bevfile') throw new cvsEgaError(constants.ERROR_IMPORT_CSVEGA_WRONG_FILE_TYPE);

    const crs = getCRSFrame(keys);
    let x = {type: "FeatureCollection"};

    const features = fromLinesToFeatures(linesObjectArray, crs);
    if(crs === "MGI"){
        const prime_meridian = getPrimeMeridian(linesObjectArray)
        x.features = features;
        x.crs = { type: 'BEV', name: '', coordType: 'mgi', properties: {frame: "301", ellipsoid: "EPSG::7004", prime_meridian: "EPSG::8901", projection: "", epoch: 2002.56}};
        x.crs.properties.projection = generateProjection(prime_meridian);
    }else if(crs === "ETRS"){
        x.features = features;
        x.crs = { type: 'BEV', name: '', coordType: 'etrs', properties: {frame: "209", ellipsoid: "", prime_meridian: "", projection: "", epoch: 2002.56 }};
    }else {
        throw new cvsEgaError(constants.ERROR_IMPORT_CSVEGA_WRONG_TYPE);
    }
    return x;
}

function getAllInputsFromFile (file) {
    let splittedFile = []
    const stringLines=file.split("\n");
    for(let i = 1; i < count(file); i++){
        const index = stringLines.findIndex((element,index) => { return index !== 0 && element.match(/PUNKTTYP;/g)});
        splittedFile.push(stringLines.splice(0,index));
    }
    splittedFile.push(stringLines.splice(0));

    return splittedFile
}

function generateLinesObjectArray(lines, keys) {
    let linesObjectArray = [];
    for(let selectedStringLine of lines){
        if(selectedStringLine.replace(/\n/g,"") === "") continue;
        let lineObject = {};
        let lineArray = selectedStringLine.split(';');

        if(lineArray.length === 1 || lineArray[0] === "") continue;

        keys.forEach((keyValue, keyIndex) => {
            lineObject[keyValue] = lineArray[keyIndex];
        });
        linesObjectArray.push(lineObject);

    }
    return linesObjectArray;
}

function getFileType(keys){
    return keys.indexOf('PUNKTTYP')>-1 && 
        keys.indexOf('PUNKTNUMMER')>-1 && 
        keys.indexOf('OeK50_BMN_NR')>-1 && 
        keys.indexOf('KG_NUMMER')>-1 && 
        keys.indexOf('KENNZEICHEN')>-1 ? 
        "bevfile" : "unknown"
}

function getPunktart(lineObject){
    if(lineObject.PUNKTTYP !== 'TP' && lineObject.PUNKTTYP !== 'EP') throw new cvsEgaError(constants.ERROR_IMPORT_CSVEGA_WRONG_POINT_TYPE);
    return lineObject.PUNKTTYP;
}

function getCRSFrame(keys){
    return keys.indexOf('X')>-1 ? 'ETRS' : 'MGI';
}

function generatePunktnummer(punktart, line){
    return punktart === "TP" ? 
            `${punktart} ${checkString(line.PUNKTNUMMER)}-${checkString(line.OeK50_BMN_NR)}${checkString(line.KENNZEICHEN)}` :
            `${punktart} ${checkString(line.KG_NUMMER)}-${checkString(line.PUNKTNUMMER)}${checkString(line.KENNZEICHEN)}`
}

function checkNumber(number,returnZero){
    if(!isNaN(number.replace(',','.')) && returnZero){
        return number !== "" ? number : 0;
    }else if(!isNaN(number.replace(',','.')) && number !== ""){
        return number
    }else{
        throw new cvsEgaError(constants.ERROR_IMPORT_CSVEGA_WRONG_NUMBER_FORMAT);
    }
}

function checkString(string,returnEmpty){
    if(!!string && string !== ""){
        return string;
    }else if(!!string && returnEmpty){
        return "";
    }else{
        throw new cvsEgaError(constants.ERROR_IMPORT_CSVEGA_EMPTY_STRING);
    }
}

function getPrimeMeridian(linesObjectArray){
    return linesObjectArray[0].MERIDIAN;
}

function generateProjection (prime_meridian){
    switch (prime_meridian) {
        case 'M28': return 'BEV::10028';
        case 'M31': return 'BEV::10031';
        case 'M34': return 'BEV::10034';
        default: throw new cvsEgaError(constants.ERROR_IMPORT_CSVEGA_WRONG_MERIDIAN);
    }
}

function hasSameCrsFrame (splittedFile) {
    let lastFrame = "";
    for(let file of splittedFile){
        if(lastFrame === "") lastFrame = getCRSFrame(file[0].split(';'));
        if(lastFrame !== getCRSFrame(file[0].split(';'))) return false;
    }
    return true;
}

const count = (str) => {
    const re = /PUNKTTYP;/g
    return ((str || '').match(re) || []).length
  }


  const fromLinesToFeatures = (lines, frame) => {
      return lines.map( line => fromLineToFeature(line, frame) );
  }

  const fromLineToFeature = (line, frame) => {
      const coords = frame === "MGI" ? [parseFloat(checkNumber(line.RECHTSWERT)), parseFloat(checkNumber(line.HOCHWERT)), parseFloat(checkNumber(line.HOEHE,true))] : [parseFloat(checkNumber(line.X)), parseFloat(checkNumber(line.Y)), parseFloat(checkNumber(line.Z))];
      return {
        type: "Feature",
        geometry: {
          type: "Point",
          coordinates: coords,
        },
        properties: {
          name: generatePunktnummer(checkString(line.PUNKTTYP), line),
          art: line.PUNKTTYP,
          messdatum: line.MESSDATUM,
          type: 'FP',

        },
      };
  }

// PUNKTTYP;OeK50_BMN_NR;KG_NUMMER;PUNKTNUMMER;ORDNUNG;KENNZEICHEN;STABART;AUFLAGE;PUNKTNAME;HINWEIS;X;Y;Z;mX;mY;mZ;vX;vY;vZ;GEODATUM;EPOCHE;EPSG_XYZ;MESSDATUM;HERKUNFTSOPERAT;KOORD_BEST_XYZ;BREITE;LAENGE;HOEHE;EPSG_GEOGR;UNDULATION_GRS80;UNDULATION_BESSEL;RW;HW;EPSG_PROJ
// PUNKTTYP;OeK50_BMN_NR;KG_NUMMER;PUNKTNUMMER;ORDNUNG;KENNZEICHEN;STABART;AUFLAGE;PUNKTNAME;HINWEIS;X;Y;Z;mX;mY;mZ;vX;vY;vZ;GEODATUM;EPOCHE;EPSG_XYZ;MESSDATUM;HERKUNFTSOPERAT;KOORD_BEST_XYZ;BREITE;LAENGE;HOEHE;EPSG_GEOGR;UNDULATION_GRS80;UNDULATION_BESSEL;RW;HW;EPSG_PROJ
// EP;183;73204;13;6;E1;EP-MARKE;2;  ;4247449.836;1041805.900;4628710.904;0.014;0.016;0.038;0.000;0.000;0.000;ETRS89/AUSTRIA 2002;2002.50;4936;23.09.2009;730909;mittels APOS-RTK bestimmt;46.8169125496;13.7813247107;1167.130;4937;49.7530;2.2140;407033.92;5185539.57;25833;
// EP;183;73204;45;6;E1;EP-MARKE;2;  ;4247526.426;1041749.068;4628559.703;0.014;0.016;0.038;0.000;0.000;0.000;ETRS89/AUSTRIA 2002;2002.50;4936;22.09.2009;730909;mittels APOS-RTK bestimmt;46.8155828927;13.7803625655;1098.520;4937;49.7440;2.2040;406958.23;5185392.95;25833;
// EP;183;73204;65;6;E1;EP-MARKE;1;  ;4247547.854;1041998.326;4628490.044;0.014;0.016;0.038;0.000;0.000;0.000;ETRS89/AUSTRIA 2002;2002.50;4936;22.09.2009;730909;mittels APOS-RTK bestimmt;46.8146282556;13.7834671580;1102.608;4937;49.7430;2.2040;407193.43;5185283.20;25833;
// EP;183;73204;66;6;E1;EP-MARKE;1;  ;4247608.621;1042124.481;4628380.526;0.014;0.016;0.038;0.000;0.000;0.000;ETRS89/AUSTRIA 2002;2002.50;4936;22.09.2009;730909;mittels APOS-RTK bestimmt;46.8133700187;13.7848825833;1083.714;4937;49.7340;2.1950;407299.24;5185141.71;25833;
// TP;183;73204;173;5;A1;KT-STEIN/STEIN OBERFL�CHE;2;AIGEN;E ;4247572.605;1041531.864;4628649.478;0.003;0.003;0.005;0.000;0.000;0.000;ETRS89/AUSTRIA 2002;2002.50;4936;01.01.2004;AGREF;GPS Grundlagennetz 24h Messung;46.8161805856;13.7774547835;1159.270;4937;49.7440;2.2030;406737.45;5185462.82;25833;