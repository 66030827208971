import React, { Component } from 'react';
import PropTypes from 'prop-types';

import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import { Grid, Card, CardContent, CardHeader, Divider } from "@mui/material";

class BevCard extends Component {
  static propTypes = {
    title: PropTypes.string,
    open: PropTypes.bool,
    show: PropTypes.bool,
    children: PropTypes.node,
  }
  static defaultProps = {
    title: 'noTitle',
    open: true,
    show: true,
  }
  state = {
    open: this.props.open,
    show: this.props.show,
  }

  componentDidUpdate(prevProps, prevState) {
    const { show, open } = this.props
    if (prevProps.show !== show) this.setState({ show });
    if (prevProps.open !== open) this.setState({ open });
  }

  toggleOpen = () => {
    if (this.props.hasOwnProperty('toggleOpen')) this.props.toggleOpen();
    this.setState({ open: !this.state.open })
  }

  getExpandIcon = (isOpen) => {
    return isOpen ?
      <ExpandLessIcon style={{ marginTop: '10px' }} /> :
      <ExpandMoreIcon style={{ marginTop: '10px' }} />
  }

  getTitleElement = (title) => {
    return <span style={{ fontWeight: 'bold', fontSize: '16px' }} >{title}</span>
  }

  render() {
    const { title, children } = this.props;
    const { show, open } = this.state
    const style = { minWidth: '45%', flexGrow: '2', margin: '0' };
    const cardContentPadding = this.props.padding !== null && this.props.padding !== undefined ? this.props.padding : 16;

    return <div style={style}>
      {show ?
        <Grid item xs={12} sm={12}>
          <Card style={{ marginBottom: 20 }}>
            <CardHeader
              sx={{
                backgroundColor: "#eee",
                cursor: "pointer",
                paddingTop: '4px',
                paddingBottom: '4px'
              }}
              onClick={this.toggleOpen}
              title={this.getTitleElement(title)}
              action={this.getExpandIcon(open)} />
            <Divider />
            {open &&
              <CardContent style={{ padding: cardContentPadding }}>{children}</CardContent>
            }
          </Card>
        </Grid> :
        null
      }
    </div>;
  }
}

export default BevCard;
